/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import compose from "recompose/compose";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import moment from "moment";
import { db, firebase } from 'firebase/client.js'
import { Editor } from "react-draft-wysiwyg";
import { EditorState, ContentState, createWithContent, convertToRaw, convertFromRaw, convertFromHtml } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import dotenv from 'dotenv';
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import TextField from "@material-ui/core/TextField";
import { FormControl, Input, InputLabel } from "@material-ui/core";



// @material-ui/icons
import Check from "@material-ui/icons/Check";
import Close from "@material-ui/icons/Close";


// core components
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardText from "components/Card/CardText";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import GridContainer from "components/Grid/GridContainer";
import GridItem from 'components/Grid/GridItem.js';
import CardFooter from "components/Card/CardFooter";
import DraggableCardContainer from "components/Draggable/DraggableCardContainer.js"

//style
import Style from "assets/jss/material-dashboard-pro-react/views/registerPageStyle";
import { set } from "immutable";
import { EmojiEvents } from "@material-ui/icons";

require('dotenv').config();
export function InvoiceForm(props) {
    const { classes, apiKey } = props;
    const docID = firebase.auth().currentUser.uid;
    const [checked, setChecked] = useState([24, 22], false);
    const [editorState1, setEditorState1] = useState(EditorState.createEmpty());
    const [editorState2, setEditorState2] = useState(EditorState.createEmpty());
    const [info, setInfo] = useState({
        comments: '',
        date: '',
        due: '',
        oamount: '',
        discounts: '',
        minTotal: 0,
        status: 'Draft',
        partPay: false,
        dueError: '',
        minError: ''
    });
    // console.log("info",info)
    const [files, setFiles] = useState({
        fileName: '',
        fileError: ''
    });
    const [counter, setCounter] = useState(1);
    const [itemCounter, setItemCounter] = useState(1);
    const [subTotal, setSubTotal] = useState(0)
    const [amt, setAmt] = useState([0]);
    const [emailArr, setEmailArr] = useState([{
        id: counter,
        email: '',
        emailErr: ''
    },]);
    const [itemArr, setItemArr] = useState([{
        itemName: '',
        quantity: '',
        price: '',
        description: '',
        id: itemCounter,
        itemNameErr: '',
        quantityErr: '',
        priceErr: '',
    },])
    const [plainText, setPlainText] = useState([])
    const [statusCode, setStatusCode] = useState(0)

    // const editorRef = useRef(null)
    // console.log('itemArritemArritemArr', (itemArr))

    let total = subTotal + (Number(info.oamount) - Number(info.discounts))
    let totalMinusPartPay = total - Number(info.minTotal)

    const handleEmail = (e, i) => {
        console.log("counter", i)
        const arr = [...emailArr];
        arr[i][e.target.id] = e.target.value;
        setEmailArr(arr);
    }

    const handleItem = (e, i, state) => {
        let sub = 0;
        // setEditorState1(state)
        const arr = [...itemArr];
        arr[i][e.target.id] = e.target.value;
        // arr[i]["description"] = draftToHtml(convertToRaw(editorState1.getCurrentContent()))
        setItemArr(arr)
        const arr2 = [...amt]
        arr2[i] = (itemArr[i].quantity * itemArr[i].price)
        setAmt(arr2);

        for (let x = 0; x < amt.length; x++) {
            sub += arr2[x]
        }
        setSubTotal(sub)
    }

    const updateText = (state, i) => {
        setEditorState1(state);
        const data = [...itemArr]
        data[i]["description"] = draftToHtml(convertToRaw(editorState1.getCurrentContent()));
        setPlainText(editorState1.getCurrentContent().getPlainText())
        // console.log("raw content", editorState1.getCurrentContent().getPlainText())
        setItemArr(data)
    }
    // console.log("desc", desc)

    const updateComments = async (state) => {
        await setEditorState2(state);
        let data = draftToHtml(convertToRaw(editorState2.getCurrentContent()));
        setInfo({ ...info, comments: data })
    }

    const handleCount = () => {
        setEmailArr([...emailArr, { id: counter + 1, email: '', emailErr: '' }])
        setCounter(counter + 1)
    }

    const handleItemCount = () => {
        // console.log("itemcounter: ", itemCounter)
        setItemArr([...itemArr, { itemName: '', quantity: '', price: '', description: '', id: itemCounter + 1, itemNameErr: '', quantityErr: '', priceErr: '' }])
        setItemCounter(itemCounter + 1)
        setAmt([...amt, 0])
        // setEditorState1(EditorState.createEmpty())
    }

    const deleteCount = (e, index) => {
        // console.log("index check: ", index)
        let arr = [...emailArr];
        arr.splice(index, 1);
        setEmailArr(arr)

    }

    // console.log('itemArritemArr', itemArr)

    const deleteItemCount = (e, index) => {
        let arr = [...itemArr]
        // console.log("index", index)
        arr.splice(index, 1)
        // console.log("after splice: ", arr)
        setItemArr(arr)

        let arr2 = [...amt];
        arr2.splice(index, 1)
        setAmt(arr2)

        let sub = subTotal
        sub -= amt[index]
        setSubTotal(sub)
    }

    const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    const handleToggle = value => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }
        setChecked(newChecked);
        if (newChecked.length === 3) {
            setInfo({ ...info, partPay: true })
        }
        else {
            setInfo({ ...info, partPay: false })
        }
    };

    const handleChange = (e) => {
        setInfo({
            ...info,
            [e.target.id]: e.target.value
        })
    }

    const onFileChange = async (e) => {
        setFiles({
            ...files,
            [e.target.id]: e.target.files[0]
        })
    }
    const validate = () => {
        let dueError = '';
        let minError = '';
        let fileError = '';
        let isValid = true;

        for (let i = 0; i < emailArr.length; i++) {
            const copyArr = [...emailArr]
            if (!emailRegex.test(emailArr[i].email)) {
                copyArr[i]["emailErr"] = "invalid input."
                isValid = false;
            }
            else {
                copyArr[i]["emailErr"] = ""
            }
            setEmailArr(copyArr)

        }

        for (let i = 0; i < itemArr.length; i++) {
            const copyArr = [...itemArr]
            if (!itemArr[i].itemName) {
                copyArr[i]["itemNameErr"] = "Invalid input."
                isValid = false;
            }
            else {
                copyArr[i]["itemNameErr"] = ""
            }
            if (!itemArr[i].quantity) {
                copyArr[i]["quantityErr"] = "Invalid input."
                isValid = false;
            }
            else {
                copyArr[i]["quantityErr"] = ""
            }
            if (!itemArr[i].price) {
                copyArr[i]["priceErr"] = "Invalid input."
                isValid = false;
            }
            else {
                copyArr[i]["priceErr"] = ""
            }
            setItemArr(copyArr)
        }

        if (!files.fileName) {
            console.log(files.fileName)
            console.log("no file chosen")
            fileError = "Error! No file selected";
            setFiles({ ...files, fileError });
            return false;
        }
        else {
            fileError = "";
            setFiles({ ...files, fileError });
        }

        if (info.partPay === true && !info.minTotal) {
            console.log("partpay error")
            minError = "Invalid input."
            setInfo({
                ...info,
                minError
            })
            return false
        }
        if (info.partPay === true && info.minTotal > total) {
            console.log("partpay error")
            minError = "Partial payment is greater than total."
            setInfo({
                ...info,
                minError
            })
            return false
        }

        if (!info.due) {
            console.log("duedate error")
            dueError = "Invalid input."
            setInfo({
                ...info,
                dueError
            })
            return false
        }
        else {
            dueError = ""
            setInfo({
                ...info,
                dueError
            })
        }

        var current = moment();
        var dueDate = moment(info.due)
        if (dueDate < current) {
            console.log("duedate has to be after")
            dueError = "Due Date has to be after today's date."
            setInfo({
                ...info,
                dueError
            })
            return false
        }
        else {
            dueError = "";
            setInfo({
                ...info,
                dueError
            })
        }

        if (isValid === false) {
            return false
        }

        return true;
    }

    const saveForm = async (e) => {
        e.preventDefault();
        console.log("email value check: ", emailArr)
        console.log("itemarr", itemArr)
        const isValid = validate();
        var request = require('request');
        let itemNameArr = []
        let itemQuantityArr = []
        let itemPriceArr = []
        let itemDescArr = []
        for (let i = 0; i < itemArr.length; i++) {
            itemNameArr.push(itemArr[i].itemName)
            itemQuantityArr.push(itemArr[i].quantity)
            itemPriceArr.push(itemArr[i].price)
            itemDescArr.push(itemArr[i].description)
        }

        let emailList = []

        for (let i = 0; i < emailArr.length; i++) {
            emailList.push(emailArr[i].email)
        }

        let items = {
            Item: itemNameArr,
            Item_Quantity: itemQuantityArr,
            Item_Price: itemPriceArr,
            Item_Description: itemDescArr
        }
        let data =
        {
            Email: emailList,
            Item_Info: items,
            Payment_ID: "N/A",
            Payment_Status: "Sent",
            Date: moment().format("MM/DD/YYYY"),
            dueDate: moment(info.due).format("MM/DD/YYYY"),
            Invoice_ID: "Placeholder",
            partialPayment: {
                bool: info.partPay,
                total: info.minTotal
            },
            Amounts: amt,
            Total: {
                subTotal: subTotal,
                discounts: info.discounts,
                otherAmounts: info.oamount,
                total: total
            },
            Comments: info.comments,
            uID: docID
        }
        if (isValid) {
            const currentDate = moment().format("MM/DD/YYYY H:mm")
            const epoch = moment(currentDate, "M/D/YYYY H:mm").valueOf()
            const dueDate = moment(info.due).format("MM/DD/YYYY H:mm")
            const epoch2 = moment(dueDate, "M/D/YYYY H:mm").valueOf()
            // console.log("normal date format: ", moment().format("LLLL"))
            // console.log("epoch time test: ", moment(epoch)._i)
            // console.log("plain text", plainText)
            // console.log("works")
            // console.log("itemNamearr",typeof(itemNameArr))
            // console.log("itemdescarr",typeof(itemDescArr))
            // for (let i = 0; i < itemArr.length; i++) {
            //     request({
            //         method: 'POST',
            //         url: 'https://protected-shelf-37912.herokuapp.com/https://api.clickup.com/api/v2/list/163548371/task',
            //         // url: 'https://api.clickup.com/api/v2/list/163548371/task?archived=false',
            //         headers: {
            //             'Authorization': `${apiKey}`,
            //             'Content-Type': 'application/json',
            //             "Access-Control-Allow-Headers": "Content-Type",
            //             "Access-Control-Allow-Origin": "*",
            //             "Access-Control-Allow-Methods": "OPTIONS,POST,GET"
            //         },
            //         body: `{  \"name\": \"${itemArr[i].itemName}\",  \"description\": \"${plainText}\",  \"assignees\": [      ],  \"tags\": [    \"tag name 1\"  ],  \"status\": \"Open\",  \"priority\": 3,  \"due_date\": ${moment(epoch2)._i},  \"due_date_time\": false,  \"time_estimate\": 8640000,  \"start_date\": ${moment(epoch)._i},  \"start_date_time\": false,  \"notify_all\": false,  \"parent\": null,  \"links_to\": null,  \"check_required_custom_fields\": true,  \"custom_fields\": [    {      \"id\": \"0a52c486-5f05-403b-b4fd-c512ff05131c\",      \"value\": 23    },    {      \"id\": \"03efda77-c7a0-42d3-8afd-fd546353c2f5\",      \"value\": \"Text field input\"    }  ]}`

            //     }, async function (error, response, body) {
            //         console.log('error:', error); // Print the error if one occurred
            //         console.log('statusCode:', response && response.statusCode); // Print the response status code if a response was received
            //         console.log('body:', body);
                    // if (response.statusCode === 200 && i === itemArr.length - 1) {
                        console.log("working properly")
                        await db.collection('users').doc(docID).collection("invoices").add(data)
                            .then(async function (docRef) {
                                db.collection('users').doc(docID).collection("invoices").doc(docRef.id).update({
                                    Invoice_ID: docRef.id
                                })
                                const file = files.fileName;
                                const storageRef = firebase.storage().ref(`Documents/Invoices/${docRef.id}`);
                                const fileRef = storageRef.child(file.name)
                                await fileRef.put(file)
                            })
                            .then (() => {
                                window.location.href=("/admin/invoice")
                            })
                    // }

                // })

            // }
        }
        else {
            console.log("ERROR")
        }

    };

    // function CardContent({ card, setCard }) {
    //     return (
    //       <GridItem xs={11}>
    //         <TextField
    //           labelText="Who will be held accountable?"
    //           formControlProps={{
    //             fullWidth: true
    //           }}
    //           inputProps={{
    //             value: card ? card.text : '',
    //             required: false,
    //             type: "text",
    //             onChange: (event) => setCard({ text: event.target.value }),
    //           }}
    //         />
    //       </GridItem>
    //     );
    //   }


    return (
        <div className={classes.container}>
            <div style={{ textAlign: "center", marginRight: "195px" }}>
                <h1>Create an Invoice </h1>
            </div>
            {/* FORM */}
            <form onSubmit={saveForm}>
                <GridContainer >
                    <GridItem xs={9}>
                        <GridContainer justify="center" style={{ padding: "0 10px" }} >
                            <GridItem xs={12}>
                                <Card>
                                    <CardHeader color="primary" text>
                                        <CardText color="primary" style={{ width: "155px", height: "50px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                            <h4>Send Invoice to </h4>
                                        </CardText>
                                    </CardHeader>

                                    <CardBody>

                                        {emailArr.map((key, index) => {
                                            return (
                                                <>
                                                    <GridContainer >
                                                        <GridItem xs={11}>

                                                            {/* {console.log("email value check: ", emailArr)} */}
                                                            <TextField
                                                                autoComplete="new-off"
                                                                key={key.id}
                                                                label="*Email"
                                                                id="email"
                                                                fullWidth
                                                                value={emailArr[index].email}
                                                                InputProps={{
                                                                    inputProps: {
                                                                        onChange: e => handleEmail(e, index),
                                                                    }

                                                                }}
                                                                error={key.emailErr}
                                                                helperText={key.emailErr ? 'Error! Input is invalid.' : ''}

                                                            />
                                                            {/* {console.log("email err test:", emailArr[index].emailErr)} */}
                                                        </GridItem>
                                                        <GridItem xs={1}>
                                                            <div style={{ display: "flex", justifyContent: "right", }}>
                                                                <Button simple style={{ color: "black", background: "white", }}
                                                                    onClick={(e) => { deleteCount(e, index) }}
                                                                >{""}
                                                                    <Close className={classes.modalClose} />
                                                                </Button>
                                                            </div>
                                                            {/* <Button style={{width:"30px"}} onClick={(e)=>{deleteCount(e,index)}}>TEST</Button> */}
                                                        </GridItem>
                                                    </GridContainer>





                                                </>
                                            );

                                        })}
                                    </CardBody>
                                    <div style={{ display: "flex" }}>
                                        <Button
                                            onClick={handleCount}
                                            color="primary"
                                            style={{ fontWeight: "600", marginTop: "10px", width: "180px", marginLeft: "20px" }}
                                        >Add another recipient</Button>
                                    </div>

                                </Card>

                            </GridItem>
                        </GridContainer>
                        <br />
                        <br />
                        <GridContainer justify="center" style={{ padding: "0 10px" }}>
                            {itemArr.map((key, index) => {
                                return (
                                    <GridItem
                                        key={key.id}
                                        xs={12} >
                                        <Card style={{ marginTop: "-15px" }}>
                                            <div style={{ display: "flex", justifyContent: "right" }}>
                                                <Button simple style={{ color: "black", background: "white", }}
                                                    onClick={(e) => { deleteItemCount(e, index) }}
                                                >{""}
                                                    <Close className={classes.modalClose} />
                                                </Button>
                                            </div>

                                            <CardBody>

                                                {/* {console.log("TESTING ITEMS: ", itemArr)} */}
                                                <GridContainer style={{ marginTop: "-25px" }}>
                                                    <GridItem xs={4}>
                                                        <TextField
                                                            label="*Line Item Name"
                                                            id="itemName"
                                                            fullWidth
                                                            value={itemArr[index].itemName}
                                                            InputProps={{
                                                                inputProps: {
                                                                    onChange: e => handleItem(e, index, editorState1),
                                                                    min: 0,
                                                                    step: 0.01
                                                                }

                                                            }}
                                                            error={key.itemNameErr}
                                                            helperText={key.itemNameErr ? 'Error! Input is invalid.' : ''}
                                                        />
                                                    </GridItem>
                                                    <GridItem xs={4}>
                                                        <TextField
                                                            label="*Quantity"
                                                            id="quantity"
                                                            fullWidth
                                                            type="number"
                                                            value={itemArr[index].quantity}
                                                            InputProps={{
                                                                inputProps: {
                                                                    onChange: e => handleItem(e, index, editorState1),
                                                                    min: 0,
                                                                    step: 0.01
                                                                }

                                                            }}
                                                            error={key.quantityErr}
                                                            helperText={key.quantityErr ? 'Error! Input is invalid.' : ''}
                                                        />
                                                    </GridItem>
                                                    <GridItem xs={4}>
                                                        <TextField
                                                            label="*Price"
                                                            id="price"
                                                            fullWidth
                                                            type="number"
                                                            value={itemArr[index].price}
                                                            InputProps={{
                                                                inputProps: {
                                                                    onChange: e => handleItem(e, index, editorState1),
                                                                    min: 0,
                                                                    step: 0.01
                                                                }

                                                            }}
                                                            error={key.priceErr}
                                                            helperText={key.priceErr ? 'Error! Input is invalid.' : ''}
                                                        />
                                                    </GridItem>
                                                    <br />
                                                    <br />
                                                    <br />
                                                    <GridItem xs={12}>
                                                        {/* <div onClick={editorRef.current.focus()}> */}
                                                        <Editor
                                                            id="description"
                                                            // ref = {editorRef}
                                                            placeholder="Description for your line item..."
                                                            editorState={EditorState.acceptSelection(editorState1, editorState1.getSelection())}
                                                            onEditorStateChange={(editorState1) => updateText(editorState1, index)}
                                                            // onEditorStateChange={(e) => handleItem(e,index)}
                                                            wrapperClassName="wrapper-class"
                                                            editorClassName="editor-class"
                                                            toolbarClassName="toolbar-class"
                                                            editorStyle={{ border: "1px solid grey" }}
                                                            toolbarStyle={{ border: "1px solid grey" }}
                                                        />
                                                        {/* </div> */}
                                                    </GridItem>
                                                </GridContainer>

                                            </CardBody>
                                            <div style={{ display: "flex", justifyContent: "right", paddingRight: "20px", marginTop: "-10px" }} id="amt">
                                                <p style={{ fontSize: "16px" }}>Amount: <b>{amt[index].toFixed(2)}</b></p>
                                                {/* {console.log(amt)} */}
                                            </div>
                                        </Card>
                                    </GridItem>
                                );

                            })}

                            {/* <div style={{ display: "flex", marginRight: "69%", marginTop: "-25px" }}> */}

                            {/* </div> */}
                        </GridContainer>
                        <GridContainer style={{ justifyContent: "left", display: "flex", paddingLeft: "26px" }}>
                            <GridItem xs={12} style={{ marginTop: "-25px" }} >
                                <Button
                                    onClick={handleItemCount}
                                    color="primary"
                                    style={{ fontWeight: "600", width: "180px" }}
                                >Add another line item</Button>
                            </GridItem>

                        </GridContainer>
                        <br />
                        <GridContainer justify="center" style={{ padding: "0 10px", paddingTop: "20px" }} >
                            <GridItem xs={12}>
                                <Card>
                                    <CardHeader color="primary" text>
                                        <CardText color="primary" style={{ width: "225px", height: "50px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                            <h4>Additional Comments </h4>
                                        </CardText>
                                    </CardHeader>
                                    <CardBody>

                                        <Editor
                                            editorState={editorState2}
                                            onEditorStateChange={updateComments}
                                            wrapperClassName="wrapper-class"
                                            editorClassName="editor-class"
                                            toolbarClassName="toolbar-class"
                                            editorStyle={{ border: "1px solid grey" }}
                                            toolbarStyle={{ border: "1px solid grey" }}
                                        />

                                    </CardBody>
                                </Card>

                            </GridItem>
                        </GridContainer>

                        <GridContainer justify="center" style={{ padding: "0 10px", paddingTop: "20px" }} >
                            <GridItem xs={12}>
                                <Card>
                                    <CardHeader color="primary" text>
                                        <CardText color="primary" style={{ width: "225px", height: "50px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                            <h4>Attach Contract Doc </h4>
                                        </CardText>
                                    </CardHeader>
                                    <CardBody>

                                        <TextField
                                            // labelText="First Name"
                                            id="fileName"
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            inputProps={{
                                                type: "file",
                                                onChange: onFileChange,
                                                disableUnderline: true,
                                                // required: true
                                            }}
                                            error={files.fileError}
                                            helperText={files.fileError ? 'Error! Please select a file.' : ''}
                                        />
                                        {/* <div style={{ color: "red", fontSize:"20px" }}>{files.fileError}</div> */}
                                    </CardBody>
                                </Card>

                            </GridItem>
                        </GridContainer>

                    </GridItem>
                    <GridItem xs={3}>
                        <GridContainer justify="center" style={{ padding: "0 10px" }} >
                            <GridItem xs={12}>
                                <Card>
                                    <CardHeader color="primary" text>
                                        <CardText color="primary" style={{ width: "155px", height: "45px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                            <h4>Invoice Total </h4>
                                        </CardText>
                                    </CardHeader>
                                    <CardBody style={{ paddingTop: "30px" }}>
                                        <TextField
                                            label="Invoice Date"
                                            id="date"
                                            variant="outlined"
                                            fullWidth
                                            InputProps={{
                                                inputProps: {
                                                    onChange: handleChange,
                                                    defaultValue: moment().format("MM/DD/YYYY"),
                                                    readOnly: true
                                                }

                                            }}
                                            focused

                                        />
                                        <br />
                                        <br />


                                        <TextField
                                            label="Due Date"
                                            id="due"
                                            variant="outlined"
                                            fullWidth
                                            focused
                                            type="date"
                                            InputProps={{
                                                inputProps: {
                                                    onChange: handleChange,

                                                }

                                            }}
                                            // color="success"
                                            error={info.dueError}
                                            helperText={info.dueError === "Invalid input." ? 'Error! Input is invalid.' :
                                                info.dueError === "Due Date has to be after today's date." ? "Due Date has to be after today's date." : ''}

                                        />

                                        <br />
                                        <p> --------------------------------------------------</p>

                                        <TextField
                                            label="Subtotal"
                                            id="subtotal"
                                            variant="outlined"
                                            fullWidth
                                            InputProps={{
                                                inputProps: {
                                                    value: !subTotal ? '' : subTotal.toFixed(2)

                                                }

                                            }}
                                        // error={info.emailError}
                                        // helperText={info.emailError ? 'Error! Input is invalid.' : ''}
                                        />
                                        <br />
                                        <br />

                                        <TextField
                                            label="Other discounts"
                                            id="discounts"
                                            variant="outlined"
                                            fullWidth
                                            type="number"
                                            InputProps={{
                                                inputProps: {
                                                    onChange: handleChange,
                                                    min: 0,
                                                    step: 0.01
                                                }

                                            }}
                                        // error={info.emailError}
                                        // helperText={info.emailError ? 'Error! Input is invalid.' : ''}
                                        />
                                        <br />
                                        <br />
                                        <TextField
                                            label="Other amount"
                                            id="oamount"
                                            variant="outlined"
                                            fullWidth
                                            type="number"
                                            InputProps={{
                                                inputProps: {
                                                    onChange: handleChange,
                                                    min: 0.00,
                                                    step: 0.01
                                                }

                                            }}
                                        // error={info.emailError}
                                        // helperText={info.emailError ? 'Error! Input is invalid.' : ''}
                                        />
                                        <br />
                                        <p> --------------------------------------------------</p>

                                        <TextField
                                            label="Total"
                                            id="total"
                                            variant="outlined"
                                            focused
                                            fullWidth
                                            InputProps={{
                                                inputProps: {
                                                    value: !total ? '' : total && info.partPay === true ? totalMinusPartPay : total.toFixed(2),
                                                    readOnly: true
                                                }

                                            }}

                                        // error={info.emailError}
                                        // helperText={info.emailError ? 'Error! Input is invalid.' : ''}
                                        />
                                        <br />
                                        <p> --------------------------------------------------</p>

                                        <h5>Payment Options</h5>

                                        <FormControlLabel

                                            control={
                                                <Checkbox
                                                    tabIndex={-1}
                                                    onClick={() => handleToggle(2)}
                                                    checkedIcon={<Check className={classes.checkedIcon} />}
                                                    icon={<Check className={classes.uncheckedIcon} />}
                                                    classes={{
                                                        checked: classes.checked,
                                                        root: classes.checkRoot
                                                    }}
                                                />
                                            }
                                            classes={{
                                                label: classes.label,
                                                root: classes.labelRoot
                                            }}
                                            label="Allow partial payment"

                                        />
                                        {info.partPay === true ? (
                                            <TextField
                                                label="Minimum due"
                                                id="minTotal"
                                                variant="outlined"
                                                focused
                                                fullWidth
                                                InputProps={{
                                                    inputProps: {
                                                        onChange: handleChange,
                                                    }

                                                }}
                                                error={info.minError}
                                                helperText={info.minError === "Invalid input." ? 'Error! Input is invalid.' : info.minError === "Partial payment is greater than total." ?
                                                    "Partial payment can't be greater than total." : ''}
                                            />
                                        ) : null}


                                    </CardBody>
                                    <Button
                                        color="primary"
                                        style={{ fontWeight: "600", marginTop: "10px", display: "flex" }}
                                        type="submit">
                                        Submit Invoice</Button>
                                    {/* <Button onClick={check}>check</Button> */}

                                </Card>

                            </GridItem>
                        </GridContainer>
                    </GridItem>
                </GridContainer>
            </form>

        </div>

    );
}

InvoiceForm.propTypes = {
    classes: PropTypes.object.isRequired,
}

const mapStateToProps = (state) => {
    return {
        AuthBool: !!state.auth.user,
        authError: state.auth.error,
        apiKey: state.auth.user && state.auth.user.apiKey
    }
}
function mapDispatchToProps(actions) {
    return (dispatch) => ({
        // edit: (key) => dispatch(actionsForNavigation.openChild("Create Invoice", `auth/create-invoice`)),
    })
}
export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(Style)
)(InvoiceForm);
