import React from 'react';
import PropTypes from 'prop-types';

import Button from 'components/CustomButtons/Button';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';

export default function FormStepContainer(props) {
    const { classes, steps, step, next, last, nextButtonLabel, lastButtonLabel } = props;
    const Content = step && steps[step];
    return (
        <GridContainer justify='center'>
            {console.log("PROPS TESTING", props)}
            <GridItem xs={12}>
                {Content}
            </GridItem>
            { last &&
                <GridItem xs={12} sm={6}>
                    <Button className={classes.lastButton} onClick={e=>last()}>{lastButtonLabel}</Button>
                </GridItem>
            }
            { next &&
                <GridItem xs={12} sm={6}>
                    <Button className={classes.nextButton} onClick={e=>next()}>{nextButtonLabel}</Button>
                </GridItem>
            }
        </GridContainer>
    );
}

FormStepContainer.propTypes = {
    step: PropTypes.string,
    steps: PropTypes.objectOf(PropTypes.node).isRequired,
    next: PropTypes.func,
    last: PropTypes.func,
    nextButtonLabel: PropTypes.node.isRequired,
    lastButtonLabel: PropTypes.node.isRequired
}