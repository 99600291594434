import React from 'react';
import loader from 'assets/img/loader.gif';
import { connect } from "react-redux";
import { isReady } from 'store/reducers/system';

function Loader(props) {
    const { loading, children } = props;
    return (
        loading ? (
            <div
                style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginTop: '20%'
                }}
            >
                <img
                    style={{ width: '20%' }}
                    src={loader}
                    alt="..."
                />
            </div>
        ) : (
            children
        )
    );
}

function mapStateToProps(state) {
    return {
        loading: !isReady(state)
    }
}

export default connect(mapStateToProps)(Loader);