import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import form from './form';
import auth from './auth';
import dialog from './dialog';
import recordset from './recordset';
import system from './system';
import navigation from './navigation';
import { config as leads } from './recordsets/leads';

export default (history) => combineReducers({
    router: connectRouter(history),
    auth,
    dialog,
    system,
    navigation,
    form,
    recordset: recordset({leads})
});