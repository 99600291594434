/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-key */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { Link, useParams } from "react-router-dom";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// @material-ui/icons

// core components
import Card from "components/Card/Card";
import { db, firebase } from 'firebase/client.js';
import compose from "recompose/compose";
import { connect } from "react-redux";
import { async } from "@firebase/util";
import Button from "components/CustomButtons/Button.js";
import walletPageStyle from "assets/jss/material-dashboard-pro-react/views/walletPageStyles.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

export function InvoicePage(props) {
    const [invoices, setInvoice] = useState([]);
    const [loading, setLoading] = useState(false);
    const { id } = useParams();
    const ref = firebase.firestore().collection("services").doc(id)
    const { classes, total } = props;




    function getInvoice() {
        setLoading(true);
        ref.get().then((queryResult) => {
            const items = []
            items.push(queryResult.data())
            setInvoice(items)
            setLoading(false)
        })

    }

    useEffect(() => {
        getInvoice();
    }, []);

    if (loading) {
        return <h1>Loading...</h1>;
    }
    return (
        <div className={classes.container}>
            <center>
                <h2>Service Details</h2>
                    {invoices.map((invoice) => (
                        <><Card style={{width: "750px",justify: "center", background: "white", fontSize:"20px", paddingTop: "10px", paddingBottom: "10px"}}>
                            <p><strong>Name:</strong> {invoice.Name.firstName + " " + invoice.Name.lastName}</p>
                            <p><strong>Address:</strong> {invoice.Address.address_line_1 + " " + invoice.Address.admin_area_2 + ", " + invoice.Address.admin_area_1 + " " + invoice.Address.postal_code + " "
                                + invoice.Address.country_code}</p>
                            <p><strong>Email:</strong> {invoice.Email}</p>
                            <p><strong>Invoice ID:</strong> {invoice.Invoice_ID}</p>
                            <p><strong>Service Types: </strong> {invoice.Types_of_Services + ' '}</p>
                            {invoice.Visibility.Exchange === true? (
                                        <>
                                        <p><strong>Min Price: </strong>${invoice.priceRange.minPrice} </p>
                                        <p><strong>Max Price: </strong>${invoice.priceRange.maxPrice} </p>
                                        </>
                                    ):
                                    <p><strong>Price: </strong>${invoice.Item_Info.Item_Price} </p>}

                        </Card>
                        </>
                    ))}
        </center>
        </div >

    );
}
InvoicePage.propTypes = {
    classes: PropTypes.object.isRequired,
}

const mapStateToProps = (state) => {
    return {
        AuthBool: !!state.auth.user,
        authError: state.auth.error
    }
}
function mapDispatchToProps(actions) {
    return (dispatch) => ({
    })
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(walletPageStyle)
)(InvoicePage);