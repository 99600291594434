import { getAccessorsForCollection, addCalculatedFields } from "../recordset";
import { CardFieldFormats } from "variables/constants";
import { DataTypes } from "variables/constants";

export const config = {
    firestoreCollection: 'leads',
    fields: {
        lastUpdated: { type: DataTypes.DATETIME, cardFormat: CardFieldFormats.SIMPLE_DATE },
        notes: { maxLength: 120 },
        currentScore: { type: DataTypes.NUMBER },
        goalScore: { type: DataTypes.NUMBER }
    },
    textSearchFields: [ "firstName", "lastName", "notes" ]
}

function calculator(column, accessor) {
    switch(column) {
        case "fullName":
            return accessor("firstName") + " " + accessor("lastName");
        default:
            return accessor(column);
    }
}

export const accessorsForRecordset = addCalculatedFields(getAccessorsForCollection(config, "recordset","leads"), calculator);

export default accessorsForRecordset;


