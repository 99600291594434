import React from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import InputAdornment from '@material-ui/core/InputAdornment';
import Icon from '@material-ui/core/Icon';
import Slide from '@material-ui/core/Slide';


// @material-ui/icons
import Email from '@material-ui/icons/Email';
// core components
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import InfoArea from 'components/InfoArea/InfoArea.js';
import Card from 'components/Card/Card.js';

import RegistrationActions from 'store/actions/forms/registration';
import { actionsForDialog } from 'store/actions/dialog';
import RegistrationAccessors from 'store/reducers/forms/registration';

import BoundInput from 'components/CustomInput/BoundInput';
import TermsAndConditionsDialog from 'views/Components/TermsAndConditionsDialog';

import Style from 'assets/jss/kordspace-template/views/pages/registration/stepStyle';

import Timeline from '@material-ui/icons/Timeline';
import Group from '@material-ui/icons/Group';

const CustomInput = BoundInput(RegistrationActions.basicDetails, RegistrationAccessors.basicDetails);
const TermsAndConditionsActions = actionsForDialog("termsAndConditions");

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
  
function BasicDetails(props) {

    const { classes, error, openTermsAndConditions } = props;

    return(
        <GridContainer>
            <GridItem xs={12} sm={10} md={8} lg={6}>
                <h2
                    className={classes.cardTitle}
                    style={{ fontSize: '1.8rem', color: '#333' }}
                >
                    Create Your Account
                </h2>
                { error &&
                    <InfoArea icon={Timeline} title='Error' description={error.message}/>
                }
                <Card style={{ paddingLeft: '20px', marginBottom: '0' }}>
                    <CustomInput
                        labelText='Email'
                        name='email'
                        formControlProps={{
                            fullWidth: true,
                        }}
                        inputProps={{
                            type: 'email',
                            disableUnderline: true,
                            endAdornment: (
                            <InputAdornment position='end'>
                                <Email />
                            </InputAdornment>
                            ),
                        }}
                    />
                </Card>

                <Card
                    style={{
                        paddingLeft: '20px',
                        marginBottom: '0',
                        marginTop: '20px',
                    }}
                >
                    <CustomInput
                        labelText='Password'
                        name='password'
                        formControlProps={{
                            fullWidth: true,
                    }}
                    inputProps={{
                        type: 'password',
                        disableUnderline: true,
                        endAdornment: (
                        <InputAdornment position='end'>
                            <Icon>lock_outline</Icon>
                        </InputAdornment>
                        ),
                    }}
                    />
                </Card>
                <Card
                    style={{
                    paddingLeft: '20px',
                    marginBottom: '0',
                    marginTop: '20px',
                    }}
                >
                    <CustomInput
                        labelText='Confirm Password'
                        name='confirmPassword'
                        formControlProps={{
                            fullWidth: true,
                        }}
                        inputProps={{
                            type: 'password',
                            disableUnderline: true,
                            endAdornment: (
                            <InputAdornment position='end'>
                                <Icon>lock_outline</Icon>
                            </InputAdornment>
                            ),
                        }}
                    />
                </Card>
                <Card style={{ paddingLeft: '20px', marginBottom: '0' }}>
                    <CustomInput
                        labelText='User Type'
                        name='userType'
                        formControlProps={{
                            fullWidth: true,
                        }}
                        inputProps={{
                            disableUnderline: true,
                            endAdornment: (
                            <InputAdornment position='end'>
                                <Group />
                            </InputAdornment>
                            ),
                        }}
                    />
                </Card>                
                <Card
                    style={{
                    paddingLeft: '20px',
                    marginBottom: '0',
                    marginTop: '20px',
                    }}
                >
                    <CustomInput
                        labelText={
                            <span className={classes.bodyText}>
                                I agree to the{' '}
                                <span
                                    onClick={() => openTermsAndConditions()}
                                    className={classes.linkText}
                                >
                                    terms and conditions
                                </span>
                                .
                            </span>}
                        name='acceptedTerms'
                        formControlProps={{
                            fullWidth: true,
                        }}
                    />
                </Card>                
                <TermsAndConditionsDialog TransitionComponent={Transition}/>
            </GridItem>
        </GridContainer>
    );
}

const mapDispatchToProps = (dispatch) => ({
    openTermsAndConditions: ()=>dispatch(TermsAndConditionsActions.openDialog())
});
  
const mapStateToProps = (state, ownProps) => {
    return {
        error: RegistrationAccessors.basicDetails.getError(state)
    };
};
  
export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(Style)
)(BasicDetails);