import {
  container,
  cardTitle,
  blackColor,
  hexToRgb,
  grayColor
} from "assets/jss/material-dashboard-pro-react.js";

import customCheckboxRadioSwitch from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.js";

const registerPageStyle = {
  ...customCheckboxRadioSwitch,
  cardTitle: {
    ...cardTitle,
    textAlign: "center"
  },
  container: {
    ...container,
    position: "relative",
    zIndex: "3"
    // paddingTop: "23vh"
  },
  cardSignup: {
    borderRadius: "6px",
    boxShadow:
      "0 16px 24px 2px rgba(" +
      hexToRgb(blackColor) +
      ", 0.14), 0 6px 30px 5px rgba(" +
      hexToRgb(blackColor) +
      ", 0.12), 0 8px 10px -5px rgba(" +
      hexToRgb(blackColor) +
      ", 0.2)",
    marginBottom: "100px",
    padding: "40px 0px",
    marginTop: "15vh"
  },
  center: {
    textAlign: "center"
  },
  right: {
    textAlign: "right"
  },
  left: {
    textAlign: "left"
  },
  form: {
    padding: "0 20px",
    position: "relative"
  },
  socialTitle: {
    fontSize: "18px"
  },
  inputAdornment: {
    marginRight: "18px",
    position: "relative"
  },
  inputAdornmentIcon: {
    color: grayColor[6]
  },
  customFormControlClasses: {
    margin: "0 12px"
  },
  checkboxLabelControl: {
    margin: "0",
  },
  checkboxLabel: {
    fontSize: "0.875rem",
    marginLeft: "6px",
    color: "rgba(" + hexToRgb(blackColor) + ", 0.26)"
  },
  modalCloseButton: {
    color: "black",
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "-10px",
    marginBottom: "-15px",
    "&:hover": {
      color: "black",
      cursor: "pointer"
    }
  },
  popperDiv: {
    display: "flex",
    // height: "85px",
    // flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0px 12px",
    height: "50px",
    // margin: "0px auto",
    "&:hover": {
      cursor: "pointer",
      background: "#F6F6F7"
    }
  },
  popperSelections: {
    width: "100%",
    margin: "0px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  submitButton: {
    float: "right",
    backgroundColor: "#ffdeab",
    borderRadius: "5px",
    color: "#3C4858",
    width: "200px",
    fontFamily: "Quattrocento Sans",
    fontWeight: "bold",
    "&:hover": {
      cursor: "pointer",
      background: "#ffdeab"
    }
  },
  cancelButton: {
    float: "left",
    backgroundColor: "#f37361",
    borderRadius: "5px",
    color: "#FFFFFF",
    width: "200px",
    fontFamily: "Quattrocento Sans",
    fontWeight: "bold",
    "&:hover": {
      cursor: "pointer",
      background: "#f37361"
    }
  },
  textBox: {
    color: "#3C4858",
    backgroundColor: "white",
    fontFamily: "Quattrocento Sans",
    fontWeight: "bold",
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "white",
    },
    "&:focus": {
      cursor: "pointer",
      backgroundColor: "white",
    },
  },
  profilePic: {
    height: "130px",
    width: "130px",
    borderRadius: "50%",
    margin: "auto",
    textAlign: "center",
    display: "block",
    marginTop: "10px"
  },
  insideText:{
    color: "#3C4858",
    fontSize: "20px",
    fontFamily:"Quattrocento Sans", 
    fontStyle:"italic", 
    fontWeight:"bold", 
    marginLeft:"20px",
    marginTop:"40px"
  }
};

export default registerPageStyle;
