import {
  cardTitle,
  grayColor
} from "assets/jss/material-dashboard-pro-react.js";

const userProfileStyles = {
  cardTitle:{
    color: "#FFFFFF",
    fontFamily: "Oleo Script Swash Cap",
    fontWeight:"bold",
  },
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px",
    "& small": {
      fontSize: "80%",
      fontWeight: "400"
    }
  },
  cardCategory: {
    marginTop: "10px",
    color: "#FFFFFF",
    textAlign: "center",
    display: "flex",
    justifyContent: "space-around"
  },
  description: {
    color: "#FFFFFF"
  },
  updateProfileButton: {
    float: "right", 
    backgroundColor: "#ffdeab",
    borderRadius: "5px",
    color:"#3C4858",
    fontFamily: "Quattrocento Sans",
    fontWeight:"bold",
    "&:hover": {
      cursor: "pointer",
      background: "#ffdeab"
    }
  },
  paypalProfileButton: {
    float: "left", 
    backgroundColor: "#f37361",
    borderRadius: "5px",
    color:"#FFFFFF",
    fontFamily: "Quattrocento Sans",
    fontWeight:"bold",
    "&:hover": {
      cursor: "pointer",
      background: "#f37361"
    }
  },
  addFundsButton: {
    width:"25%",
    float: "middle", 
    backgroundColor: "#AD1E5A",
    borderRadius: "5px",
    color:"#FFFFFF",
    fontFamily: "Quattrocento Sans",
    fontWeight:"bold",
    "&:hover": {
      cursor: "pointer",
      background: "#AD1E5A"
    }
  }
};
export default userProfileStyles;
