/* eslint-disable no-unused-vars */
/*!

=========================================================
* Material Dashboard PRO React - v1.9.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Route, Switch, Redirect } from "react-router";
import { ConnectedRouter, routerMiddleware } from "connected-react-router";
import thunk from 'redux-thunk';
import logger from 'redux-logger';
import AuthLayout from "layouts/Auth.js";
import RtlLayout from "layouts/RTL.js";
import AdminLayout from "layouts/Admin.js";
import PageLayout from "layouts/Pages.js";
// import { DndProvider } from 'react-dnd'
// import { HTML5Backend } from 'react-dnd-html5-backend'

import { createStore, applyMiddleware, compose } from 'redux';
import { Provider } from 'react-redux';
import rootReducer from 'store/reducers/root'

import RegistrationActions from "store/actions/forms/registration";
import AuthenticationActions from "store/actions/auth";
import SystemActions from "store/actions/system";
import LeadsActions from "store/actions/recordsets/leads";
import AuthActions from "store/actions/auth"
import { chain } from "store/actions/util";
import { push } from "connected-react-router";
import fbConfig from 'firebase/client';
import { firebase,db } from 'firebase/client';
import '@firebase/auth';
import "assets/scss/material-dashboard-pro-react.scss?v=1.9.0";
import InvoiceDetails from "views/Dashboard/Invoices/InvoiceDetails";
import InvoicePage from "views/Dashboard/Invoices/InvoicePage";
import PublicInvoice from "views/Pages/Invoices/PublicInvoice";
import iFramePage from "views/Dashboard/Utilities/iFramePage.js"
import { startSetActionsList, startSetUsersList, startSetPendingActionsList } from './store/actions/actionsActions'
import { startSetAssessmentsList } from './store/actions/assessmentActions'
import PublicInvoiceDetails from "views/Pages/Invoices/PublicInvoiceDetails";
import iFramePubPage from "views/Pages/Utilities/iFramePubPage"


const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const hist = createBrowserHistory();

const store = createStore(
  rootReducer(hist),
  compose(
    applyMiddleware(
      // thunk.withExtraArgument({ getFirebase, getFirestore }),
      thunk,
      routerMiddleware(hist),
      logger
    ),
    // reduxFirestore(fbConfig),
    // reactReduxFirebase(fbConfig)

  )
);


// Initialize anything that doesn't require a login
store.dispatch(RegistrationActions.initialize());

ReactDOM.render(
    <Provider store={store}>
      {/* <DndProvider backend={HTML5Backend}> */}
        <ConnectedRouter history={hist}>
        <Switch>
            <Route path="/rtl" component={RtlLayout} />
            <Route path="/auth" component={AuthLayout} />
            <Route path="/admin" component={AdminLayout} />
            <Route path="/pages" component={PageLayout} />
            <Route path="admin/invoice/:id" component={InvoicePage}/>
            <Route path="auth/public-invoice/:uid" component={PublicInvoice}/>
            <Route path="auth/public-invoice/:uid/:docId" component={PublicInvoiceDetails}/>
            <Route path="pages/iframe-invoice/:uid/:id" component={iFramePage}/>
            <Route path="pages/pub-iframe-invoice/:uid/:id" component={iFramePubPage}/>
            <Redirect from="/" to="/auth/login-page"/>
        </Switch>
        </ConnectedRouter>
        {/* </DndProvider> */}
    </Provider>,
    document.getElementById("root")
);

firebase.auth().onAuthStateChanged((user) => {
    if (user) {
        // Do initialization that requires user login
        store.dispatch(chain(
            AuthenticationActions.startLoadProfile(user),
            LeadsActions.initialize(),
            SystemActions.setReady(true)
        ));
    } 
    else {
        // store.dispatch(chain(
        //     // Revert changes to store code once registration is completed. Commented out just to get main ui rendering
        //     SystemActions.setReady(true),
        //     // push('/admin/profile')));
            store.dispatch(push('/auth/login-page'));
    }
    if (user) {
        // Name, email address, and profile photo Url
        store.dispatch(AuthActions.login(user));
        store.dispatch(startSetActionsList(user.uid))
        // ADMIN PANEL ONLY SHOWS IF firebase > TEST-users > Loggedin user document > admin: true
        store.dispatch(startSetUsersList(user))
        store.dispatch(startSetAssessmentsList(user.uid))
    
        if (hist.location.pathname === '/') {
          hist.push('/dashboard')
        }
      } 
      else {
        hist.push('/auth/login-page')
      }
    //create a new if statement that checks email link then hist.push(link url here)
})