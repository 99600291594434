/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-key */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { Link, useParams } from "react-router-dom";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// @material-ui/icons

// core components
import Card from "components/Card/Card";
import { db, firebase } from 'firebase/client.js';
import compose from "recompose/compose";
import { connect } from "react-redux";
import { async } from "@firebase/util";
import Button from "components/CustomButtons/Button.js";
import walletPageStyle from "assets/jss/material-dashboard-pro-react/views/walletPageStyles.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

// forms
import AskForm from "views/Forms/ExchangeForms/AskForm";
import BidForm from "views/Forms/ExchangeForms/BidForm";

import exchangePageStyle from "assets/jss/material-dashboard-pro-react/views/exchangePageStyles.js";
import { VictoryTheme, VictoryAxis, VictoryCandlestick, VictoryChart } from "victory";

export function ExchangePage(props) {
    const { classes } = props;
    const [invoices, setInvoice] = useState([]);
    const [loading, setLoading] = useState(false);
    const { id } = useParams();
    const ref = firebase.firestore().collection("services").doc(id)

    const sampleDataDates = [
        { x: new Date(2016, 6, 1), open: 5, close: 10, high: 15, low: 0 },
        { x: new Date(2016, 6, 2), open: 10, close: 15, high: 20, low: 5 },
        { x: new Date(2016, 6, 3), open: 15, close: 20, high: 22, low: 10 },
        { x: new Date(2016, 6, 4), open: 20, close: 10, high: 25, low: 7 },
        { x: new Date(2016, 6, 5), open: 20, close: 10, high: 25, low: 7 },
        { x: new Date(2016, 6, 6), open: 20, close: 10, high: 25, low: 7 },
        { x: new Date(2016, 6, 7), open: 20, close: 10, high: 25, low: 7 },
    ];

    function getInvoice() {
        setLoading(true);
        ref.get().then((queryResult) => {
            const items = []
            items.push(queryResult.data())
            setInvoice(items)
            setLoading(false)
        })

    }

    useEffect(() => {
        getInvoice();
    }, []);

    if (loading) {
        return <h1>Loading...</h1>;
    }

    return (
        <div>
            <h1 className={classes.header}>Vendor Service Trade Volume</h1>
            <center>
            {invoices.map((invoice) => (
                        <><Card style={{width: "750px",justify: "center", background: "white", fontSize:"20px", paddingTop: "10px", paddingBottom: "10px"}}>
                            <p><strong>Name:</strong> {invoice.Name.firstName + " " + invoice.Name.lastName}</p>
                            <p><strong>Address:</strong> {invoice.Address.address_line_1 + " " + invoice.Address.admin_area_2 + ", " + invoice.Address.admin_area_1 + " " + invoice.Address.postal_code + " "
                                + invoice.Address.country_code}</p>
                            <p><strong>Email:</strong> {invoice.Email}</p>
                            <p><strong>Invoice ID:</strong> {invoice.Invoice_ID}</p>
                            <p><strong>Service Types: </strong> {invoice.Types_of_Services + ' '}</p>
                            {invoice.Visibility.Exchange === true? (
                                        <>
                                        <p><strong>Min Price: </strong>${invoice.priceRange.minPrice} </p>
                                        <p><strong>Max Price: </strong>${invoice.priceRange.maxPrice} </p>
                                        </>
                                    ):
                                    <p><strong>Price: </strong>${invoice.Item_Info.Item_Price} </p>}

                        </Card>
                        </>
                    ))}
                    </center>
            <GridContainer>
                <GridItem xs={12}>
                    <Card>
                        <VictoryChart
                            theme={VictoryTheme.material}
                            domainPadding={{ x: 10 }}
                            scale={{ x: "time" }}
                            height={300}
                            animate={{
                                onEnter: {
                                duration: 500,
                                before: () => ({
                                    _y: 0,
                                    fill: "orange",
                                    label: "BYE"
                                })
                                }
                            }}
                        >
                            <VictoryAxis tickFormat={t => `${t.getDate()}/${t.getMonth()}`} />
                            <VictoryAxis dependentAxis />
                            <VictoryCandlestick
                                candleColors={{ positive: "#5f5c5b", negative: "#c43a31" }}
                                data={sampleDataDates}
                            />
                        </VictoryChart>
                    </Card>
                </GridItem>
                <GridItem xs={6}>
                    <AskForm />
                </GridItem>
                <GridItem xs={6}>
                    <BidForm />
                </GridItem>
            </GridContainer>
        </div>
    );
}

ExchangePage.propTypes = {
    classes: PropTypes.object.isRequired,
}

function mapStateToProps(state) {
    return {

    };
}

function mapDispatchToProps(actions) {
    return (dispatch) => ({

    })
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(exchangePageStyle)
)(ExchangePage);