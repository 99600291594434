import { userTypes } from 'variables/config';
import { DataTypes } from 'variables/constants';
import { getAccessorsForForm } from '../form';

export const config = {
    fields: {
        userType: { mandatory: true, default: 'CLIENT', values: userTypes },
        firstName: { mandatory: true },
        lastName: { mandatory: true },
        phoneNumber: { mandatory: true },
        dateOfBirth: { type: DataTypes.DATETIME },
        practiceName: { mandatory: true },
        association: { mandatory: true },
        barNumber: { mandatory: true }
    }
}

export default getAccessorsForForm(config, "form", "profile");
