/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-key */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { Link, useParams } from "react-router-dom";
import actionsForNavigation from "store/actions/navigation";
import { db, firebase } from 'firebase/client.js';
import compose from "recompose/compose";
import { connect } from "react-redux";

// @material-ui/core components
import InputAdornment from "@material-ui/core/InputAdornment";
import Fade from '@material-ui/core/Fade';
import withStyles from "@material-ui/core/styles/withStyles";
import Popper from "@material-ui/core/Popper";

// @material-ui/icons
import { Close, Cancel, Check, DoubleArrow, Block, Search } from "@material-ui/icons";
import MoreVertOutlinedIcon from '@material-ui/icons/MoreVertOutlined';

// core components
import CustomInput from "components/CustomInput/CustomInput";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

//styles
import servicesPageStyle from "assets/jss/material-dashboard-pro-react/views/servicesPageStyles.js";
import { primaryCardHeader } from "assets/jss/material-dashboard-pro-react";
import Edit from "@material-ui/icons/Edit";

const tableHeaders = ["Invoice ID", "Date Created", "Due Date", "Email", "Status", "Actions"]

export function Estimates(props) {
    const [invoices, setInvoice] = useState([]);
    const [loading, setLoading] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [popperOpen, setPopperOpen] = useState(false)
    const [show, setShow] = useState(false);
    const [inner, setInner] = useState(false);
    const [values, setValues] = useState({
        Private: false,
        Marketplace: false,
        Exchange: false
    });
    const [error, setError] = useState({
        valueError: '',
        selectError: ''
    })
    const open = Boolean(anchorEl);
    const docID = firebase.auth().currentUser.uid;
    const ref = firebase.firestore().collection("users").doc(docID).collection("estimates");
    const { classes, history } = props;
    const [currentInvoice, setCurrentInvoice] = useState({});
    console.log("currentInvoice: ", currentInvoice)
    let id = useParams();
    const [price, setPrice] = useState({
        minPrice: '',
        maxPrice: '',
        priceErrorMin: '',
        priceErrorMax: '',
        priceErrorRange: ''
    })
    const [images, setImages] = useState('');
    const [select, setSelect] = useState('');
    const [copy, setCopy] = useState({
        copied: false
    })

    const decimalRegex = /^[0-9]+(\.[0-9]{1,2})?$/;


    const sentClick = async (e, invoice) => {
        await ref.doc(invoice).update({
            Payment_Status: "Sent"
        })
    }

    const deleteClick = async (e, invoice) => {
        await ref.doc(invoice).delete()
    }

    const pendClick = async (e, invoice) => {
        await ref.doc(invoice).update({
            Payment_Status: "Pending"
        })
    }

    const cancelClick = async (e, invoice) => {
        await ref.doc(invoice).update({
            Payment_Status: "Cancel"
        })
    }

    const validate = () => {
        let priceErrorMin = '';
        let priceErrorMax = '';
        let priceErrorRange = '';

        if (!decimalRegex.test(price.minPrice)) {
            priceErrorMin = "Error! Invalid Input"
        }

        if (!decimalRegex.test(price.maxPrice)) {
            priceErrorMax = "Error! Invalid Input"
        }

        if (price.minPrice > price.maxPrice) {
            priceErrorRange = "Error! Please check your ranges and try again"
        }


        if (priceErrorMin || priceErrorMax || priceErrorRange) {
            setPrice({
                ...price,
                priceErrorMin,
                priceErrorMax,
                priceErrorRange
            });
            return false;
        }
        return true;
    }

    const checkEmpty = () => {
        let valueError = '';
        let selectError = '';
        if (values.Private === false && values.Marketplace === false && values.Exchange === false) {
            valueError = "Must have at least one selected."
        }
        if (select.length == '') {
            selectError = "Must have at least one selected."
        }

        if (valueError || selectError) {
            setError({
                ...error,
                valueError,
                selectError
            });
            return true
        }
        return false
    }
    const saveForm = async (e, invoice, i) => {
        e.preventDefault();
        const isValid = validate();
        const isEmpty = checkEmpty();
        const serviceRef = firebase.firestore().collection("users").doc(docID).collection("services");
        let items = {
            Item: "N/A",
            Item_Price: "N/A"
        }
        let prices = {
            minPrice: price.minPrice,
            maxPrice: price.maxPrice
        }

        if (isValid && values.Exchange === true) {
            console.log("everything is true")
            await serviceRef.doc(invoice)
                .set({
                    Visibility: values,
                    Types_of_Services: select,
                    priceRange: prices,
                    Item_Info: items,
                    imgUrl: images
                })
            const invoiceData = await ref.doc(invoice).get()
            const serviceData = await serviceRef.doc(invoice).get()
            db.collection('services').doc(invoiceData.data().Invoice_ID).set(invoiceData.data())
            db.collection('services').doc(invoiceData.data().Invoice_ID).update(serviceData.data())
            window.location.href = (`/admin/invoice`)
        }

        else if (!values.Exchange === true && !isEmpty) {
            await serviceRef.doc(invoice)
                .set({
                    Visibility: values,
                    Types_of_Services: select,
                    imgUrl: images,
                    priceRange: prices
                })
            const invoiceData = await ref.doc(invoice).get()
            const serviceData = await serviceRef.doc(invoice).get()
            db.collection('services').doc(invoiceData.data().Invoice_ID).set(invoiceData.data())
            db.collection('services').doc(invoiceData.data().Invoice_ID).update(serviceData.data())
            window.location.href = (`/admin/invoice`)
        }

    }



    function getInvoice() {
        setLoading(true);
        ref.onSnapshot((querySnapshot) => {
            const items = [];
            querySnapshot.forEach((doc) => {
                items.push(doc.data());
            });
            setInvoice(items);
            setLoading(false);
        });
    }
    useEffect(() => {
        getInvoice();
    }, []);

    if (loading) {
        return <h1>Loading...</h1>;
    }

    const renderTableData = () => {
        return invoices.map((invoice, i) => {
            // console.log('invoiceinvoice', invoice)
            // console.log("TESTING MAP", invoice.Visibility)

            return (
                invoice.uID === firebase.auth().currentUser.uid ?
                    <tr style={{ width: "100%", minHeight: "100px" }} style={{ color: "#3C4858", fontFamily: "Quattrocento Sans", fontSize: "16px", fontWeight: "400" }}>
                        <td className={classes.tableCell} style={{ fontWeight: "700", }}>
                            {invoice.Invoice_ID}
                        </td>
                        <td className={classes.tableCell} style={{ width: "20%" }}>
                            {invoice.Date}
                        </td>
                        <td className={classes.tableCell} style={{ width: "20%" }}>
                            {invoice.dueDate}
                        </td>
                        <td className={classes.tableCell}>
                            {invoice.Email.map((emails, index) => (
                                <td style={{ width: "50%" }}>
                                    {emails}
                                </td>
                            ))}
                        </td>
                        <td className={classes.tableCell}>
                            {invoice.Payment_Status}
                        </td>
                        <td className={classes.tableCell}>
                            <MoreVertOutlinedIcon onClick={(event) => {
                                setCurrentInvoice(invoices[i])
                                setPopperOpen(!popperOpen)
                                // console.log("popperOpen 3 dots: ", popperOpen)
                                setAnchorEl(anchorEl ? null : event.currentTarget);
                                // console.log("anchorEl 3 dots: ", anchorEl)
                                setShow(false)
                            }} />
                            <Popper style={!anchorEl ? { display: "none" } : { zIndex: 1000 }} placement={'right'} id={'transitions-popper'} open={popperOpen} anchorEl={anchorEl} transition>
                                {({ TransitionProps }) => (
                                    <Fade {...TransitionProps} >
                                        <Card style={{ width: "154px", display: "flex" }}>
                                            <Button
                                                onClick={(event) => {
                                                    setAnchorEl(anchorEl ? null : event.currentTarget);
                                                    console.log("anchorEl", anchorEl)
                                                    setPopperOpen(!popperOpen)
                                                    console.log("popperOpen: ", popperOpen)
                                                }}
                                                simple
                                                className={classes.modalCloseButton}
                                                key="close"
                                                aria-label="Close"
                                            >
                                                {""}
                                                <Close className={classes.modalClose} />
                                            </Button>
                                            <div className={classes.popperDiv}>
                                                <div className={classes.popperSelections}>
                                                    <Link to={`/admin/estimate/${currentInvoice.Invoice_ID}`} style={{ color: "black" }}>
                                                        Edit Invoice
                                                    </Link>
                                                    <Edit />
                                                </div>
                                            </div>
                                            <div className={classes.popperDiv}>
                                                <div className={classes.popperSelections} onClick={(e) => sentClick(e, currentInvoice.Invoice_ID)}>
                                                    Sent
                                                    <DoubleArrow />
                                                </div>

                                            </div>

                                            <div className={classes.popperDiv}>
                                                <div className={classes.popperSelections} onClick={(e) => pendClick(e, currentInvoice.Invoice_ID)}>
                                                    Pending
                                                    <Check />
                                                </div>

                                            </div>

                                            <div className={classes.popperDiv}>
                                                <div className={classes.popperSelections} onClick={(e) => cancelClick(e, currentInvoice.Invoice_ID)}>
                                                    Cancel
                                                    <Block />
                                                </div>

                                            </div>

                                            <div className={classes.popperDiv}>
                                                <div className={classes.popperSelections} onClick={(e) => deleteClick(e, currentInvoice.Invoice_ID)}>
                                                    Delete
                                                    <Cancel />
                                                </div>

                                            </div>



                                        </Card>
                                    </Fade>
                                )}
                            </Popper>
                        </td>
                    </tr>
                    : null
            )
        })
    }

    const renderTableHeader = () => {
        return tableHeaders.map((header, index) => {
            return <th style={{
                width: "14%", fontSize: "25px", borderBottom: "1px solid rgb(171 167 167)", background: "#AD1E5A", color: "white", textTransform: "uppercase",
                fontFamily: "Quattrocento Sans", fontWeight: "bold",
            }} key={index}>{header}
                {/* <TextField InputProps={{
              classes: {
                input: classes.textField
              }
            }}  />  */}
            </th>
        })
    }

    return (
        <div className={classes.container}>
              <GridContainer style={{
                alignItems: "center",
                justifyContent: "center", margin: "0px auto", width: "95%"
            }}>
                        <GridItem xs={6}><h1 style={{ color: "white", fontFamily: "Quattrocento Sans", fontWeight: "bold" }}>Estimate Lists</h1></GridItem>
                        <GridItem xs={6} sm={6} md={6}>
                            <CustomInput
                                labelText="Search Services"
                                id="material"
                                formControlProps={{
                                    fullWidth: true
                                }}
                                inputProps={{
                                    endAdornment: (<InputAdornment position="end"><Search style={{ color: "white" }} /></InputAdornment>)
                                }}
                            />
                        </GridItem>
                    <Card style={{ marginTop: "5px" }}>
                        <table style={{ width: "100%", }}>
                            {/* <tbody> */}
                            <tr className={classes.headerRow}>{renderTableHeader()} </tr>
                            {renderTableData()}
                            {/* </tbody> */}
                        </table>
                    </Card>
            </GridContainer>
            <GridContainer>
                <GridItem xs={3}>
                    <Link to="/admin/invoice/create-estimate">
                        <Button style={{ height: "50px", width: "50px", fontSize: "2rem", right: "20px", bottom: "16px", position: "fixed", zIndex: "10", background: "#FFDEAB", color: "#4F0E51" }} justIcon round>+</Button>
                    </Link>
                </GridItem>
            </GridContainer>
        </div>

    );
}
Estimates.propTypes = {
    classes: PropTypes.object.isRequired,
}

const mapStateToProps = (state) => {
    return {
        AuthBool: !!state.auth.user,
        authError: state.auth.error
    }
}
function mapDispatchToProps(actions) {
    return (dispatch) => ({
    })
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(servicesPageStyle)
)(Estimates);