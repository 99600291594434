/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-key */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { Link, useParams } from "react-router-dom";
import actionsForNavigation from "store/actions/navigation";
import { db, firebase } from 'firebase/client.js';
import compose from "recompose/compose";
import { connect } from "react-redux";
import { PayPalButton } from "react-paypal-button-v2";
import { blue } from "@material-ui/core/colors";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Editor } from "react-draft-wysiwyg";
import { EditorState, ContentState, createWithContent, convertToRaw, convertFromRaw, convertFromHTML } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import jsPDF from "jspdf";
import html2canvas from 'html2canvas';
import moment from "moment";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import TextField from "@material-ui/core/TextField";
// @material-ui/icons
import Check from "@material-ui/icons/Check";
import Close from "@material-ui/icons/Close";

// core components
import Card from "components/Card/Card.js";
import CardText from "components/Card/CardText";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

//styles
import Style from "assets/jss/material-dashboard-pro-react/views/registerPageStyle";


export function InvoicePage(props) {
    const [invoices, setInvoice] = useState([]);
    const [loading, setLoading] = useState(false);
    // const docID = firebase.auth().currentUser.uid;
    const { uid, docId } = useParams();
    const ref = firebase.firestore().collection("users").doc(uid).collection("invoices").doc(docId);
    const { classes } = props;
    const paypal = useRef();
    const [copy, setCopy] = useState({
        copied: false
    })
    const [checked, setChecked] = useState([24, 22], false);
    const [editorState1, setEditorState1] = useState(EditorState.createEmpty());
    const [editorState2, setEditorState2] = useState(EditorState.createEmpty());
    const [info, setInfo] = useState({
        comments: '',
        date: '',
        dueDate: '',
        oamount: '',
        discounts: '',
        minTotal: 0,
        status: 'Draft',
        partPay: false,
        dueDateError: '',
        minError: ''
    });

    const [counter, setCounter] = useState(1);
    const [itemCounter, setItemCounter] = useState(1);
    const [subTotal, setSubTotal] = useState(0)
    const [amt, setAmt] = useState([0]);
    const [emailArr, setEmailArr] = useState([{
        id: counter,
        email: '',
        emailErr: ''
    },]);
    const [itemArr, setItemArr] = useState([{
        itemName: '',
        quantity: '',
        price: '',
        // description: draftToHtml(convertToRaw(editorState1.getCurrentContent())),
        description: '',
        id: itemCounter,
        itemNameErr: '',
        quantityErr: '',
        priceErr: '',
    },])
    const total = subTotal + (Number(info.oamount) - Number(info.discounts)) - Number(info.minTotal)
    const handleToggle = value => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }
        setChecked(newChecked);
        if (newChecked.length === 3) {
            setInfo({ ...info, partPay: true })
        }
        else {
            setInfo({ ...info, partPay: false })
        }
    };

    function getInvoice() {
        setLoading(true);
        ref.get().then((queryResult) => {
            const items = []
            items.push(queryResult.data())
            setEditorState1(EditorState.createWithContent(
                ContentState.createFromBlockArray(
                    convertFromHTML(items[0].Item_Info.Item_Description[0])
                )
            ))
            setEditorState2(EditorState.createWithContent(
                ContentState.createFromBlockArray(
                    convertFromHTML(items[0].Comments)
                )
            ))
            setInfo({
                ...info,
                date: items[0].Date,
                dueDate: items[0].dueDate,
                oamount: items[0].Total.otherAmounts,
                discounts: items[0].Total.discounts,
                minTotal: items[0].partialPayment.total,
                partPay: items[0].partialPayment.bool,
            })
            setAmt(items[0].Amounts)
            for (let i = 0; i < items[0].Item_Info.Item.length; i++) {
                // handleItem(e,i)
                // setItemArr([{ itemName: items[0].Item_Info.Item[i], quantity: items[0].Item_Info.Item_Quantity[i], price: items[0].Item_Info.Item_Price[i], description: '', id: itemCounter + 1, itemNameErr: '', quantityErr: '', priceErr: '' }])
                // console.log("itemarr: ", items[0].Item_Info.Item[i], "index check", i)
                // if(items[0].Item_Info.Item.length > 1){
                //     console.log("testing if statement")
                setItemArr([...itemArr, { itemName: items[0].Item_Info.Item[i], quantity: items[0].Item_Info.Item_Quantity[i], price: items[0].Item_Info.Item_Price[i], description: '', id: itemCounter + 1, itemNameErr: '', quantityErr: '', priceErr: '' }])
                // }
                // else{
                // setItemArr([{itemName: items[0].Item_Info.Item[i], quantity: items[0].Item_Info.Item_Quantity[i], price: items[0].Item_Info.Item_Price[i], description: '', id: itemCounter + 1, itemNameErr: '', quantityErr: '', priceErr: '' },])
                // }
            }
            setEmailArr([{
                ...emailArr,
                email: items[0].Email
            },])
            setSubTotal(items[0].Total.subTotal)
            setInvoice(items)
            setLoading(false)
        })

    }
    useEffect(() => {
        getInvoice();
    }, []);
    const generatePDF = async () => {
        const doc = new jsPDF();
        doc.setDrawColor(77, 171, 41);
        const currentDate = moment().format("MM/DD/YYYY");

        doc.setFontSize(12);
        doc.setFillColor(220, 220, 220);
        doc.rect(3, 2, 203, 35, 'F');

        doc.text(5, 10, `Invoice ID: ${docId}`)
        doc.setFont("helvetica", "normal");

        // doc.setFontSize(12);
        doc.text(`Invoice Date: ${currentDate}`, 5, 20);
        doc.setFont("helvetica", "normal");

        // doc.setFontSize(12);
        doc.text(`Due Date: ${info.dueDate}`, 5, 30)
        doc.setFont("helvetica", "normal");

        doc.setFontSize(30);
        doc.text(`Total Due`, 150, 20)
        doc.text(`${Number(total).toFixed(2)}`, 150, 30, "left")
        doc.setFont("helvetica", "bold");

        doc.setFontSize(20);
        doc.text(`Bill To:`, 5, 50)
        doc.setFont("helvetica", "normal");

        doc.setFontSize(12);
        doc.text(`${invoices[0].Email}`, 5, 55)
        doc.setFont("helvetica", "normal");


        var col = ["Items", "Description", "Qty/Hrs", "Price", "Amount($)"];
        var rows = [];
        // const descripInput = document.getElementById('descrip');
        // var description = html2canvas(descripInput).then(async (canvas) => {
        //     const imgData = canvas.toDataURL('image/png');
        //     console.log("imgData inside", imgData)
        //     // const pdf = new jsPDF();
        //     doc.addImage(imgData, 'JPEG', -5, 80, 100, 20)
        //     return doc
        // })
        
        invoices.forEach(async invoice => {
            for (let i = 0; i < invoice.Item_Info.Item.length; i++) {

                // invoice.Item_Info.Item_Description[i]
                var temp = [invoice.Item_Info.Item[i], invoice.Item_Info.Item_Description[i], invoice.Item_Info.Item_Quantity[i]
                    , invoice.Item_Info.Item_Price[i], Number(invoice.Amounts[i]).toFixed(2)]
                rows.push(temp)
            }
        });
        
        doc.autoTable(col, rows, { startY: 60, tableWidth: 200, margin: 5, styles: { fontSize: 12, fillColor: (220, 220, 220), textColor: "black" } })
        // await description
        doc.setFontSize(16);
        doc.text(`Subtotal`, 150, 250, "right")
        doc.text(`${Number(subTotal).toFixed(2)}`, 180, 250)
        doc.line(100, 255, 200, 255)
        doc.text("Total", 150, 265, "right")
        doc.text(`${Number(total).toFixed(2)}`, 180, 265)
        doc.line(100, 270, 200, 270)
        if (info.partPay === true) {
            doc.text("Amount Due", 150, 280, "right")
            doc.text(`${Number(info.minTotal).toFixed(2)}`, 180, 280)
        }
        else {
            doc.text("Amount Due", 150, 280, "right")
            doc.text(`${Number(total).toFixed(2)}`, 180, 280)
        }

        doc.addPage();
        doc.setFontSize(14);
        doc.text(`Notes for Customer`, 5, 30)
        doc.setFontSize(12)
        const input = document.getElementById('comments')
        var test = html2canvas(input).then(async (canvas) => {
            const imgData = canvas.toDataURL('image/png');
            const pdf = new jsPDF();
            doc.addImage(imgData, 'JPEG', -55, 40)
            console.log("testing pdf", doc)
            return doc
        })
        await test



        doc.save(`Invoice-${docId}.pdf`);
    }
    // console.log("testing invoices", invoices[0])
    if (loading) {
        return <h1>Loading...</h1>;
    }
    return (
        
        <div className={classes.container}>
           
            <div id="invoice">
                <h2>Invoice #{docId}</h2>

                {/* <GridContainer justify="center"> */}
                {invoices.map((invoice) => (
                    <>
                        <GridContainer >
                            <GridItem xs={9}>
                                <GridContainer justify="center" style={{ padding: "0 10px" }} >
                                    <GridItem xs={12}>
                                        <Card>
                                            <CardHeader color="primary" text>
                                                <CardText color="primary" style={{ width: "155px", height: "50px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                    <h4>Send Invoice to </h4>
                                                </CardText>
                                            </CardHeader>

                                            <CardBody>

                                                {emailArr.map((key, index) => {
                                                    return (
                                                        <>
                                                            <GridContainer >
                                                                <GridItem xs={11}>

                                                                    {/* {console.log("email value check: ", emailArr)} */}
                                                                    <TextField
                                                                        autoComplete="new-off"
                                                                        key={key.id}
                                                                        label="*Email"
                                                                        id="email"
                                                                        fullWidth
                                                                        InputProps={{
                                                                            inputProps: {
                                                                                // onChange: e => handleEmail(e, index),
                                                                                value: emailArr[index].email,
                                                                                readOnly: true
                                                                            }

                                                                        }}

                                                                        error={key.emailErr}
                                                                        helperText={key.emailErr ? 'Error! Input is invalid.' : ''}

                                                                    />
                                                                    {/* {console.log("email err test:", emailArr[index].emailErr)} */}
                                                                </GridItem>

                                                            </GridContainer>

                                                        </>
                                                    );

                                                })}
                                            </CardBody>

                                        </Card>

                                    </GridItem>
                                </GridContainer>
                                <br />
                                <br />
                                <GridContainer justify="center" style={{ padding: "0 10px" }}>
                                    {itemArr.map((key, index) => {
                                        return (
                                            <GridItem
                                                key={key.id}
                                                xs={12} >
                                                <Card >
                                                    <CardHeader color="primary" text>
                                                        <CardText color="primary" style={{ width: "155px", height: "50px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                            <h4>Line Item(s) </h4>
                                                        </CardText>
                                                    </CardHeader>



                                                    <CardBody>

                                                        {/* {console.log("TESTING ITEMS: ", itemArr)} */}
                                                        <GridContainer >
                                                            <GridItem xs={4}>
                                                                <TextField
                                                                    label="*Line Item Name"
                                                                    id="itemName"
                                                                    fullWidth
                                                                    InputProps={{
                                                                        inputProps: {
                                                                            // onChange: e => handleItem(e, index, editorState1),
                                                                            min: 0,
                                                                            step: 0.01,
                                                                            // defaultValue: invoice.Item_Info.Item
                                                                            // value: itemArr[index].itemName,
                                                                            defaultValue: invoice.Item_Info.Item[index],
                                                                            readOnly: true
                                                                        }

                                                                    }}
                                                                    error={key.itemNameErr}
                                                                    helperText={key.itemNameErr ? 'Error! Input is invalid.' : ''}
                                                                />
                                                            </GridItem>
                                                            <GridItem xs={4}>
                                                                <TextField
                                                                    label="*Quantity"
                                                                    id="quantity"
                                                                    fullWidth
                                                                    type="number"
                                                                    // value={itemArr[index].quantity}
                                                                    InputProps={{
                                                                        inputProps: {
                                                                            // onChange: e => handleItem(e, index, editorState1),
                                                                            min: 0,
                                                                            step: 0.01,
                                                                            // value: itemArr[index].quantity,
                                                                            defaultValue: invoice.Item_Info.Item_Quantity[index],
                                                                            readOnly: true
                                                                        }

                                                                    }}
                                                                    error={key.quantityErr}
                                                                    helperText={key.quantityErr ? 'Error! Input is invalid.' : ''}
                                                                />
                                                            </GridItem>
                                                            <GridItem xs={4}>
                                                                <TextField
                                                                    label="*Price"
                                                                    id="price"
                                                                    fullWidth
                                                                    type="number"
                                                                    // value={itemArr[index].price}
                                                                    InputProps={{
                                                                        inputProps: {
                                                                            // onChange: e => handleItem(e, index, editorState1),
                                                                            min: 0,
                                                                            step: 0.01,
                                                                            // value: itemArr[index].price,
                                                                            defaultValue: invoice.Item_Info.Item_Price[index],
                                                                            readOnly: true
                                                                        }

                                                                    }}
                                                                    error={key.priceErr}
                                                                    helperText={key.priceErr ? 'Error! Input is invalid.' : ''}
                                                                />
                                                            </GridItem>
                                                            <br />
                                                            <br />
                                                            <br />
                                                            <GridItem xs={12}>
                                                                <div id="descrip" dangerouslySetInnerHTML={{ __html: invoice.Item_Info.Item_Description }} />

                                                                {/* <div onClick={editorRef.current.focus()}> */}
                                                                {/* <Editor
                                                                    readOnly={true}
                                                                    id="description"
                                                                    // defaultValue={convertFromHTML(invoice.Item_Info.Item_Description)}
                                                                    placeholder="Description for your line item..."
                                                                    editorState={EditorState.acceptSelection(editorState1, editorState1.getSelection())}
                                                                    // onEditorStateChange={(editorState1) => updateText(editorState1, index)}
                                                                    // onEditorStateChange={(e) => handleItem(e,index)}
                                                                    wrapperClassName="wrapper-class"
                                                                    editorClassName="editor-class"
                                                                    toolbarClassName="toolbar-class"
                                                                    editorStyle={{ border: "1px solid grey" }}
                                                                    toolbarStyle={{ border: "1px solid grey" }}
                                                                /> */}
                                                                {/* </div> */}
                                                            </GridItem>
                                                        </GridContainer>

                                                    </CardBody>
                                                    <div style={{ display: "flex", justifyContent: "right", paddingRight: "20px", marginTop: "-10px" }} id="amt">
                                                        <p style={{ fontSize: "16px" }}>Amount: <b>{Number(amt[index]).toFixed(2)}</b></p>
                                                        {/* {console.log(amt)} */}
                                                    </div>
                                                </Card>
                                            </GridItem>
                                        );

                                    })}

                                    {/* <div style={{ display: "flex", marginRight: "69%", marginTop: "-25px" }}> */}

                                    {/* </div> */}
                                </GridContainer>
                                <GridContainer style={{ justifyContent: "left", display: "flex", paddingLeft: "26px" }}>
                                    <GridItem xs={12} style={{ marginTop: "-25px" }} >
                                        {/* <Button
                                            onClick={handleItemCount}
                                            color="primary"
                                            style={{ fontWeight: "600", width: "180px" }}
                                        >Add another line item</Button> */}
                                    </GridItem>

                                </GridContainer>
                                <br />
                                <GridContainer justify="center" style={{ padding: "0 10px", paddingTop: "20px" }} >
                                    <GridItem xs={12}>
                                        <Card>
                                            <CardHeader color="primary" text>
                                                <CardText color="primary" style={{ width: "225px", height: "50px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                    <h4>Additional Comments </h4>
                                                </CardText>
                                            </CardHeader>
                                            <CardBody>
                                                <div className="invoiceComments" id="comments" dangerouslySetInnerHTML={{ __html: invoice.Comments }} />
                                                {/* <Editor
                                                    readOnly={true}
                                                    editorState={editorState2}
                                                    // onEditorStateChange={updateComments}
                                                    wrapperClassName="wrapper-class"
                                                    editorClassName="editor-class"
                                                    toolbarClassName="toolbar-class"
                                                    editorStyle={{ border: "1px solid grey" }}
                                                    toolbarStyle={{ border: "1px solid grey" }}
                                                /> */}

                                            </CardBody>
                                        </Card>

                                    </GridItem>
                                </GridContainer>

                            </GridItem>
                            <GridItem xs={3}>
                                <GridContainer justify="center" style={{ padding: "0 10px" }} >
                                    <GridItem xs={12}>
                                        <Card>
                                            <CardHeader color="primary" text>
                                                <CardText color="primary" style={{ width: "155px", height: "45px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                    <h4>Invoice Total </h4>
                                                </CardText>
                                            </CardHeader>
                                            <CardBody style={{ paddingTop: "30px" }}>
                                                <TextField
                                                    label="Invoice Created "
                                                    id="date"
                                                    variant="outlined"
                                                    fullWidth
                                                    InputProps={{
                                                        inputProps: {
                                                            // onChange: handleChange,
                                                            // defaultValue: moment().format("MM/DD/YYYY"),
                                                            defaultValue: invoice.Date,
                                                            readOnly: true
                                                        }

                                                    }}
                                                    focused

                                                />
                                                <br />
                                                <br />


                                                <TextField
                                                    label="Due Date"
                                                    id="dueDate"
                                                    variant="outlined"
                                                    fullWidth
                                                    focused
                                                    // type="date"
                                                    InputProps={{
                                                        inputProps: {
                                                            // onChange: handleChange,
                                                            defaultValue: invoice.dueDate,
                                                            readOnly: true
                                                        }

                                                    }}
                                                    // color="success"
                                                    error={info.dueDateError}
                                                    helperText={info.dueDateError === "Invalid input." ? 'Error! Input is invalid.' :
                                                        info.dueDateError === "Due Date has to be after today's date." ? "Due Date has to be after today's date." : ''}

                                                />

                                                <br />
                                                <p> --------------------------------------------------</p>

                                                <TextField
                                                    label="Subtotal"
                                                    id="subtotal"
                                                    variant="outlined"
                                                    fullWidth
                                                    InputProps={{
                                                        inputProps: {
                                                            value: !subTotal ? '' : subTotal.toFixed(2),
                                                            readOnly: true
                                                        }

                                                    }}
                                                // error={info.emailError}
                                                // helperText={info.emailError ? 'Error! Input is invalid.' : ''}
                                                />
                                                <br />
                                                <br />

                                                <TextField
                                                    label="Other discounts"
                                                    id="discounts"
                                                    variant="outlined"
                                                    fullWidth
                                                    type="number"
                                                    InputProps={{
                                                        inputProps: {
                                                            // onChange: handleChange,
                                                            min: 0,
                                                            step: 0.01,
                                                            defaultValue: invoice.Total.discounts,
                                                            readOnly: true
                                                        }

                                                    }}
                                                // error={info.emailError}
                                                // helperText={info.emailError ? 'Error! Input is invalid.' : ''}
                                                />
                                                <br />
                                                <br />
                                                <TextField
                                                    label="Other amount"
                                                    id="oamount"
                                                    variant="outlined"
                                                    fullWidth
                                                    type="number"
                                                    InputProps={{
                                                        inputProps: {
                                                            // onChange: handleChange,
                                                            min: 0.00,
                                                            step: 0.01,
                                                            defaultValue: invoice.Total.otherAmounts,
                                                            readOnly: true
                                                        }

                                                    }}
                                                />
                                                <br />
                                                <p> --------------------------------------------------</p>

                                                <TextField
                                                    label="Total"
                                                    id="total"
                                                    variant="outlined"
                                                    focused
                                                    fullWidth
                                                    InputProps={{
                                                        inputProps: {
                                                            value: !total ? '' : total.toFixed(2),
                                                            readOnly: true,
                                                        }

                                                    }}

                                                // error={info.emailError}
                                                // helperText={info.emailError ? 'Error! Input is invalid.' : ''}
                                                />
                                                <br />
                                                <p> --------------------------------------------------</p>

                                                <h5>Payment Options</h5>

                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            disabled
                                                            checked={invoice.partialPayment.bool}
                                                            tabIndex={-1}
                                                            onClick={() => handleToggle(2)}
                                                            checkedIcon={<Check className={classes.checkedIcon} />}
                                                            icon={<Check className={classes.uncheckedIcon} />}
                                                            classes={{
                                                                checked: classes.checked,
                                                                root: classes.checkRoot
                                                            }}
                                                        />
                                                    }
                                                    classes={{
                                                        label: classes.label,
                                                        root: classes.labelRoot
                                                    }}
                                                    label="Allow partial payment"

                                                />
                                                {info.partPay === true ? (
                                                    <TextField
                                                        label="Minimum due"
                                                        id="minTotal"
                                                        variant="outlined"
                                                        focused
                                                        fullWidth
                                                        InputProps={{
                                                            inputProps: {
                                                                // onChange: handleChange,
                                                                defaultValue: invoice.partialPayment.total,
                                                                readOnly: true
                                                            }

                                                        }}
                                                        error={info.minError}
                                                        helperText={info.minError === "Invalid input." ? 'Error! Input is invalid.' : info.minError === "Partial payment is greater than total." ?
                                                            "Partial payment can't be greater than total." : ''}
                                                    />
                                                ) : null}


                                            </CardBody>
                                            <Button
                                                color="primary"
                                                style={{ fontWeight: "600", marginTop: "10px", display: "flex" }}
                                                onClick={generatePDF}>
                                                Download Invoice (PDF)</Button>
                                            {/* <Button onClick={check}>check</Button> */}

                                        </Card>

                                    </GridItem>
                                </GridContainer>
                            </GridItem>
                        </GridContainer>

                        {/* <Link to ={`/pages/pub-iframe-invoice/${invoice.uID}/${invoice.Invoice_ID}`}>
                            <Button style={{ width: "150px", fontWeight: "600", color: "#FFFFFF", backgroundColor: "#FC6047", marginTop: "5px" }}>
                                Pay this Invoice
                            </Button>
                            </Link> */}
                    </>
                ))}
                {/* </GridContainer> */}
            </div>
        </div>

    );
}
InvoicePage.propTypes = {
    classes: PropTypes.object.isRequired,
}

const mapStateToProps = (state) => {
    return {
        AuthBool: !!state.auth.user,
        authError: state.auth.error
    }
}
function mapDispatchToProps(actions) {
    return (dispatch) => ({
    })
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(Style)
)(InvoicePage);