import React, { useState, useEffect } from 'react';
import { db, firebase } from 'firebase/client.js';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import wurlaIcon from "assets/img/wurla-icon-1.png"
import avatar from "assets/img/faces/marc.jpg";
import lockIcon from "assets/img/Vector.png"
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';

// @material-ui/icons
import AddIcon from '@material-ui/icons/Add';

// core components
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import Button from 'components/CustomButtons/Button.js';
import Card from 'components/Card/Card.js';
import CardBody from 'components/Card/CardBody.js';
import CardHeader from 'components/Card/CardHeader.js';
import TokenTransactionItem from 'components/TokenTransactionItem/TokenTransactionItem';
import Loader from 'components/Loader/Loader';
import CardAvatar from 'components/Card/CardAvatar';
import { AddCircle } from '@material-ui/icons';

const Wallet = ({ classes, events }) => {
  // const [earned, spent] = events.reduce(
  //   (r, v) => {
  //     const fee = v.type === 'fee';
  //     r[fee ? 1 : 0] += v.tokens;
  //     return r;
  //   },
  //   [0, 0]
  // );
  const uid = firebase.auth().currentUser.uid;
  const [earned, setEarned] = useState(0);
  const [spent, setSpent] = useState(0)
  const [invoices, setInvoice] = useState([]);
  const [eventsInfo, setEventsInfo] = useState([])
  const [loading, setLoading] = useState(false);
  let totalCoins = earned - spent;
  let totalUSD = totalCoins / 1000;
  const [check, setCheck] = useState(false)
  function getInfo() {
    let coinsEarned = 0;
    let coinsSpent = 0;
    db.collection('users').doc(uid).get()
      .then(docSnap => {
        let items = docSnap.get("events")
        if (items === undefined || items === 0) {
          console.log("doesn't exist")
        }
        else {
          console.log("doc does exist")
          setEventsInfo(items)
          setCheck(true)
          for (let i = 0; i < items.length; i++) {
            if(items[i].type === "earned"){
              coinsEarned += Number(items[i].coins)
            }
            if(items[i].type === "spent"){
              coinsSpent += Number(items[i].coins)
            }
            
          }
          coinsEarned *= 1000
          coinsSpent *= 1000
          setSpent(coinsSpent)
          setEarned(coinsEarned)

        }
      })

  }

  useEffect(() => {
    getInfo();
  }, []);

  if (loading) {
    return <h1>Loading...</h1>;
  }
  return (
    <div>
      <GridContainer style={{ padding: '0px 20px', justifyContent: "space-between" }}>
        <GridItem xs={12}>
          <Card profile style={{ borderRadius: "5px", background: `linear-gradient(180deg, rgba(79, 14, 81, 0.93) 0%, rgba(142, 37, 114, 0.93) 100%)` }}>
            <CardAvatar>
              <a>
                <img style={{ maxWidth: "200px", maxHeight: "200px", margin: "-120px auto 0", borderRadius: "50%", overflow: "hidden", padding: "0", }} src={wurlaIcon} />
              </a>
            </CardAvatar>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} style={{ justifyContent: "center", color: "white" }}>
                  <p style={{ fontFamily: "Oleo Script Swash Caps", fontWeight: "700", fontSize: "35px" }}>${totalUSD.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} WUSD</p>
                  <p style={{ fontFamily: "Quattrocento Sans", fontWeight: "700", fontSize: "18px", paddingTop: "10px" }}>{totalCoins.toLocaleString()} coins</p>
                </GridItem>
                <GridItem xs={6} sm={6} md={6} style={{ color: "white" }}>
                  <p style={{ fontFamily: "Oleo Script Swash Caps", fontWeight: "700", fontSize: "35px" }}>{earned.toLocaleString()} coins</p>
                  <p style={{ fontFamily: "Quattrocento Sans", fontWeight: "700", fontSize: "18px", paddingTop: "5px", marginRight: "20px" }}>Earned</p>
                  {check === false ?
                    <Card>
                      <div style={{ display: "flex", flexDirection: "row", fontFamily: "Quattrocento Sans", fontWeight: "700", color: "#3C4858", fontSize: "22px", justifyContent: "space-between" }}>
                        <Button style={{ width: '10px', height: '40px', background: "linear-gradient(180deg, #F37361 0%, #FFDEAB 100%)", fontSize: "25px", marginLeft: "5px", marginTop: "15px" }}>+</Button>
                        <p style={{ marginTop: "15px", textAlign: "center" }}>PaymentID <br />Status</p>
                        <p style={{ marginTop: "15px", marginRight: "10px", color: "green", textAlign: "center" }}>$0 USD <br /> <p style={{ color: "#3C4858" }}>Date</p></p>
                      </div>
                    </Card>
                    :
                    <Card>
                      {(eventsInfo.reverse()).map((info) =>
                        <>
                          {info.type === "earned" ?
                            < div style={{ display: "flex", flexDirection: "row", fontFamily: "Quattrocento Sans", fontWeight: "700", color: "#3C4858", fontSize: "22px", justifyContent: "space-between" }}>
                              <Button style={{ width: '10px', height: '40px', background: "linear-gradient(180deg, #F37361 0%, #FFDEAB 100%)", fontSize: "25px", marginLeft: "5px", marginTop: "15px" }}>+</Button>
                              <p style={{ marginTop: "15px", textAlign: "center" }}>{info.paymentID} <br /> {info.status}</p>
                              <p style={{ marginTop: "15px", marginRight: "10px", color: "green", textAlign: "center" }}>${info.coins.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} USD <br /> <p style={{ color: "#3C4858" }}>{info.datePaid}</p></p>
                            </div>
                            : null}
                        </>
                      )}

                    </Card>}
                </GridItem>
                <GridItem xs={6} style={{ color: "white" }}>
                  <p style={{ fontFamily: "Oleo Script Swash Caps", fontWeight: "700", fontSize: "35px" }}>{spent.toLocaleString()} coins</p>
                  <p style={{ fontFamily: "Quattrocento Sans", fontWeight: "700", fontSize: "18px", paddingTop: "5px", marginRight: "20px" }}>Spent</p>
                  {check === false ?
                    <Card>
                      <div style={{ display: "flex", flexDirection: "row", fontFamily: "Quattrocento Sans", fontWeight: "700", color: "#3C4858", fontSize: "22px", justifyContent: "space-between" }}>
                        <Button style={{ width: '10px', height: '40px', background: "linear-gradient(180deg, #F37361 0%, #FFDEAB 100%)", fontSize: "25px", marginLeft: "5px", marginTop: "15px" }}>-</Button>
                        <p style={{ marginTop: "10px", textAlign: "center" }}>PaymentID <br /> Status</p>
                        <p style={{ marginTop: "10px", marginRight: "10px", color: "red", textAlign: "center" }}>-$0 USD <br /> <p style={{ color: "#3C4858" }}>Date</p></p>
                      </div>
                    </Card>
                    :
                    <Card>
                      {(eventsInfo.reverse()).map((info) =>
                        <>
                          {info.type === "spent" ?
                            <div style={{ display: "flex", flexDirection: "row", fontFamily: "Quattrocento Sans", fontWeight: "700", color: "#3C4858", fontSize: "22px", justifyContent: "space-between" }}>
                              <Button style={{ width: '10px', height: '40px', background: "linear-gradient(180deg, #F37361 0%, #FFDEAB 100%)", fontSize: "25px", marginLeft: "5px", marginTop: "15px" }}>-</Button>
                              <p style={{ marginTop: "10px", textAlign: "center" }}>{info.paymentID} <br /> {info.status}</p>
                              <p style={{ marginTop: "10px", marginRight: "10px", color: "red", textAlign: "center" }}>-${info.coins.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} USD <br /> <p style={{ color: "#3C4858" }}>{info.datePaid}</p></p>
                            </div>
                            : null}
                        </>
                      )}

                    </Card>
                  }


                </GridItem>



                <GridItem xs={4}>
                  <Button style={{ fontFamily: "Quattrocento Sans", fontWeight: "bold", fontSize: "22px", float: "left", width: "250px", height: "60px", borderRadius: "5px", background: "#AD1E5A" }}>Transfer</Button>
                </GridItem>
                <GridItem xs={4} >
                  <Button style={{ fontFamily: "Quattrocento Sans", fontWeight: "bold", fontSize: "22px", float: "middle", width: "250px", height: "60px", borderRadius: "5px", background: "#F37361" }}>Withdraw</Button>
                </GridItem>
                <GridItem xs={4} >
                  <Button style={{ fontFamily: "Quattrocento Sans", fontWeight: "bold", fontSize: "22px", float: "right", width: "250px", height: "60px", borderRadius: "5px", background: "#FFDEAB", color: "#3C4858" }}>Deposit</Button>
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={6} sm={6} md={6} style={{ marginTop: "-35px" }}>
          <Card style={{ background: 'linear-gradient(180deg, rgba(79, 14, 81, 0.93) 0%, rgba(142, 37, 114, 0.93) 100%)', padding: '5%' }}>
            <div style={{ color: "white", fontFamily: "Quattrocento Sans", fontWeight: "bold", display: "flex", paddingTop: "15px" }}>
              <h1 style={{ fontFamily: "Quattrocento Sans", fontWeight: "bold", marginTop: "-30px", alignSelf: "flex-start", paddingRight: "30px" }}>Vault</h1>
              <Button style={{ fontSize: "20px", fontFamily: "Quattrocento Sans", fontWeight: "bold", marginTop: "-27px", width: "200px", height: "40px", background: "linear-gradient(0deg, #8E2572, #8E2572), #8E2572" }}> Learn More</Button>
            </div>
            <center>
              <p style={{ fontFamily: "Oleo Script Swash Caps", fontWeight: "700", fontSize: "35px", color: "white" }}>$100 USD</p>
              <p style={{ fontFamily: "Quattrocento Sans", fontWeight: "700", fontSize: "18px", paddingTop: "5px", marginRight: "10px", color: "white" }}> 10,000 coins</p>
            </center>
            <p style={{ fontFamily: "Quattrocento Sans", fontWeight: "700", fontSize: "18px", color: "white" }}> Contracts Locked</p>
            <Card style={{ marginTop: "-3px" }}>
              <div style={{ display: "flex", flexDirection: "row", fontFamily: "Quattrocento Sans", fontWeight: "700", color: "#3C4858", fontSize: "22px", justifyContent: "space-between" }}>
                <img src={lockIcon} style={{ width: "60px", height: "60px", padding: "10px" }} />
                <p style={{ marginTop: "10px", textAlign: "left" }}>ABC TECH <br /> 12/26/2021</p>
                <p style={{ marginTop: "10px", marginRight: "10px", color: "green", textAlign: "left" }}>$1.25 <br /> <p style={{ color: "#3C4858" }}>Interest Gained</p></p>
                <p style={{ marginTop: "10px", textAlign: "center", paddingRight: "20px" }}>01<br />Days</p>
              </div>
            </Card>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Button style={{ fontSize: "22px", fontFamily: "Quattrocento Sans", fontWeight: "bold", width: "200px", height: "70px", float: "left", background: "#F37361", borderRadius: "5px" }}>Add More</Button>
              <Button style={{ fontSize: "22px", fontFamily: "Quattrocento Sans", fontWeight: "bold", width: "220px", height: "70px", float: "right", background: "#FFDEAB", borderRadius: "5px", color: "#3C4858" }}>Unlock Contract</Button>
            </div>
          </Card>
        </GridItem>
        <GridItem xs={6} sm={6} md={6} style={{ marginTop: "-35px" }}>
          <Card style={{ background: 'linear-gradient(180deg, rgba(79, 14, 81, 0.93) 0%, rgba(142, 37, 114, 0.93) 100%)', padding: '5%' }}>
            <div style={{ color: "white", fontFamily: "Quattrocento Sans", fontWeight: "bold", display: "flex", paddingTop: "15px" }}>
              <h1 style={{ fontFamily: "Quattrocento Sans", fontWeight: "bold", marginTop: "-30px", alignSelf: "flex-start", paddingRight: "30px" }}>D5</h1>
            </div>
            <center>
              <p style={{ fontFamily: "Oleo Script Swash Caps", fontWeight: "700", fontSize: "35px", color: "white" }}>$50 USD</p>
              <p style={{ fontFamily: "Quattrocento Sans", fontWeight: "700", fontSize: "18px", paddingTop: "5px", marginRight: "10px", color: "white" }}> 5,000 coins</p>
            </center>
            <p style={{ fontFamily: "Quattrocento Sans", fontWeight: "700", fontSize: "18px", color: "white" }}> Funds Locked</p>
            <Card style={{ marginTop: "-3px" }}>
              <div style={{ display: "flex", flexDirection: "row", fontFamily: "Quattrocento Sans", fontWeight: "700", color: "#3C4858", fontSize: "22px", justifyContent: "space-between" }}>
                <img src={lockIcon} style={{ width: "60px", height: "60px", padding: "10px" }} />
                <p style={{ marginTop: "10px", textAlign: "left" }}>ABC TECH <br /> 12/26/2021</p>
                <p style={{ marginTop: "10px", marginRight: "10px", color: "green", textAlign: "left" }}>$1.25 <br /> <p style={{ color: "#3C4858" }}>Interest Gained</p></p>
                <p style={{ marginTop: "10px", textAlign: "center", paddingRight: "20px" }}>01<br />Days</p>
              </div>
            </Card>
            <Button style={{ fontSize: "22px", fontFamily: "Quattrocento Sans", fontWeight: "bold", height: "70px", background: "#AD1E5A", borderRadius: "5px" }}>Lock More Funds</Button>
          </Card>
        </GridItem>
      </GridContainer>
    </div >
  );
};

const mapStateToProps = (state) => {
  const { uid, events } = state.profile || {};
  return { uid, events };
};

Wallet.defaultProps = {
  events: [],
};

Wallet.propTypes = {
  uid: PropTypes.string,
  classes: PropTypes.object.isRequired,
  events: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      tokens: PropTypes.number.isRequired,
    })
  ),
};

export default compose(
  connect(mapStateToProps),
  //   withStyles(Style)
)(Wallet);
