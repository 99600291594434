import { grayColor } from "assets/jss/material-dashboard-pro-react.js";

const modalStyle = theme => ({
  modalRoot: {
    overflow: "auto",
    alignItems: "unset",
    justifyContent: "unset"
  },
  modal: {
    [theme.breakpoints.up("sm")]: {
      maxWidth: "800px",
      margin: "auto"
    },
    background: "linear-gradient(180deg, rgba(79, 14, 81, 0.93) 0%, rgba(142, 37, 114, 0.93) 100%);",
    borderRadius: "6px",
    marginTop: "100px !important",
    overflow: "visible",
    maxHeight: "unset",
    position: "relative",
    height: "fit-content"
  },
  modalHeader: {
    borderBottom: "none",
    paddingTop: "24px",
    paddingRight: "24px",
    paddingBottom: "0",
    paddingLeft: "24px",
    minHeight: "16.43px"
  },
  modalTitle: {
    margin: "0",
    lineHeight: "1.42857143"
  },
  modalCloseButton: {
    // color: grayColor[0],
    color:"white",
    marginTop: "-12px",
    WebkitAppearance: "none",
    padding: "0",
    cursor: "pointer",
    background: "0 0",
    border: "0",
    fontSize: "inherit",
    opacity: ".9",
    textShadow: "none",
    fontWeight: "700",
    lineHeight: "1",
    float: "right",
    "&:hover" : {
        color: grayColor[1],
    },
  },
  modalClose: {
    width: "16px",
    height: "16px",
  },
  modalBody: {
    paddingTop: "24px",
    paddingRight: "24px",
    paddingBottom: "16px",
    paddingLeft: "24px",
    position: "relative",
    overflow: "visible"
  },
  modalFooter: {
    padding: "15px",
    textAlign: "right",
    paddingTop: "0",
    margin: "0"
  },
  modalFooterCenter: {
    marginLeft: "auto",
    marginRight: "auto"
  },
  instructionNoticeModal: {
    marginBottom: "25px"
  },
  imageNoticeModal: {
    maxWidth: "150px"
  },
  modalSmall: {
    width: "300px"
  },
  modalSmallBody: {
    paddingTop: "0"
  },
  modalSmallFooterFirstButton: {
    margin: "0",
    paddingLeft: "16px",
    paddingRight: "16px",
    width: "auto",
    backgroundColor:"green"
  },
  modalSmallFooterSecondButton: {
    marginBottom: "0",
    marginLeft: "5px",
    backgroundColor:'red'
  },
  cancelButton:{
    float: "left", 
    backgroundColor: "#f37361",
    borderRadius: "5px",
    color:"#FFFFFF",
    width : "200px",
    fontFamily: "Quattrocento Sans",
    fontWeight:"bold",
    "&:hover": {
      cursor: "pointer",
      background: "#f37361"
    }
  },
  submitButton: {
    alignItems:"center",
    float:"right",
    marginRight:"20px",
    backgroundColor: "#ffdeab",
    borderRadius: "5px",
    color:"#3C4858",
    width : "200px",
    fontFamily: "Quattrocento Sans",
    fontWeight:"bold",
    "&:hover": {
      cursor: "pointer",
      background: "#ffdeab"
    }
  },
});

export default modalStyle;
