import React, { useState, useRef, useEffect } from "react";
import BoundEditorForm from 'components/Explorer/BoundEditorForm';
import { connect } from "react-redux";
import actions from 'store/actions/forms/profile';
import accessors from 'store/reducers/forms/profile';
import PropTypes from "prop-types";
import compose from "recompose/compose";
import { Link } from "react-router-dom";
import moment from "moment";
import { db, firebase } from 'firebase/client.js'
import actionsForNavigation from "store/actions/navigation";

// @material-ui/core components
import InputAdornment from "@material-ui/core/InputAdornment";
import { Box, Dialog, DialogActions, DialogContent, DialogTitle } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import TextField from "@material-ui/core/TextField";
// @material-ui/icons
import { Edit, } from "@material-ui/icons";
// core components
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardAvatar from "components/Card/CardAvatar.js";
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

// styles
import Style from "assets/jss/material-dashboard-pro-react/views/registerPageStyle";
import avatar from "assets/img/faces/marc.jpg";
import noLogo from "assets/img/add-logo.png";

export function ProfileEditor(props) {
    const { classes } = props;
    const [loading, setLoading] = useState(false);
    const uID = firebase.auth().currentUser.uid;
    const ref = firebase.firestore().collection("users").doc(uID)
    const [profileInfo, setProfileInfo] = useState([]);
    const [info, setInfo] = useState({
        fName: '',
        lName: '',
        number: '',
        email: '',
        type: '',
        company: '',
        serviceType: '',
        otherCompany: '',
        payPalAcc: '',
        description: '',
        fNameErr: '',
        lNameErr: '',
        numberErr: '',
        emailErr: '',
        serviceTypeErr: '',
        companyErr: '',
    });
    const [companyInfo, setCompanyInfo] = useState({
        companyName: '',
        serviceType: '',
        address: '',
        city: '',
        state: '',
        zipcode: '',
        number: '',
        companyNameError: '',
        serviceTypeErr: '',
        addressError: '',
        cityError: '',
        stateError: '',
        zipcodeError: '',
        numberError: ''
    })
    const [files, setFiles] = useState({
        fileName: '',
        fileError: ''
    });

    const [logos, setLogos] = useState({
        logo: '',
        logoError: ''
    })
    const [show, setShow] = useState(false);
    const onClose = () => {
        setShow(false)
    }
    const [show2, setShow2] = useState(false)
    const onCloseShow2 = () => {
        setShow2(false)
    }
    const [showLogo, setShowLogo] = useState(false);
    const onCloseLogo = () => {
        setShowLogo(false)
        setLogos({
            logo: '',
            logoError: ''
        })
    }
    const [image, setImage] = useState([])
    const [logoimg, setLogoImg] = useState([])
    const onFileChange = async (e) => {
        setFiles({
            ...files,
            [e.target.id]: e.target.files[0]
        })
    }

    const onLogoChange = async (e) => {
        setLogos({
            ...logos,
            [e.target.id]: e.target.files[0]
        })
    }

    const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    const getImage = async () => {
        let storageRef = await firebase.storage().ref().child(`${uID}/profilePicture`).listAll();
        const urls = await Promise.all(storageRef.items.map((refer) => refer.getDownloadURL()));
        setImage(urls);
    }

    const getLogo = async () => {
        let storageRef = await firebase.storage().ref().child(`${uID}/companyLogo`).listAll();
        const urls = await Promise.all(storageRef.items.map((refer) => refer.getDownloadURL()));
        setLogoImg(urls);
    }

    const handleChange = (e) => {
        setInfo({
            ...info,
            [e.target.id]: e.target.value
        })
    }

    const companyChange = (e) => {
        setCompanyInfo({
            ...companyInfo,
            [e.target.id]: e.target.value
        })
    }

    const checkFile = () => {
        let fileError = ""

        if (!files.fileName) {
            fileError = "Invalid input."
            setFiles({ ...files, fileError })
            return false
        }
        return true
    }
    const onSubmit = async () => {
        let isValid = checkFile();

        if (isValid) {
            console.log("success")
            const file = files.fileName;
            const storageRef = firebase.storage().ref(`${uID}/profilePicture/`)
            const fileRef = storageRef.child(file.name)
            await fileRef.put(file)
                .then(() => {
                    window.location.reload()
                })
        }
        else {
            console.log("error")
        }
    }

    const checkLogo = () => {
        let logoError = ""

        if (!logos.logo) {
            logoError = "Invalid input."
            setLogos({ ...logos, logoError })
            return false
        }
        return true
    }


    const validate = () => {
        let fNameErr = '';
        let lNameErr = '';
        let numberErr = '';
        let emailErr = '';
        let serviceTypeErr = '';
        let companyErr = ''

        if (!info.fName) {
            fNameErr = "invalid input"
        }
        if (!info.lName) {
            lNameErr = "invalid input"
        }
        if (!info.number) {
            numberErr = 'invalid input'
        }
        if (!emailRegex.test(info.email)) {
            emailErr = "invalid email"
        }
        if (!info.serviceType) {
            serviceTypeErr = "invalid input"
        }
        if (!info.company) {
            companyErr = "invalid input"
        }

        if (fNameErr || lNameErr || numberErr || emailErr || serviceTypeErr || companyErr) {
            setInfo({
                ...info,
                fNameErr,
                lNameErr,
                numberErr,
                emailErr,
                serviceTypeErr,
                companyErr
            })
            return false
        }
        return true
    }
    const onSubmitLogo = async (e) => {
        e.preventDefault();
        let isValid = checkLogo();
        if (isValid) {
            console.log("working")
            setShowLogo(false)
            console.log("success")
            const file = logos.logo;
            const storageRef = firebase.storage().ref(`${uID}/companyLogo/`)
            const fileRef = storageRef.child(file.name)
            await fileRef.put(file)
                .then(() => {
                    setShowLogo(false)
                })
        }
        else {
            console.log("error inside submit logo")
        }
    }

    const onUpdate = async (e) => {
        e.preventDefault();
        const isValid = validate();
        // console.log(info)

        let data = {
            firstName: info.fName,
            lastName: info.lName,
            email: info.email,
            number: info.number,
            company: info.company,
            otherCompany: info.otherCompany,
            serviceType: info.serviceType,
            payPalEmail: info.payPalAcc,
            description: info.description
        }
        if (isValid) {
            console.log("success")
            await ref.update(data)
                .then(() => {
                    props.reload()
                })
        }
        else {
            console.log("error inside on update")
        }
    }

    const checkCompany = () => {
        let companyNameError = "";
        let serviceTypeError = "";
        let addressError = "";
        let cityError = "";
        let stateError = "";
        let zipcodeError = "";
        let numberError = "";

        if (!companyInfo.companyName) {
            companyNameError = "Invalid input"
        }
        if (!companyInfo.serviceType) {
            serviceTypeError = "Invalid input"
        }
        if (!companyInfo.address) {
            addressError = "Invalid input"
        }
        if (!companyInfo.city) {
            cityError = "Invalid input"
        }
        if (!companyInfo.state) {
            stateError = "Invalid input"
        }
        if (!companyInfo.zipcode) {
            zipcodeError = "Invalid input"
        }
        if (!companyInfo.number) {
            numberError = "Invalid input"
        }

        if (companyNameError || serviceTypeError || addressError || cityError || stateError
            || zipcodeError || numberError) {
            setCompanyInfo({
                ...companyInfo,
                companyNameError,
                serviceTypeError,
                addressError,
                cityError,
                stateError,
                zipcodeError,
                numberError
            })
            return false
        }

        return true

    }

    const onSubmitCompany = async (e) => {
        e.preventDefault();
        let isValid = checkCompany();

        let data = {
            companyName: companyInfo.companyName,
            serviceType: companyInfo.serviceType,
            address: companyInfo.address,
            city: companyInfo.city,
            state: companyInfo.state,
            zipcode: companyInfo.zipcode,
            number: companyInfo.number,
        }
        console.log("company info: ", companyInfo)
        if (isValid) {
            console.log("inside valid")
            await db.collection('users').doc(uID).collection('company-info').add(data)
                .then(() => {
                    props.reload()
                })
        }
        else {
            console.log("error inside onsubmit company")
        }
    }

    function getProfileInfo() {
        setLoading(true);
        ref.get().then((queryResult) => {
            const items = []
            items.push(queryResult.data())
            // console.log("itemsitems: ", items[0])
            setInfo({
                ...info,
                fName: items[0].firstName,
                lName: items[0].lastName,
                number: items[0].phoneNumber,
                email: items[0].email,
                type: items[0].userType,
                company: items[0].company,
            })
            setProfileInfo(items)
            setLoading(false)
        })

    }
    useEffect(() => {
        getProfileInfo();
    }, []);
    return (
        <div >
            <GridContainer style={{ justifyContent: "center" }}>
                <GridItem xs={12} sm={12} md={8} >
                    <Card profile style={{ background: `linear-gradient(180deg, rgba(79, 14, 81, 0.93) 0%, rgba(142, 37, 114, 0.93) 100%)`, borderRadius: "5px", width: "925px" }}>
                        <CardAvatar profile>
                            <a href="#pablo" onClick={(e) => setShow(true)}>
                                {image.length === 0 ? <img src={avatar} alt="..." /> : <img src={image[0]} alt="..." />}
                            </a>
                            <Dialog
                                open={show}
                                onClose={onClose}
                                aria-labelledby="alert-dialog-title"
                                aria-describedby="alert-dialog-description"
                            >
                                <form onSubmit={onSubmit} style={{ background: `linear-gradient(180deg, rgba(79, 14, 81, 0.93) 0%, rgba(127, 31, 106, 0.93) 100%)` }}>
                                    <DialogTitle id="alert-dialog-title" style={{ color: "white", fontFamily: "Quattrocento Sans", textAlign: "center" }}>
                                        Upload a profile picture
                                    </DialogTitle>
                                    <DialogContent>
                                        <GridContainer justify="center" style={{ padding: "0 10px" }}>
                                            <GridItem xs={12}>
                                                <Card >
                                                    <TextField
                                                        id="fileName"
                                                        formControlProps={{
                                                            fullWidth: true
                                                        }}
                                                        inputProps={{
                                                            type: "file",
                                                            onChange: onFileChange,
                                                            disableUnderline: true,
                                                        }}
                                                        error={files.fileError}
                                                        helperText={files.fileError ? 'Error! Please select a file.' : ''}
                                                    />
                                                </Card>
                                            </GridItem>

                                        </GridContainer>
                                    </DialogContent>
                                    <DialogActions>
                                        <Button onClick={onClose} className={classes.cancelButton}>
                                            Cancel
                                        </Button>
                                        <Button type="submit" className={classes.submitButton}>
                                            Update
                                        </Button>
                                    </DialogActions>
                                </form>
                            </Dialog>
                        </CardAvatar>
                        <CardBody profile>
                            <p style={{ fontFamily: "Quattrocento Sans", fontStyle: "italic", fontWeight: "bold", color: "#FFDEAB", marginTop: "-10px" }}>Edit Profile Picture</p>
                            {getImage() && getLogo() && profileInfo.map((data) => (
                                <>
                                    <form onSubmit={onUpdate}>
                                        <GridContainer>
                                            <GridItem xs={4}>
                                                <TextField
                                                    label="*First Name"
                                                    variant="filled"
                                                    id="fName"
                                                    fullWidth
                                                    InputProps={{
                                                        inputProps: {
                                                            onChange: handleChange,
                                                            defaultValue: data.firstName
                                                        },
                                                        className: classes.textBox

                                                    }}
                                                    error={info.fNameErr}
                                                    helperText={info.fNameErr ? 'Error! Input is invalid.' : ''}
                                                />
                                            </GridItem>
                                            <GridItem xs={4}>
                                                <TextField
                                                    label="*Last Name"
                                                    variant="filled"
                                                    id="lName"
                                                    fullWidth
                                                    InputProps={{
                                                        inputProps: {
                                                            onChange: handleChange,
                                                            defaultValue: data.lastName
                                                        },
                                                        className: classes.textBox

                                                    }}
                                                    error={info.lNameErr}
                                                    helperText={info.lNameErr ? 'Error! Input is invalid.' : ''}
                                                />
                                            </GridItem>
                                            <GridItem xs={4}>
                                                <TextField
                                                    label="*Phone Number"
                                                    variant="filled"
                                                    id="number"
                                                    fullWidth
                                                    InputProps={{
                                                        inputProps: {
                                                            onChange: handleChange,
                                                            defaultValue: data.phoneNumber
                                                        },
                                                        className: classes.textBox

                                                    }}
                                                    error={info.numberErr}
                                                    helperText={info.numberErr ? 'Error! Input is invalid.' : ''}
                                                />
                                            </GridItem>
                                            <GridItem xs={4} style={{ marginTop: "10px" }}>
                                                <TextField
                                                    label="*Email"
                                                    variant="filled"
                                                    id="email"
                                                    fullWidth
                                                    InputProps={{
                                                        inputProps: {
                                                            onChange: handleChange,
                                                            defaultValue: data.email,
                                                        },
                                                        className: classes.textBox

                                                    }}
                                                    error={info.emailErr}
                                                    helperText={info.emailErr ? 'Error! Input is invalid.' : ''}
                                                />
                                            </GridItem>
                                            <GridItem xs={4} style={{ marginTop: "10px" }}>
                                                <TextField
                                                    label="User Type"
                                                    variant="filled"
                                                    id="type"
                                                    fullWidth
                                                    InputProps={{
                                                        inputProps: {
                                                            onChange: handleChange,
                                                            defaultValue: data.userType,
                                                            readOnly: true
                                                        },
                                                        className: classes.textBox

                                                    }}
                                                // error={key.itemNameErr}
                                                // helperText={key.itemNameErr ? 'Error! Input is invalid.' : ''}
                                                />
                                            </GridItem>
                                            <GridItem xs={4} style={{ marginTop: "10px" }}>
                                                <TextField
                                                    label="*Company"
                                                    variant="filled"
                                                    id="company"
                                                    fullWidth
                                                    InputProps={{
                                                        inputProps: {
                                                            onChange: handleChange,
                                                            defaultValue: data.company
                                                        },
                                                        className: classes.textBox,
                                                        endAdornment: (<InputAdornment position="end" onClick={(e) => setShow2(true)}><Edit style={{ color: "black" }} /> </InputAdornment>),

                                                    }}
                                                    error={info.companyErr}
                                                    helperText={info.companyErr ? 'Error! Input is invalid.' : ''}
                                                />

                                            </GridItem>
                                            <GridItem xs={4} style={{ marginTop: "10px" }}>
                                                <TextField
                                                    label="*Service Type"
                                                    variant="filled"
                                                    id="serviceType"
                                                    fullWidth
                                                    InputProps={{
                                                        inputProps: {
                                                            onChange: e => handleChange(e),
                                                        },
                                                        className: classes.textBox

                                                    }}
                                                    error={info.serviceTypeErr}
                                                    helperText={info.serviceTypeErr ? 'Error! Input is invalid.' : ''}
                                                />
                                            </GridItem>
                                            <GridItem xs={4} style={{ marginTop: "10px" }}>
                                                <TextField
                                                    label="Other Company"
                                                    variant="filled"
                                                    id="otherCompany"
                                                    fullWidth
                                                    InputProps={{
                                                        inputProps: {
                                                            onChange: e => handleChange(e),
                                                        },
                                                        className: classes.textBox

                                                    }}
                                                // error={key.itemNameErr}
                                                // helperText={key.itemNameErr ? 'Error! Input is invalid.' : ''}
                                                />
                                            </GridItem>
                                            <GridItem xs={4} style={{ marginTop: "10px" }}>
                                                <TextField
                                                    label="PayPal Account"
                                                    variant="filled"
                                                    id="payPalAcc"
                                                    fullWidth
                                                    InputProps={{
                                                        inputProps: {
                                                            onChange: e => handleChange(e),
                                                        },
                                                        className: classes.textBox

                                                    }}
                                                // error={key.itemNameErr}
                                                // helperText={key.itemNameErr ? 'Error! Input is invalid.' : ''}
                                                />
                                            </GridItem>
                                            <GridItem xs={12} style={{ marginTop: "10px" }}>
                                                <TextField
                                                    multiline={true}
                                                    rows={3}
                                                    variant="filled"
                                                    label="Description"
                                                    id="description"
                                                    fullWidth
                                                    InputProps={{
                                                        inputProps: {
                                                            onChange: e => handleChange(e),
                                                        },
                                                        className: classes.textBox

                                                    }}
                                                />
                                            </GridItem>
                                        </GridContainer>
                                        <Button className={classes.cancelButton} onClick={e => props.reload()} style={{ marginTop: "20px" }}>
                                            Cancel
                                        </Button>

                                        <Button
                                            type="submit"
                                            className={classes.submitButton} style={{ marginTop: "20px" }}>
                                            Submit
                                        </Button>
                                    </form>
                                    <Dialog
                                        open={show2}
                                        // onClose={onClose}
                                        aria-labelledby="alert-dialog-title"
                                        aria-describedby="alert-dialog-description"
                                        maxWidth="md">
                                        <form onSubmit={onSubmitCompany} style={{ background: `linear-gradient(180deg, rgba(79, 14, 81, 0.93) 0%, rgba(127, 31, 106, 0.93) 100%)` }}>
                                            <DialogTitle id="alert-dialog-title" style={{ color: "white", fontFamily: "Quattrocento Sans", textAlign: "center" }}>
                                                Please fill out the information below
                                            </DialogTitle>
                                            <DialogContent>
                                                <GridContainer style={{ justifyContent: "center" }}>
                                                    <GridContainer>
                                                        <GridItem xs={6}>
                                                            <Box
                                                                sx={{
                                                                    width: 300,
                                                                    height: 100,
                                                                    backgroundColor: "white"
                                                                }}
                                                                style={{ marginLeft: "15px", marginTop: "15px", display: "flex" }}
                                                                onClick={() => { setShowLogo(true) }}
                                                            >
                                                                {logoimg.length === 0 ?
                                                                    <a>
                                                                        <img src={noLogo} style={{ width: 50, height: 50, marginLeft: "10px", marginTop: "25px" }} />
                                                                        <p className={classes.insideText}>Add company logo</p>
                                                                    </a>
                                                                    :
                                                                    <a>
                                                                        <img src={logoimg[0]} alt="..." style={{ maxWidth: 300, maxHeight: 100 }}/>
                                                                    </a>
                                                                }
                                                            </Box>
                                                        </GridItem>
                                                        <GridItem xs={6}>
                                                            <GridItem xs={6} style={{ marginTop: "-20px" }}>
                                                                <Card style={{ width: "350px" }}>
                                                                    <TextField
                                                                        label="Main Company Name"
                                                                        id="companyName"
                                                                        inputProps={{
                                                                            onChange: companyChange,
                                                                            // disableUnderline: true,
                                                                        }}
                                                                        error={companyInfo.companyNameError}
                                                                        helperText={companyInfo.companyNameError ? 'Error! Invalid input.' : ''}
                                                                    />

                                                                </Card>

                                                            </GridItem>
                                                            <GridItem xs={6} style={{ marginTop: "20px" }}>
                                                                <Card style={{ width: "350px" }}>
                                                                    <TextField
                                                                        label="Service Type"
                                                                        id="serviceType"
                                                                        formControlProps={{
                                                                            fullWidth: true
                                                                        }}
                                                                        inputProps={{
                                                                            onChange: companyChange,
                                                                            disableUnderline: true,
                                                                        }}
                                                                        error={companyInfo.serviceTypeErr}
                                                                        helperText={companyInfo.serviceTypeErr ? 'Error! Invalid input.' : ''}
                                                                    />

                                                                </Card>

                                                            </GridItem>
                                                        </GridItem>
                                                    </GridContainer>
                                                    <GridItem xs={6} style={{ marginTop: "-20px" }}>
                                                        <Card>
                                                            <TextField
                                                                label="Address"
                                                                id="address"
                                                                formControlProps={{
                                                                    fullWidth: true
                                                                }}
                                                                inputProps={{
                                                                    onChange: companyChange,
                                                                    disableUnderline: true,
                                                                }}
                                                                error={companyInfo.addressError}
                                                                helperText={companyInfo.addressError ? 'Error! Invalid input.' : ''}
                                                            />

                                                        </Card>

                                                    </GridItem>
                                                    <GridItem xs={6} style={{ marginTop: "-20px" }}>
                                                        <Card>
                                                            <TextField
                                                                label="City"
                                                                id="city"
                                                                formControlProps={{
                                                                    fullWidth: true
                                                                }}
                                                                inputProps={{
                                                                    onChange: companyChange,
                                                                    disableUnderline: true,
                                                                }}
                                                                error={companyInfo.cityError}
                                                                helperText={companyInfo.cityError ? 'Error! Invalid input.' : ''}
                                                            />

                                                        </Card>

                                                    </GridItem>
                                                    <GridItem xs={4} style={{ marginTop: "-20px" }}>
                                                        <Card>
                                                            <TextField
                                                                label="State"
                                                                id="state"
                                                                formControlProps={{
                                                                    fullWidth: true
                                                                }}
                                                                inputProps={{
                                                                    onChange: companyChange,
                                                                    disableUnderline: true,
                                                                }}
                                                                error={companyInfo.stateError}
                                                                helperText={companyInfo.stateError ? 'Error! Invalid input.' : ''}
                                                            />

                                                        </Card>

                                                    </GridItem>
                                                    <GridItem xs={4} style={{ marginTop: "-20px" }}>
                                                        <Card>
                                                            <TextField
                                                                label="Zip Code"
                                                                id="zipcode"
                                                                formControlProps={{
                                                                    fullWidth: true
                                                                }}
                                                                inputProps={{
                                                                    onChange: companyChange,
                                                                    disableUnderline: true,
                                                                }}
                                                                error={companyInfo.zipcodeError}
                                                                helperText={companyInfo.zipcodeError ? 'Error! Invalid input.' : ''}
                                                            />

                                                        </Card>

                                                    </GridItem>
                                                    <GridItem xs={4} style={{ marginTop: "-20px" }}>
                                                        <Card>
                                                            <TextField
                                                                label="Phone Number"
                                                                id="number"
                                                                formControlProps={{
                                                                    fullWidth: true
                                                                }}
                                                                inputProps={{
                                                                    onChange: companyChange,
                                                                    disableUnderline: true,
                                                                }}
                                                                error={companyInfo.numberError}
                                                                helperText={companyInfo.numberError ? 'Error! Invalid input.' : ''}
                                                            />

                                                        </Card>

                                                    </GridItem>
                                                </GridContainer>
                                                <Button onClick={onCloseShow2} className={classes.cancelButton} style={{ padding: "10px" }}>
                                                    Cancel
                                                </Button>
                                                <Button type="submit" className={classes.submitButton} style={{ padding: "10px" }}>
                                                    Send
                                                </Button>

                                            </DialogContent>


                                        </form>

                                    </Dialog>
                                    <Dialog
                                        open={showLogo}
                                        onClose={onCloseLogo}
                                    >
                                        <form onSubmit={onSubmitLogo} style={{ background: `linear-gradient(180deg, rgba(79, 14, 81, 0.93) 0%, rgba(127, 31, 106, 0.93) 100%)` }}>
                                            <DialogTitle id="alert-dialog-title" style={{ color: "white", fontFamily: "Quattrocento Sans", textAlign: "center" }}>
                                                Upload a company logo
                                            </DialogTitle>
                                            <DialogContent>
                                                <GridContainer justify="center" style={{ padding: "0 10px" }}>
                                                    <GridItem xs={12}>
                                                        <Card >
                                                            <TextField
                                                                id="logo"
                                                                inputProps={{
                                                                    type: "file",
                                                                    onChange: onLogoChange,
                                                                    disableUnderline: true,
                                                                }}
                                                                error={logos.logoError}
                                                                helperText={logos.logoError ? 'Error! Please select a file.' : ''}
                                                            />
                                                        </Card>
                                                    </GridItem>

                                                </GridContainer>
                                            </DialogContent>
                                            <DialogActions>
                                                <Button onClick={onCloseLogo} className={classes.cancelButton}>
                                                    Cancel
                                                </Button>
                                                <Button type="submit" className={classes.submitButton}>
                                                    Update
                                                </Button>
                                            </DialogActions>
                                        </form>
                                    </Dialog>
                                </>
                            ))}


                        </CardBody>



                        {/* <EditorForm order={["firstName", "lastName", "phoneNumber", "company", "serviceType"]} /> */}
                    </Card>
                </GridItem>
            </GridContainer>
        </div>
    );
}

function mapStateToProps() {
    return (state) => {
        return {
            uid: state.auth.uid
        }
    };
}

function mapDispatchToProps() {
    return (dispatch) => {
        return {
            initialize: (mode, key) => dispatch(actions.initialize(mode, key)),
            reload: () => dispatch(actionsForNavigation.openChild("Profil Page", `/admin/profile`)),
        };
    }
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(Style)
)(ProfileEditor);