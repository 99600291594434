/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-key */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { Link, useParams } from "react-router-dom";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, ContentState, createWithContent, convertToRaw, convertFromRaw, convertFromHTML } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import actionsForNavigation from "store/actions/navigation";
import { async } from "@firebase/util";
import { db, firebase } from 'firebase/client.js';
import compose from "recompose/compose";
import { connect } from "react-redux";
import moment from "moment";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import TextField from "@material-ui/core/TextField";
// @material-ui/icons
import Check from "@material-ui/icons/Check";
import Close from "@material-ui/icons/Close";
// core components
import Card from "components/Card/Card.js";
import CardText from "components/Card/CardText";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import { PayPalButton } from "react-paypal-button-v2";
import { blue } from "@material-ui/core/colors";

// styles
import Style from "assets/jss/material-dashboard-pro-react/views/registerPageStyle";

export function InvoicePage(props) {
    const [invoices, setInvoice] = useState([]);
    const [loading, setLoading] = useState(false);
    const docID = firebase.auth().currentUser.uid;
    const { id } = useParams();
    const ref = firebase.firestore().collection("users").doc(docID).collection("estimates").doc(id);
    const { classes } = props;
    const paypal = useRef();

    const [checked, setChecked] = useState([24, 22], false);
    const [editorState1, setEditorState1] = useState(EditorState.createEmpty());
    const [editorState2, setEditorState2] = useState(EditorState.createEmpty());
    const [info, setInfo] = useState({
        comments: '',
        date: '',
        dueDate: '',
        oamount: '',
        discounts: '',
        minTotal: 0,
        status: 'Draft',
        partPay: false,
        dueDateError: '',
        minError: ''
    });
    // console.log("info",info)
    const [files, setFiles] = useState({
        fileName: '',
        fileError: ''
    });
    const [counter, setCounter] = useState(1);
    const [itemCounter, setItemCounter] = useState(1);
    const [subTotal, setSubTotal] = useState(0)
    const [amt, setAmt] = useState([0]);
    const [emailArr, setEmailArr] = useState([{
        id: counter,
        email: '',
        emailErr: ''
    },]);
    const [itemArr, setItemArr] = useState([{
        itemName: '',
        quantity: '',
        price: '',
        // description: draftToHtml(convertToRaw(editorState1.getCurrentContent())),
        description: '',
        id: itemCounter,
        itemNameErr: '',
        quantityErr: '',
        priceErr: '',
    },])

    // const editorRef = useRef(null)
    // console.log('itemArritemArritemArr', (itemArr))

    let total = Number(subTotal) + (Number(info.oamount) - Number(info.discounts))
    let totalMinusPartPay = total - Number(info.minTotal)
    const handleEmail = (e, i) => {
        console.log("counter", i)
        const arr = [...emailArr];
        arr[i][e.target.id] = e.target.value;
        setEmailArr(arr);
    }

    const handleItem = (e, i, state) => {
        let sub = 0;
        // setEditorState1(state)
        const arr = [...itemArr];
        arr[i][e.target.id] = e.target.value;
        // arr[i]["description"] = draftToHtml(convertToRaw(editorState1.getCurrentContent()))
        setItemArr(arr)
        const arr2 = [...amt]
        arr2[i] = (itemArr[i].quantity * itemArr[i].price)
        setAmt(Number(arr2));

        for (let x = 0; x < amt.length; x++) {
            sub += arr2[x]
        }
        setSubTotal(sub)
    }

    const updateText = (state, i) => {
        setEditorState1(state);
        const data = [...itemArr]
        data[i]["description"] = draftToHtml(convertToRaw(editorState1.getCurrentContent()));
        setItemArr(data)
    }
    // console.log("desc", desc)

    const updateComments = async (state) => {
        await setEditorState2(state);
        let data = draftToHtml(convertToRaw(editorState2.getCurrentContent()));
        setInfo({ ...info, comments: data })
    }

    const handleCount = () => {
        setEmailArr([...emailArr, { id: counter + 1, email: '', emailErr: '' }])
        setCounter(counter + 1)
    }

    const handleItemCount = () => {
        // console.log("itemcounter: ", itemCounter)
        setItemArr([...itemArr, { itemName: '', quantity: '', price: '', description: '', id: itemCounter + 1, itemNameErr: '', quantityErr: '', priceErr: '' }])
        setItemCounter(itemCounter + 1)
        setAmt([...amt, 0])
    }

    const deleteCount = (e, index) => {
        // console.log("index check: ", index)
        let arr = [...emailArr];
        arr.splice(index, 1);
        setEmailArr(arr)

    }

    // console.log('itemArritemArr', itemArr)

    const deleteItemCount = (e, index) => {
        let arr = [...itemArr]
        // console.log("index", index)
        arr.splice(index, 1)
        // console.log("after splice: ", arr)
        setItemArr(arr)

        let arr2 = [...amt];
        arr2.splice(index, 1)
        setAmt(arr2)

        let sub = subTotal
        sub -= amt[index]
        setSubTotal(sub)
    }

    const zipRegex = /^(?:\d{5})?$/;
    const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    const handleToggle = value => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }
        setChecked(newChecked);
        if (newChecked.length === 3) {
            setInfo({ ...info, partPay: true })
        }
        else {
            setInfo({ ...info, partPay: false })
        }
    };

    const handleChange = (e) => {
        setInfo({
            ...info,
            [e.target.id]: e.target.value
        })
    }

    const onFileChange = async (e) => {
        setFiles({
            ...files,
            [e.target.id]: e.target.files[0]
        })
    }
    const validate = () => {
        let dueDateError = '';
        let minError = '';
        let fileError = '';
        let isValid = true;

        for (let i = 0; i < emailArr.length; i++) {
            const copyArr = [...emailArr]
            if (!emailArr[i].email) {
                copyArr[i]["emailErr"] = "invalid input."
                isValid = false;
            }
            else {
                copyArr[i]["emailErr"] = ""
            }
            setEmailArr(copyArr)

        }

        for (let i = 0; i < itemArr.length; i++) {
            const copyArr = [...itemArr]
            if (!itemArr[i].itemName) {
                copyArr[i]["itemNameErr"] = "Invalid input."
                isValid = false;
            }
            else {
                copyArr[i]["itemNameErr"] = ""
            }
            if (!itemArr[i].quantity) {
                copyArr[i]["quantityErr"] = "Invalid input."
                isValid = false;
            }
            else {
                copyArr[i]["quantityErr"] = ""
            }
            if (!itemArr[i].price) {
                copyArr[i]["priceErr"] = "Invalid input."
                isValid = false;
            }
            else {
                copyArr[i]["priceErr"] = ""
            }
            setItemArr(copyArr)
        }

        if (!files.fileName) {
            console.log(files.fileName)
            console.log("no file chosen")
            fileError = "Error! No file selected";
            setFiles({ ...files, fileError });
            return false;
        }
        else {
            fileError = "";
            setFiles({ ...files, fileError });
        }

        if (info.partPay === true && !info.minTotal) {
            console.log("partpay error")
            minError = "Invalid input."
            setInfo({
                ...info,
                minError
            })
            return false
        }
        if (info.partPay === true && info.minTotal > total) {
            console.log("partpay error")
            minError = "Partial payment is greater than total."
            setInfo({
                ...info,
                minError
            })
            return false
        }

        if (!info.dueDate) {
            console.log("duedate error")
            dueDateError = "Invalid input."
            setInfo({
                ...info,
                dueDateError
            })
            return false
        }
        else {
            dueDateError = ""
            setInfo({
                ...info,
                dueDateError
            })
        }

        var current = moment();
        var dueDate = moment(info.dueDate)
        if (dueDate < current) {
            console.log("duedate has to be after")
            dueDateError = "Due Date has to be after today's date."
            setInfo({
                ...info,
                dueDateError
            })
            return false
        }
        else {
            dueDateError = "";
            setInfo({
                ...info,
                dueDateError
            })
        }

        if (isValid === false) {
            return false
        }

        return true;
    }

    const saveForm = async (e) => {
        e.preventDefault();
        // console.log("email value check: ", emailArr)
        // console.log("itemarr", itemArr)
        const isValid = validate();

        let itemNameArr = []
        let itemQuantityArr = []
        let itemPriceArr = []
        let itemDescArr = []
        for (let i = 0; i < itemArr.length; i++) {
            itemNameArr.push(itemArr[i].itemName)
            itemQuantityArr.push(itemArr[i].quantity)
            itemPriceArr.push(itemArr[i].price)
            itemDescArr.push(itemArr[i].description)
        }

        let emailList = []

        for (let i = 0; i < emailArr.length; i++) {
            emailList.push(emailArr[i].email)
        }

        let items = {
            Item: itemNameArr,
            Item_Quantity: itemQuantityArr,
            Item_Price: itemPriceArr,
            Item_Description: itemDescArr
        }
        let data =
        {
            Email: emailList,
            Item_Info: items,
            Payment_ID: "N/A",
            Payment_Status: "Draft",
            Date: moment().format("MM/DD/YYYY"),
            dueDate: moment(info.dueDate).format("MM/DD/YYYY"),
            Invoice_ID: "Placeholder",
            partialPayment: {
                bool: info.partPay,
                total: info.minTotal
            },
            Amounts: amt,
            Total: {
                subTotal: subTotal,
                discounts: info.discounts,
                otherAmounts: info.oamount,
                total: total
            },
            Comments: info.comments,
            uID: docID
        }
        if (isValid) {
            console.log("works")
            await db.collection('users').doc(docID).collection("invoices").add(data)
                .then(async function (docRef) {
                    db.collection('users').doc(docID).collection("invoices").doc(docRef.id).update({
                        Invoice_ID: docRef.id
                    })
                    const file = files.fileName;
                    const storageRef = firebase.storage().ref(`Documents/Invoices/${docRef.id}`);
                    const fileRef = storageRef.child(file.name)
                    await fileRef.put(file)
                    // setInfo({
                    //     firstName: '',
                    //     lastName: '',
                    //     streetName: '',
                    //     cityName: '',
                    //     stateName: '',
                    //     zipcode: '',
                    //     countryName: '',
                    //     email: '',
                    //     price: '',
                    //     status: ''
                    // })

                })

                .catch(function (err) {
                    console.log("Error:", err)
                })
            window.location.href = (`/admin/invoice/create-invoice`)
        }
        else {
            console.log("ERROR")
        }

    };

    function getInvoice() {
        setLoading(true);
        ref.get().then((queryResult) => {
            const items = []
            items.push(queryResult.data())
            setEditorState1(EditorState.createWithContent(
                ContentState.createFromBlockArray(
                    convertFromHTML(items[0].Item_Info.Item_Description[0])
                )
            ))
            setEditorState2(EditorState.createWithContent(
                ContentState.createFromBlockArray(
                    convertFromHTML(items[0].Comments)
                )
            ))
            setInfo({
                ...info,
                date: items[0].Date,
                dueDate: items[0].dueDate,
                oamount: items[0].Total.otherAmounts,
                discounts: items[0].Total.discounts,
                minTotal: items[0].partialPayment.total,
                partPay: items[0].partialPayment.bool,
            })
            for (let i = 0; i < items[0].Item_Info.Item.length; i++) {
                // handleItem(e,i)
                // setItemArr([{ itemName: items[0].Item_Info.Item[i], quantity: items[0].Item_Info.Item_Quantity[i], price: items[0].Item_Info.Item_Price[i], description: '', id: itemCounter + 1, itemNameErr: '', quantityErr: '', priceErr: '' }])
                // console.log("itemarr: ", items[0].Item_Info.Item[i], "index check", i)
                // if(items[0].Item_Info.Item.length > 1){
                //     console.log("testing if statement")
                setItemArr([...itemArr, { itemName: items[0].Item_Info.Item[i], quantity: items[0].Item_Info.Item_Quantity[i], price: items[0].Item_Info.Item_Price[i], description: '', id: itemCounter + 1, itemNameErr: '', quantityErr: '', priceErr: '' }])
                // }
                // else{
                // setItemArr([{itemName: items[0].Item_Info.Item[i], quantity: items[0].Item_Info.Item_Quantity[i], price: items[0].Item_Info.Item_Price[i], description: '', id: itemCounter + 1, itemNameErr: '', quantityErr: '', priceErr: '' },])
                // }
            }
            setAmt(items[0].Amounts)
            setEmailArr([{
                ...emailArr,
                email: items[0].Email
            },])
            setSubTotal(items[0].Total.subTotal)
            setInvoice(items)
            setLoading(false)
        })

    }
    useEffect(() => {
        getInvoice();
    }, []);

    if (loading) {
        return <h1>Loading...</h1>;
    }
    return (
        <div className={classes.container}>

            <div style={{ textAlign: "left" }}>
                <h1 style={{ fontFamily: "Oleo Script Swash Caps", fontWeight: "700", color: "#4F0E51" }}>Invoice {id}</h1>
            </div>
            {invoices.map((invoice) => (
                <>

                    {/* FORM */}
                    <form onSubmit={saveForm}>
                        <Card style={{ background: `linear-gradient(180deg, rgba(79, 14, 81, 0.93) 0%, rgba(127, 31, 106, 0.93) 100%)` }}>
                            <GridContainer >
                                <GridItem xs={9}>
                                    <GridContainer justify="center" style={{ padding: "0 10px" }} >
                                        <GridItem xs={12}>
                                            <Card>
                                                <CardHeader color="primary" text>
                                                    <CardText style={{ width: "155px", height: "50px", display: "flex", justifyContent: "center", alignItems: "center", background: "#AD1E5A" }}>
                                                        <h4>Send Invoice to </h4>
                                                    </CardText>
                                                </CardHeader>

                                                <CardBody>

                                                    {emailArr.map((key, index) => {
                                                        return (
                                                            <>
                                                                <GridContainer >
                                                                    <GridItem xs={11}>

                                                                        {/* {console.log("email value check: ", emailArr)} */}
                                                                        <TextField
                                                                            autoComplete="new-off"
                                                                            key={key.id}
                                                                            label="*Email"
                                                                            id="email"
                                                                            fullWidth
                                                                            InputProps={{
                                                                                inputProps: {
                                                                                    onChange: e => handleEmail(e, index),
                                                                                    value: emailArr[index].email
                                                                                }

                                                                            }}

                                                                            error={key.emailErr}
                                                                            helperText={key.emailErr ? 'Error! Input is invalid.' : ''}

                                                                        />
                                                                        {/* {console.log("email err test:", emailArr[index].emailErr)} */}
                                                                    </GridItem>
                                                                    <GridItem xs={1}>
                                                                        <div style={{ display: "flex", justifyContent: "right", }}>
                                                                            <Button simple style={{ color: "black", background: "white", }}
                                                                                onClick={(e) => { deleteCount(e, index) }}
                                                                            >{""}
                                                                                <Close className={classes.modalClose} />
                                                                            </Button>
                                                                        </div>
                                                                        {/* <Button style={{width:"30px"}} onClick={(e)=>{deleteCount(e,index)}}>TEST</Button> */}
                                                                    </GridItem>
                                                                </GridContainer>
                                                            </>
                                                        );

                                                    })}
                                                </CardBody>
                                                <div style={{ display: "flex" }}>
                                                    <Button
                                                        onClick={handleCount}
                                                        style={{ fontWeight: "600", marginTop: "10px", width: "180px", marginLeft: "20px", background: "#AD1E5A" }}
                                                    >Add another recipient</Button>
                                                </div>

                                            </Card>

                                        </GridItem>
                                    </GridContainer>
                                    <GridContainer justify="center" style={{ padding: "0 10px", paddingTop: "25px" }}>
                                        {itemArr.map((key, index) => {
                                            console.log("itemarr test", itemArr)
                                            return (
                                                <GridItem
                                                    key={key.id}
                                                    xs={12}
                                                >

                                                    <Card style={{ marginTop: "2px" }}>
                                                        <CardHeader color="primary" text >
                                                            <CardText style={{ width: "155px", height: "50px", display: "flex", justifyContent: "center", alignItems: "center", background: "#AD1E5A" }}>
                                                                <h4>Line Item(s) </h4>
                                                            </CardText>
                                                        </CardHeader>
                                                        <div style={{ display: "flex", justifyContent: "right" }}>
                                                            <Button simple style={{ color: "black", background: "white", }}
                                                                onClick={(e) => { deleteItemCount(e, index) }}
                                                            >{""}
                                                                <Close className={classes.modalClose} />
                                                            </Button>
                                                        </div>

                                                        <CardBody>

                                                            {/* {console.log("TESTING ITEMS: ", itemArr)} */}
                                                            <GridContainer style={{ marginTop: "-25px" }}>
                                                                {console.log("invoicearr", invoice)}
                                                                <GridItem xs={4}>
                                                                    <TextField
                                                                        label="*Line Item Name"
                                                                        id="itemName"
                                                                        fullWidth
                                                                        InputProps={{
                                                                            inputProps: {
                                                                                onChange: e => handleItem(e, index, editorState1),
                                                                                min: 0,
                                                                                step: 0.01,
                                                                                defaultValue: invoice.Item_Info.Item[index]
                                                                                // value: itemArr[index].itemName[index]
                                                                            }

                                                                        }}
                                                                        error={key.itemNameErr}
                                                                        helperText={key.itemNameErr ? 'Error! Input is invalid.' : ''}
                                                                    />
                                                                </GridItem>
                                                                <GridItem xs={4}>
                                                                    <TextField
                                                                        label="*Quantity"
                                                                        id="quantity"
                                                                        fullWidth
                                                                        type="number"
                                                                        // value={itemArr[index].quantity}
                                                                        InputProps={{
                                                                            inputProps: {
                                                                                onChange: e => handleItem(e, index, editorState1),
                                                                                min: 0,
                                                                                step: 0.01,
                                                                                // value: itemArr[index].quantity[index]
                                                                                defaultValue: invoice.Item_Info.Item_Quantity[index]
                                                                            }

                                                                        }}
                                                                        error={key.quantityErr}
                                                                        helperText={key.quantityErr ? 'Error! Input is invalid.' : ''}
                                                                    />
                                                                </GridItem>
                                                                <GridItem xs={4}>
                                                                    <TextField
                                                                        label="*Price"
                                                                        id="price"
                                                                        fullWidth
                                                                        type="number"
                                                                        // value={itemArr[index].price}
                                                                        InputProps={{
                                                                            inputProps: {
                                                                                onChange: e => handleItem(e, index, editorState1),
                                                                                min: 0,
                                                                                step: 0.01,
                                                                                // value: itemArr[index].price[index],
                                                                                defaultValue: invoice.Item_Info.Item_Price[index]
                                                                            }

                                                                        }}

                                                                        error={key.priceErr}
                                                                        helperText={key.priceErr ? 'Error! Input is invalid.' : ''}
                                                                    />
                                                                </GridItem>
                                                                <br />
                                                                <br />
                                                                <br />
                                                                <GridItem xs={12}>
                                                                    {/* <div onClick={editorRef.current.focus()}> */}
                                                                    <Editor
                                                                        id="description"
                                                                        // defaultValue={convertFromHTML(invoice.Item_Info.Item_Description)}
                                                                        placeholder="Description for your line item..."
                                                                        editorState={EditorState.acceptSelection(editorState1, editorState1.getSelection())}
                                                                        onEditorStateChange={(editorState1) => updateText(editorState1, index)}
                                                                        // onEditorStateChange={(e) => handleItem(e,index)}
                                                                        wrapperClassName="wrapper-class"
                                                                        editorClassName="editor-class"
                                                                        toolbarClassName="toolbar-class"
                                                                        editorStyle={{ border: "1px solid grey" }}
                                                                        toolbarStyle={{ border: "1px solid grey" }}
                                                                    />
                                                                    {/* </div> */}
                                                                </GridItem>
                                                            </GridContainer>

                                                        </CardBody>
                                                        <div style={{ display: "flex", justifyContent: "right", paddingRight: "20px", marginTop: "-10px" }} id="amt">
                                                            <p style={{ fontSize: "16px" }}>Amount: <b>{Number(amt[index]).toFixed(2)}</b></p>
                                                            {console.log("amtamt", amt)}
                                                        </div>
                                                    </Card>
                                                </GridItem>
                                            );

                                        })}

                                    </GridContainer>
                                    <GridContainer style={{ justifyContent: "left", display: "flex", paddingLeft: "26px" }}>
                                        <GridItem xs={12} style={{ marginTop: "-25px" }} >
                                            <Button
                                                onClick={handleItemCount}
                                                style={{ fontWeight: "600", width: "180px", background: "#AD1E5A" }}
                                            >Add another line item</Button>
                                            <Button
                                                onClick={(e) => handleItem(e, itemCounter - 1)}
                                                style={{ fontWeight: "600", width: "180px", background: "#AD1E5A" }}
                                            >Save line item infos</Button>
                                        </GridItem>

                                    </GridContainer>
                                    {/* <br /> */}
                                    <GridContainer justify="center" style={{ padding: "0 10px", paddingTop: "25px" }} >
                                        <GridItem xs={12}>
                                            <Card>
                                                <CardHeader color="primary" text>
                                                    <CardText style={{ width: "225px", height: "50px", display: "flex", justifyContent: "center", alignItems: "center", background: "#AD1E5A" }}>
                                                        <h4>Additional Comments </h4>
                                                    </CardText>
                                                </CardHeader>
                                                <CardBody>

                                                    <Editor
                                                        editorState={editorState2}
                                                        onEditorStateChange={updateComments}
                                                        wrapperClassName="wrapper-class"
                                                        editorClassName="editor-class"
                                                        toolbarClassName="toolbar-class"
                                                        editorStyle={{ border: "1px solid grey" }}
                                                        toolbarStyle={{ border: "1px solid grey" }}
                                                    />

                                                </CardBody>
                                            </Card>

                                        </GridItem>
                                    </GridContainer>

                                    <GridContainer justify="center" style={{ padding: "0 10px", paddingTop: "15px" }} >
                                        <GridItem xs={12}>
                                            <Card>
                                                <CardHeader color="primary" text>
                                                    <CardText style={{ width: "225px", height: "50px", display: "flex", justifyContent: "center", alignItems: "center", background: "#AD1E5A" }}>
                                                        <h4>Attach Contract Doc </h4>
                                                    </CardText>
                                                </CardHeader>
                                                <CardBody>

                                                    <TextField
                                                        // labelText="First Name"
                                                        id="fileName"
                                                        formControlProps={{
                                                            fullWidth: true
                                                        }}
                                                        inputProps={{
                                                            type: "file",
                                                            onChange: onFileChange,
                                                            // disableUnderline: true,
                                                            // required: true
                                                        }}
                                                        error={files.fileError}
                                                        helperText={files.fileError ? 'Error! Please select a file.' : ''}
                                                    />
                                                    {/* <div style={{ color: "red", fontSize:"20px" }}>{files.fileError}</div> */}
                                                </CardBody>
                                            </Card>

                                        </GridItem>
                                    </GridContainer>

                                </GridItem>
                                <GridItem xs={3}>
                                    <GridContainer justify="center" style={{ padding: "0 10px" }} >
                                        <GridItem xs={12}>
                                            <Card>
                                                <CardHeader color="primary" text>
                                                    <CardText style={{ width: "155px", height: "45px", display: "flex", justifyContent: "center", alignItems: "center", background: "#AD1E5A" }}>
                                                        <h4>Invoice Total </h4>
                                                    </CardText>
                                                </CardHeader>
                                                <CardBody style={{ paddingTop: "30px" }}>
                                                    <TextField
                                                        label="Invoice Created "
                                                        id="date"
                                                        variant="outlined"
                                                        fullWidth
                                                        InputProps={{
                                                            inputProps: {
                                                                onChange: handleChange,
                                                                // defaultValue: moment().format("MM/DD/YYYY"),
                                                                defaultValue: invoice.Date,
                                                                readOnly: true
                                                            }

                                                        }}
                                                        focused

                                                    />
                                                    <br />
                                                    <br />


                                                    <TextField
                                                        label="Due Date"
                                                        id="dueDate"
                                                        variant="outlined"
                                                        fullWidth
                                                        focused
                                                        // type="date"
                                                        InputProps={{
                                                            inputProps: {
                                                                onChange: handleChange,
                                                                defaultValue: invoice.dueDate
                                                            }

                                                        }}
                                                        // color="success"
                                                        error={info.dueDateError}
                                                        helperText={info.dueDateError === "Invalid input." ? 'Error! Input is invalid.' :
                                                            info.dueDateError === "Due Date has to be after today's date." ? "Due Date has to be after today's date." : ''}

                                                    />

                                                    <br />
                                                    <p> --------------------------------------------------</p>

                                                    <TextField
                                                        label="Subtotal"
                                                        id="subtotal"
                                                        variant="outlined"
                                                        fullWidth
                                                        InputProps={{
                                                            inputProps: {
                                                                value: !subTotal ? '' : Number(subTotal).toFixed(2)

                                                            }

                                                        }}
                                                    // error={info.emailError}
                                                    // helperText={info.emailError ? 'Error! Input is invalid.' : ''}
                                                    />
                                                    <br />
                                                    <br />

                                                    <TextField
                                                        label="Other discounts"
                                                        id="discounts"
                                                        variant="outlined"
                                                        fullWidth
                                                        type="number"
                                                        InputProps={{
                                                            inputProps: {
                                                                onChange: handleChange,
                                                                min: 0,
                                                                step: 0.01,
                                                                defaultValue: invoice.Total.discounts
                                                            }

                                                        }}
                                                    // error={info.emailError}
                                                    // helperText={info.emailError ? 'Error! Input is invalid.' : ''}
                                                    />
                                                    <br />
                                                    <br />
                                                    <TextField
                                                        label="Other amount"
                                                        id="oamount"
                                                        variant="outlined"
                                                        fullWidth
                                                        type="number"
                                                        InputProps={{
                                                            inputProps: {
                                                                onChange: handleChange,
                                                                min: 0.00,
                                                                step: 0.01,
                                                                defaultValue: invoice.Total.otherAmounts
                                                            }

                                                        }}
                                                    // error={info.emailError}
                                                    // helperText={info.emailError ? 'Error! Input is invalid.' : ''}
                                                    />
                                                    <br />
                                                    <p> --------------------------------------------------</p>

                                                    <TextField
                                                        label="Total"
                                                        id="total"
                                                        variant="outlined"
                                                        focused
                                                        fullWidth
                                                        InputProps={{
                                                            inputProps: {
                                                                value: !total ? '' : total && info.partPay === true ? totalMinusPartPay : total.toFixed(2),
                                                                readOnly: true,
                                                            }

                                                        }}

                                                    // error={info.emailError}
                                                    // helperText={info.emailError ? 'Error! Input is invalid.' : ''}
                                                    />
                                                    {console.log(info.partPay)}
                                                    <br />
                                                    <p> --------------------------------------------------</p>

                                                    <h5>Payment Options</h5>

                                                    <FormControlLabel

                                                        control={
                                                            <Checkbox
                                                                checked={invoice.partialPayment.bool}
                                                                tabIndex={-1}
                                                                onClick={() => handleToggle(2)}
                                                                checkedIcon={<Check className={classes.checkedIcon} />}
                                                                icon={<Check className={classes.uncheckedIcon} />}
                                                                classes={{
                                                                    checked: classes.checked,
                                                                    root: classes.checkRoot
                                                                }}
                                                            />
                                                        }
                                                        classes={{
                                                            label: classes.label,
                                                            root: classes.labelRoot
                                                        }}
                                                        label="Allow partial payment"

                                                    />
                                                    {info.partPay === true ? (
                                                        <TextField
                                                            label="Minimum due"
                                                            id="minTotal"
                                                            variant="outlined"
                                                            focused
                                                            fullWidth
                                                            InputProps={{
                                                                inputProps: {
                                                                    onChange: handleChange,
                                                                    defaultValue: invoice.partialPayment.total
                                                                }

                                                            }}
                                                            error={info.minError}
                                                            helperText={info.minError === "Invalid input." ? 'Error! Input is invalid.' : info.minError === "Partial payment is greater than total." ?
                                                                "Partial payment can't be greater than total." : ''}
                                                        />
                                                    ) : null}


                                                </CardBody>
                                                <Button
                                                    style={{ fontWeight: "600", marginTop: "10px", display: "flex", background: "#AD1E5A" }}
                                                    type="submit">
                                                    Submit Invoice</Button>
                                                {/* <Button onClick={check}>check</Button> */}

                                            </Card>

                                        </GridItem>
                                    </GridContainer>
                                </GridItem>
                            </GridContainer>
                        </Card>
                    </form>
                </>
            ))}
        </div >

    );
}
InvoicePage.propTypes = {
    classes: PropTypes.object.isRequired,
}

const mapStateToProps = (state) => {
    return {
        AuthBool: !!state.auth.user,
        authError: state.auth.error
    }
}
function mapDispatchToProps(actions) {
    return (dispatch) => ({
    })
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(Style)
)(InvoicePage);