/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import compose from "recompose/compose";
import { connect } from "react-redux";
import { Link, useParams } from "react-router-dom";
import actionsForNavigation from "store/actions/navigation";
import moment from "moment";
import { actionsForDialog } from 'store/actions/dialog';

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from '@material-ui/core/Icon';
import TextField from "@material-ui/core/TextField";
import { Dialog, DialogActions, DialogTitle, DialogContent } from "@material-ui/core";



// @material-ui/icons
import MailOutline from "@material-ui/icons/MailOutline";
import Check from "@material-ui/icons/Check";
import Email from "@material-ui/icons/Email";
import Group from '@material-ui/icons/Group';
import { Info } from "@material-ui/icons";


// core components
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";

import Style from "assets/jss/material-dashboard-pro-react/views/registerPageStyle";
import { db, firebase } from 'firebase/client.js'
import GridContainer from "components/Grid/GridContainer";
import GridItem from 'components/Grid/GridItem.js';
import { Grid } from "@material-ui/core";
import CardFooter from "components/Card/CardFooter";
import uuid from 'react-native-uuid';
import { PayPalButton } from "react-paypal-button-v2";
// import { register } from "serviceWorker";

export function InvoiceForm(props) {
    const { classes, register } = props;
    const [invoices, setInvoice] = useState([]);
    const [loading, setLoading] = useState(false);
    const [exist, setExist] = useState(false)
    const [checked, setChecked] = useState([24, 22], false);
    const [userEmail, setUserEmail] = useState({
        email: "",
        emailErr: ""
    })
    const [info, setInfo] = useState({
        firstName: '',
        lastName: '',
        id: ' ',
        datePaid: '',
        total: '',
        notes: '',
        paid: '',
        firstError: '',
        lastError: '',
        check: false,
        boxError: '',
        paidError: '',
    });
    const { uid, id } = useParams();
    const ref = firebase.firestore().collection("users").doc(uid).collection("invoices").doc(id);
    const [show, setShow] = useState(false);
    const [show2, setShow2] = useState(false);
    function getInvoice() {
        setLoading(true);
        ref.get().then((queryResult) => {
            const items = []
            items.push(queryResult.data())
            setInvoice(items)
            setLoading(false)
        })

    }

    const onChange = (e) => {
        setUserEmail({
            [e.target.id]: e.target.value
        })
    }

    const closeShow2 = () => {
        setShow2(false);
        setUserEmail({
            email: "",
            emailErr: ""
        })
    }

    const checkEmail = async () => {
        if (!userEmail.email) {
            setUserEmail({
                emailErr: "Invalid input"
            })
        }
        else {
            console.log("clickclickclick")
            const userRef = db.collection('users');
            const snapshot = await userRef.where('email', '==', `${userEmail.email}`).get()
            if (snapshot.empty) {
                console.log("user doesn't exist")
            }
            else {
                setExist(true)
                setShow(false)
                setShow2(false)
                console.log("user does exist")
            }
        }

    }
    useEffect(() => {
        getInvoice();
    }, []);

    const handleToggle = value => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
            setInfo({
                ...info,
                check: true
            })
        } else {
            newChecked.splice(currentIndex, 1);

        }
        setChecked(newChecked);

    };

    const handleChange = (e) => {
        setInfo({
            ...info,
            [e.target.id]: e.target.value
        })
    }

    const validate = () => {
        let firstError = "";
        let lastError = "";
        let boxError = "";
        let paidError = ""


        if (!info.firstName) {
            firstError = "Can't have an empty input";
        }

        if (!info.lastName) {
            lastError = "Can't have an empty input";
        }

        if (info.check === false) {
            boxError = "Please check the box to confirm the invoice"
        }

        if (!info.paid) {
            paidError = "Invalid Input"
        }

        if (firstError || lastError || boxError || paidError) {
            setInfo({
                ...info,
                firstError,
                lastError,
                boxError,
                paidError
            });
            return false;
        }
        return true;
    }

    return (
        <div className={classes.container}>
            <h1>Pay an Invoice </h1>
            <h3>Url ID: {id}</h3>
            <div style={{ display: "flex", justifyContent: "center" }}>

            </div>

            {/* FORM */}

            <form>
                {invoices.map((invoice) => (
                    <>

                        <Card login style={{ backgroundColor: "rgb(247, 243, 240)" }}>
                            <p style={{ paddingLeft: "10px", paddingTop: "5px" }}>*Required</p>

                            <GridContainer justify="center" style={{ padding: "0 10px" }}>
                                {/* <CardBody style={{ paddingBottom: "0px"}}> */}
                                <GridItem xs={6}>
                                    <Card style={{ paddingLeft: "20px", marginBottom: "0", marginTop: "20px" }}>

                                        <TextField
                                            label="First Name"
                                            id="firstName"
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            InputProps={{
                                                inputProps: {
                                                    onChange: handleChange,
                                                }

                                            }}
                                            error={info.firstError}
                                            helperText={info.firstError ? 'Error! Input is invalid.' : ''}
                                        />

                                    </Card>
                                </GridItem>
                                <GridItem xs={6}>
                                    <Card style={{ paddingLeft: "20px", marginBottom: "0", marginTop: "20px" }}>

                                        <TextField
                                            label="Last Name"
                                            id="lastName"
                                            formControlProps={{
                                                fullWidth: true,

                                            }}
                                            InputProps={{
                                                inputProps: {
                                                    onChange: handleChange,
                                                }

                                            }}
                                            error={info.lastError}
                                            helperText={info.lastError ? 'Error! Input is invalid.' : ''}
                                        />

                                    </Card>
                                </GridItem>

                                <GridItem xs={3}>
                                    <Card style={{ paddingLeft: "20px", marginBottom: "0", marginTop: "20px" }}>

                                        <TextField
                                            label="Invoice ID"
                                            id="invoiceId"
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            InputProps={{
                                                inputProps: {
                                                    onChange: handleChange,
                                                    value: id,
                                                    readOnly: true
                                                }

                                            }}
                                        />
                                    </Card>
                                </GridItem>
                                <GridItem xs={3}>
                                    <Card style={{ paddingLeft: "20px", marginBottom: "0", marginTop: "20px" }}>

                                        <TextField
                                            label="Invoice Date"
                                            id="date"
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            InputProps={{
                                                inputProps: {
                                                    onChange: handleChange,
                                                    value: invoice.Date,
                                                    readOnly: true
                                                }

                                            }}
                                        />
                                    </Card>
                                </GridItem>

                                <GridItem xs={3}>
                                    <Card style={{ paddingLeft: "20px", marginBottom: "0", marginTop: "20px" }}>
                                        {exist === false ? (
                                            <>
                                                <TextField
                                                    label="Invoice Total + 2% Fee"
                                                    id="total"
                                                    formControlProps={{
                                                        fullWidth: true,
                                                    }}
                                                    InputProps={{
                                                        inputProps: {
                                                            onChange: handleChange,
                                                            value: invoice.partialPayment.bool === true ? invoice.partialPayment.total + (invoice.partialPayment.total * 0.02) :
                                                                invoice.Total.total + (invoice.Total.total * 0.02),
                                                            readOnly: true
                                                        },
                                                        endAdornment: (<InputAdornment position="end" onClick={(e) => setShow(true)}><Info style={{ color: "black" }} /> </InputAdornment>),

                                                    }}
                                                />
                                                <Dialog
                                                    open={show}
                                                    // onClose={setShow(false)}
                                                    aria-labelledby="alert-dialog-title"
                                                    aria-describedby="alert-dialog-description">
                                                    <h4 style={{ paddingLeft: "5px" }}>There is a 2% fee on the invoice total. To waiver this fee,
                                                        click the register button below to sign up. If you already have an account, click the check button to see if it exists.</h4>
                                                    <div style={{ justifyContent: "space-between", padding: "10px", display: "flex" }}>
                                                        <Button style={{ float: "left", width: "150px" }} onClick={() => setShow(false)}>
                                                            Cancel
                                                        </Button>
                                                        <Button style={{ float: "center", width: "150px" }} onClick={() => setShow2(true)}>
                                                            Check
                                                        </Button>
                                                        <Dialog
                                                            open={show2}
                                                        >
                                                            <DialogTitle id="alert-dialog-title" style={{ fontFamily: "Quattrocento Sans", textAlign: "center", }}>
                                                                Please enter your email to see if you have an existing account
                                                            </DialogTitle>
                                                            <DialogContent>
                                                                <GridContainer justify="center" style={{ padding: "0 10px" }}>
                                                                    <GridItem xs={12}>
                                                                        <Card>
                                                                            <TextField
                                                                                // label="*Please enter your API Key"
                                                                                id="email"
                                                                                formControlProps={{
                                                                                    fullWidth: true
                                                                                }}
                                                                                inputProps={{
                                                                                    onChange: onChange,
                                                                                    // disableUnderline: true,
                                                                                }}
                                                                                error={userEmail.emailErr}
                                                                                helperText={userEmail.emailErr ? 'Error! Invalid input.' : ''}
                                                                            />

                                                                        </Card>

                                                                    </GridItem>
                                                                    <Button onClick={closeShow2}>
                                                                        Cancel
                                                                    </Button>
                                                                    <Button onClick={checkEmail}>
                                                                        Check
                                                                    </Button>
                                                                </GridContainer>
                                                            </DialogContent>
                                                        </Dialog>
                                                        <Button style={{ float: "right", width: "150px" }} onClick={() => register()}>
                                                            Register
                                                        </Button>
                                                        {/* </Link> */}
                                                    </div>
                                                </Dialog>
                                            </>
                                        ) :
                                            <TextField
                                                label="Invoice Total w/o Fees"
                                                id="total"
                                                formControlProps={{
                                                    fullWidth: true,
                                                }}
                                                InputProps={{
                                                    inputProps: {
                                                        onChange: handleChange,
                                                        value: invoice.partialPayment.bool === true ? invoice.partialPayment.total  :
                                                            invoice.Total.total ,
                                                        readOnly: true
                                                    },
                                                    // endAdornment: (<InputAdornment position="end" onClick={(e) => setShow(true)}><Info style={{ color: "black" }} /> </InputAdornment>),

                                                }}
                                            />}

                                    </Card>
                                </GridItem>

                                <GridItem xs={3}>
                                    <Card style={{ paddingLeft: "20px", marginBottom: "0", marginTop: "20px" }}>

                                        <TextField
                                            label="Amount to Pay"
                                            id="paid"
                                            type="number"
                                            formControlProps={{
                                                fullWidth: true,
                                            }}
                                            InputProps={{
                                                inputProps: {
                                                    onChange: handleChange,
                                                    step: 0.01,
                                                    min: 0.01
                                                }

                                            }}
                                            error={info.paidError}
                                            helperText={info.paidError ? 'Error! Input is invalid.' : ''}
                                        />
                                    </Card>
                                </GridItem>

                                <GridItem xs={12}>
                                    <Card style={{ paddingLeft: "20px", marginBottom: "0", marginTop: "20px" }}>

                                        <TextField
                                            label="Notes"
                                            id="notes"
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            InputProps={{
                                                inputProps: {
                                                    onChange: handleChange,
                                                }

                                            }}
                                        />

                                    </Card>
                                </GridItem>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            required
                                            tabIndex={-1}
                                            onClick={() => handleToggle(2)}
                                            checkedIcon={<Check className={classes.checkedIcon} />}
                                            icon={<Check className={classes.uncheckedIcon} />}
                                            classes={{
                                                checked: classes.checked,
                                                root: classes.checkRoot
                                            }}
                                        />
                                    }
                                    classes={{
                                        label: classes.label,
                                        root: classes.labelRoot
                                    }}
                                    label="Confirm this Invoice"

                                />

                            </GridContainer>
                            <div style={{ color: "red", textAlign: "center", paddingBottom: "10px" }}>{info.boxError}</div>
                            <div style={{ display: "flex", justifyContent: "center" }}>
                                <PayPalButton
                                    type="submit"
                                    style={{ color: "blue", shape: "pill" }}
                                    createOrder={(details, actions) => {
                                        return actions.order.create({
                                            intent: "CAPTURE",
                                            purchase_units: [{
                                                amount: {
                                                    value: info.paid
                                                }
                                            }]
                                        })
                                    }}
                                    onClick={(data, actions) => {
                                        const isValid = validate();
                                        if (isValid) {
                                            setInfo({
                                                ...info,
                                                check: false
                                            })
                                            return true;
                                        }
                                        else {
                                            // console.log("BUTTON NOT VALIDATED")
                                            return false;
                                        }
                                    }}


                                    async onApprove={(data, actions) => {
                                        return actions.order.capture().then(async function (details) {
                                            alert("Transaction: " + details.purchase_units[0].payments.captures[0].id + "has been completed.")
                                            const currentDate = moment(invoice.Date).format("MM/DD/YYYY H:mm")
                                            const epoch = moment(currentDate, "M/D/YYYY H:mm").valueOf()
                                            const dueDate = moment(invoice.dueDate).format("MM/DD/YYYY H:mm")
                                            const epoch2 = moment(dueDate, "M/D/YYYY H:mm").valueOf()
                                            let status = ""

                                            if (invoice.Total.total - details.purchase_units[0].amount.value === 0) {
                                                status = "COMPLETED"
                                            }
                                            else {
                                                status = "PARTIALLY COMPLETED"
                                            }

                                            let paymentInfo = {
                                                Name: details.purchase_units[0].shipping.name.full_name,
                                                datePaid: moment().format("MM/DD/YYYY"),
                                                Total: {
                                                    amoundPaid: details.purchase_units[0].amount.value,
                                                    total: invoice.Total.total - details.purchase_units[0].amount.value
                                                },
                                                Payment_ID: details.purchase_units[0].payments.captures[0].id,
                                                Payment_Status: details.purchase_units[0].payments.captures[0].status,
                                            }
                                            setInfo({
                                                firstName: '',
                                                lastName: '',
                                                id: ' ',
                                                date: '',
                                                total: '',
                                                notes: '',
                                                firstError: '',
                                                lastError: '',
                                                check: false,
                                                boxError: ''
                                            })
                                            // var request = require('request');
                                            // request({
                                            //     method: 'POST',
                                            //     url: 'https://protected-shelf-37912.herokuapp.com/https://api.clickup.com/api/v2/list/163548371/task',
                                            //     headers: {
                                            //         'Authorization': 'pk_48092534_OBTI15EOHWARXAY0KKPQMHUZTLN8GUFD',
                                            //         'Content-Type': 'application/json',
                                            //         "Access-Control-Allow-Headers": "Content-Type",
                                            //         "Access-Control-Allow-Origin": "*",
                                            //         "Access-Control-Allow-Methods": "OPTIONS,POST,GET"
                                            //     },
                                            //     body: `{  \"name\": \"testing paying and upload to clickup\",  \"description\": \"paypal and upload to clickup description\",  \"assignees\": [      ],  \"tags\": [    \"tag name 1\"  ],  \"status\": \"Open\",  \"priority\": 3,  \"due_date\": ${moment(epoch2)._i},  \"due_date_time\": false,  \"time_estimate\": 8640000,  \"start_date\": ${moment(epoch)._i},  \"start_date_time\": false,  \"notify_all\": false,  \"parent\": null,  \"links_to\": null,  \"check_required_custom_fields\": true,  \"custom_fields\": [    {      \"id\": \"0a52c486-5f05-403b-b4fd-c512ff05131c\",      \"value\": 23    },    {      \"id\": \"03efda77-c7a0-42d3-8afd-fd546353c2f5\",      \"value\": \"Text field input\"    }  ]}`

                                            // }, async function (error, response, body) {
                                            //     console.log('error:', error); // Print the error if one occurred
                                            //     console.log('statusCode:', response && response.statusCode); // Print the response status code if a response was received
                                            //     console.log('body:', body);
                                                // if (response.statusCode === 200) {
                                                    // console.log("working properly")
                                                    await ref.set(paymentInfo, { merge: true })
                                                    await db.collection('users').doc(uid).update({
                                                        events: firebase.firestore.FieldValue.arrayUnion(...[{
                                                            label: "Invoiced received",
                                                            event: "received payment",
                                                            type: "earned",
                                                            coins: details.purchase_units[0].amount.value,
                                                            paymentID: details.purchase_units[0].payments.captures[0].id,
                                                            status: status,
                                                            datePaid: moment().format("MM/DD/YYYY"),
                                                        }])

                                                    })
                                                        .then(() => {
                                                            props.edit()
                                                        })
                                                // }

                                            // })

                                        })


                                    }}
                                />
                            </div>

                        </Card>


                    </>
                ))}
            </form>


        </div>

    );
}

InvoiceForm.propTypes = {
    classes: PropTypes.object.isRequired,
}

const mapStateToProps = (state) => {
    return {
        AuthBool: !!state.auth.user,
        authError: state.auth.error
    }
}
function mapDispatchToProps(actions) {
    return (dispatch) => ({
        edit: (key) => dispatch(actionsForNavigation.openChild("Pay Invoice", `/auth/request-invoice`)),
        register: (key) => dispatch(actionsForNavigation.openChild("Register Page", `/pages/registration`))
    })
}
export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(Style)
)(InvoiceForm);
