import { DataTypes } from "variables/constants";
import { OpenMode } from "variables/constants";
import { MandatoryFieldError, DataTypeError } from "variables/errors";
import { DateTime } from "luxon";

DateTime.local();

const initState = { };

export function getForm(state, formName) {
    return state[formName] || { steps: {} };
}

function getStep(form, stepName) {
    return form.steps[stepName] || { fields: {}, fieldErrors: {} } ;
}

function getField(step, fieldName) {
    return step.fields[fieldName];
}

function getFieldError(step, fieldName) {
    return step.fieldErrors[fieldName];
}

export function getFormStep(state, formName, stepName) {
    return getStep(getForm(state, formName), stepName);
}

export function getFormStepError(state, formName, stepName) {
    return getStep(getForm(state, formName), stepName).error;
}

export function getFormStepMode(state, formName, stepName) {
    return getStep(getForm(state, formName), stepName).mode;
}

export function getFormStepField(state, formName, stepName, fieldName) {
    return getField(getStep(getForm(state, formName), stepName), fieldName);
}

export function getFormStepFieldError(state, formName, stepName, fieldName) {
    return getFieldError(getStep(getForm(state, formName), stepName), fieldName);
}

export function getStepConfig(config, step) {
    return config[step] || { fields: {} };
}

export function getFormFieldConfig(config, field) {
    return (config.fields && config.fields[field]) || {};
}

export function getStepFieldConfig(config, step, field) {
    return getStepConfig(config, step).fields[field] || getFormFieldConfig(config, field) || {};
}

function bindReducer(reducer, accessor) {
    return (state, ...rest) => accessor(state[reducer], ...rest);
}

function bindReducerAndForm(reducer, form, accessor) {
    return (state, ...rest) => accessor(state[reducer], form, ...rest);
}

function bindReducerFormAndStep(reducer, form, step, accessor) {
    return (state, ...rest) => accessor(state[reducer], form, step, ...rest);
}

function bindConfig(config, accessor) {
    return (...rest) => accessor(config, ...rest);
}

function bindConfigAndStep(config, step, accessor) {
    return (...rest) => accessor(config, step, ...rest);
}

export function getAccessors(reducer) {
    return {
        getForm : bindReducer(reducer, getForm),
        getFormStep : bindReducer(reducer, getFormStep),
        getFormStepField : bindReducer(reducer, getFormStepField),
        getFormStepFieldError : bindReducer(reducer, getFormStepFieldError)
    }
}

export function getAccessorsForForm(config, reducer, form) {
    return {
        get : bindReducerAndForm(reducer, form, getForm),
        getStep : bindReducerAndForm(reducer, form, getFormStep),
        getStepError: bindReducerAndForm(reducer, form, getFormStepError),
        getStepMode: bindReducerAndForm(reducer, form, getFormStepMode),
        getStepField : bindReducerAndForm(reducer, form, getFormStepField),
        getStepFieldError : bindReducerAndForm(reducer, form, getFormStepFieldError),
        getError: bindReducerFormAndStep(reducer, form, "DEFAULT", getFormStepError),
        getMode: bindReducerFormAndStep(reducer, form, "DEFAULT", getFormStepMode),
        getField : bindReducerFormAndStep(reducer, form, "DEFAULT", getFormStepField),
        getFieldError : bindReducerFormAndStep(reducer, form, "DEFAULT", getFormStepFieldError),
        getConfig: () => config,
        getStepConfig: bindConfig(config, getStepConfig),
        getStepFieldConfig: bindConfig(config, getStepFieldConfig),
        getFieldConfig: bindConfigAndStep(config, "DEFAULT", getStepFieldConfig),
        getFormName: () => form,
        getStepName: () => "DEFAULT",
        getAccessorsForStep: step => getAccessorsForFormStep(config, reducer, form, step)
    }
}

export function getAccessorsForFormStep(config, reducer, form, step) {
    return {
        get : bindReducerFormAndStep(reducer, form, step, getFormStep),
        getError: bindReducerFormAndStep(reducer, form, step, getFormStepError),
        getMode: bindReducerFormAndStep(reducer, form, step, getFormStepMode),
        getField : bindReducerFormAndStep(reducer, form, step, getFormStepField),
        getFieldError : bindReducerFormAndStep(reducer, form, step, getFormStepFieldError),
        getConfig: bindConfigAndStep(config, step, getStepConfig),
        getFieldConfig: bindConfigAndStep(config, step, getStepFieldConfig),
        getFormName: () => form,
        getStepName: () => step    
    }
}

export function addSteps(formAccessor, steps) {
    return steps.reduce((accumulator, step)=>({...accumulator, [step] : formAccessor.getAccessorsForStep(step)}), formAccessor)
}

function booleanFromString(stringValue) {
    if (stringValue === null || stringValue === undefined) return undefined;
    // stringValue = stringValue.toLower();
    if (stringValue === 'true') return true;
    if (stringValue === 'false') return false;
    return undefined;
}

function applyConfig(config) {

    function getForm(form, state) {
        const formConfig = config || { fields: {} }
        const formState = state[form] || { steps: {} }

        function updateForm(state, formState) {
            return {
                ...state,
                [form] : formState
            }
        }

        function setStep(step, stepState) {
            return {
                ...formState,
                step,
                steps: { ...formState.steps, [step] : stepState }
            }
        }

        function getStep(step) {

            const stepConfig = formConfig[step] || { fields: {} };
            const stepState = formState.steps[step] || { fields: {}, fieldErrors: {} };
            
            function getDefaultFieldConfig(field) {
                return (formConfig.fields && formConfig.fields[field]) || {}
            }

            function getFieldConfig(field) {
                return stepConfig.fields[field] || getDefaultFieldConfig(field);
            }

            function initialize(mode, values) {
                const result = {};
                for (const [key,value] of Object.entries(stepConfig)) {
                    if (value.default) result[key] = value.default;
                }
                return { fields: { ...result, ...values }, fieldErrors: {}, mode }
            }

            function validateField(field, value, error = {}) {
                let fieldError = undefined;
                const config = getFieldConfig(field);
                if (value === undefined || value === '') {
                    if (config.mandatory) fieldError = new MandatoryFieldError();
                } else {
                    switch (config.type) {
                        case DataTypes.NUMBER:
                            if (isNaN(value)) 
                                fieldError = new DataTypeError(DataTypes.NUMBER); 
                            else 
                                value = Number(value);
                            break;
                        case DataTypes.DATETIME:
                            const dtValue = DateTime.isDateTime(value) ? value : DateTime.fromISO(String(value));
                            if (dtValue.isValid) {
                                value = dtValue;
                            } else {
                                fieldError = new DataTypeError(DataTypes.DATETIME); 
                            }
                            break;
                        case DataTypes.BOOLEAN:
                            const boolValue = typeof value === 'boolean' ? value : booleanFromString(value);
                            if (boolValue === undefined) {
                                fieldError = new DataTypeError(DataTypes.BOOLEAN);
                            } else {
                                value = boolValue
                           }
                            break;
                        default:
                            break; // no validation
                    }
                }
                error = { [field] : fieldError, ...error };
                return { ...stepState, fields: { ...stepState.fields, [field] : value }, fieldErrors:  { ...stepState.fieldErrors, ...error } }
            }

            function updateField(fieldName, update) {
                return {
                    ...stepState, 
                    fields: {...stepState.fields, [fieldName] : update(getField(stepState, fieldName)) }
                }
            }

            function updateFieldRow(field, index, update) {
                const copy = field ? [ ...field ] : [ {} ];
                copy[index] = update(field[index]);
                return copy;
            }
            
            function updateColumn(row, columnName, update) {
                return {
                    ...row,
                    [columnName] : update(row[columnName])
                }
            }
            
            function updateFieldRowColumn(fieldName, index, columnName, update) {
                return updateField(fieldName, field=>updateFieldRow(field, index, row=>updateColumn(row, columnName, update)));
            }
            
            function startStep(mode, values) {
                return {
                    ...stepState, 
                    fields: { ...stepState.fields, ...values },
                    mode
                };
            }

            function setError(error) {
                return {
                    ...stepState,
                    error,
                    fieldErrors:  (error && error.fieldErrors) ? { ...stepState.fieldErrors, ...error.fieldErrors } : stepState.fieldErrors
                }
            }

            function updateStep(state, stepState) {
                return updateForm(state, {
                    ...formState, 
                    steps: { ...formState.steps, [step] : stepState }
                });
            }

            return { 
                updateStep, 
                validateField, 
                updateField, 
                updateFieldRowColumn, 
                initialize, 
                startStep, 
                setError 
            }
        }

        function initialize(values) {
            const result = { steps: {} }
            const valueSteps = values ? Object.getOwnPropertyNames(values) : [];
            const steps = new Set([...valueSteps, ...Object.getOwnPropertyNames(formConfig)]);
            for (const step of steps) {
                const stepValues = (values && values[step]) || {};
                result.steps[step] = getStep(step).initialize(stepValues);
            }
            return result;            
        }

        return {
            updateForm,
            initialize,
            getStep,
            setStep
        }
    }

    return {
        getForm
    }
}

export default function reducer(state = initState, action) {

    const config = applyConfig(action.config);
    const form = config.getForm(action.form, state);
    const step = form.getStep(action.step);

    switch (action.type) {
        case 'FORM_EDIT_FIELD':
            return step.updateStep(state, step.updateField(action.field, ()=>action.value));
        case 'FORM_VALIDATE_FIELD':
            return step.updateStep(state, step.validateField(action.field, action.value, action.error));
        case 'FORM_VALIDATE_STEP':
            return step.updateStep(state, step.setError(undefined));
        case 'FORM_EDIT_TABLE_FIELD':
            return step.updateStep(state, step.updateFieldRowColumn(action.field, action.index, action.column, ()=>action.value));
        case 'FORM_VALIDATE_TABLE_FIELD':
            // TODO: Implement
            return state;
        case 'FORM_ADD_TABLE_ROW':
            return step.updateStep(state, step.updateField(action.field, field=>[...field, {}]));
        case 'FORM_REMOVE_TABLE_ROW':
            return step.updateStep(state, step.updateField(action.field, field=>field.filter((_, i) => i !== action.index)));
        case 'FORM_INITIALIZE':
            return form.updateForm(state, form.initialize(action.values));
        case 'FORM_INITIALIZE_STEP':
            state = form.updateForm(state, form.setStep(action.step, step.initialize(action.openMode || OpenMode.UPDATE, action.values)));
            return state;
        case 'FORM_START_STEP':
            state = form.updateForm(state, form.setStep(action.step, step.startStep(action.openMode || OpenMode.UPDATE, action.values)));
            return state;
        case 'FORM_SUBMIT':
        case 'FORM_CANCEL':
            return state; // By default these actions do nothing; however it is useful to log state
        case 'FORM_SET_ERROR':
            if (!action.error.code) console.error("unexpected error", action.error);
            return step.updateStep(state, step.setError(action.error));
        default:
            return state;
    }
}