/* eslint-disable no-useless-escape */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useState } from "react";
import PropTypes from "prop-types";
import compose from "recompose/compose";
import { connect } from "react-redux";
import { Link, useParams, useHistory, NavLink } from "react-router-dom";


// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from '@material-ui/core/Icon';



// @material-ui/icons
import MailOutline from "@material-ui/icons/MailOutline";
import Check from "@material-ui/icons/Check";
import Email from "@material-ui/icons/Email";
import Group from '@material-ui/icons/Group';


// core components
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";

import Style from "assets/jss/material-dashboard-pro-react/views/registerPageStyle";
import { db, firebase } from 'firebase/client.js'
import GridContainer from "components/Grid/GridContainer";
import GridItem from 'components/Grid/GridItem.js';
import CardFooter from "components/Card/CardFooter";
import { setIn } from "immutable";
import { LaptopWindows } from "@material-ui/icons";
import actionsForNavigation from "store/actions/navigation";




export function PublicInvoice(props) {
    const { classes } = props;
    const [checked, setChecked] = useState([24, 22], false);
    const [info, setInfo] = useState({
        userId: "",
        userError: "",
        invoiceID: "",
        idError: "",
        enabled: ""

    });

    let uid = useParams();


    const handleChange = (e) => {
        setInfo({
            ...info,
            [e.target.id]: e.target.value
        })
    }
    const saveForm = async (e) => {
        e.preventDefault();
        let userError="";
        let idError = "";
        const first = await db.collection('users').doc(info.userId);
        first.get()
            .then(
                doc => {
                    if (doc.exists) {
                        // console.log("submitted")
                        setInfo({
                            ...info,
                            userError: "",
                            enabled: "false"
                        });
                        // console.log(info.enabled)
                    }
                    else {
                        // console.log("error id doesn't exist")
                        userError = "This user ID doesn't exist"
                        if (idError) {
                            setInfo({
                                ...info,
                                // enabled: "",
                                userError
                            })

                        }
                    }
                }
            )
            const second = await first.collection("invoices").doc(info.invoiceID);
            second.get()
            .then(
                doc =>{
                    if(doc.exists){
                        console.log("In second part")
                        setInfo({
                            ...info,
                            idError: "",
                            enabled: "false"
                        });
                    }
                    else{
                        idError = "This invoice ID doesn't exist"
                        if(idError){
                            setInfo({
                                ...info,
                                enabled: "",
                                idError
                            })
                        }
                    }
                }
            )
    };
    return (

        <div className={classes.container}>
            <GridContainer justify="center">
                <GridItem xs={12} sm={6} md={4} className={classes.formContainer}>
                    {/* FORM */}
                    <form onSubmit={saveForm}>
                        <Card login style={{ backgroundColor: "rgb(247, 243, 240)" }}>
                            <CardBody style={{ paddingBottom: "0px" }}>
                                <Card style={{ paddingLeft: "20px", marginBottom: "5px", marginTop: "20px" }}>

                                    <CustomInput
                                        labelText="Please enter user id"
                                        id="userId"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        inputProps={{
                                            onChange: handleChange,
                                            disableUnderline: true,
                                            required: true
                                        }}
                                    />
                                    <div style={{ color: "red" }}>{info.userError}</div>
                                </Card>

                                <Card style={{ paddingLeft: "20px", marginBottom: "5px", marginTop: "20px" }}>

                                    <CustomInput
                                        labelText="Please enter invoice id"
                                        id="invoiceID"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        inputProps={{
                                            onChange: handleChange,
                                            disableUnderline: true,
                                            required: true
                                        }}
                                    />
                                    <div style={{ color: "red" }}>{info.idError}</div>
                                </Card>
                                <Button
                                    style={{ fontWeight: "600", color: "#FFFFFF", backgroundColor: "#FC6047", marginTop: "10px" }}
                                    type="submit"
                                    color="primary"
                                    simple size="lg"
                                    block>
                                    Check if Invoice Exist
                                </Button>
                                <br />
                                {info.enabled ?(
                                <Link to ={`/pages/pub-iframe-invoice/${info.userId}/${info.invoiceID}`}>
                                    <Button
                                        style={{ fontWeight: "600", color: "#FFFFFF", backgroundColor: "#FC6047", marginTop: "10px" }}
                                        color="primary"
                                        simple size="lg"
                                        block>
                                        Pay Invoice
                                    </Button>
                                 </Link>
                                ) : null}
                            </CardBody>
                        </Card>
                    </form>

                </GridItem>
            </GridContainer>
        </div>

    );
}

PublicInvoice.propTypes = {
    classes: PropTypes.object.isRequired,
}

const mapStateToProps = (state) => {
    return {
        AuthBool: !!state.auth.user,
        authError: state.auth.error
    }
}
function mapDispatchToProps(actions) {
    return (dispatch) => ({
        // edit: (key) => dispatch(actionsForNavigation.openChild("Create Invoice", `/auth/publicinvoice/123`)),
    })
}
export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(Style)
)(PublicInvoice);
