import React, { useState } from "react";
import PropTypes from "prop-types";
import { firebase } from 'firebase/client.js';
import actionsForNavigation from "store/actions/navigation";
import { Link } from "react-router-dom";
import compose from "recompose/compose";
import { connect } from "react-redux";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import { makeStyles } from "@material-ui/core/styles";
import { Menu, MenuItem} from "@material-ui/core";


// @material-ui/icons
import Face from "@material-ui/icons/Face";
import Chat from "@material-ui/icons/Chat";
import Build from "@material-ui/icons/Build";
import FileCopy from "@material-ui/icons/FileCopy";
import Edit from "@material-ui/icons/Edit";
import Close from "@material-ui/icons/Close";
import Add from "@material-ui/icons/Add";
import Download from "@material-ui/icons/ArrowDownward";
import { AddCircleOutlineRounded } from "@material-ui/icons";


// core components
import CustomInput from "components/CustomInput/CustomInput.js";
import CustomTabs from "components/CustomTabs/CustomTabs.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Table from "components/Table/Table.js";
import Button from "components/CustomButtons/Button.js";

// forms
import ProofOfDeliveryForm from "views/Forms/DocumentsForms/ProofOfFulfillmentForm";
import ProofOfInsuranceForm from "views/Forms/DocumentsForms/ProofOfInsuranceForm";
import ProofOfOwnershipForm from "views/Forms/DocumentsForms/ProofOfOwnershipForm";

// styles
import documentsPageStyle from "assets/jss/material-dashboard-pro-react/views/documentsPageStyles.js";
import { infoBoxShadow } from "assets/jss/material-dashboard-pro-react";
import styles from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js";

var link = "";
export function DocumentsPage(props) {
  const { classes } = props;
  const [menu, setMenu] = useState(null);
  const open = Boolean(menu);

  // const onChange = async (e) => {
  //   setFiles({
  //     ...files,
  //     [e.target.id]: e.target.files[0]
  //   })
  // }

  // const validate = () => {
  //   let fileError = '';
  //   console.log(files.fileName)
  //   if (!files.fileName) {
  //     fileError = "Error! No file selected";
  //     console.log("ERROR")
  //   }
  //   if (fileError) {
  //     setFiles({
  //       ...files,
  //       fileError
  //     });
  //     return false
  //   }
  //   return true;
  // }

  const onClick1 = () => {
    const storage = firebase.storage()
    const path = 'Documents/ProofOfFulfillment/TESTPDF.pdf'


    storage.ref(path).getDownloadURL().then((url) => {
      var xhr = new XMLHttpRequest();
      xhr.open('GET', url, true);
      xhr.responseType = 'blob';

      xhr.onload = (event) => {
        if (xhr.status === 200) {
          console.log("Downloadable")
          // Create a new Blob object using the 
          //response data of the onload object
          var blob = new Blob([xhr.response], { type: 'image/pdf' });
          //Create a link element, hide it, direct 
          //it towards the blob, and then 'click' it programatically
          let a = document.createElement("a");
          a.style = "display: none";
          document.body.appendChild(a);
          //Create a DOMString representing the blob 
          //and point the link element towards it
          let urlLink = window.URL.createObjectURL(blob);
          a.href = urlLink;
          a.download = 'myFile.pdf';
          //programatically click the link to trigger the download
          a.click();
          //release the reference to the file by revoking the Object URL
          window.URL.revokeObjectURL(urlLink);
        }
        else {
          console.log("THERE IS AN ERROR")
        }
      };

      xhr.send();
    })
  }

  const onClick2 = () => {
    window.location.href=("/admin/documents/proof-of-funds")
    // console.log("TEST2")
  }

  const onClick3 = () => {
    // window.location.href=("/admin/documents")
    console.log("Delete document")
  }

  const onClick4 = () => {
    // window.location.href=("/admin/documents")
    console.log("Copy document")
  }

  const handleClick = (e) => {
    // setCurrentInvoice(invoices[i])
    setMenu(e.currentTarget)
  };

  const handleClose = () => {
    setMenu(null);
  };

  const buttons = [
    { background: "#F37361", word: "Download", action: onClick1 },
    { background: `linear-gradient(0deg, #AD1E5A, #AD1E5A), #AD1E5A`, word: "Edit", action: onClick2 },
    { background: `linear-gradient(0deg, #8E2572, #8E2572), #8E2572`, word: "Close", action: onClick3 },
    { background: `linear-gradient(0deg, #FFDEAB, #FFDEAB), #FFDEAB`, word: "FileCopy", action: onClick4 }
  ].map((prop, key) => {
    return (
      <Button style={{background:prop.background, fontFamily:"Quattrocento Sans", fontWeight:"bold", width:"200px", fontSize: "16px", marginRight:"5px"}} className={classes.actionButton} key={key} onClick={prop.action}>
        {prop.word}
        {/* <prop.icon className={classes.icon} /> */}
      </Button>
    );
  });

  return (
    <div>
      <h1 style={{fontFamily:"Oleo Script Swash Caps", fontWeight:"bold",color: "#4F0E51"}}>Documents</h1>
      <GridContainer>
        <GridItem xs={12}>
          <CustomTabs
            headerColor="rose"
            tabs={[
              {
                tabName: "All",
                tabIcon: Face,
                tabContent: (
                  <Table
                    tableHead={["#", "Name", "Since", "Status","Actions"]}
                    tableData={[["1", "Proof of Funds", "2013","Signed", buttons], ["2", "Proof of Insurance", "2013","Propsed", buttons], ["3", "Proof of Fulfillment", "2015","Cancelled", buttons], ["4", "Proof of Ownership", "2015","Proposed", buttons]]}
                    customCellClasses={[
                      classes.center,
                      classes.right,
                      classes.right
                    ]}
                    // 0 is for classes.center, 4 is for classes.right, 5 is for classes.right
                    customClassesForCells={[0, 4, 5]}
                    customHeadCellClasses={[
                      classes.center,
                      classes.right,
                      classes.right
                    ]}
                    // 0 is for classes.center, 4 is for classes.right, 5 is for classes.right
                    customHeadClassesForCells={[0, 4, 5]}
                  />

                )
              },
              {
                tabName: "Funds",
                tabIcon: Chat,
                tabContent: (
                  <Table
                    tableHead={["#", "Name", "Since", "Status","Actions"]}
                    tableData={[["1", "Proof of Funds", "2013","Signed", buttons]]}
                    customCellClasses={[
                      classes.center,
                      classes.right,
                      classes.right
                    ]}
                    // 0 is for classes.center, 4 is for classes.right, 5 is for classes.right
                    customClassesForCells={[0, 4, 5]}
                    customHeadCellClasses={[
                      classes.center,
                      classes.right,
                      classes.right
                    ]}
                    // 0 is for classes.center, 4 is for classes.right, 5 is for classes.right
                    customHeadClassesForCells={[0, 4, 5]}
                  />
                )
              },
              {
                tabName: "Insurance",
                tabIcon: Build,
                tabContent: (
                  <Table
                    tableHead={["#", "Name", "Since", "Status", "Actions"]}
                    tableData={[["1", "Proof of Insurance", "2013","Proposed", buttons]]}
                    customCellClasses={[
                      classes.center,
                      classes.right,
                      classes.right
                    ]}
                    // 0 is for classes.center, 4 is for classes.right, 5 is for classes.right
                    customClassesForCells={[0, 4, 5]}
                    customHeadCellClasses={[
                      classes.center,
                      classes.right,
                      classes.right
                    ]}
                    // 0 is for classes.center, 4 is for classes.right, 5 is for classes.right
                    customHeadClassesForCells={[0, 4, 5]}
                  />
                )
              },
              {
                tabName: "Fulfillment",
                tabIcon: Build,
                tabContent: (
                  <Table
                    tableHead={["#", "Name", "Since", "Status", "Actions"]}
                    tableData={[["1", "Proof of Fulfillment", "Cancelled", "2015", buttons]]}
                    customCellClasses={[
                      classes.center,
                      classes.right,
                      classes.right
                    ]}
                    // 0 is for classes.center, 4 is for classes.right, 5 is for classes.right
                    customClassesForCells={[0, 4, 5]}
                    customHeadCellClasses={[
                      classes.center,
                      classes.right,
                      classes.right
                    ]}
                    // 0 is for classes.center, 4 is for classes.right, 5 is for classes.right
                    customHeadClassesForCells={[0, 4, 5]}
                  />
                )
              },
              {
                tabName: "Ownership",
                tabIcon: Build,
                tabContent: (
                  <Table
                    tableHead={["#", "Name", "Since", "Status", "Actions"]}
                    tableData={[["1", "Proof of Ownership","Proposed", "2015", buttons]]}
                    customCellClasses={[
                      classes.center,
                      classes.right,
                      classes.right
                    ]}
                    // 0 is for classes.center, 4 is for classes.right, 5 is for classes.right
                    customClassesForCells={[0, 4, 5]}
                    customHeadCellClasses={[
                      classes.center,
                      classes.right,
                      classes.right
                    ]}
                    // 0 is for classes.center, 4 is for classes.right, 5 is for classes.right
                    customHeadClassesForCells={[0, 4, 5]}
                  />
                )
              }
            ]}
          />
        </GridItem>
      </GridContainer>

      <GridContainer>
        <GridItem xs={3}>
          <Button
            style={{ height: "50px", width: "50px", fontSize: "2rem", right: "20px", bottom: "16px", position: "fixed", zIndex: "10", background: "#FFDEAB", color: "#4F0E51" }} justIcon round
            justIcon round color="info"
            aria-controls="long-menu"
            aria-expanded={open ? 'true' : undefined}
            onClick={(e) => handleClick(e)}
          >+</Button>
          <Menu
            id="long-menu"
            MenuListProps={{
              "aria-labelledby": "long-button"
            }}
            anchorEl={menu}
            open={open}
            onClose={handleClose}
            PaperProps={{
              style: {
                width: "20ch",
                background: `linear-gradient(0deg, #FFDEAB, #FFDEAB), #FFDEAB`,
              }
            }}
          >
            <MenuItem
            >
              <Link to="/admin/documents/proof-of-funds" style={{color:"#3C4858", fontFamily:"Quattrocento Sans"}}>
                Proof of Funds
              </Link>
            </MenuItem>

            <MenuItem
            >
              <Link to="/admin/documents/proof-of-insurance" style={{color:"#3C4858",fontFamily:"Quattrocento Sans"}}>
                Proof of Insurance
              </Link>
            </MenuItem>

            <MenuItem
            >
              <Link to="/admin/documents/proof-of-fulfillment" style={{color:"#3C4858",fontFamily:"Quattrocento Sans"}}>
                Proof of Fulfillment
              </Link>
            </MenuItem>

            <MenuItem
            >
              <Link to="/admin/documents/proof-of-ownership" style={{color:"#3C4858",fontFamily:"Quattrocento Sans"}}>
                Proof of Ownership
              </Link>
            </MenuItem>
          </Menu>
        </GridItem>
      </GridContainer>
    </div>
  );
}

DocumentsPage.propTypes = {
  classes: PropTypes.object.isRequired,
}

function mapStateToProps(state) {
  return {

  };
}

function mapDispatchToProps(actions) {
  return (dispatch) => ({
    edit: (key) => dispatch(actionsForNavigation.openChild("Documents", `/admin/documents`)),
  })
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(documentsPageStyle)
)(DocumentsPage);