import React, { useState } from "react";
import PropTypes from "prop-types";
import { firebase } from 'firebase/client.js';
import actionsForNavigation from "store/actions/navigation";
import { Link, useParams } from "react-router-dom";
import compose from "recompose/compose";
import { connect } from "react-redux";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import Face from "@material-ui/icons/Face";
import Chat from "@material-ui/icons/Chat";
import Build from "@material-ui/icons/Build";
import Person from "@material-ui/icons/Person";
import Edit from "@material-ui/icons/Edit";
import Close from "@material-ui/icons/Close";
import Add from "@material-ui/icons/Add";

// core components
import CustomInput from "components/CustomInput/CustomInput.js";
import CustomTabs from "components/CustomTabs/CustomTabs.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Table from "components/Table/Table.js";
import Button from "components/CustomButtons/Button.js";

// styles
import documentsPageStyle from "assets/jss/material-dashboard-pro-react/views/documentsPageStyles.js";
import { infoBoxShadow } from "assets/jss/material-dashboard-pro-react";
import styles from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js";

export function DocumentsPage(props) {
    const { classes } = props;
    const [files, setFiles] = useState({
        fileName: '',
        fileError: ''
    })

    const onChange = async (e) => {
        setFiles({
            ...files,
            [e.target.id]: e.target.files[0]
        })


    }

    const validate = () => {
        let fileError = '';
        console.log(files.fileName)
        if (!files.fileName) {
            fileError = "Error! No file selected";
            console.log("ERROR")
        }
        if (fileError) {
            setFiles({
                ...files,
                fileError
            });
            return false
        }
        return true;
    }

    const onSubmit = async (e) => {
        e.preventDefault();

        const isValid = validate();

        if (isValid) {
            const file = files.fileName;
            const storageRef = firebase.storage().ref("Documents/ProofOfFulfillment");
            const fileRef = storageRef.child(file.name)
            await fileRef.put(file)
        }
        window.location.reload();
    }

    return (
        <div>
            <h1>Proof of Fulfillment</h1>
            <GridContainer
                style={{ border: "1px solid black", width: "350px", left: "290px", position: "fixed" }}>
                <form onSubmit={onSubmit}>
                    <CustomInput
                        // labelText="First Name"
                        id="fileName"
                        formControlProps={{
                            fullWidth: true
                        }}
                        inputProps={{
                            type: "file",
                            onChange: onChange,
                            disableUnderline: true,
                            // required: true
                        }}
                    />
                    <div style={{ color: "red" }}>{files.fileError}</div>
                    <Button
                        type="submit"
                        className={classes.newButton}>
                        Upload file
                    </Button>
                </form>
                </GridContainer>
        </div>
    );
}

DocumentsPage.propTypes = {
    classes: PropTypes.object.isRequired,
}

function mapStateToProps(state) {
    return {

    };
}

function mapDispatchToProps(actions) {
    return (dispatch) => ({
        edit: (key) => dispatch(actionsForNavigation.openChild("Documents", `/admin/documents`)),
    })
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(documentsPageStyle)
)(DocumentsPage);
