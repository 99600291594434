import React, { useState, } from "react";
import PropTypes from "prop-types";
import { firebase } from 'firebase/client.js'
import withStyles from "@material-ui/core/styles/withStyles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Collapse from "@material-ui/core/Collapse";
import { NavLink } from "react-router-dom";
import cx from "classnames";
import avatar from "assets/img/faces/avatar.jpg";
import compose from 'recompose/compose';
import { connect } from 'react-redux';

import sidebarStyle from "assets/jss/material-dashboard-pro-react/components/sidebarStyle.js";
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import EditIcon from '@material-ui/icons/Edit';

class UserInfo extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            openAvatar: false,
            image: []
        };
    }

    openCollapse(collapse) {
        var st = {};
        st[collapse] = !this.state[collapse];
        this.setState(st);
    }

    render() {
        const { classes, firstName, lastName, miniActive, rtlActive, bgColor, uid } = this.props;

        const itemText =
            classes.itemText +
            " " +
            cx({
                [classes.itemTextMini]: miniActive,
                [classes.itemTextMiniRTL]:
                    rtlActive && miniActive,
                [classes.itemTextRTL]: rtlActive
            });
        const collapseItemText =
            classes.collapseItemText +
            " " +
            cx({
                [classes.collapseItemTextMini]:
                    miniActive,
                [classes.collapseItemTextMiniRTL]:
                    rtlActive && miniActive,
                [classes.collapseItemTextRTL]: rtlActive
            });
        const userWrapperClass =
            classes.user +
            " " +
            cx({
                [classes.whiteAfter]: bgColor === "white"
            });
        const caret =
            classes.caret +
            " " +
            cx({
                [classes.caretRTL]: rtlActive
            });
        const collapseItemMini =
            classes.collapseItemMini +
            " " +
            cx({
                [classes.collapseItemMiniRTL]: rtlActive
            });
        const photo =
            classes.photo +
            " " +
            cx({
                [classes.photoRTL]: rtlActive
            });
        // const [image, setImage] = useState([])
        
        const getImage = async () => {
            let storageRef = await firebase.storage().ref().child(`${uid}/profilePicture`).listAll();
            const urls = await Promise.all(storageRef.items.map((refer) => refer.getDownloadURL()));
            this.setState({image: urls})
            // console.log("TESTING getImage function")
        }
        getImage()
        return (
        
            <div className={userWrapperClass}>
                <div className={photo}>
                    {this.state.image.length===0?<img src={avatar} alt="..." className={classes.avatarImg}/>:<img src={this.state.image} className={classes.avatarImg} alt="..." />}
                </div>
                <List className={classes.list}>
                    <ListItem className={classes.item + " " + classes.userItem}>
                        <NavLink
                            to={"#"}
                            className={classes.itemLink + " " + classes.userCollapseButton}
                            onClick={() => this.openCollapse("openAvatar")}
                        >
                            <ListItemText
                                primary={firstName + ' ' + lastName } 
                                secondary={
                                    <b
                                        className={
                                            caret +
                                            " " +
                                            classes.userCaret +
                                            " " +
                                            (this.state.openAvatar ? classes.caretActive : "")
                                        }
                                    />
                                }
                                disableTypography={true}
                                className={itemText + " " + classes.userItemText}
                            />
                        </NavLink>
                        <Collapse in={this.state.openAvatar} unmountOnExit>
                            <List className={classes.list + " " + classes.collapseList}>
                                <ListItem className={classes.collapseItem}>
                                    <NavLink
                                        to="/admin/profile"
                                        className={
                                            classes.itemLink + " " + classes.userCollapseLinks
                                        }
                                    >
                                        <div style={{ display: "flex" }}>
                                            <AccountCircleIcon style={{ margin: "0 15px 0 0" }} />
                                            <ListItemText
                                                primary={rtlActive ? "ملفي" : "My Profile"}
                                                disableTypography={true}
                                                className={collapseItemText}
                                            />
                                        </div>
                                    </NavLink>
                                </ListItem>
                                <ListItem className={classes.collapseItem}>
                                    <NavLink
                                        to="/admin/user/editor"
                                        className={
                                            classes.itemLink + " " + classes.userCollapseLinks
                                        }
                                    >
                                        <div style={{ display: "flex" }}>
                                            <EditIcon style={{ margin: "0 15px 0 0" }} />
                                            <ListItemText
                                                primary={
                                                    rtlActive ? "تعديل الملف الشخصي" : "Edit Profile"
                                                }
                                                disableTypography={true}
                                                className={collapseItemText}
                                            />
                                        </div>
                                    </NavLink>
                                </ListItem>
                            </List>
                        </Collapse>
                    </ListItem>
                </List>
            </div>
        );
    }
}

UserInfo.propTypes = {
    classes: PropTypes.object.isRequired,
    bgColor: PropTypes.oneOf(["white", "black", "blue"]),
    rtlActive: PropTypes.bool,
    miniActive: PropTypes.bool,
    firstName: PropTypes.string,
    lastName: PropTypes.string
};

function mapStateToProps(state) {
    return {
        firstName: state.auth.user && state.auth.user.firstName,
        lastName: state.auth.user && state.auth.user.lastName,
        uid: state.auth.user && state.auth.user.uid
    };
}

export default compose(
    connect(mapStateToProps),
    withStyles(sidebarStyle)
)(UserInfo);