/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import RegistrationActions from 'store/actions/forms/registration';
import RegistrationAccessors from 'store/reducers/forms/registration';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import InputAdornment from '@material-ui/core/InputAdornment';

// @material-ui/icons
import Face from '@material-ui/icons/Face';
import Phone from '@material-ui/icons/Phone';
import Timeline from '@material-ui/icons/Timeline';

// core components
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import InfoArea from 'components/InfoArea/InfoArea.js';
import Card from 'components/Card/Card.js';
import BoundInput from 'components/CustomInput/BoundInput';

// styles
import Style from 'assets/jss/kordspace-template/views/pages/registration/stepStyle';

const CustomInput = BoundInput(RegistrationActions.commonProfile, RegistrationAccessors.commonProfile);

export function CommonProfile(props) {

    const { classes, error } = props;

    return(
        <GridContainer>
            <GridItem xs={12} sm={10} md={8} lg={6}>
                <h2
                    className={classes.cardTitle}
                    style={{ fontSize: '1.8rem', color: '#000' }}
                >
                    Tell us something about yourself
                </h2>
                { error &&
                    <InfoArea icon={Timeline} title='Error' description={error.message}/>
                }
                <Card style={{ paddingLeft: '20px', marginBottom: '0' }}>
                    <CustomInput
                        labelText='First Name'
                        name='firstName'
                        formControlProps={{
                            fullWidth: true,
                        }}
                        inputProps={{
                            disableUnderline: true,
                            endAdornment: (
                            <InputAdornment position='end'>
                                <Face />
                            </InputAdornment>
                            ),
                        }}
                    />
                </Card>

                <Card
                    style={{
                        paddingLeft: '20px',
                        marginBottom: '0',
                        marginTop: '20px',
                    }}
                >
                    <CustomInput
                        labelText='Last Name'
                        name='lastName'
                        formControlProps={{
                            fullWidth: true,
                    }}
                    inputProps={{
                        disableUnderline: true,
                        endAdornment: (
                        <InputAdornment position='end'>
                            <Face/>
                        </InputAdornment>
                        ),
                    }}
                    />
                </Card>
                <Card
                    style={{
                        paddingLeft: '20px',
                        marginBottom: '0',
                        marginTop: '20px',
                    }}
                >
                    <CustomInput
                        labelText='Phone Number'
                        name='phoneNumber'
                        formControlProps={{
                            fullWidth: true,
                    }}
                    inputProps={{
                        disableUnderline: true,
                        endAdornment: (
                        <InputAdornment position='end'>
                            <Phone/>
                        </InputAdornment>
                        ),
                    }}
                    />
                </Card>                
                <Card
                    style={{
                    paddingLeft: '20px',
                    marginBottom: '0',
                    marginTop: '20px',
                    }}
                >
                    <CustomInput
                        labelText='Date of Birth'
                        name='dateOfBirth'
                        formControlProps={{
                            fullWidth: true,
                        }}
                        inputProps={{
                            type: "date",
                            disableUnderline: true                            
                        }}
                    />
                </Card>
            </GridItem>
        </GridContainer>
    );
}

const mapDispatchToProps = (dispatch) => ({});
  
const mapStateToProps = (state, ownProps) => {
    return {
        error: RegistrationAccessors.commonProfile.getError(state)
    };
};
  
export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(Style)
)(CommonProfile);