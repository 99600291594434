import React,  { useEffect } from "react";
import { useParams } from 'react-router';
import BoundForm from "components/Form/BoundForm";
import BoundFormFields from "components/Form/BoundFormFields";
import EditorForm from "./EditorForm";
import { connect } from "react-redux";
import { OpenMode } from 'variables/constants';

function BoundEditorForm(props) {
    const { key } = useParams();
    const { initialize, mode } = props;

    useEffect(() => {
        initialize((mode && OpenMode[mode]) || OpenMode.UPDATE, key);
    },[key, mode, initialize]);

    return (
        <EditorForm {...props}/>
    )
}

function mapStateToProps(actions, accessors) { 

    const Form = BoundForm(actions, accessors);
    const FormFields = BoundFormFields(actions, accessors);
    
    return (state)=> {
        return {
            Form,
            FormFields,
            mode: state.router.location && state.router.location.query && state.router.location.query.mode
        }
    };
}

function mapDispatchToProps(actions){
    return (dispatch) => {
        return {
            initialize: (mode, key)=>dispatch(actions.initialize(mode, key))
        };
    }
}

export default (actions, accessors)=>connect(mapStateToProps(actions, accessors), mapDispatchToProps(actions))(BoundEditorForm);