/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import compose from "recompose/compose";
import { connect } from "react-redux";
import { Link, useParams } from "react-router-dom";
import actionsForNavigation from "store/actions/navigation";
import moment from "moment";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from '@material-ui/core/Icon';
import TextField from "@material-ui/core/TextField";




// @material-ui/icons
import MailOutline from "@material-ui/icons/MailOutline";
import Check from "@material-ui/icons/Check";
import Email from "@material-ui/icons/Email";
import Group from '@material-ui/icons/Group';


// core components
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";

import Style from "assets/jss/material-dashboard-pro-react/views/registerPageStyle";
import { db, firebase } from 'firebase/client.js'
import GridContainer from "components/Grid/GridContainer";
import GridItem from 'components/Grid/GridItem.js';
import { Grid } from "@material-ui/core";
import CardFooter from "components/Card/CardFooter";
import uuid from 'react-native-uuid';
import { PayPalButton } from "react-paypal-button-v2";



export function InvoiceForm(props) {
    const { classes } = props;
    const [invoices, setInvoice] = useState([]);
    const [loading, setLoading] = useState(false);
    const [checked, setChecked] = useState([24, 22], false);
    const [info, setInfo] = useState({
        firstName: '',
        lastName: '',
        id: ' ',
        date: '',
        total: '',
        notes: '',
        paid: '',
        firstError: '',
        lastError: '',
        check: false,
        boxError: '',
        paidError: '',
    });
    const { uid, id } = useParams();
    const ref = firebase.firestore().collection("users").doc(uid).collection("invoices").doc(id);


    function getInvoice() {
        setLoading(true);
        ref.get().then((queryResult) => {
            const items = []
            items.push(queryResult.data())
            setInvoice(items)
            setLoading(false)
        })

    }

    const handleToggle = value => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
            setInfo({
                ...info,
                check: true
            })
        } else {
            newChecked.splice(currentIndex, 1);

        }
        setChecked(newChecked);

    };

    const handleChange = (e) => {
        setInfo({
            ...info,
            [e.target.id]: e.target.value
        })
    }

    const validate = () => {
        let firstError = "";
        let lastError = "";
        let boxError = "";
        let paidError = ""


        if (!info.firstName) {
            firstError = "Can't have an empty input";
        }

        if (!info.lastName) {
            lastError = "Can't have an empty input";
        }

        if (info.check === false) {
            boxError = "Please check the box to confirm the invoice"
        }

        if (!info.paid){
            paidError = "Invalid Input"
        }

        if (firstError || lastError || boxError || paidError) {
            setInfo({
                ...info,
                firstError,
                lastError,
                boxError,
                paidError
            });
            return false;
        }
        return true;
    }
    useEffect(() => {
        getInvoice();
    }, []);
    return (
        <div className={classes.container}>
            <h1>Pay an Invoice </h1>
            <h3>Url ID: {id}</h3>
            <div style={{ display: "flex", justifyContent: "center" }}>

            </div>

            {/* FORM */}

            <form>
                {invoices.map((invoice) => (
                    <>

                        <Card login style={{ backgroundColor: "rgb(247, 243, 240)" }}>
                            <p style={{ paddingLeft: "10px", paddingTop: "5px" }}>*Required</p>

                            <GridContainer justify="center" style={{ padding: "0 10px" }}>
                                {/* <CardBody style={{ paddingBottom: "0px"}}> */}
                                <GridItem xs={6}>
                                    <Card style={{ paddingLeft: "20px", marginBottom: "0", marginTop: "20px" }}>

                                        <TextField
                                            label="First Name"
                                            id="firstName"
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            InputProps={{
                                                inputProps: {
                                                    onChange: handleChange,
                                                }

                                            }}
                                            error={info.firstError}
                                            helperText={info.firstError ? 'Error! Input is invalid.' : ''}
                                        />

                                    </Card>
                                </GridItem>
                                <GridItem xs={6}>
                                    <Card style={{ paddingLeft: "20px", marginBottom: "0", marginTop: "20px" }}>

                                        <TextField
                                            label="Last Name"
                                            id="lastName"
                                            formControlProps={{
                                                fullWidth: true,

                                            }}
                                            InputProps={{
                                                inputProps: {
                                                    onChange: handleChange,
                                                }

                                            }}
                                            error={info.lastError}
                                            helperText={info.lastError ? 'Error! Input is invalid.' : ''}
                                        />

                                    </Card>
                                </GridItem>

                                <GridItem xs={3}>
                                    <Card style={{ paddingLeft: "20px", marginBottom: "0", marginTop: "20px" }}>

                                        <TextField
                                            label="Invoice ID"
                                            id="invoiceId"
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            InputProps={{
                                                inputProps: {
                                                    onChange: handleChange,
                                                    value: id,
                                                    readOnly: true
                                                }

                                            }}
                                        />
                                    </Card>
                                </GridItem>
                                <GridItem xs={3}>
                                    <Card style={{ paddingLeft: "20px", marginBottom: "0", marginTop: "20px" }}>

                                        <TextField
                                            label="Invoice Date"
                                            id="date"
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            InputProps={{
                                                inputProps: {
                                                    onChange: handleChange,
                                                    value: invoice.Date,
                                                    readOnly: true
                                                }

                                            }}
                                        />
                                    </Card>
                                </GridItem>

                                <GridItem xs={3}>
                                    <Card style={{ paddingLeft: "20px", marginBottom: "0", marginTop: "20px" }}>

                                        <TextField
                                            label="Invoice Total"
                                            id="total"
                                            formControlProps={{
                                                fullWidth: true,
                                            }}
                                            InputProps={{
                                                inputProps: {
                                                    onChange: handleChange,
                                                    value: invoice.Item_Info.Item_Price,
                                                    readOnly: true
                                                }

                                            }}

                                        />
                                    </Card>
                                </GridItem>

                                <GridItem xs={3}>
                                    <Card style={{ paddingLeft: "20px", marginBottom: "0", marginTop: "20px" }}>

                                        <TextField
                                            label="Amount to Pay"
                                            id="paid"
                                            type="number"
                                            formControlProps={{
                                                fullWidth: true,
                                            }}
                                            InputProps={{
                                                inputProps: {
                                                    onChange: handleChange,
                                                    step: 0.01,
                                                    min: 0.01
                                                }

                                            }}
                                            error={info.paidError}
                                            helperText={info.paidError ? 'Error! Input is invalid.' : ''}
                                        />
                                    </Card>
                                </GridItem>

                                <GridItem xs={12}>
                                    <Card style={{ paddingLeft: "20px", marginBottom: "0", marginTop: "20px" }}>

                                        <TextField
                                            label="Notes"
                                            id="notes"
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            InputProps={{
                                                inputProps: {
                                                    onChange: handleChange,
                                                }

                                            }}
                                        />

                                    </Card>
                                </GridItem>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            required
                                            tabIndex={-1}
                                            onClick={() => handleToggle(2)}
                                            checkedIcon={<Check className={classes.checkedIcon} />}
                                            icon={<Check className={classes.uncheckedIcon} />}
                                            classes={{
                                                checked: classes.checked,
                                                root: classes.checkRoot
                                            }}
                                        />
                                    }
                                    classes={{
                                        label: classes.label,
                                        root: classes.labelRoot
                                    }}
                                    label="Confirm this Invoice"

                                />
                            </GridContainer>
                            <div style={{ color: "red", textAlign: "center", paddingBottom: "10px"}}>{info.boxError}</div>
                            <div style={{ display: "flex", justifyContent: "center" }}>
                                <PayPalButton
                                    type="submit"
                                    style={{ color: "blue", shape: "pill" }}
                                    createOrder={(details, actions) => {
                                        return actions.order.create({
                                            intent: "CAPTURE",
                                            purchase_units: [{
                                                amount: {
                                                    value: info.paid
                                                }
                                            }]
                                        })
                                    }}
                                    onClick={(data, actions) => {
                                        const isValid = validate();
                                        if (isValid) {
                                            setInfo({
                                                ...info,
                                                check: false
                                            })
                                            return true;
                                        }
                                        else {
                                            // console.log("BUTTON NOT VALIDATED")
                                            return false;
                                        }
                                    }}


                                    async onApprove={(data, actions) => {
                                        return actions.order.capture().then(function (details) {
                                            alert("Transaction: " + details.purchase_units[0].payments.captures[0].id + "has been completed.")
                                            let itemInfo =
                                            {
                                                Item: "N/A",
                                                Item_Price: invoice.Item_Info.Item_Price - details.purchase_units[0].amount.value,
                                            }
                                            let paymentInfo = {
                                                // Address: details.purchase_units[0].shipping.address,
                                                Name: details.purchase_units[0].shipping.name.full_name,
                                                // Email: details.payer.email_address,
                                                Date: moment().format("MM/DD/YYYY"),
                                                Item_Info: itemInfo,
                                                Payment_ID: details.purchase_units[0].payments.captures[0].id,
                                                Payment_Status: details.purchase_units[0].payments.captures[0].status,
                                            }
                                            setInfo({
                                                firstName: '',
                                                lastName: '',
                                                id: ' ',
                                                date: '',
                                                total: '',
                                                notes: '',
                                                firstError: '',
                                                lastError: '',
                                                check: false,
                                                boxError: ''
                                            })
                                            ref.update(paymentInfo);
                                            props.edit()
                                        })


                                    }}
                                />
                            </div>

                        </Card>


                    </>
                ))}
            </form>


        </div>

    );
}

InvoiceForm.propTypes = {
    classes: PropTypes.object.isRequired,
}

const mapStateToProps = (state) => {
    return {
        AuthBool: !!state.auth.user,
        authError: state.auth.error
    }
}
function mapDispatchToProps(actions) {
    return (dispatch) => ({
        edit: (key) => dispatch(actionsForNavigation.openChild("Pay Invoice", `/auth/request-invoice`)),
    })
}
export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(Style)
)(InvoiceForm);
