/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { db, firebase } from 'firebase/client.js';
import compose from "recompose/compose";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { actionsForDialog } from 'store/actions/dialog';
import Multiselect from 'multiselect-react-dropdown';

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

// @material-ui/icons
import Check from "@material-ui/icons/Check";

// core components
import TermsAndConditionsDialog from 'views/Components/TermsAndConditionsDialog';
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardFooter from "components/Card/CardFooter";
import actionsForNavigation from "store/actions/navigation";
import Style from "assets/jss/material-dashboard-pro-react/views/registerPageStyle";
import CustomInput from "components/CustomInput/CustomInput";


const TermsAndConditionsActions = actionsForDialog("termsAndConditions");



export function StaffSignUpPage(props) {
  const { classes, openTermsAndConditions } = props;
  const [checked, setChecked] = useState([24, 22], false);
  const [loading, setLoading] = useState(false);
  const [invoices, setInvoice] = useState([])
  // const [user, setUser] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [select, setSelect] = useState('');
  const [selectErr, setSelectErr] = useState('')
  const [info, setInfo] = useState({
    fName: '',
    lName: '',
    email: '',
    password: '',
    confirmpw: '',
    terms: false,
    dob: '',
    number: '',
    userType: 'Staff',
    company: 'Kordspace',
    title: '',
    dobErr: '',
    titleErr: '',
    numberErr: '',
    firstNameErr: '',
    lastNameErr: '',
    passwordErr: ''

  })

  const [vendorID, setVendorID] = useState('')

  let { id } = useParams();
  const [options, setOptions] = useState(["Agency", "Driver", "General"])
  const numberReg = /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})\s*$/;
  const [emailErr, setEmailErr] = useState('');
  const [pwErr, setPwErr] = useState('');
  const [hasAcc, setHasAcc] = useState(false);

  const ref = db.collection('users').doc(id)

  const handleToggle = value => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setChecked(newChecked);
    if (newChecked.length === 3) {
      setInfo({ ...info, terms: true })
    }
    else {
      setInfo({ ...info, terms: false })
    }
  };

  const onSelect = (e) => {
    setSelect(e)
  }

  const clearInputs = () => {
    setEmail('');
    setPassword('');
  }

  const clearErrors = () => {
    setEmailErr('');
    setPwErr('');
  }

  const onChange = (e) => {
    setInfo({
      ...info,
      [e.target.id]: e.target.value
    })
  }
  const checkEmpty = () => {
    if (select == '') {
      setSelectErr("Must have one selected.")
      return true
    }
    return false
  }
  const validate = () => {
    let firstNameErr = '';
    let lastNameErr = '';
    let passwordErr = '';
    let titleErr = '';
    let dobErr = '';
    let numberErr = '';

    if (info.fName == "") {
      firstNameErr = "Invalid input."
    }
    if (!info.lName) {
      lastNameErr = "Invalid input."
    }
    if (info.password != info.confirmpw || !info.password) {
      passwordErr = "Passwords must match."
    }
    if (!info.dob) {
      dobErr = "Invalid input."
    }
    if (!info.title) {
      titleErr = "Invalid input."
    }
    if (!numberReg.test(info.number) || !info.number) {
      numberErr = "Invalid input."
    }

    if (firstNameErr || lastNameErr || passwordErr || dobErr || numberErr || titleErr) {
      setInfo({
        ...info,
        firstNameErr,
        lastNameErr,
        passwordErr,
        dobErr,
        numberErr,
        titleErr
      });
      return false;
    }
    return true;
  }


  const handleLogin = () => {
    clearErrors();
    firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .catch((err) => {
        switch (err.code) {
          case "auth/invalid-email":
          case "auth/user-disabled":
          case "auth/user-not-found":
            setEmailErr(err.message);
            break;
          case "auth/wrong-password":
            setPwErr(err.message);
            break;
        }
      });

  };
  const handleSignup = (e) => {
    e.preventDefault()
    const isValid = validate();
    const isEmpty = checkEmpty();
    if (isValid && !isEmpty) {
      clearErrors();
      firebase
        .auth()
        .createUserWithEmailAndPassword(info.email, info.password)
        .then(async function (data) {
          console.log('uid,', data.user.uid)
          await db.collection('users').doc(data.user.uid).set({
            firstName: info.fName,
            lastName: info.lName,
            email: info.email,
            userType: info.userType,
            dateOfBirth: info.dob,
            phoneNumber: info.number,
            credentails: info.email,
            accetpedTerms: info.terms,
            staffType: select
          })
            .then(async () => {
              await db.collection('users').doc(vendorID).collection('staffs').doc(data.user.uid).set({
                firstName: info.fName,
                lastName: info.lName,
                staffID: data.user.uid,
                userType: info.userType,
                staffType: select,
                email: info.email,
                jobTitle: info.title,
                phoneNumber: info.number,
                company: info.company,
                vendorID: vendorID,
                status: "Active"

              })
            })

        })

        //deletes temporary doc in subcollection staffs
        .then(async () => {
          await db.collection('users').doc(vendorID).collection('staffs').doc(id).delete();
          console.log("finished deleting temp doc")
        })
        //deletes the temporary doc created from payrollpage
        .then(async () => {
          await db.collection('users').doc(id).delete();
          console.log("finished deleting temp doc")
        })
        // .then(window.location.reload())
        .catch((err) => {
          console.log("ERROR:", err)
          switch (err.code) {
            case "auth/email-already-in-use":
            case "auth/invalid-email":
              setEmailErr(err.message);
              break;
            // case "auth/weak-password":
            //   setPwErr(err.message);
            //   break;
          }
        });
      props.reset()
    }
    else {
      console.log("ERROR")
    }


  };

  const handleLogout = () => {
    firebase.auth().signOut().then(function () {
      console.log('Signed Out');
    },
      function (error) {
        console.error(error)
      })
  };
  function getInvoice() {
    setLoading(true);
    ref.get().then((queryResult) => {
      const items = []
      items.push(queryResult.data())
      setInfo({
        ...info,
        fName: items[0].Name.firstName,
        lName: items[0].Name.lastName,
        title: items[0].jobTitle,
        number: items[0].phoneNumber,
        email: items[0].email
      })
      setVendorID(items[0].vendorID)
      // console.log("TESTING CHECK:",items)
      setInvoice(items)
      setLoading(false)
    })
  }

  useEffect(() => {
    getInvoice();
  }, []);

  return (
    <div className={classes.container}>
      {invoices.map((invoice) => (
        <>
          <form onSubmit={handleSignup}>
            <Card login style={{ backgroundColor: "rgb(247, 243, 240)" }}>
              <p style={{ paddingLeft: "10px", paddingTop: "5px" }}>*Required</p>
              <GridContainer justify="center" style={{ padding: "0 10px" }}>
                {/* <CardBody style={{ paddingBottom: "0px"}}> */}
                <GridItem xs={6}>
                  <Card style={{ paddingLeft: "20px", marginBottom: "0", marginTop: "20px" }}>

                    <TextField
                      label="*First Name"
                      id="fName"
                      formControlProps={{
                        fullWidth: true
                      }}
                      InputProps={{
                        inputProps: {
                          defaultValue: invoice.Name.firstName,
                          onChange: onChange
                        }

                      }}
                      error={info.firstNameErr}
                      helperText={info.firstNameErr ? 'Error! Input is invalid.' : ''}
                    />
                  </Card>
                </GridItem>
                <GridItem xs={6}>
                  <Card style={{ paddingLeft: "20px", marginBottom: "0", marginTop: "20px" }}>

                    <TextField
                      label="*Last Name"
                      id="lName"
                      formControlProps={{
                        fullWidth: true,

                      }}
                      InputProps={{
                        inputProps: {
                          defaultValue: invoice.Name.lastName,
                          onChange: onChange
                        }

                      }}
                      error={info.lastNameErr}
                      helperText={info.lastNameErr ? 'Error! Input is invalid.' : ''}
                    />
                  </Card>
                </GridItem>
                <GridItem xs={6}>
                  <Card style={{ paddingLeft: "20px", marginBottom: "0", marginTop: "20px" }}>

                    <TextField
                      label="*Email"
                      id="email"
                      type="email"
                      formControlProps={{
                        fullWidth: true,

                      }}
                      InputProps={{
                        inputProps: {
                          defaultValue: invoice.email,
                          // onChange: onChange
                          readOnly: true
                        }

                      }}
                    // error={emailErr}
                    // helperText={emailErr ? 'Error! Email has already been registerd' : ''}
                    />
                  </Card>
                </GridItem>

                <GridItem xs={6}>
                  <Card style={{ paddingLeft: "20px", marginBottom: "0", marginTop: "20px" }}>
                    *Date of Birth
                    <TextField
                      // label="*Date of Birth"
                      id="dob"
                      formControlProps={{
                        disableUnderline: true,
                        // fullWidth: true,
                      }}
                      InputProps={{
                        inputProps: {
                          type: "date",
                          onChange: onChange
                        }

                      }}
                      error={info.dobErr}
                      helperText={info.dobErr ? 'Error! Input is invalid.' : ''}
                    />
                  </Card>
                </GridItem>
                <GridItem xs={6}>
                  <Card style={{ paddingLeft: "20px", marginBottom: "0", marginTop: "20px" }}>

                    <TextField
                      label="*Phone Number"
                      id="number"
                      formControlProps={{
                        fullWidth: true,

                      }}
                      InputProps={{
                        inputProps: {
                          defaultValue: invoice.phoneNumber,
                          onChange: onChange
                        }

                      }}
                      error={info.numberErr}
                      helperText={info.numberErr ? 'Error! Input is invalid.' : ''}
                    />
                  </Card>
                </GridItem>

                <GridItem xs={6}>
                  <Card style={{ paddingLeft: "20px", marginBottom: "0", marginTop: "20px" }}>

                    <TextField
                      label="*Job Title"
                      id="title"
                      formControlProps={{
                        fullWidth: true,

                      }}
                      InputProps={{
                        inputProps: {
                          defaultValue: invoice.jobTitle,
                          onChange: onChange
                        }

                      }}
                      error={info.titleErr}
                      helperText={info.titleErr ? 'Error! Input is invalid.' : ''}
                    />
                  </Card>
                </GridItem>

                <GridItem xs={6}>
                  <Card style={{ paddingLeft: "20px", marginBottom: "0", marginTop: "20px" }}>

                    <TextField
                      label="UserType"
                      id="userType"
                      formControlProps={{
                        fullWidth: true,

                      }}

                      InputProps={{
                        inputProps: {
                          defaultValue: "Staff",
                          readOnly: true
                        }

                      }}
                    />
                  </Card>
                </GridItem>
                <GridItem xs={6}>
                  <Card style={{ marginBottom: "0", marginTop: "20px" }}>

                    <Multiselect
                      id="staffType"
                      onSelect={onSelect}
                      required={true}
                      isObject={false}
                      options={options}
                      selectionLimit={1}
                      placeholder="*Select one option"
                    />
                    <div style={{ color: "red" }}>{selectErr}</div>
                    {/* {console.log("testing select err:", selectErr)} */}
                  </Card>
                </GridItem>
                <GridItem xs={6}>
                  <Card style={{ paddingLeft: "20px", marginBottom: "0", marginTop: "20px" }}>

                    <TextField
                      label="*Password"
                      id="password"
                      type="password"
                      formControlProps={{
                        fullWidth: true,

                      }}
                      InputProps={{
                        inputProps: {
                          onChange: onChange
                        }

                      }}
                      error={info.passwordErr}
                      helperText={info.passwordErr ? 'Error! Password needs to match.' : ''}
                    />
                  </Card>
                </GridItem>
                <GridItem xs={6}>
                  <Card style={{ paddingLeft: "20px", marginBottom: "0", marginTop: "20px" }}>

                    <TextField
                      label="Confirm Password"
                      id="confirmpw"
                      type="password"
                      formControlProps={{
                        fullWidth: true,

                      }}
                      InputProps={{
                        inputProps: {
                          onChange: onChange
                        }

                      }}
                      error={info.passwordErr}
                      helperText={info.passwordErr ? 'Error! Password needs to match.' : ''}
                    />
                  </Card>
                </GridItem>

                <FormControlLabel
                  control={
                    <Checkbox
                      tabIndex={-1}
                      onClick={() => handleToggle(2)}
                      checkedIcon={<Check className={classes.checkedIcon} />}
                      icon={<Check className={classes.uncheckedIcon} />}
                      required
                      classes={{
                        checked: classes.checked,
                        root: classes.checkRoot
                      }}
                    />
                  }
                  classes={{
                    label: classes.label,
                    root: classes.labelRoot
                  }}
                  label="I agree to the terms and conditions"
                />
              </GridContainer>
              {<div style={{ textAlign: "center" }}>{<a title="terms" onClick={(e) => { props.openTermsAndConditions() }}>Terms and Conditions </a>}</div>}
              <TermsAndConditionsDialog />
              <div style={{ display: "flex", justifyContent: "center" }}>
                <CardFooter style={{ padding: "0px 5px 10px 5px" }} className={classes.justifyContentCenter}>
                  <Button
                    style={{ fontWeight: "600", color: "#FFFFFF", backgroundColor: "#FC6047", marginTop: "10px", display: "flex" }}
                    type="submit"
                  >
                    Register
                  </Button>
                </CardFooter>
              </div>
            </Card>
          </form>
        </>
      ))}
    </div>

  );
}
StaffSignUpPage.propTypes = {
  classes: PropTypes.object.isRequired,
}
function mapDispatchToProps(actions) {
  return (dispatch) => ({
    openTermsAndConditions: () => dispatch(TermsAndConditionsActions.openDialog()),
    reset: (key) => dispatch(actionsForNavigation.openChild("Login Page", `/auth/login-page`))
  })
}
export default compose(
  connect(null, mapDispatchToProps),
  withStyles(Style)
)(StaffSignUpPage);
