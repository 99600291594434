/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-key */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { db, firebase } from 'firebase/client.js';
import compose from "recompose/compose";
import { connect } from "react-redux";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import InputAdornment from "@material-ui/core/InputAdornment";

// @material-ui/icons
import Search from "@material-ui/icons/Search";

// core components
import CustomInput from "components/CustomInput/CustomInput";
import Card from "components/Card/Card";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

//styles
import servicesPageStyle from "assets/jss/material-dashboard-pro-react/views/servicesPageStyles.js";


const tableHeaders = ["Invoice ID", "Date Created", "Due Date", "Email", "Status"]
export function Estimates(props) {
    const [invoices, setInvoice] = useState([]);
    const [loading, setLoading] = useState(false);
    const docID = firebase.auth().currentUser.uid;
    const ref = firebase.firestore().collection("users").doc(docID).collection("archived");
    const { classes } = props;

    function getInvoice() {
        setLoading(true);
        ref.onSnapshot((querySnapshot) => {
            const items = [];
            querySnapshot.forEach((doc) => {
                items.push(doc.data());
            });
            setInvoice(items);
            setLoading(false);
        });
    }
    useEffect(() => {
        getInvoice();
    }, []);

    if (loading) {
        return <h1>Loading...</h1>;
    }
    const renderTableData = () => {
        return invoices.map((invoice, i) => {
            // console.log('invoiceinvoice', invoice)
            // console.log("TESTING MAP", invoice.Visibility)

            return (
                invoice.uID === firebase.auth().currentUser.uid ?
                    <tr style={{ width: "100%", minHeight: "100px" }} style={{ color: "#3C4858", fontFamily: "Quattrocento Sans", fontSize: "16px", fontWeight: "400" }}>
                        <td className={classes.tableCell} style={{ fontWeight: "700", }}>
                            {invoice.Invoice_ID}
                        </td>
                        <td className={classes.tableCell} style={{ width: "20%" }}>
                            {invoice.Date}
                        </td>
                        <td className={classes.tableCell} style={{ width: "20%" }}>
                            {invoice.dueDate}
                        </td>
                        <td className={classes.tableCell}>
                            {invoice.Email.map((emails, index) => (
                                <td style={{ width: "50%" }}>
                                    {emails}
                                </td>
                            ))}
                        </td>
                        <td className={classes.tableCell}>
                            {invoice.Payment_Status}
                        </td>

                    </tr>
                    : null
            )
        })
    }
    const renderTableHeader = () => {
        return tableHeaders.map((header, index) => {
            return <th style={{
                width: "14%", fontSize: "25px", borderBottom: "1px solid rgb(171 167 167)", background: "#AD1E5A", color: "white", textTransform: "uppercase",
                fontFamily: "Quattrocento Sans", fontWeight: "bold",
            }} key={index}>{header}
                {/* <TextField InputProps={{
              classes: {
                input: classes.textField
              }
            }}  />  */}
            </th>
        })
    }
    return (
        <div className={classes.container}>
            <GridContainer style={{
                alignItems: "center",
                justifyContent: "center", margin: "0px auto", width: "95%"
            }}>
                <GridItem xs={6}><h1 style={{ color: "white", fontFamily: "Quattrocento Sans", fontWeight: "bold" }}>Archived List</h1></GridItem>
                <GridItem xs={6} sm={6} md={6}>
                    <CustomInput
                        labelText="Search Services"
                        id="material"
                        formControlProps={{
                            fullWidth: true
                        }}
                        inputProps={{
                            endAdornment: (<InputAdornment position="end"><Search style={{ color: "white" }} /></InputAdornment>)
                        }}
                    />
                </GridItem>
                <Card style={{ marginTop: "5px" }}>
                    <table style={{ width: "100%", }}>
                        {/* <tbody> */}
                        <tr className={classes.headerRow}>{renderTableHeader()} </tr>
                        {renderTableData()}
                        {/* </tbody> */}
                    </table>
                </Card>
                {/* </Card> */}
            </GridContainer>
        </div>

    );
}
Estimates.propTypes = {
    classes: PropTypes.object.isRequired,
}

const mapStateToProps = (state) => {
    return {
        AuthBool: !!state.auth.user,
        authError: state.auth.error
    }
}
function mapDispatchToProps(actions) {
    return (dispatch) => ({
    })
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(servicesPageStyle)
)(Estimates);