import { firebase } from "@firebase/app";
import "@firebase/firestore";
import "@firebase/storage";
import "@firebase/functions"
import dotenv from 'dotenv';

// ensure config is read
dotenv.config();

// Initialize Firebase from .env files
const projectId = process.env.REACT_APP_PROJECT_ID;

var config = {
  apiKey: process.env.REACT_APP_FB_API_KEY,
  authDomain: `${projectId}.firebaseapp.com`,
  databaseURL: `https://${projectId}.firebaseio.com`,
  projectId: projectId,
  storageBucket: `${projectId}.appspot.com`,
  messagingSenderId: process.env.REACT_APP_FB_MSG_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  check: process.env.CLICK_UP_API_KEY
};

firebase.initializeApp(config);
firebase.firestore().settings({});

const db = firebase.firestore();

export {
  db,
  firebase,
  firebase as default
};