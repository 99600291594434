import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import compose from "recompose/compose";
import { Link, useParams } from "react-router-dom";
import { connect } from "react-redux";

// core components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";

//styles
import withStyles from "@material-ui/core/styles/withStyles";
import servicesPageStyle from "assets/jss/material-dashboard-pro-react/views/servicesPageStyles";


const tableHeaders = ["Driver ID", "Name", "Date", "Miles Traveled"]

export function DetailsPage(props) {
    const { classes } = props;
    const [loading, setLoading] = useState(false)
    const [info, setInfo] = useState([])

    function getInfo() {
        setLoading(true);
        const request = require('request');
        const options = {
            method: 'GET',
            url: 'https://protected-shelf-37912.herokuapp.com/https://api.keeptruckin.com/v1/logs?driver_ids=923009&per_page=25&page_no=1',
            headers: {
                Accept: 'application/json',
                'X-Api-Key': "263c915b-9227-48ee-b618-39f96f05d355",
                "Access-Control-Allow-Headers": "Content-Type",
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Methods": "OPTIONS,POST,GET"
            }
        };
        request(options, function (error, response) {
            if (error) throw new Error(error);
            const body = JSON.parse(response.body)
            setInfo(body["logs"])
            setLoading(false)
        });
    }
    useEffect(() => {
        getInfo();
    }, []);
    if (loading) {
        return <h1>Loading..</h1>
    }
    const renderTableHeader = () => {
        return tableHeaders.map((header, index) => {
            return <th style={{
                width: "14%", fontSize: "25px", borderBottom: "1px solid rgb(171 167 167)", background: "#AD1E5A", color: "white", textTransform: "uppercase",
                fontFamily: "Quattrocento Sans", fontWeight: "bold",
            }} key={index}>{header}
            </th>
        })
    }
    const renderTableData = () => {
        return info.map((data, i) => {
            // console.log(data["log"]["date"])
            return (
                <tr style={{ fontSize: "20px", padding: "20px" }}>
                    <td className={classes.tableCell} >
                        {data["log"]["driver"]["id"]}
                    </td>
                    <td className={classes.tableCell} >
                        {data["log"]["driver"]["first_name"] + " " + data["log"]["driver"]["last_name"]}
                    </td>
                    <td className={classes.tableCell} >
                        {data["log"]["date"]}
                    </td>
                    <td className={classes.tableCell} >
                        {data["log"]["total_miles"] ? (
                            data["log"]["total_miles"]
                        ) : (
                            "N/A"
                        )}
                    </td>
                </tr>
            )
        })
    }
    return (
        <div>
            <GridContainer style={{
                alignItems: "center",
                margin: "0px auto", width: "95%",
            }}>
                <GridItem>
                    <h1 style={{ color: "#4F0E51", fontFamily: "Oleo Script Swash Caps", fontWeight: "bold", marginBottom: "-20px", paddingLeft:"10px" }}>Payroll Details</h1>
                </GridItem>
            </GridContainer>
            <GridContainer style={{
                alignItems: "center",
                justifyContent: "center", margin: "0px auto", width: "95%"
            }}>
                <Card style={{ background: `linear-gradient(180deg, rgba(79, 14, 81, 0.93) 0%, rgba(127, 31, 106, 0.93) 100%)`, padding: "20px", paddingTop: "5px" }}>
                    <GridItem xs={6} style={{marginLeft:"-10px"}}><h1 style={{ color: "white", fontFamily: "Quattrocento Sans", fontWeight: "bold" }}>Staff Logs </h1></GridItem>
                    <Card style={{ marginTop: "5px" }}>
                        <table style={{ width: "100%", }}>
                            <tbody>
                                <tr className={classes.headerRow}>{renderTableHeader()} </tr>
                                {renderTableData()}
                            </tbody>
                        </table>
                    </Card>
                </Card>

            </GridContainer>
        </div>
    )
}

DetailsPage.propTypes = {
    classes: PropTypes.object.isRequired,
}

function mapStateToProps(state) {
    return {

    };
}

function mapDispatchToProps(actions) {
    return (dispatch) => ({
    })
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(servicesPageStyle)
)(DetailsPage);