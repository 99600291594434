
import { addCloseHandlers, addInitializers, addValidations, actionsForForm } from '../form';
import { userTypes } from 'variables/config';
import AuthActions from 'store/actions/auth';
import { FieldValidationError } from 'variables/errors';
import { config } from '../../reducers/forms/registration';
import { push } from 'connected-react-router';
import { chain } from 'store/actions/util';
import actionsForNavigation from 'store/actions/navigation';

const validations = {
    validateField: (field, value, getState) => {
        // Don't validate blank fields
        if (value === undefined || value === '') return {};
        switch(field) {
            case "userType":
                if (userTypes[value])
                    return { userType: undefined }
                else
                    return { userType: new FieldValidationError("Not an accepted user type") }                    
            case "firstName":
                if (value.length > 1) 
                    return { firstName: undefined }
                else
                    return { firstName: new FieldValidationError("Please input your first name in full") }
            case "lastName":
                if (value.length > 1) 
                    return { firstName: undefined }
                else 
                    return { firstName: new FieldValidationError("Please input your last name in full") }
            case "birthDate":
                if (!isNaN(Date.parse(value)))
                    return { birthDate: undefined }
                else
                    return { birthDate: new FieldValidationError("Not a valid date") }
            case "phoneNumber":
                if (value.length > 6) 
                    return { phoneNumber: undefined }
                else 
                    return { phoneNumber: new FieldValidationError("Please input your telephone number in full") }                    
            case "barAssociation":
                if (value.length > 3) 
                    return { association: undefined }
                else
                    return { association: new FieldValidationError("Please input your bar association") }
            case "barNumber":
                if (value.length > 3) 
                    return { barNumber: undefined }
                else 
                    return { barNumber: new FieldValidationError("Please input your bar number in full") }               
            default: 
                return {};
        }
    }
}


const closeHandlers = {
    submit: () => (dispatch, getState) => {
        return dispatch(chain(AuthActions.startUpdateProfile(getState().form.profile.steps.DEFAULT.fields), actionsForNavigation.close()))
    },
    cancel: () => (dispatch, getState) => {
        return dispatch(actionsForNavigation.close());
    }
}

const initHandlers = {
    initialize: () => (dispatch, getState) => {
        return Promise.resolve(getState().auth.user);
    }
}

export default addInitializers(
    addValidations(
        addCloseHandlers(
            actionsForForm(config, "profile"), 
            closeHandlers
        ), 
        validations
    ),
    initHandlers
);

