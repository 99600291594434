import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import Checkbox from "@material-ui/core/Checkbox";

import styles from "assets/jss/material-dashboard-pro-react/components/customCheckboxStyle.js";

const useStyles = makeStyles(styles);

function toBoolean(value) {
    if (typeof value === 'string') return value.toLowerCase() === 'true';
    return !!value;
}

export default function CustomCheckbox(props) {
    const classes = useStyles();
    const {
        formControlProps,
        labelText,
        id,
        labelProps,
        inputProps,
        error,
        inputRootCustomClasses,
        success,
        helperText
    } = props;

    const labelClasses = classNames({
        [" " + classes.labelRootError]: error,
        [" " + classes.labelRootSuccess]: success && !error
    });

    const marginTop = classNames({
        [inputRootCustomClasses]: inputRootCustomClasses !== undefined
    });

    var formControlClasses;
    if (formControlProps !== undefined) {
        formControlClasses = classNames(
            formControlProps.className,
            classes.formControl
        );
    } else {
        formControlClasses = classes.formControl;
    }
    var helpTextClasses = classNames({
        [classes.labelRootError]: error,
        [classes.labelRootSuccess]: success && !error
    });

    return (
        <div>
            <FormControl {...formControlProps} className={formControlClasses}>
                <FormControlLabel
                    className={classes.labelRoot + " " + labelClasses}
                    control={
                        <Checkbox
                            classes={{
                                root: marginTop,
                                disabled: classes.disabled,
                            }}
                            id={id}
                            checked={toBoolean(inputProps.value)}
                            {  ...inputProps }
                        />
                    }
                    {...labelProps}
                    label={labelText}
                />
                {helperText !== undefined ? (
                    <FormHelperText id={id + "-text"} className={helpTextClasses}>
                        {helperText}
                    </FormHelperText>
                ) : null}
            </FormControl>
        </div>                    
    );
}

CustomCheckbox.propTypes = {
    labelText: PropTypes.node,
    labelProps: PropTypes.object,
    id: PropTypes.string,
    inputProps: PropTypes.object,
    formControlProps: PropTypes.object,
    inputRootCustomClasses: PropTypes.string,
    error: PropTypes.bool,
    success: PropTypes.bool,
    white: PropTypes.bool,
    helperText: PropTypes.node
};


