// const pagesStyle = theme => ({
//     wrapper: {
//       height: "auto",
//       minHeight: "100vh",
//       position: "relative",
//       top: "0"
//     },
//     fullPage: {
//       padding: "120px 0",
//       position: "relative",
//       minHeight: "100vh",
//       display: "flex!important",
//       margin: "0",
//       border: "0",
//       color: "#fff",
//       alignItems: "center",
//       backgroundSize: "cover",
//       backgroundPosition: "center center",
//       height: "100%",
//       [theme.breakpoints.down("sm")]: {
//         minHeight: "fit-content!important"
//       },
//       "& footer": {
//         position: "absolute",
//         bottom: "0",
//         width: "100%",
//         border: "none !important"
//       },
//       "&:before": {
//         backgroundColor: "rgba(0, 0, 0, 0.65)"
//       },
//       "&:before,&:after": {
//         display: "block",
//         content: '""',
//         position: "absolute",
//         width: "100%",
//         height: "100%",
//         top: "0",
//         left: "0",
//         zIndex: "2"
//       }
//     }
//   });
  
//   export default pagesStyle;
  

import {
  drawerWidth,
  drawerMiniWidth,
  transition,
  containerFluid
} from "assets/jss/material-dashboard-pro-react.js";

const pageStyle = theme => ({
  wrapper: {
    position: "relative",
    top: "0",
    height: "100vh",
    "&:after": {
      display: "table",
      clear: "both",
      content: '" "'
    }
  },
  mainPanel: {
    transitionProperty: "top, bottom, width",
    transitionDuration: ".2s, .2s, .35s",
    transitionTimingFunction: "linear, linear, ease",
    [theme.breakpoints.up("md")]: {
      width: `calc(100% - ${drawerWidth}px)`
    },
    overflow: "auto",
    position: "relative",
    float: "right",
    ...transition,
    maxHeight: "100%",
    width: "100%",
    overflowScrolling: "touch"
  },
  content: {
    // marginTop: "70px",
    padding: "100px 15px 0 0",
    minHeight: "100vh",
    background: "linear-gradient(162deg, rgba(255,222,171,1) 34%, rgba(173,30,90,1) 100%)"
  },
  container: { ...containerFluid },
  map: {
    marginTop: "70px"
  },
  mainPanelSidebarMini: {
    [theme.breakpoints.up("md")]: {
      width: `calc(100% - ${drawerMiniWidth}px)`
    }
  },
  mainPanelWithPerfectScrollbar: {
    overflow: "hidden !important"
  },
});

export default pageStyle;
