const servicesPageStyles = (theme) => ({
    header: {
        border: "1px solid black",
    },
    newButton: {
        float: "center",
        backgroundColor: "#ffb163"
    },
    headerRow: {
        [theme.breakpoints.up("md")]: {
            height: "100px",
            // background: "#D3D3D3",
        }

    },
    tableCell: {
        [theme.breakpoints.up("md")]: {
            minWidth: "13%",
            textAlign: "center",
        },
    },
    typeTableCell: {
        [theme.breakpoints.up("md")]: {
            minWidth: "14%",
            textAlign: "center",
            display: "flex",
            minHeight: "100px",
            alignItems: "center"
        },

    },
    cancelButton: {
        float: "left",
        width: "25%",
        backgroundColor: "#f37361",
        borderRadius: "5px",
        color: "#FFFFFF",
        fontFamily: "Quattrocento Sans",
        fontWeight: "bold",
        "&:hover": {
            cursor: "pointer",
            background: "#f37361"
        },
        marginLeft: "21px",
        marginBottom: "20px"
    },
    submitButton: {
        float: "right",
        width: "25%",
        backgroundColor: "#FFDEAB",
        borderRadius: "5px",
        color: "#3C4858",
        fontFamily: "Quattrocento Sans",
        fontWeight: "bold",
        "&:hover": {
            cursor: "pointer",
            background: "#FFDEAB"
        },
        marginRight: "21px",
        marginBottom: "20px"
    },
    modalCloseButton: {
        color: "black",
        display: "flex",
        justifyContent: "flex-end",
        marginTop: "-10px",
        marginBottom: "-15px",
        "&:hover": {
            color: "black",
            cursor: "pointer"
        }
    },
    popperDiv: {
        display: "flex",
        // height: "85px",
        // flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "0px 12px",
        height: "50px",
        // margin: "0px auto",
        "&:hover": {
            cursor: "pointer",
            background: "#F6F6F7"
        }
    },
    popperSelections: {
        width: "100%",
        margin: "0px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
    },
    profilePic: {
        height:"130px",
        width:"130px",
        borderRadius:"50%",
        margin:"auto",
        textAlign:"center",
        display:"block",
        marginTop:"10px"
    },
    csvButton: {
        borderRadius: "5px",
        background: `linear-gradient(0deg, #F37361, #F37361), #F37361`,
        fontSize: "16px",
        fontFamily: "Quattrocento Sans",
        fontWeight: "bold",
        marginRight: "5px",
        width: "200px",
        height: "50px"
    },
    pdfButton: {
        marginLeft: "10px",
        borderRadius: "5px",
        background: `linear-gradient(0deg, #FFDEAB, #FFDEAB), #FFDEAB`,
        fontSize: "16px",
        fontFamily: "Quattrocento Sans",
        fontWeight: "bold",
        color: "#3C4858",
        width: "200px",
        height: "50px"
    }

});

export default servicesPageStyles;