/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-key */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import compose from "recompose/compose";
import { connect } from "react-redux";
import { Link, useParams } from "react-router-dom";
import actionsForNavigation from "store/actions/navigation";
import { db, firebase } from "firebase/client";
import { NavLink, withRouter } from 'react-router-dom';

// @material-ui/core components
import InputAdornment from "@material-ui/core/InputAdornment";
import withStyles from "@material-ui/core/styles/withStyles";
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import servicesPageStyle from "assets/jss/material-dashboard-pro-react/views/servicesPageStyles.js";
import MoreVertOutlinedIcon from '@material-ui/icons/MoreVertOutlined';
import styles from "assets/jss/material-dashboard-pro-react/cardImagesStyles.js";
import Fade from '@material-ui/core/Fade';
import Popper from "@material-ui/core/Popper";
import Close from "@material-ui/icons/Close";

// @material-ui/icons
import Search from '@material-ui/icons/Search'

// core componentss
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CustomInput from 'components/CustomInput/CustomInput.js';
import Slide from "@material-ui/core/Slide";


const tableHeaders = ["Invoice ID", "Type(s) Of Service", "Set Price", "Min Price", "Max Price", "Date", "Actions"]

const Transition = (props) => {
    return <Slide direction="down" {...props} />
}


export function ExchangeVendorServicesPage(props) {
    const { classes, history } = props;
    let { category } = useParams();
    console.log(category)
    const [invoices, setInvoice] = useState([]);
    const [loading, setLoading] = useState(false);
    const [services, setServices] = useState(["Manufacturing", "Fabrication", "Construction", "Real Estate", "Title Transfer", "Ranching",
        "Agriculture", "Recruiting", "Drivers", "Hospitatlity", "Digital Advertising", "Application Design", "Software Development",
        "Shipping FTL", "Shipping LTL", "Shipping Air", "Contract Law", "Accounting", "Coporate Law", "Wood Working", "Plumbing", "Electrician", "Architecture",
        "Civil Enginerring", "3D Design", "2D Design", "3D Printing", "Influencer Marketing", "Aerospace Engineering", "Structural Engineering", "IT Managed Services",
        "Warehousing", "Hazmat Freight", "Cold Chain Freight", "Oversized Freight", "Logistics", "Factoring"]);
    const [anchorEl, setAnchorEl] = useState(null);
    const [popperOpen, setPopperOpen] = useState(false)
    const [serviceSelected, setServiceSelected] = useState({})
    // console.log('serviceSelected', serviceSelected)

    function getInvoice() {
        setLoading(true);
        db.collection('services').onSnapshot((querySnapshot) => {
            const items = [];
            querySnapshot.forEach((doc) => {
                items.push(doc.data());
            });
            setInvoice(items);
            setLoading(false);
        });
    }
    useEffect(() => {
        getInvoice();
    }, []);

    if (loading) {
        return <h1>Loading..</h1>
    }

    const renderTableData = () => {
        return invoices.map((invoice, i) => {
            console.log('invoiceinvoice', invoice)
            return (
                <>
                    {invoice.Types_of_Services.includes(category) && invoice.Visibility.Exchange === true ? (
                        <tr style={{ width: "100%", minHeight: "100px" }} style={{ color: "#3C4858", fontFamily: "Quattrocento Sans", fontSize: "16px", fontWeight: "400" }}>
                            <td className={classes.tableCell} >
                                {invoice.Invoice_ID}
                            </td>
                            <td className={classes.typeTableCell}>
                                {invoice.Types_of_Services.map((service, indes) => (
                                    <td style={{ width: "50%" }}>
                                        {service}
                                    </td>
                                ))}
                            </td>
                            <td className={classes.tableCell}>
                                {invoice.Item_Info.Item_Price ? (
                                    invoice.Item_Info.Item_Price
                                ) : (
                                    "N/A"
                                )}
                            </td>
                            <td className={classes.tableCell}>
                                {invoice.priceRange.minPrice ? (
                                    invoice.priceRange.minPrice
                                ) : (
                                    "N/A"
                                )}
                            </td>
                            <td className={classes.tableCell}>
                                {invoice.priceRange.maxPrice ? (
                                    invoice.priceRange.maxPrice
                                ) : (
                                    "N/A"
                                )}
                            </td>
                            <td className={classes.tableCell}>
                                {invoice.Date}
                            </td>
                            {/* <td className={classes.tableCell}>
                    {invoice.Payment_Status ? invoice.Payment_Status : "N/A"}
                </td> */}
                            <td className={classes.tableCell}>
                                <MoreVertOutlinedIcon onClick={(event) => {
                                    setServiceSelected(invoices[i])
                                    setPopperOpen(!popperOpen)
                                    setAnchorEl(anchorEl ? null : event.currentTarget);
                                }} />
                                <Popper style={!anchorEl ? { display: "none" } : { zIndex: 1000 }} placement={'right'} id={'transitions-popper'} open={popperOpen} anchorEl={anchorEl} transition>
                                    {({ TransitionProps }) => (
                                        <Fade {...TransitionProps} >
                                            <Card style={{ width: "154px", display: "flex" }}>
                                                <Button
                                                    onClick={(event) => {
                                                        setAnchorEl(anchorEl ? null : event.currentTarget);
                                                        setPopperOpen(!popperOpen)
                                                    }}
                                                    simple
                                                    className={classes.modalCloseButton}
                                                    key="close"
                                                    aria-label="Close"
                                                >
                                                    {" "}
                                                    <Close className={classes.modalClose} />
                                                </Button>
                                                <div onClick={() => history.push(`/admin/exchange/${category}/${serviceSelected.Invoice_ID}`)} className={classes.popperDiv}>
                                                    <div className={classes.popperSelections}>
                                                        View Details
                                                        <EditOutlinedIcon />
                                                    </div>

                                                </div>
                                            </Card>
                                        </Fade>
                                    )}
                                </Popper>
                            </td>
                        </tr>
                    ) : null}
                </>
            )
        })
    }



    const renderTableHeader = () => {
        return tableHeaders.map((header, index) => {
            return <th style={{ width: "14%", fontSize: "25px", borderBottom: "1px solid rgb(171 167 167)", background: "#AD1E5A", color: "white", textTransform: "uppercase",
            fontFamily: "Quattrocento Sans", fontWeight: "bold", }} key={index}>{header}
                {/* <TextField InputProps={{
              classes: {
                input: classes.textField
              }
            }}  />  */}
            </th>
        })
    }

    return (
        <div>
             <GridContainer style={{
                alignItems: "center",
                justifyContent: "center", margin: "0px auto", width: "95%"
            }}>
                <Card style={{ background: `linear-gradient(180deg, rgba(79, 14, 81, 0.93) 0%, rgba(127, 31, 106, 0.93) 100%)`, padding: "20px", paddingTop: "5px" }}>
                    <GridContainer>
                        <GridItem xs={6}><h1 style={{ color: "white", fontFamily: "Quattrocento Sans", fontWeight: "bold" }}>{category}</h1></GridItem>
                        <GridItem xs={6} sm={6} md={6}>
                            <CustomInput
                                labelText="Search Services"
                                id="material"
                                formControlProps={{
                                    fullWidth: true
                                }}
                                inputProps={{
                                    endAdornment: (<InputAdornment position="end"><Search style={{color:"white"}}/></InputAdornment>)
                                }}
                            />
                        </GridItem>
                    </GridContainer>
                    <Card style={{ marginTop: "5px" }}>
                        <table style={{ width: "100%", }}>
                            {/* <tbody> */}
                            <tr className={classes.headerRow}>{renderTableHeader()} </tr>
                            {renderTableData()}
                            {/* </tbody> */}
                        </table>
                    </Card>
                </Card>
            </GridContainer>
            {/* <GridContainer>
                <GridItem xs={3}>
                    <Link to="/admin/services/service-form">
                        <Button style={{ height: "50px", width: "50px", fontSize: "2rem", right: "20px", bottom: "16px", position: "fixed", zIndex: "10" }} justIcon round color="info">+</Button>
                    </Link>
                </GridItem>
            </GridContainer> */}
        </div >
    );
}

ExchangeVendorServicesPage.propTypes = {
    classes: PropTypes.object.isRequired,
}

function mapStateToProps(state) {
    return {

    };
}

function mapDispatchToProps(actions) {
    return (dispatch) => ({
    })
}

export default withRouter(compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(servicesPageStyle)
)(ExchangeVendorServicesPage)
)
