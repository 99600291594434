/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-key */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import compose from "recompose/compose";
import { connect } from "react-redux";
import { Link, useParams } from "react-router-dom";
import actionsForNavigation from "store/actions/navigation";
import { db, firebase } from "firebase/client";
import { NavLink, withRouter } from 'react-router-dom';

// @material-ui/core components
import InputAdornment from "@material-ui/core/InputAdornment";
import withStyles from "@material-ui/core/styles/withStyles";
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import servicesPageStyle from "assets/jss/material-dashboard-pro-react/views/servicesPageStyles.js";
import MoreVertOutlinedIcon from '@material-ui/icons/MoreVertOutlined';
import styles from "assets/jss/material-dashboard-pro-react/cardImagesStyles.js";
import Fade from '@material-ui/core/Fade';
import Popper from "@material-ui/core/Popper";
import Close from "@material-ui/icons/Close";

// @material-ui/icons
import Search from '@material-ui/icons/Search'

// core componentss
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CustomInput from 'components/CustomInput/CustomInput.js';
import Slide from "@material-ui/core/Slide";


const tableHeaders = ["Company", "Type(s) Of Service", "Hours", "Set Price", "Min Price", "Max Price",  "Actions"]

const Transition = (props) => {
    return <Slide direction="down" {...props} />
}


export function ExploreCategoryPage(props) {
    const { classes, history } = props;
    let { category } = useParams();
    const [invoices, setInvoice] = useState([]);
    const [loading, setLoading] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [popperOpen, setPopperOpen] = useState(false)
    const [serviceSelected, setServiceSelected] = useState({})
    // console.log('serviceSelected', serviceSelected)

    function getInvoice() {
        setLoading(true);
        db.collection('services').onSnapshot((querySnapshot) => {
            const items = [];
            querySnapshot.forEach((doc) => {
                items.push(doc.data());
            });
            setInvoice(items);
            setLoading(false);
        });
    }
    useEffect(() => {
        getInvoice();
    }, []);

    if (loading) {
        return <h1>Loading..</h1>
    }

    const renderTableData = () => {
        return invoices.map((invoice, i) => {
            console.log('invoiceinvoice', invoice)
            return (
                <>
                    {invoice.Types_of_Services.includes(category) && invoice.Visibility.Marketplace === true ? (
                        <tr style={{ width: "100%", minHeight: "100px" }} style={{ color: "#3C4858", fontFamily: "Quattrocento Sans", fontSize: "16px", fontWeight: "400" }}>
                            <td className={classes.tableCell} >
                                Company Name
                            </td>
                            <td className={classes.typeTableCell}>
                                {invoice.Types_of_Services.map((service, indes) => (
                                    <td style={{ width: "50%" }}>
                                        {service}
                                    </td>
                                ))}
                            </td>
                            <td className={classes.tableCell}>
                                N/A
                            </td>
                            <td className={classes.tableCell}>
                                {invoice.Item_Info.Item_Price ? (
                                    invoice.Item_Info.Item_Price
                                ) : (
                                    "N/A"
                                )}
                            </td>
                            <td className={classes.tableCell}>
                                {invoice.priceRange.minPrice ? (
                                    invoice.priceRange.minPrice
                                ) : (
                                    "N/A"
                                )}
                            </td>
                            <td className={classes.tableCell}>
                                {invoice.priceRange.maxPrice ? (
                                    invoice.priceRange.maxPrice
                                ) : (
                                    "N/A"
                                )}
                            </td>
                            <td className={classes.tableCell}>
                                <MoreVertOutlinedIcon onClick={(event) => {
                                    setServiceSelected(invoices[i])
                                    setPopperOpen(!popperOpen)
                                    setAnchorEl(anchorEl ? null : event.currentTarget);
                                }} />
                                <Popper style={!anchorEl ? { display: "none" } : { zIndex: 1000 }} placement={'right'} id={'transitions-popper'} open={popperOpen} anchorEl={anchorEl} transition>
                                    {({ TransitionProps }) => (
                                        <Fade {...TransitionProps} >
                                            <Card style={{ width: "154px", display: "flex" }}>
                                                <Button
                                                    onClick={(event) => {
                                                        setAnchorEl(anchorEl ? null : event.currentTarget);
                                                        setPopperOpen(!popperOpen)
                                                    }}
                                                    simple
                                                    className={classes.modalCloseButton}
                                                    key="close"
                                                    aria-label="Close"
                                                >
                                                    {" "}
                                                    <Close className={classes.modalClose} />
                                                </Button>
                                                <div onClick={() => history.push(`/admin/marketplace/${category}/${serviceSelected.Invoice_ID}`)} className={classes.popperDiv}>
                                                    <div className={classes.popperSelections}>
                                                        View Details
                                                        <EditOutlinedIcon />
                                                    </div>

                                                </div>
                                            </Card>
                                        </Fade>
                                    )}
                                </Popper>
                            </td>
                        </tr>
                    ) : null}
                </>
            )
        })
    }



    const renderTableHeader = () => {
        return tableHeaders.map((header, index) => {
            return <th style={{ width: "14%", fontSize: "25px", borderBottom: "1px solid rgb(171 167 167)", background: "#AD1E5A", color: "white", textTransform: "uppercase",
            fontFamily: "Quattrocento Sans", fontWeight: "bold", }} key={index}>{header}
                {/* <TextField InputProps={{
              classes: {
                input: classes.textField
              }
            }}  />  */}
            </th>
        })
    }

    return (
        <div>
            <GridContainer style={{
                alignItems: "center",
                justifyContent: "center", margin: "0px auto", width: "95%"
            }}>
                <Card style={{ background: `linear-gradient(180deg, rgba(79, 14, 81, 0.93) 0%, rgba(127, 31, 106, 0.93) 100%)`, padding: "20px", paddingTop: "5px" }}>
                    <GridContainer>
                        <GridItem xs={6}><h1 style={{ color: "white", fontFamily: "Quattrocento Sans", fontWeight: "bold" }}>{category}</h1></GridItem>
                        <GridItem xs={6} sm={6} md={6}>
                            <CustomInput
                                labelText="Search Services"
                                id="material"
                                formControlProps={{
                                    fullWidth: true
                                }}
                                inputProps={{
                                    endAdornment: (<InputAdornment position="end"><Search style={{color:"white"}}/></InputAdornment>)
                                }}
                            />
                        </GridItem>
                    </GridContainer>
                    <Card style={{ marginTop: "5px" }}>
                        <table style={{ width: "100%", }}>
                            {/* <tbody> */}
                            <tr className={classes.headerRow}>{renderTableHeader()} </tr>
                            {renderTableData()}
                            {/* </tbody> */}
                        </table>
                    </Card>
                </Card>
            </GridContainer>
        </div >
    );
}

ExploreCategoryPage.propTypes = {
    classes: PropTypes.object.isRequired,
}

function mapStateToProps(state) {
    return {

    };
}

function mapDispatchToProps(actions) {
    return (dispatch) => ({
    })
}

export default withRouter(compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(servicesPageStyle)
)(ExploreCategoryPage)
)
