import { userTypes, staffOnly } from 'variables/config';
import { DataTypes } from 'variables/constants';
import { getAccessorsForForm, addSteps } from '../form';

export const config = {
    basicDetails: { 
        fields: {
            email:           { mandatory: true },
            password:        { mandatory: true },
            confirmPassword: { mandatory: true },
            userType:        { mandatory: true, default: userTypes.CUSTOMER, values: userTypes },
            acceptedTerms:   { mandatory: true, type: DataTypes.BOOLEAN }
        }
    },
    commonProfile: {
        fields: {
            firstName:  { mandatory: true },
            lastName: { mandatory: true },
            phoneNumber: { mandatory: true },
            dateOfBirth: { type: DataTypes.DATE }
        }
    },
    lawyerProfile: {
        fields: {
            practiceName: { mandatory: true },
            association: { mandatory: true}, 
            barNumber: { mandatory: true} 
        }
    }
}

export default addSteps(getAccessorsForForm(config, "form", "registration"), ["basicDetails", "commonProfile", "lawyerProfile"]);

