const documentPageStyles = () => ({
    header: {
        border: "1px solid black"
    },
    newButton: {
        float: "center",
        backgroundColor: "#ffb163"
    },
});

export default documentPageStyles;