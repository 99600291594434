import React, { useState } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import { connect } from "react-redux";
import compose from "recompose/compose";
import { db, firebase } from 'firebase/client.js'
import BoundDialog2 from 'components/Dialog/BoundDialog2';
import { actionsForDialog } from 'store/actions/dialog';
import { getAccessorsForDialog } from 'store/reducers/dialog';
import { DialogActions, DialogContent, DialogContentText, DialogTitle } from "@material-ui/core";
import Card from "components/Card/Card.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import { TextField } from '@material-ui/core';
import Style from 'assets/jss/kordspace-template/views/components/integrationDialogStyle';
import CardAvatar from "components/Card/CardAvatar.js";
import avatar from "assets/img/faces/marc.jpg";

const Actions = actionsForDialog("initiate");
const Accessors = getAccessorsForDialog("dialog", "initiate");
const Dialog = BoundDialog2(Actions, Accessors);


export function IntegrationDialog(props) {
    let { classes } = props;
    const uID = firebase.auth().currentUser.uid;
    const [info, setInfo] = useState({
        firstName: '',
        lastName: '',
        company: '',
        jobTitle: '',
        email: '',
        number: '',
        status: '',
        firstNameError: '',
        lastNameError: '',
        companyError: '',
        jobTitleError: '',
        emailError: '',
        statusError: '',
    })
    const [image, setImage] = useState([])
    const onChange = (e) => {
        setInfo({
            ...info,
            [e.target.id]: e.target.value
        })
    }
    const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const validate = () => {
        let firstNameError = "";
        let lastNameError = "";
        let companyError = "";
        let jobTitleError = "";
        let emailError = "";
        let statusError = "";

        if (!info.firstName) {
            firstNameError = "Invalid input."
        }
        if (!info.lastName) {
            lastNameError = "Invalid input."
        }
        if (!info.company) {
            companyError = "Invalid input"
        }
        if (!info.jobTitle) {
            jobTitleError = "Invalid input"
        }
        if (!emailRegex.test(info.email)) {
            emailError = "Invalid input"
        }
        if (!info.status) {
            statusError = "Invalid input"
        }

        if (firstNameError || lastNameError || companyError ||
            jobTitleError || emailError || statusError) {
            setInfo({
                ...info,
                firstNameError,
                lastNameError,
                companyError,
                jobTitleError,
                emailError,
                statusError
            })
            return false
        }
        return true
    }

    const onSubmit = async (e) => {
        e.preventDefault()
        const isValid = validate()

        if(isValid){
            console.log("success")
        }
        else{
            console.log("error")
        }

    }

    const getImage = async () => {
        const user = firebase.auth().currentUser.uid;
        let storageRef = await firebase.storage().ref().child(`${user}/profilePicture`).listAll();
        const urls = await Promise.all(storageRef.items.map((ref) => ref.getDownloadURL()));
        setImage(urls);
    }
    getImage()
    return (
        <Dialog name="initiate" closeAtBottom {...props}>
            <CardAvatar profile style={{ marginTop: "-100px" }}>
                <a >
                    {image.length === 0 ? <img src={avatar} alt="..." /> : <img src={image[0]} alt="..." />}

                </a>
            </CardAvatar>
            <form onSubmit={onSubmit}>
                <DialogTitle id="alert-dialog-title" style={{ color: "white", fontFamily: "Quattrocento Sans", textAlign: "center" }}>
                    Please fill out the information below
                </DialogTitle>
                <DialogContent>
                    <GridContainer style={{ justifyContent: "center" }}>
                        <GridItem xs={6} style={{ marginTop: "-20px" }}>
                            <Card >
                                <TextField
                                    label="First Name"
                                    id="firstName"
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    inputProps={{
                                        onChange: onChange,
                                        // disableUnderline: true,
                                    }}
                                    error={info.firstNameError}
                                    helperText={info.firstNameError ? 'Error! Invalid input.' : ''}
                                />

                            </Card>

                        </GridItem>
                        <GridItem xs={6} style={{ marginTop: "-20px" }}>
                            <Card>
                                <TextField
                                    label="Last Name"
                                    id="lastName"
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    inputProps={{
                                        onChange: onChange,
                                        disableUnderline: true,
                                    }}
                                    error={info.lastNameError}
                                    helperText={info.lastNameError ? 'Error! Invalid input.' : ''}
                                />

                            </Card>

                        </GridItem>
                        <GridItem xs={6} style={{ marginTop: "-20px" }}>
                            <Card>
                                <TextField
                                    label="Company"
                                    id="company"
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    inputProps={{
                                        onChange: onChange,
                                        disableUnderline: true,
                                    }}
                                    error={info.companyError}
                                    helperText={info.companyError ? 'Error! Invalid input.' : ''}
                                />

                            </Card>

                        </GridItem>
                        <GridItem xs={6} style={{ marginTop: "-20px" }}>
                            <Card>
                                <TextField
                                    label="Job Title"
                                    id="jobTitle"
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    inputProps={{
                                        onChange: onChange,
                                        disableUnderline: true,
                                    }}
                                    error={info.jobTitleError}
                                    helperText={info.jobTitleError ? 'Error! Invalid input.' : ''}
                                />

                            </Card>

                        </GridItem>
                        <GridItem xs={4} style={{ marginTop: "-20px" }}>
                            <Card>
                                <TextField
                                    label="Email"
                                    id="email"
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    inputProps={{
                                        onChange: onChange,
                                        disableUnderline: true,
                                    }}
                                    error={info.emailError}
                                    helperText={info.emailError ? 'Error! Invalid input.' : ''}
                                />

                            </Card>

                        </GridItem>
                        <GridItem xs={4} style={{ marginTop: "-20px" }}>
                            <Card>
                                <TextField
                                    label="Phone (optional)"
                                    id="number"
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    inputProps={{
                                        onChange: onChange,
                                        disableUnderline: true,
                                    }}
                                />

                            </Card>

                        </GridItem>
                        <GridItem xs={4} style={{ marginTop: "-20px" }}>
                            <Card>
                                <TextField
                                    label="Status"
                                    id="status"
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    inputProps={{
                                        onChange: onChange,
                                        disableUnderline: true,
                                    }}
                                    error={info.statusError}
                                    helperText={info.statusError ? 'Error! Invalid input.' : ''}
                                />

                            </Card>

                        </GridItem>
                    </GridContainer>

                </DialogContent>
                <Button type="submit" className={classes.submitButton} >
                    Send
                </Button>
            </form>

        </Dialog>
    );
}

export default withStyles(Style)(IntegrationDialog);