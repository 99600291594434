import React from 'react';

// core components
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import Card from 'components/Card/Card.js';
import Button from "components/CustomButtons/Button.js";
import compose from "recompose/compose";
import { connect } from "react-redux";
import actionsForNavigation from "store/actions/navigation";
import AuthActions from 'store/actions/auth';
import { actionsForDialog } from 'store/actions/dialog';
import TermsAndConditionsDialog from 'views/Components/TermsAndConditionsDialog';
import IntegrationsDialog from './IntegrationsDialog';
import Slide from '@material-ui/core/Slide';

// @material-ui/icons
import SettingsIcon from '@material-ui/icons/Settings';
import { Edit, Lock, Note, AddOutlined, Update, ReportProblem, Help, NotInterested, Apps } from '@material-ui/icons';


import Style from "assets/jss/material-dashboard-pro-react/views/settingsPageStyle";

import withStyles from "@material-ui/core/styles/withStyles";


const TermsAndConditionsActions = actionsForDialog("termsAndConditions");
const integrationActions = actionsForDialog("integration")

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export function SettingsPage(props) {
    const { classes, openTermsAndConditions, openIntegration } = props;

    return (
        <div>
            <h1 style={{ color: "#4F0E51", fontFamily: "Oleo Script Swash Caps", fontWeight: "bold" }}>Settings</h1>

            <Card style={{ width: "100%", borderRadius: "5px", background: `linear-gradient(178.99deg, #4F0E51 0.87%, #7F1F6A 95.47%)`, color: "white" }}>

                <GridContainer>
                    <GridItem xs={6}>
                        <h2 className={classes.cardHeader}>Account</h2>
                        <div className={classes.cardLink}>
                            <Edit />
                            <p
                                onClick={e => props.edit()}
                                className={classes.linkName}>Edit Profile</p>
                        </div>
                        <div className={classes.cardLink}>
                            <SettingsIcon />
                            <p className={classes.linkName}>Account Settings</p>
                        </div>
                        <div className={classes.cardLink}>
                            <Lock />
                            <p className={classes.linkName}>Privacy</p>
                        </div>
                        <div className={classes.cardLink}>
                            <Note />
                            <p
                                onClick={() => openTermsAndConditions()}
                                className={classes.linkName}>Terms of Use</p>
                        </div>
                        <TermsAndConditionsDialog TransitionComponent={Transition} />

                        <div className={classes.cardLink}>
                            <Apps />
                            <p
                                onClick={() => openIntegration()}
                                className={classes.linkName}>Integrations</p>
                        </div>
                        <IntegrationsDialog TransitionComponent={Transition} />

                        <h2 className={classes.cardHeader}>Databases</h2>

                        <div className={classes.cardLink}>
                            <AddOutlined />
                            <p className={classes.linkName}>Load New Customer Database</p>
                        </div>
                        <div className={classes.cardLink}>
                            <Update />
                            <p className={classes.linkName}>Merge/Update Customer Database</p>
                        </div>
                    </GridItem>

                    <GridItem xs={6}>
                        <h2 className={classes.cardHeader}>Support</h2>

                        <div className={classes.cardLink}>
                            <ReportProblem />
                            <p className={classes.linkName}>Report A Problem</p>
                        </div>
                        <div className={classes.cardLink}>
                            <Help />
                            <p className={classes.linkName}>Help Center</p>
                        </div>
                    </GridItem>

                </GridContainer>
                <GridItem xs={12}>
                    <Button
                        
                        onClick={e => { props.logout() }}
                        className={classes.logOutButton}>
                        Log Out
                    </Button>
                </GridItem>
            </Card>
        </div >
    )
}

function mapStateToProps(state) {
    return {
        // nothing yet
    };
}

function mapDispatchToProps(actions) {
    return (dispatch) => ({
        edit: (key) => dispatch(actionsForNavigation.openChild("Profile Editor", `/admin/user/editor`)),
        logout: () => dispatch(AuthActions.startLogout()),
        openTermsAndConditions: () => dispatch(TermsAndConditionsActions.openDialog()),
        openIntegration: () => dispatch(integrationActions.openDialog())
    })
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(Style)
)(SettingsPage);