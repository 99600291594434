import React from "react";
import PropTypes from "prop-types";
import compose from "recompose/compose";
import { connect } from "react-redux";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

import RegistrationActions from "store/actions/forms/registration";
import RegistrationAccessors from "store/reducers/forms/registration";
import BoundFormStepContainer from "components/Form/BoundFormStepContainer";
import Style from "assets/jss/material-dashboard-pro-react/views/registerPageStyle";
import BasicDetails from "./BasicDetails";
import CommonProfile from "./CommonProfile";
// import LawyerProfile from "./Registration/LawyerProfile";
import AlreadyRegistered from "./AlreadyRegistered";

const FormStepContainer = BoundFormStepContainer(RegistrationActions, RegistrationAccessors);

export function RegisterPage(props) {
  const { classes } = props;

  return (
    <div className={classes.container}>
      <FormStepContainer
        classes={classes}
        steps={{
          basicDetails: <BasicDetails />,
          alreadyRegistered: <AlreadyRegistered />,
          commonProfile: <CommonProfile />,
          // lawyerProfile: <LawyerProfile />,
        }}
        nextButtonLabel="Next"
        lastButtonLabel="Last"
      />
    </div>
  );
}

RegisterPage.propTypes = {
  classes: PropTypes.object.isRequired,
}

function mapStateToProps(state) {
  return {

  };
}

function mapDispatchToProps(actions) {
  return (dispatch) => ({

  })
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(Style)
)(RegisterPage)
