const walletPageStyles = {
    invoiceButton: {
        float: "center",
        backgroundColor: "#ffb163"
    },
    newButton:{
        float: "center",
        width: "200px",
        backgroundColor: "#ffb163",
    }
};

export default walletPageStyles;