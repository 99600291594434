/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-key */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { Link, useParams } from "react-router-dom";
import actionsForNavigation from "store/actions/navigation";
import { db, firebase } from 'firebase/client.js';
import compose from "recompose/compose";
import { connect } from "react-redux";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { register } from "serviceWorker.js";
import { set } from "immutable";
import Multiselect from 'multiselect-react-dropdown';
import NumberFormat from 'react-number-format';

// @material-ui/core components
import InputAdornment from "@material-ui/core/InputAdornment";
import withStyles from "@material-ui/core/styles/withStyles";
import Fade from '@material-ui/core/Fade';
import Popper from "@material-ui/core/Popper";
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import TextField from "@material-ui/core/TextField";

// @material-ui/icons
import MoreVert from "@material-ui/icons/MoreVert";
import { Close, Cancel, Check, DoubleArrow, Block, SettingsEthernet, ChangeHistory, Archive, Search } from "@material-ui/icons";
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import MoreVertOutlinedIcon from '@material-ui/icons/MoreVertOutlined';

// core components
import CustomInput from "components/CustomInput/CustomInput";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

//styles
import servicesPageStyle from "assets/jss/material-dashboard-pro-react/views/servicesPageStyles.js";
import { primaryCardHeader } from "assets/jss/material-dashboard-pro-react";
import Edit from "@material-ui/icons/Edit";

const tableHeaders = ["Invoice ID", "Date Created", "Due Date", "Email", "Status", "Actions"]


export function Invoices(props) {
    const [invoices, setInvoice] = useState([]);
    const [loading, setLoading] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [popperOpen, setPopperOpen] = useState(false)
    const [show, setShow] = useState(false);
    const [inner, setInner] = useState(false);
    const [values, setValues] = useState({
        Private: false,
        Marketplace: false,
        Exchange: false
    });
    const [error, setError] = useState({
        valueError: '',
        selectError: ''
    })
    const open = Boolean(anchorEl);
    const docID = firebase.auth().currentUser.uid;
    const ref = firebase.firestore().collection("users").doc(docID).collection("invoices");
    const { classes, history } = props;
    const [currentInvoice, setCurrentInvoice] = useState({});
    console.log("currentInvoice: ", currentInvoice)
    let id = useParams();
    const [price, setPrice] = useState({
        minPrice: '',
        maxPrice: '',
        priceErrorMin: '',
        priceErrorMax: '',
        priceErrorRange: ''
    })
    const [images, setImages] = useState('');
    const [select, setSelect] = useState('');
    const [copy, setCopy] = useState({
        copied: false
    })
    const [services, setServices] = useState(["Manufacturing", "Fabrication", "Construction", "Real Estate", "Title Transfer", "Ranching",
        "Agriculture", "Recruiting", "Drivers", "Hospitatlity", "Digital Advertising", "Application Design", "Software Development",
        "Shipping FTL", "Shipping LTL", "Shipping Air", "Contract Law", "Accounting", "Coporate Law", "Wood Working", "Plumbing", "Electrician", "Architecture",
        "Civil Enginerring", "3D Design", "2D Design", "3D Printing", "Influencer Marketing", "Aerospace Engineering", "Structural Engineering", "IT Managed Services",
        "Warehousing", "Hazmat Freight", "Cold Chain Freight", "Oversized Freight", "Logistics", "Factoring"]);

    const decimalRegex = /^[0-9]+(\.[0-9]{1,2})?$/;




    const sentClick = async (e, invoice) => {
        await ref.doc(invoice).update({
            Payment_Status: "Sent"
        })
    }

    const deleteClick = async (e, invoice) => {
        await ref.doc(invoice).delete()
    }

    const arcClick = async (e, invoice) => {
        const invoiceData = await ref.doc(invoice).get()
        db.collection('users').doc(docID).collection('archived').doc(invoiceData.data().Invoice_ID).set(invoiceData.data())
            .then(async () => {
                await ref.doc(invoice).delete()
                window.location.reload()
            })
    }

    const paidClick = async (e, invoice) => {
        await ref.doc(invoice).update({
            Payment_Status: "Paid"
        })
    }

    const pendClick = async (e, invoice) => {
        await ref.doc(invoice).update({
            Payment_Status: "Pending"
        })
    }

    const cancelClick = async (e, invoice) => {
        await ref.doc(invoice).update({
            Payment_Status: "Cancel"
        })
    }

    const clickShow = () => {
        setShow(true);
    };
    const clickClose = () => {
        setShow(false);
        setValues(false);
        setPrice({
            ...price,
            minPrice: '',
            maxPrice: '',
            priceErrorMin: '',
            priceErrorMax: '',
            priceErrorRange: ''
        })
        setError({
            ...error,
            valueError: '',
            selectError: ''
        })
    };

    const innerShow = () => {
        setInner(true);
        setShow(false);
    }

    const innerClose = () => {
        setInner(false);
        setValues(false);
        setPrice({
            ...price,
            minPrice: '',
            maxPrice: '',
            priceErrorMin: '',
            priceErrorMax: '',
            priceErrorRange: ''
        })
        setError({
            ...error,
            valueError: '',
            selectError: ''
        })
    }


    const handleClick = (e, i) => {
        setCurrentInvoice(invoices[i])
        // setMenu(e.currentTarget)
    };

    const handleClose = (e) => {
        // setMenu(null);
        setValues(false);
        setCopy({
            ...copy,
            copied: false
        })
    };

    const handlePrice = (e) => {
        setPrice({
            ...price,
            [e.target.id]: e.target.value
        })

    }

    const onSelect = (e) => {
        setSelect(e)
        let storageRef = "";
        storageRef = firebase.storage().ref(`/Images/${e[0]}.jpg`)
        storageRef.getDownloadURL().then((url) => {
            setImages(url)
        })
    }

    const handleChange = (e) => {
        setValues({
            ...values,
            [e.target.name]: e.target.checked
        })
    }

    const checkbox = [
        { name: "Private", label: "Private" },
        { name: "Marketplace", label: "Marketplace" },
        { name: "Exchange", label: "Exchange" }
    ].map((prop, key) => {
        return (
            <>
                <FormControlLabel
                    key={key}
                    control={
                        <Checkbox
                            name={prop.name}
                            type="checkbox"
                            onChange={e => handleChange(e)}
                            tabIndex={-1}
                            classes={{
                                checked: classes.checked,
                                root: classes.checkRoot
                            }}
                        />
                    }
                    classes={{
                        label: classes.label,
                        root: classes.labelRoot
                    }}
                    label={prop.name}
                />

            </>
        )
    })

    const validate = () => {
        let priceErrorMin = '';
        let priceErrorMax = '';
        let priceErrorRange = '';

        if (!decimalRegex.test(price.minPrice)) {
            priceErrorMin = "Error! Invalid Input"
        }

        if (!decimalRegex.test(price.maxPrice)) {
            priceErrorMax = "Error! Invalid Input"
        }

        if (price.minPrice > price.maxPrice) {
            priceErrorRange = "Error! Please check your ranges and try again"
        }


        if (priceErrorMin || priceErrorMax || priceErrorRange) {
            setPrice({
                ...price,
                priceErrorMin,
                priceErrorMax,
                priceErrorRange
            });
            return false;
        }
        return true;
    }

    const checkEmpty = () => {
        let valueError = '';
        let selectError = '';
        if (values.Private === false && values.Marketplace === false && values.Exchange === false) {
            valueError = "Must have at least one selected."
        }
        if (select.length == '') {
            selectError = "Must have at least one selected."
        }

        if (valueError || selectError) {
            setError({
                ...error,
                valueError,
                selectError
            });
            return true
        }
        return false
    }
    const saveForm = async (e, invoice, i) => {
        e.preventDefault();
        const isValid = validate();
        const isEmpty = checkEmpty();
        const serviceRef = firebase.firestore().collection("users").doc(docID).collection("services");
        let items = {
            Item: "N/A",
            Item_Price: "N/A"
        }
        let prices = {
            minPrice: price.minPrice,
            maxPrice: price.maxPrice
        }

        if (isValid && values.Exchange === true) {
            console.log("everything is true")
            await serviceRef.doc(invoice)
                .set({
                    Visibility: values,
                    Types_of_Services: select,
                    priceRange: prices,
                    Item_Info: items,
                    imgUrl: images
                })
            const invoiceData = await ref.doc(invoice).get()
            const serviceData = await serviceRef.doc(invoice).get()
            db.collection('services').doc(invoiceData.data().Invoice_ID).set(invoiceData.data())
            db.collection('services').doc(invoiceData.data().Invoice_ID).update(serviceData.data())
            window.location.href = (`/admin/invoice`)
        }

        else if (!values.Exchange === true && !isEmpty) {
            await serviceRef.doc(invoice)
                .set({
                    Visibility: values,
                    Types_of_Services: select,
                    imgUrl: images,
                    priceRange: prices
                })
            const invoiceData = await ref.doc(invoice).get()
            const serviceData = await serviceRef.doc(invoice).get()
            db.collection('services').doc(invoiceData.data().Invoice_ID).set(invoiceData.data())
            db.collection('services').doc(invoiceData.data().Invoice_ID).update(serviceData.data())
            window.location.href = (`/admin/invoice`)
        }

    }



    function getInvoice() {
        setLoading(true);
        ref.onSnapshot((querySnapshot) => {
            const items = [];
            querySnapshot.forEach((doc) => {
                items.push(doc.data());
            });
            setInvoice(items);
            setLoading(false);
        });
    }
    useEffect(() => {
        getInvoice();
    }, []);

    if (loading) {
        return <h1>Loading...</h1>;
    }
    const renderTableData = () => {
        return invoices.map((invoice, i) => {
            // console.log('invoiceinvoice', invoice)
            // console.log("TESTING MAP", invoice.Visibility)

            return (
                invoice.uID === firebase.auth().currentUser.uid ?
                    <tr style={{ width: "100%", minHeight: "100px" }} style={{ color: "#3C4858", fontFamily: "Quattrocento Sans", fontSize: "16px", fontWeight: "400" }}>
                        <td className={classes.tableCell} style={{ fontWeight: "700", }}>
                            {invoice.Invoice_ID}
                        </td>
                        <td className={classes.tableCell} style={{ width: "20%" }}>
                            {invoice.Date}
                        </td>
                        <td className={classes.tableCell} style={{ width: "20%" }}>
                            {invoice.dueDate}
                        </td>
                        <td className={classes.tableCell}>
                            {invoice.Email.map((emails, index) => (
                                <td style={{ width: "50%" }}>
                                    {emails}
                                </td>
                            ))}
                        </td>
                        <td className={classes.tableCell}>
                            {invoice.Payment_Status}
                        </td>
                        <td className={classes.tableCell}>
                            <MoreVertOutlinedIcon onClick={(event) => {
                                setCurrentInvoice(invoices[i])
                                setPopperOpen(!popperOpen)
                                // console.log("popperOpen 3 dots: ", popperOpen)
                                setAnchorEl(anchorEl ? null : event.currentTarget);
                                // console.log("anchorEl 3 dots: ", anchorEl)
                                setShow(false)
                            }} />
                            <Popper style={!anchorEl ? { display: "none" } : { zIndex: 1000 }} placement={'right'} id={'transitions-popper'} open={popperOpen} anchorEl={anchorEl} transition>
                                {({ TransitionProps }) => (
                                    <Fade {...TransitionProps} >
                                        <Card style={{ width: "154px", display: "flex" }}>
                                            <Button
                                                onClick={(event) => {
                                                    setAnchorEl(anchorEl ? null : event.currentTarget);
                                                    console.log("anchorEl", anchorEl)
                                                    setPopperOpen(!popperOpen)
                                                    console.log("popperOpen: ", popperOpen)
                                                    setCopy({ ...copy, copied: false })
                                                }}
                                                simple
                                                className={classes.modalCloseButton}
                                                key="close"
                                                aria-label="Close"
                                            >
                                                {""}
                                                <Close className={classes.modalClose} />
                                            </Button>
                                            <div className={classes.popperDiv} style={{ width: "150px" }}>
                                                <div className={classes.popperSelections} style={{ width: "150px" }}>
                                                    <CopyToClipboard text={`<iframe src="http://localhost:3000/pages/iframe-invoice/${docID}/${currentInvoice.Invoice_ID}" name="embedInvoice" scrolling="Yes" height="500px" width="1000px" style="border: none;"></iframe>`}
                                                        onCopy={() => setCopy({ copied: true })}>
                                                        <p>Embed</p>
                                                    </CopyToClipboard>
                                                    {copy.copied ? <span style={{ color: 'red' }}>Copied.</span> : null}
                                                    <SettingsEthernet />
                                                </div>
                                            </div>
                                            <div className={classes.popperDiv}>
                                                <div className={classes.popperSelections}>
                                                    <Link to={`/admin/invoice/${currentInvoice.Invoice_ID}`} style={{ color: "black" }}>
                                                        Edit Invoice
                                                    </Link>
                                                    <Edit />
                                                </div>
                                            </div>
                                            <div className={classes.popperDiv}>
                                                <div className={classes.popperSelections} onClick={() => { setShow(true) }}>
                                                    Convert to Service
                                                    <ChangeHistory />
                                                </div>

                                            </div>
                                            <Dialog
                                                open={show}
                                                onClose={clickClose}
                                                aria-labelledby="alert-dialog-title"
                                                aria-describedby="alert-dialog-description"
                                            >
                                                <DialogTitle id="alert-dialog-title">
                                                    {"Do you want to convert this invoice to a service?"}
                                                </DialogTitle>
                                                <DialogContent>
                                                    <DialogContentText id="alert-dialog-description">
                                                        Converting this invoice to a service will create a copy of the invoice to be reused later.
                                                        This can be saved privately and used as a template, changed to a public listed contracting rate on the explorer marketplace, listed as a variable exchange rate service
                                                        which allows bid/ask on the service prices as well as futures trading and scheduling for matched to your current fulfillment capacity.
                                                    </DialogContentText>
                                                </DialogContent>
                                                <DialogActions>
                                                    <Button onClick={clickClose} style={{ fontWeight: "600", color: "#FFFFFF", backgroundColor: "#FC6047", marginTop: "10px", display: "flex" }}>Disagree</Button>
                                                    <Button onClick={innerShow} autoFocus style={{ fontWeight: "600", color: "#FFFFFF", backgroundColor: "#FC6047", marginTop: "10px", display: "flex" }}>
                                                        Agree
                                                    </Button>
                                                </DialogActions>
                                            </Dialog>


                                            <Dialog
                                                open={inner}
                                                onClose={innerClose}
                                                aria-labelledby="alert-inner-title"
                                                aria-describedby="alert-inner-description"
                                                fullWidth={true}
                                            >
                                                <form onSubmit={(e) => saveForm(e, currentInvoice.Invoice_ID, i)}>
                                                    <DialogTitle id="alert-inner-title">
                                                        {"Service Form"}
                                                    </DialogTitle>
                                                    <DialogContent>
                                                        <Card login style={{ backgroundColor: "rgb(247, 243, 240)" }}>
                                                            <GridContainer justify="center" style={{ padding: "0 10px", paddingBottom: "10px", marginTop: "-10px" }}>
                                                                <GridItem xs={12}>
                                                                    <Card style={{ paddingLeft: "20px", marginBottom: "0", marginTop: "20px" }}>
                                                                        {checkbox}
                                                                        <div style={{ color: "red" }}>{error.valueError}</div>
                                                                        {values.Exchange === true ? (
                                                                            <>
                                                                                <GridContainer>
                                                                                    <GridItem xs={6} style={{ paddingLeft: "0px" }}>
                                                                                        <Card style={{ width: "85%" }}>
                                                                                            <TextField
                                                                                                required
                                                                                                label="*Min Price"
                                                                                                id="minPrice"
                                                                                                formControlProps={{
                                                                                                    fullWidth: true
                                                                                                }}
                                                                                                type="number"
                                                                                                InputProps={{
                                                                                                    inputProps: {
                                                                                                        onChange: handlePrice,
                                                                                                        min: 1,
                                                                                                        step: 0.01
                                                                                                    }

                                                                                                }}
                                                                                                error={price.priceErrorRange}
                                                                                                helperText={price.priceErrorRange ? 'Error! Please check your ranges and try again" ' : ''}
                                                                                            />

                                                                                        </Card>
                                                                                        You can edit parameters in Service Page.
                                                                                    </GridItem>

                                                                                    <GridItem xs={6}>
                                                                                        <Card style={{ width: "85%" }}>

                                                                                            <TextField
                                                                                                label="*Max Price"
                                                                                                id="maxPrice"
                                                                                                formControlProps={{
                                                                                                    fullWidth: true
                                                                                                }}
                                                                                                type="number"
                                                                                                InputProps={{
                                                                                                    inputProps: {
                                                                                                        onChange: handlePrice,
                                                                                                        min: 0.01,
                                                                                                        step: 0.01
                                                                                                    }

                                                                                                }}
                                                                                                error={price.priceErrorRange}
                                                                                                helperText={price.priceErrorRange ? 'Error! Please check your ranges and try again" ' : ''}
                                                                                            />
                                                                                        </Card>

                                                                                    </GridItem>

                                                                                </GridContainer>
                                                                            </>
                                                                        ) : null}


                                                                    </Card>
                                                                    Select all that apply.
                                                                </GridItem>

                                                                <GridItem xs={12}>
                                                                    <Card style={{ marginBottom: "0", marginTop: "20px" }}>
                                                                        <Multiselect
                                                                            id="services"
                                                                            onSelect={onSelect}
                                                                            isObject={false}
                                                                            options={services}
                                                                            selectionLimit={3}
                                                                            placeholder="*Select up to 3 services"
                                                                        />
                                                                        <div style={{ color: "red" }}>{error.selectError}</div>

                                                                    </Card>
                                                                    You can edit the parameters later from the Services Page
                                                                </GridItem>

                                                            </GridContainer>
                                                        </Card>

                                                    </DialogContent>
                                                    <DialogActions>
                                                        <Button onClick={innerClose} style={{ fontWeight: "600", color: "#FFFFFF", backgroundColor: "#FC6047", marginTop: "10px", display: "flex" }}>Cancel</Button>
                                                        <Button type="submit" style={{ fontWeight: "600", color: "#FFFFFF", backgroundColor: "#FC6047", marginTop: "10px", display: "flex" }}>
                                                            Submit
                                                        </Button>
                                                    </DialogActions>
                                                </form>
                                            </Dialog>
                                            <div className={classes.popperDiv}>
                                                <div className={classes.popperSelections} onClick={(e) => sentClick(e, currentInvoice.Invoice_ID)}>
                                                    Sent
                                                    <DoubleArrow />
                                                </div>

                                            </div>

                                            <div className={classes.popperDiv}>
                                                <div className={classes.popperSelections} onClick={(e) => paidClick(e, currentInvoice.Invoice_ID)}>
                                                    Paid
                                                    <Check />
                                                </div>

                                            </div>

                                            <div className={classes.popperDiv}>
                                                <div className={classes.popperSelections} onClick={(e) => cancelClick(e, currentInvoice.Invoice_ID)}>
                                                    Cancel
                                                    <Block />
                                                </div>

                                            </div>

                                            <div className={classes.popperDiv}>
                                                <div className={classes.popperSelections} onClick={(e) => arcClick(e, currentInvoice.Invoice_ID)}>
                                                    Archived
                                                    <Archive />
                                                </div>

                                            </div>

                                            <div className={classes.popperDiv}>
                                                <div className={classes.popperSelections} onClick={(e) => deleteClick(e, currentInvoice.Invoice_ID)}>
                                                    Delete
                                                    <Cancel />
                                                </div>

                                            </div>



                                        </Card>
                                    </Fade>
                                )}
                            </Popper>
                        </td>
                    </tr>
                    : null
            )
        })
    }
    const renderTableHeader = () => {
        return tableHeaders.map((header, index) => {
            return <th style={{
                width: "14%", fontSize: "25px", borderBottom: "1px solid rgb(171 167 167)", background: "#AD1E5A", color: "white", textTransform: "uppercase",
                fontFamily: "Quattrocento Sans", fontWeight: "bold",
            }} key={index}>{header}
                {/* <TextField InputProps={{
              classes: {
                input: classes.textField
              }
            }}  />  */}
            </th>
        })
    }
    return (
        <div className={classes.container} >
            <GridContainer style={{
                alignItems: "center",
                justifyContent: "center", margin: "0px auto", width: "95%"
            }}>
                        <GridItem xs={6}><h1 style={{ color: "white", fontFamily: "Quattrocento Sans", fontWeight: "bold" }}>Invoice List</h1></GridItem>
                        <GridItem xs={6} sm={6} md={6}>
                            <CustomInput
                                labelText="Search Services"
                                id="material"
                                formControlProps={{
                                    fullWidth: true
                                }}
                                inputProps={{
                                    endAdornment: (<InputAdornment position="end"><Search style={{ color: "white" }} /></InputAdornment>)
                                }}
                            />
                        </GridItem>
                    <Card style={{ marginTop: "5px" }}>
                        <table style={{ width: "100%", }}>
                            {/* <tbody> */}
                            <tr className={classes.headerRow}>{renderTableHeader()} </tr>
                            {renderTableData()}
                            {/* </tbody> */}
                        </table>
                    </Card>
                {/* </Card> */}
            </GridContainer>
            <GridContainer>
                <GridItem xs={3}>
                    <Link to="/admin/invoice/create-invoice">
                        <Button style={{ height: "50px", width: "50px", fontSize: "2rem", right: "20px", bottom: "16px", position: "fixed", zIndex: "10", background: "#FFDEAB", color: "#4F0E51" }} justIcon round>+</Button>
                    </Link>
                </GridItem>
            </GridContainer>
        </div>

    );
}
Invoices.propTypes = {
    classes: PropTypes.object.isRequired,
}

const mapStateToProps = (state) => {
    return {
        AuthBool: !!state.auth.user,
        authError: state.auth.error
    }
}
function mapDispatchToProps(actions) {
    return (dispatch) => ({
    })
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(servicesPageStyle)
)(Invoices);