/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-key */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { db, firebase } from 'firebase/client.js';
import compose from "recompose/compose";
import { connect } from "react-redux";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import InputAdornment from "@material-ui/core/InputAdornment";

// @material-ui/icons
import Search from "@material-ui/icons/Search";

// core components
import CustomInput from "components/CustomInput/CustomInput";
import Card from "components/Card/Card";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";

//styles
import servicesPageStyle from "assets/jss/material-dashboard-pro-react/views/servicesPageStyles.js";


const tableHeaders = ["#", "Product Name", "Type", "Qty", "Price", "Amount"]
export function Reports(props) {
    const [invoices, setInvoice] = useState([]);
    const [loading, setLoading] = useState(false);
    const docID = firebase.auth().currentUser.uid;
    const ref = firebase.firestore().collection("users").doc(docID).collection("archived");
    const { classes } = props;
    const [check, setCheck]= useState(false);
    function getInvoice() {
        setLoading(true);
        ref.onSnapshot((querySnapshot) => {
            const items = [];
            querySnapshot.forEach((doc) => {
                items.push(doc.data());
            });
            setInvoice(items);
            setLoading(false);
            if(items.length === 0){
                setCheck(false);
            }
            else{
                setCheck(true)
            }
        });
    }
    useEffect(() => {
        getInvoice();
    }, []);

    if (loading) {
        return <h1>Loading...</h1>;
    }
    const renderTableData = () => {
        return (
            check === false ?
                <tr style={{ width: "100%", minHeight: "100px" }} style={{ color: "#3C4858", fontFamily: "Quattrocento Sans", fontSize: "16px", fontWeight: "400" }}>
                    <td className={classes.tableCell} style={{ fontWeight: "700", }}>
                        1 w/ check = false
                    </td>
                    <td className={classes.tableCell} style={{ width: "20%" }}>
                        Stand Forklift Service
                    </td>
                    <td className={classes.tableCell} style={{ width: "20%" }}>
                        Service
                    </td>
                    <td className={classes.tableCell}>
                        10
                    </td>
                    <td className={classes.tableCell}>
                        $20,000
                    </td>
                    <td className={classes.tableCell}>
                        $200,000
                    </td>

                </tr>
                :
                invoices.map((invoice, i) => {
                    return (
                        invoice.uID === firebase.auth().currentUser.uid ?
                            <tr style={{ width: "100%", minHeight: "100px" }} style={{ color: "#3C4858", fontFamily: "Quattrocento Sans", fontSize: "16px", fontWeight: "400" }}>
                                <td className={classes.tableCell} style={{ fontWeight: "700", }}>
                                    1 w/ check = true
                                </td>
                                <td className={classes.tableCell} style={{ width: "20%" }}>
                                    Stand Forklift Service
                                </td>
                                <td className={classes.tableCell} style={{ width: "20%" }}>
                                    Service
                                </td>
                                <td className={classes.tableCell}>
                                    10
                                </td>
                                <td className={classes.tableCell}>
                                    $20,000
                                </td>
                                <td className={classes.tableCell}>
                                    $200,000
                                </td>

                            </tr>
                            : null
                    )
                })
        )
    }
    const renderTableHeader = () => {
        return tableHeaders.map((header, index) => {
            return <th style={{
                width: "14%", fontSize: "25px", borderBottom: "1px solid rgb(171 167 167)", background: "#AD1E5A", color: "white", textTransform: "uppercase",
                fontFamily: "Quattrocento Sans", fontWeight: "bold",
            }} key={index}>{header}
                {/* <TextField InputProps={{
              classes: {
                input: classes.textField
              }
            }}  />  */}
            </th>
        })
    }
    return (
        <div className={classes.container}>
            <h1 style={{ color: "#4F0E51", fontFamily: "Oleo Script Swash Caps", fontWeight: "bold" }}>History and Reports</h1>
            <Card style={{ background: `linear-gradient(180deg, rgba(79, 14, 81, 0.93) 0%, rgba(127, 31, 106, 0.93) 114.48%)`, paddingLeft: "10px" }}>
                <GridContainer style={{
                    alignItems: "right",
                    justifyContent: "right", margin: "0px auto", width: "95%"
                }}>
                    <GridItem style={{ marginBottom: "-200px", marginTop: "20px" }}>
                        <Button className={classes.csvButton}>
                            Export TO CSV
                        </Button>
                        <Button className={classes.pdfButton}>
                            Export TO PDF
                        </Button>
                    </GridItem>
                </GridContainer>
                <GridContainer style={{
                    alignItems: "center",
                    justifyContent: "center", margin: "0px auto", width: "95%"
                }}>
                    <GridItem xs={6}><h1 style={{ color: "white", fontFamily: "Quattrocento Sans", fontWeight: "bold" }}>All Reports</h1></GridItem>
                    <GridItem xs={6} sm={6} md={6}>

                    </GridItem >
                    <Card style={{ marginTop: "5px" }}>
                        <table style={{ width: "100%", }}>
                            {/* <tbody> */}
                            <tr className={classes.headerRow}>{renderTableHeader()} </tr>
                            {renderTableData()}
                            {/* </tbody> */}
                        </table>
                        <GridContainer style={{ justifyContent: "right", paddingBottom: "20px", paddingTop: "80px", paddingRight: "10px" }}>
                            <GridItem style={{ fontSize: "30px", fontFamily: "Quattrocento Sans", fontWeight: "bold" }}>
                                Total $200,000
                            </GridItem>
                        </GridContainer>
                    </Card>

                </GridContainer>

            </Card>
        </div>

    );
}
Reports.propTypes = {
    classes: PropTypes.object.isRequired,
}

const mapStateToProps = (state) => {
    return {
        AuthBool: !!state.auth.user,
        authError: state.auth.error
    }
}
function mapDispatchToProps(actions) {
    return (dispatch) => ({
    })
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(servicesPageStyle)
)(Reports);