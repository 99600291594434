/* eslint-disable no-unused-vars */
import { firebase, db } from 'firebase/client';
import '@firebase/auth';

import { errorCodes } from 'variables/errors';
import { logger } from './util';

const users = db.collection("users");

const googleAuthProvider = new firebase.auth.GoogleAuthProvider();

function startGoogleLogin() {
  return (dispatch) => {
    return firebase
      .auth()
      .signInWithPopup(googleAuthProvider)
      .then((user) => {
        dispatch(setUser(user));
      })
  };
}

function startEmailLogin(credentials) {
  return logger("startEmailLogin", (dispatch) => {
    return firebase
      .auth()
      .signInWithEmailAndPassword(credentials.email, credentials.password)
      .then((user) => {
        dispatch(setUser(user));
      })
  });
}

function startLoadProfile(user) {
  return logger("startLoadProfile", (dispatch) => {
    return users.doc(user.uid).get().then(snapshot => {
      dispatch(setUser({ ...snapshot.data(), uid: user.uid }));
    });
  });
}

function startUpdateProfile(user) {
    return logger("startUpdateProfile",
        dispatch => { 
            const { uid, ...docData } = user;
            return users.doc(uid).set(docData).then(() => dispatch(updateUser(user)));
        }
    );
}

function startUserAdvance(credentials) {
  // Check with Jonathan how he wants to handle the firebase call
  // return (dispatch) => {
  //     return firebase.auth()
  //         .signInWithEmailAndPassword(credentials.email, credentials.password)
  //         .then(user => {
  //             dispatch(setUser(user));
  //         }).catch((error) => {
  //             dispatch(setError(error));
  //         });
  // };
}


function startDelete(credentials) {
  return logger("startDelete", (dispatch) => {

    console.warn("deleting", credentials && credentials.email);

    const userCredential = credentials
      ? firebase.auth().signInWithEmailAndPassword(credentials.email, credentials.password).then(credential => credential.user)
      : Promise.resolve(firebase.auth().currentUser);

    return userCredential.then(user => {
      if (user) {
        return Promise.all([
          users.doc(user.uid).delete(),
          user.delete()
        ]).then(() =>
          dispatch(logout())
        )
      } else {
        return Promise.reject({ code: errorCodes.APP_AUTH_NOT_LOGGED_IN, message: "user not logged in" });
      }
    }).catch(error => {
      // firebase seems to generate an uncaught auth/wrong-password if the user does not exist. We get an error
      // saying the user does not exist here, but the auth/wrong-password seems totally uncatchable.
      console.warn("Note, if there is an auth/wrong-password error just before/after this, don't worry about it");
      return Promise.reject(error);
    });
  });
}

function startEmailSignup(credentials, profile) {
  const userDoc = { credentials: credentials.email, ...profile };
  return logger("startEmailSignup",
    dispatch => firebase.auth()
      .createUserWithEmailAndPassword(credentials.email, credentials.password)
      .then(user => users.doc(firebase.auth().currentUser.uid).set(userDoc))
      .then(() => { dispatch(setUser(userDoc)) })
  );
}

function startPasswordReset(emailAddress) {
  return (dispatch) =>
    firebase
      .auth()
      .sendPasswordResetEmail(emailAddress)
      .catch((error) => {
        dispatch(setError(error));
      });
}

function startLogout() {
  return (dispatch) => {
    return firebase
      .auth()
      .signOut()
      .then(() => {
        dispatch(logout());
      })
      .catch((error) => {
        dispatch(setError(error));
      });
  };
}

function setUser(user) {
  return {
    type: "AUTH_SET_USER",
    user,
  };
}

function updateUser(user) {
  return {
    type: "AUTH_UPDATE_USER",
    user,
  };
}

function setError(error) {
  return {
    type: "AUTH_SET_ERROR",
    error,
  };
}

function logout() {
  return {
    type: "AUTH_LOGOUT",
  };
}

export const login = (user = {}) => ({
  type: 'LOGIN',
  user
})


// function thunkify(action) {
//   return (dispatch, getState) => {
//     try {
//       dispatch(action);
//       getState(); // I think this is only necessary becasue of the test framework.
//       return Promise.resolve();
//     } catch (error) {
//       return Promise.reject(error);
//     }
//   };
// }

// export function actionsForAuth() {
//   return {
//     updateUser: (user) => thunkify(updateUser(user)),
//   };
// }

export default {
  startGoogleLogin,
  startEmailLogin,
  startUserAdvance,
  startEmailSignup,
  startPasswordReset,
  startLogout,
  startDelete,
  startLoadProfile,
  startUpdateProfile,
  setUser,
  updateUser,
  setError,
  logout,
  login
};
