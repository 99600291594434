export const userTypes = {
    CUSTOMER: "Customer",
    VENDOR: "Vendor",
    // STAFF: "Staff",
}

export const staffOnly = {
    STAFF: "Staff"
}

export const uris = Object.freeze({
    HOME: "/admin/dashboard"
});

export const logging = Object.freeze({
    LOG_ASYNC_ACTIONS : true
});