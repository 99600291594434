/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import compose from "recompose/compose";
import { connect } from "react-redux";
import { Link, useParams } from "react-router-dom";
import moment from "moment";
import Multiselect from 'multiselect-react-dropdown';


// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { FormGroup, FormLabel } from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from '@material-ui/core/Icon';
import TextField from "@material-ui/core/TextField";
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@material-ui/core";


// @material-ui/icons
import MailOutline from "@material-ui/icons/MailOutline";
import Check from "@material-ui/icons/Check";
import Email from "@material-ui/icons/Email";
import Group from '@material-ui/icons/Group';


// core components

import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";

import Style from "assets/jss/material-dashboard-pro-react/views/registerPageStyle";
import { db, firebase } from 'firebase/client.js'
import GridContainer from "components/Grid/GridContainer";
import GridItem from 'components/Grid/GridItem.js';
import { Grid } from "@material-ui/core";
import CardFooter from "components/Card/CardFooter";
import { Visibility } from "@material-ui/icons";




export function InvoiceForm(props) {
    const { classes } = props;
    const { id } = useParams();
    const ref = db.collection("services").doc(id);
    const uid = firebase.auth().currentUser.uid;
    const subRef = firebase.firestore().collection('users').doc(uid).collection('services').doc(id);
    const [loading, setLoading] = useState(false);
    const [show, setShow] = useState(false);
    const [invoices, setInvoice] = useState([]);
    
    const [checked, setChecked] = useState([24, 22], false);
    const [info, setInfo] = useState({
        firstName: '',
        lastName: '',
        streetName: '',
        cityName: '',
        stateName: '',
        zipcode: '',
        countryName: '',
        email: '',
        price: '',
        zipError: '',
        fnameError: '',
        lnameError: '',
        streetError: '',
        cityError: '',
        stateError: '',
        countryError: '',
        emailError: '',
        priceError: ''

    });

    const clickShow = () => {
        setShow(true)
    }

    const clickClose = () => {
        setShow(false)
    }

    const clickDelete = async() => {
        await ref.delete();
        await subRef.delete();
        window.location.href = (`/admin/services`)
    }


    const [error, setError] = useState({
        valueError: '',
        selectError: ''
    })
    const [price, setPrice] = useState({
        minPrice: '',
        maxPrice: '',
        priceErrorMin: '',
        priceErrorMax: '',
        priceErrorRange: ''
    })
    const [images, setImages] = useState('');
    const [values, setValues] = useState({
        Private: false,
        Marketplace: false,
        Exchange: false
    });
    const [select, setSelect] = useState('');
    const [services, setServices] = useState(["Manufacturing", "Fabrication", "Construction", "Real Estate", "Title Transfer", "Ranching",
        "Agriculture", "Recruiting", "Drivers", "Hospitatlity", "Digital Advertising", "Application Design", "Software Development",
        "Shipping FTL", "Shipping LTL", "Shipping Air", "Contract Law", "Accounting", "Coporate Law", "Wood Working", "Plumbing", "Electrician", "Architecture",
        "Civil Enginerring", "3D Design", "2D Design", "3D Printing", "Influencer Marketing", "Aerospace Engineering", "Structural Engineering", "IT Managed Services",
        "Warehousing", "Hazmat Freight", "Cold Chain Freight", "Oversized Freight", "Logistics", "Factoring"]);

    const zipRegex = /^(?:\d{5})?$/;
    const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    

    const handleToggle = value => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }
        setChecked(newChecked);
    };

    const handleChange = (e) => {
        setInfo({
            ...info,
            [e.target.id]: e.target.value,
        });
    }

    const handleCheck = (e) => {
        setValues({
            ...values,
            [e.target.name]: e.target.checked
        });
    }

    const onSelect = (e) => {
        setSelect(e)
        let storageRef = "";
        storageRef = firebase.storage().ref(`/Images/${e[0]}.jpg`)
        storageRef.getDownloadURL().then((url) => {
            setImages(url)
        })
    }

    const handlePrice = (e) => {
        setPrice({
            ...price,
            [e.target.id]: e.target.value
        })

    }
    function getInvoice() {
        setLoading(true)
        ref.get().then((queryResult) => {
            const items = [];
            items.push(queryResult.data())
            setSelect(items[0].Types_of_Services)
            setValues(items[0].Visibility)
            setPrice({
                ...price,
                minPrice: items[0].priceRange.minPrice,
                maxPrice: items[0].priceRange.maxPrice
            })
            setInfo({
                ...info,
                firstName: items[0].Name.firstName,
                lastName: items[0].Name.lastName,
                streetName: items[0].Address.address_line_1,
                cityName: items[0].Address.admin_area_2,
                stateName: items[0].Address.admin_area_1,
                zipcode: items[0].Address.postal_code,
                countryName: items[0].Address.country_code,
                email: items[0].Email,
                price: items[0].Item_Info.Item_Price,
            })
            setImages(items[0].imgUrl)
            setInvoice(items)
            setLoading(false)
        })

    }
    const checkbox = [
        { name: "Private", label: "Private", status: values.Private },
        { name: "Marketplace", label: "Marketplace", status: values.Marketplace },
        { name: "Exchange", label: "Exchange", status: values.Exchange }
    ].map((prop, key) => {
        return (
            <FormControlLabel
                key={key}
                checked={prop.status}
                control={
                    <Checkbox
                        name={prop.name}
                        type="checkbox"
                        onChange={e => handleCheck(e)}
                        tabIndex={-1}
                        classes={{
                            checked: classes.checked,
                            root: classes.checkRoot
                        }}
                    />
                }
                classes={{
                    label: classes.label,
                    root: classes.labelRoot
                }}
                label={prop.name}
            />
        )
    })

    const checkRange = () => {
        var priceErrorMin = '';
        var priceErrorMax = '';
        var priceErrorRange = '';

        if (!price.minPrice) {
            priceErrorMin = "Invalid Input"
        }

        if (!price.maxPrice) {
            priceErrorMax = "Invalid Input"
        }

        if (price.maxPrice < price.minPrice) {
            priceErrorRange = "Error! Please check your ranges and try again"
        }

        if (priceErrorMin || priceErrorMax || priceErrorRange) {
            setPrice({
                ...price,
                priceErrorMin,
                priceErrorMax,
                priceErrorRange
            });
            return false;
        }
        return true;
    }

    const validate = () => {
        let zipError = '';
        let fnameError = '';
        let lnameError = '';
        let streetError = '';
        let cityError = '';
        let stateError = '';
        let countryError = '';
        let priceError = '';
        let emailError = '';

        if (!zipRegex.test(info.zipcode)) {
            zipError = "Invalid input";
        }

        if (!emailRegex.test(info.email)) {
            emailError = "Invalid input"
        }

        if (!info.firstName) {
            fnameError = "Input cannot be empty"
        }
        if (!info.lastName) {
            lnameError = "Input cannot be empty"
        }
        if (!info.streetName) {
            streetError = "Input cannot be empty"
        }
        if (!info.cityName) {
            cityError = "Input cannot be empty"
        }
        if (!info.stateName) {
            stateError = "Input cannot be empty"
        }
        if (!info.countryName) {
            countryError = "Input cannot be empty"
        }
        if (!info.price) {
            priceError = "Input cannot be empty"
        }

        if (zipError || fnameError || lnameError || streetError || cityError || stateError ||
            countryError || priceError || emailError) {
            setInfo({
                ...info,
                zipError,
                fnameError,
                lnameError,
                streetError,
                cityError,
                stateError,
                countryError,
                priceError,
                emailError
            });
            return false;
        }
        return true;

    }

    const checkEmpty = () => {
        let valueError = '';
        let selectError = '';
        if (values.Private === false && values.Marketplace === false && values.Exchange === false) {
            valueError = "Must have at least one selected."
        }
        if (select == '') {
            selectError = "Must have at least one selected."
        }

        if (valueError || selectError) {
            setError({
                ...error,
                valueError,
                selectError
            });
            return true
        }
        return false
    }

    const saveForm = async (e) => {
        e.preventDefault();
        const isValid = validate();
        const check = checkRange();
        const isEmpty = checkEmpty();

        let addressData = {
            address_line_1: info.streetName,
            admin_area_1: info.stateName,
            admin_area_2: info.cityName,
            country_code: info.countryName,
            postal_code: info.zipcode
        }

        let exchangeSelect = {
            Item: "N/A",
            Item_Price: "N/A"
        }
        let items = {
            Item: "N/A",
            Item_Price: info.price
        }

        let prices = {
            minPrice: price.minPrice,
            maxPrice: price.maxPrice
        }

        let data =
        {
            Name: {
                firstName: info.firstName,
                lastName: info.lastName
            },
            Address: addressData,
            Email: info.email,
            Item_Info: items,
            Payment_ID: "N/A",
            Date: moment().format("MM/DD/YYYY"),
            priceRange: prices,
            Visibility: values,
            Types_of_Services: select,
            imgUrl: images
        }
        //if user doesnt select exchange an an option
        if ((isValid && !price.minPrice) && !isEmpty) {
            await ref.update(data)
            // .then(function (docRef) {
            //     db.collection('services').doc(docRef.id).update({
            //         Invoice_ID: docRef.id
            //     })
            // })
            // .catch(function (err) {
            //     console.log("Error:", err)
            // })
            window.location.href = (`/admin/services/service-form`)
        }

        //if user selects exchange reset price and use price range prices
        else if (isValid && check) {
            await ref.update(data)
                .then(function (docRef) {
                    db.collection('services').doc(docRef.id).update({
                        // Invoice_ID: docRef.id,
                        Item_Info: exchangeSelect
                    })
                })
                .catch(function (err) {
                    console.log("Error:", err)
                })
            window.location.href = (`/admin/services/service-form`)
        }
        else {
            console.log("ERROR")
        }
    };
    useEffect(() => {
        getInvoice();
    }, []);
    return (
        <div className={classes.container}>
            <div style={{ display: "flex", justifyContent: "center" }}>
                <h1>Service Form</h1>
            </div>
            {/* FORM */}
            <form onSubmit={saveForm}>
                {invoices.map((invoice) => (
                    <>
                        <Card login style={{ backgroundColor: "rgb(247, 243, 240)" }}>
                        <div style={{display:"flex", justifyContent:"right"}}>
                            <CardFooter style={{ padding: "0px 5px 10px 5px" }} className={classes.justifyContentCenter}>
                                    <Button
                                        style={{ fontWeight: "600", color: "#FFFFFF", backgroundColor: "rgb(173 30 90)", marginTop: "10px"}}
                                        onClick={clickShow}
                                    >
                                        Delete Form
                                    </Button>
                                </CardFooter>
                                <Dialog
                                        open={show}
                                        onClose={clickClose}
                                        aria-labelledby="alert-dialog-title"
                                        aria-describedby="alert-dialog-description"
                                    >
                                        <DialogTitle id="alert-dialog-title">
                                            {"Do you want to convert this invoice to a service?"}
                                        </DialogTitle>
                                        <DialogContent>
                                            <DialogContentText id="alert-dialog-description">
                                                Are you sure you want to delete this form?
                                            </DialogContentText>
                                        </DialogContent>
                                        <DialogActions>
                                            <Button onClick={clickClose} style={{ fontWeight: "600", color: "#FFFFFF", backgroundColor: "rgb(173 30 90)", marginTop: "10px", display: "flex" }}>Cancel</Button>
                                            <Button onClick={clickDelete} autoFocus style={{ fontWeight: "600", color: "#FFFFFF", backgroundColor: "rgb(173 30 90)", marginTop: "10px", display: "flex" }}>Delete</Button>
                                        </DialogActions>
                                    </Dialog>
                                </div>
                            <p style={{ paddingLeft: "10px", paddingTop: "5px" }}>*Required</p>
                    
                            <GridContainer justify="center" style={{ padding: "0 10px" }}>
                                <GridItem xs={6}>
                                    <Card style={{ paddingLeft: "20px", marginBottom: "0", marginTop: "20px" }}>

                                        <TextField

                                            label="*First Name"
                                            id="firstName"
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            InputProps={{
                                                inputProps: {
                                                    onChange: handleChange,
                                                    disableUnderline: true,
                                                    defaultValue: invoice.Name.firstName,

                                                }

                                            }}
                                            error={info.fnameError}
                                            helperText={info.fnameError ? 'Error! Input is invalid.' : ''}
                                        />
                                    </Card>
                                </GridItem>
                                <GridItem xs={6}>
                                    <Card style={{ paddingLeft: "20px", marginBottom: "0", marginTop: "20px" }}>

                                        <TextField
                                            label="*Last Name"
                                            id="lastName"
                                            formControlProps={{
                                                fullWidth: true,

                                            }}
                                            InputProps={{
                                                inputProps: {
                                                    onChange: handleChange,
                                                    disableUnderline: true,
                                                    defaultValue: invoice.Name.lastName,

                                                }

                                            }}
                                            error={info.lnameError}
                                            helperText={info.lnameError ? 'Error! Input is invalid.' : ''}
                                        />
                                    </Card>
                                </GridItem>

                                <GridItem xs={4}>
                                    <Card style={{ paddingLeft: "20px", marginBottom: "0", marginTop: "20px" }}>

                                        <TextField
                                            label="*Street Address"
                                            id="streetName"
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            InputProps={{
                                                inputProps: {
                                                    onChange: handleChange,
                                                    disableUnderline: true,
                                                    defaultValue: invoice.Address.address_line_1,

                                                }

                                            }}
                                            error={info.streetError}
                                            helperText={info.streetError ? 'Error! Input is invalid.' : ''}
                                        />
                                    </Card>
                                </GridItem>
                                <GridItem xs={4}>
                                    <Card style={{ paddingLeft: "20px", marginBottom: "0", marginTop: "20px" }}>

                                        <TextField
                                            label="*City"
                                            id="cityName"
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            InputProps={{
                                                inputProps: {
                                                    onChange: handleChange,
                                                    disableUnderline: true,
                                                    defaultValue: invoice.Address.admin_area_2,

                                                }

                                            }}
                                            error={info.cityError}
                                            helperText={info.cityError ? 'Error! Input is invalid.' : ''}
                                        />
                                    </Card>
                                </GridItem>

                                <GridItem xs={4}>
                                    <Card style={{ paddingLeft: "20px", marginBottom: "0", marginTop: "20px" }}>

                                        <TextField
                                            label="*State"
                                            id="stateName"
                                            formControlProps={{
                                                fullWidth: true,
                                            }}
                                            InputProps={{
                                                inputProps: {
                                                    onChange: handleChange,
                                                    disableUnderline: true,
                                                    defaultValue: invoice.Address.admin_area_1,

                                                }

                                            }}
                                            error={info.stateError}
                                            helperText={info.stateError ? 'Error! Input is invalid.' : ''}
                                        />
                                    </Card>
                                </GridItem>

                                <GridItem xs={6}>
                                    <Card style={{ paddingLeft: "20px", marginBottom: "0", marginTop: "20px" }}>

                                        <TextField
                                            label="*Zipcode"
                                            id="zipcode"
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            InputProps={{
                                                inputProps: {
                                                    onChange: handleChange,
                                                    disableUnderline: true,
                                                    defaultValue: invoice.Address.postal_code,

                                                }

                                            }}
                                            error={info.zipError}
                                            helperText={info.zipError ? 'Error! Input is invalid.' : ''}
                                        />
                                    </Card>
                                </GridItem>
                                <GridItem xs={6}>
                                    <Card style={{ paddingLeft: "20px", marginBottom: "0", marginTop: "20px" }}>

                                        <TextField

                                            label="*Country"
                                            id="countryName"
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            InputProps={{
                                                inputProps: {
                                                    onChange: handleChange,
                                                    disableUnderline: true,
                                                    defaultValue: invoice.Address.country_code,

                                                }

                                            }}
                                            error={info.countryError}
                                            helperText={info.countryError ? 'Error! Input is invalid.' : ''}
                                        />
                                    </Card>
                                </GridItem>

                                <GridItem xs={6}>
                                    <Card style={{ paddingLeft: "20px", marginBottom: "0", marginTop: "20px" }}>

                                        <TextField
                                            label="*Email"
                                            id="email"
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            InputProps={{
                                                inputProps: {
                                                    onChange: handleChange,
                                                    defaultValue: invoice.Email,
                                                }

                                            }}
                                            error={info.emailError}
                                            helperText={info.emailError ? 'Error! Input is invalid.' : ''}
                                        />
                                    </Card>
                                </GridItem>
                                <GridItem xs={6}>
                                    <Card style={{ paddingLeft: "20px", marginBottom: "0", marginTop: "20px" }}>

                                        <TextField

                                            label="*Price"
                                            id="price"
                                            formControlProps={{
                                                fullWidth: true,
                                            }}

                                            type="number"
                                            InputProps={{
                                                inputProps: {
                                                    onChange: handleChange,
                                                    min: 0,
                                                    step: 0.01,
                                                    defaultValue: invoice.Item_Info.Item_Price
                                                }


                                            }}
                                            error={info.priceError}
                                            helperText={info.priceError ? 'Error! Input is invalid.' : ''}
                                        />
                                    </Card>
                                </GridItem>
                                <GridItem xs={6}>
                                    <Card style={{ paddingLeft: "20px", marginBottom: "0", marginTop: "20px" }}>
                                        {checkbox}
                                        <div style={{ color: "red" }}>{error.valueError}</div>
                                        {values.Exchange === true ? (
                                            <>
                                                <GridContainer>
                                                    <GridItem xs={6} style={{ paddingLeft: "0px" }}>
                                                        <Card style={{ width: "85%" }}>

                                                            <TextField
                                                                label="*Min Price"
                                                                id="minPrice"
                                                                formControlProps={{
                                                                    fullWidth: true
                                                                }}
                                                                type="number"
                                                                InputProps={{
                                                                    inputProps: {
                                                                        onChange: handlePrice,
                                                                        min: 0,
                                                                        step: 0.01,
                                                                        defaultValue: invoice.priceRange.minPrice
                                                                    }

                                                                }}
                                                                error={price.priceErrorMin}
                                                                helperText={price.priceErrorMin ? 'Error! Input is invalid.' : ''}
                                                            />
                                                        </Card>
                                                    </GridItem>
                                                    <GridItem xs={6}>
                                                        <Card style={{ width: "85%" }}>

                                                            <TextField
                                                                label="*Max Price"
                                                                id="maxPrice"
                                                                formControlProps={{
                                                                    fullWidth: true
                                                                }}
                                                                type="number"
                                                                InputProps={{
                                                                    inputProps: {
                                                                        onChange: handlePrice,
                                                                        min: 0.01,
                                                                        step: 0.01,
                                                                        defaultValue: invoice.priceRange.maxPrice
                                                                    }

                                                                }}
                                                                error={price.priceErrorMax}
                                                                helperText={price.priceErrorMax ? 'Error! Input is invalid.' : ''}
                                                            />
                                                        </Card>
                                                        <div style={{ color: "red" }}>{price.priceErrorRange}</div>

                                                    </GridItem>
                                                </GridContainer>
                                            </>
                                        ) : null}
                                    </Card>
                                </GridItem>

                                <GridItem xs={6}>
                                    <Card style={{ marginBottom: "0", marginTop: "20px" }}>
                                        <Multiselect
                                            selectedValues={invoice.Types_of_Services}
                                            id="services"
                                            onSelect={onSelect}
                                            onRemove={onSelect}
                                            required={true}
                                            isObject={false}
                                            options={services}
                                            selectionLimit={3}
                                            placeholder="*Select up to 3 services"
                                        />
                                        <div style={{ color: "red" }}>{error.selectError}</div>
                                    </Card>
                                </GridItem>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            tabIndex={-1}
                                            onClick={() => handleToggle(2)}
                                            checkedIcon={<Check className={classes.checkedIcon} />}
                                            icon={<Check className={classes.uncheckedIcon} />}
                                            required
                                            classes={{
                                                checked: classes.checked,
                                                root: classes.checkRoot
                                            }}
                                        />
                                    }
                                    classes={{
                                        label: classes.label,
                                        root: classes.labelRoot
                                    }}
                                    label="Confirm this Invoice"
                                />
                            </GridContainer>
                            <div style={{display:"flex", justifyContent:"center"}}>
                                <CardFooter style={{ padding: "0px 5px 10px 5px"}} className={classes.justifyContentCenter}>
                                    <Button
                                        style={{ fontWeight: "600", color: "#FFFFFF", backgroundColor: "#FC6047", marginTop: "10px"}}
                                        type="submit"
                                    >
                                        Submit Form
                                    </Button>
                                </CardFooter>
                            </div>
                        </Card>

                    </>
                ))}

            </form>


        </div>

    );
}

InvoiceForm.propTypes = {
    classes: PropTypes.object.isRequired,
}

const mapStateToProps = (state) => {
    return {
        AuthBool: !!state.auth.user,
        authError: state.auth.error
    }
}
function mapDispatchToProps(actions) {
    return (dispatch) => ({
        // edit: (key) => dispatch(actionsForNavigation.openChild("Create Invoice", `auth/create-invoice`)),
    })
}
export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(Style)
)(InvoiceForm);
