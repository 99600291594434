import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import compose from 'recompose/compose';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
// core components
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import Button from 'components/CustomButtons/Button';

import RegistrationActions from 'store/actions/forms/registration';

import Style from 'assets/jss/kordspace-template/views/pages/registration/stepStyle';

export function AlreadyRegistered(props) {

    const { classes, passwordReset } = props;

    return(
        <GridContainer>
            <GridItem xs={12} sm={10} md={8} lg={6}>
                <p className={classes.errorMessage}>
                    Error: Email already in use. <br />
                    Forgot your password?
                </p>
                <Button
                    style={{
                        backgroundColor: '#FC6047',
                        color: 'white',
                        fontWeight: '600',
                    }}
                    type='button'
                    onClick={e=>passwordReset()}
                    color='danger'
                    simple
                    size='lg'
                    block
                >
                    Reset Password
                  </Button>
                <p className={classes.errorMessage}>
                    Send a password reset email...
                </p>
            </GridItem>
        </GridContainer>
    );
}

AlreadyRegistered.propTypes = {
    classes: PropTypes.object.isRequired,
    passwordReset: PropTypes.func.isRequired
}

const mapDispatchToProps = (dispatch) => ({
    passwordReset: ()=>{ dispatch(RegistrationActions.cancelAndResetPassword()) }
});
  
const mapStateToProps = () => {
    return {
    };
};
  
export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(Style)
)(AlreadyRegistered);
