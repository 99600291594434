/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-key */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import compose from "recompose/compose";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import actionsForNavigation from "store/actions/navigation";
import { db, firebase } from "firebase/client";
import { NavLink, withRouter } from 'react-router-dom';
import Multiselect from 'multiselect-react-dropdown';
import moment from "moment";

// @material-ui/core components
import InputAdornment from "@material-ui/core/InputAdornment";
import withStyles from "@material-ui/core/styles/withStyles";
import Slide from "@material-ui/core/Slide";
import Fade from '@material-ui/core/Fade';
import Popper from "@material-ui/core/Popper";
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";

// @material-ui/icons
import Search from '@material-ui/icons/Search'
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import MoreVertOutlinedIcon from '@material-ui/icons/MoreVertOutlined';
import Close from "@material-ui/icons/Close";
import Info from "@material-ui/icons/InfoOutlined";
// core componentss
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CustomInput from 'components/CustomInput/CustomInput.js';


//Style
import styles from "assets/jss/material-dashboard-pro-react/cardImagesStyles.js";
import servicesPageStyle from "assets/jss/material-dashboard-pro-react/views/servicesPageStyles.js";


const tableHeaders = ["Invoice ID", "Type(s) Of Service", "Visibility", "Set Price", "Min Price", "Max Price", "Date", "Actions"]

const Transition = (props) => {
    return <Slide direction="down" {...props} />
}


export function ServicesPage(props) {
    const { classes, history } = props;
    const docID = firebase.auth().currentUser.uid;
    const serviceSub = db.collection("users").doc(docID).collection("services");
    const [invoices, setInvoice] = useState([]);
    const [loading, setLoading] = useState(false);
    const [services, setServices] = useState(["Manufacturing", "Fabrication", "Construction", "Real Estate", "Title Transfer", "Ranching",
        "Agriculture", "Recruiting", "Drivers", "Hospitatlity", "Digital Advertising", "Application Design", "Software Development",
        "Shipping FTL", "Shipping LTL", "Shipping Air", "Contract Law", "Accounting", "Coporate Law", "Wood Working", "Plumbing", "Electrician", "Architecture",
        "Civil Enginerring", "3D Design", "2D Design", "3D Printing", "Influencer Marketing", "Aerospace Engineering", "Structural Engineering", "IT Managed Services",
        "Warehousing", "Hazmat Freight", "Cold Chain Freight", "Oversized Freight", "Logistics", "Factoring"]);
    const [anchorEl, setAnchorEl] = useState(null);
    const [popperOpen, setPopperOpen] = useState(false)
    const [serviceSelected, setServiceSelected] = useState({})
    // console.log('serviceSelected', serviceSelected)
    const [show, setShow] = useState(false);
    const [show2, setShow2] = useState(false);
    const [values, setValues] = useState({
        Private: false,
        Marketplace: false,
        Exchange: false
    });
    const [price, setPrice] = useState({
        minPrice: '',
        maxPrice: '',
        priceErrorRange: ''
    })
    const [error, setError] = useState({
        valueError: '',
        selectError: ''
    })
    const [select, setSelect] = useState('');

    const handleCheck = (e) => {
        setValues({
            ...values,
            [e.target.name]: e.target.checked
        })
        console.log(values)
    }

    const handlePrice = (e) => {
        setPrice({
            ...price,
            [e.target.id]: e.target.value
        })

    }
    const [images, setImages] = useState('');
    const onSelect = (e) => {
        setSelect(e)
        let storageRef = "";
        storageRef = firebase.storage().ref(`/Images/${e[0]}.jpg`)
        storageRef.getDownloadURL().then((url) => {
            setImages(url)
        })
    }

    const checkbox = [
        { name: "Private", label: "Private", status: values.Private },
        { name: "Marketplace", label: "Marketplace", status: values.Marketplace },
        { name: "Exchange", label: "Exchange", status: values.Exchange }
    ].map((prop, key) => {
        return (
            <>
                <FormControlLabel
                    key={key}
                    checked={prop.status}
                    control={
                        <Checkbox
                            name={prop.name}
                            type="checkbox"
                            onChange={(e) => handleCheck(e)}
                            tabIndex={-1}
                            classes={{
                                checked: classes.checked,
                                root: classes.checkRoot
                            }}
                        />
                    }
                    classes={{
                        label: classes.label,
                        root: classes.labelRoot
                    }}
                    label={prop.name}
                />

            </>
        )
    })

    function getInvoice() {
        setLoading(true);
        db.collection('services').onSnapshot((querySnapshot) => {
            const items = [];
            querySnapshot.forEach((doc) => {
                items.push(doc.data());
            });
            setInvoice(items);
            setLoading(false);
        });
    }
    
    useEffect(() => {
        getInvoice();
    }, []);

    if (loading) {
        return <h1>Loading..</h1>
    }
    const validate = () => {
        let priceErrorRange = '';

        if (price.minPrice > price.maxPrice) {
            priceErrorRange = "Error! Please check your ranges and try again"
        }


        if (priceErrorRange) {
            setPrice({
                ...price,
                priceErrorRange
            });
            return false;
        }
        return true;
    }

    const checkEmpty = () => {
        let valueError = '';
        let selectError = '';
        if (values.Private === false && values.Marketplace === false && values.Exchange === false) {
            valueError = "Must have at least one selected."
        }
        if (select.length == '') {
            selectError = "Must have at least one selected."
        }

        if (valueError || selectError) {
            setError({
                ...error,
                valueError,
                selectError
            });
            return true
        }
        return false
    }
    const saveForm = async (e, invoice) => {
        e.preventDefault();
        const isValid = validate();
        const isEmpty = checkEmpty();

        let items = {
            Item: "N/A",
            Item_Price: "N/A"
        }
        let prices = {
            minPrice: price.minPrice,
            maxPrice: price.maxPrice
        }
        //need to update data into services subcollection then
        //need to update data into services collection

        if (isValid && values.Exchange === true) {
            console.log("everything is true")
            await serviceSub.doc(invoice)
                .update({
                    Visibility: values,
                    Types_of_Services: select,
                    priceRange: prices,
                    Item_Info: items,
                    imgUrl: images
                })
            const subData = await serviceSub.doc(invoice).get()
            db.collection("services").doc(invoice).update(subData.data())
            window.location.href = (`/admin/services`)
        }

        else if (!values.Exchange === true && !isEmpty) {
            await serviceSub.doc(invoice)
                .update({
                    Visibility: values,
                    Types_of_Services: select,
                    imgUrl: images,
                    priceRange: prices
                })
            const subData = await serviceSub.doc(invoice).get()
            db.collection("services").doc(invoice).update(subData.data())
            window.location.href = (`/admin/services`)
        }
        else {
            console.log("ERROR")
        }

    }

    const renderTableData = () => {
        return invoices.map((invoice, i) => {
            // console.log('invoiceinvoice', invoice)
            // console.log("TESTING MAP", invoice.Visibility)

            return (
                invoice.uID === firebase.auth().currentUser.uid ?
                    <tr style={{ width: "100%", minHeight: "100px" }} style={{ color: "#3C4858", fontFamily: "Quattrocento Sans", fontSize: "16px", fontWeight: "400" }}>
                        <td className={classes.tableCell} style={{ fontWeight: "700", }}>
                            {invoice.Invoice_ID}
                        </td>
                        <td className={classes.typeTableCell}>
                            {invoice.Types_of_Services.map((service, indes) => (
                                <td style={{ width: "50%" }}>
                                    {service}
                                </td>
                            ))}
                        </td>
                        <td className={classes.tableCell} style={{ width: "20%" }}>
                            {invoice.Visibility.Private === true ? (
                                "Private "
                            ) : (
                                ""
                            )}
                            {invoice.Visibility.Marketplace === true ? (
                                "Marketplace "
                            ) : (
                                ""
                            )}
                            {invoice.Visibility.Exchange === true ? (
                                "Exchange "
                            ) : (
                                ""
                            )}
                        </td>
                        <td className={classes.tableCell}>
                            {invoice.Item_Info.Item_Price ? (
                                invoice.Item_Info.Item_Price
                            ) : (
                                "N/A"
                            )}
                        </td>
                        <td className={classes.tableCell}>
                            {invoice.Visibility.Exchange === true ? (
                                invoice.priceRange.minPrice
                            ) : (
                                "N/A"
                            )}
                        </td>
                        <td className={classes.tableCell}>
                            {invoice.Visibility.Exchange === true ? (
                                invoice.priceRange.maxPrice
                            ) : (
                                "N/A"
                            )}
                        </td>
                        <td className={classes.tableCell} style={{ fontWeight: "700" }}>
                            {invoice.Date}
                        </td>
                        {/* <td className={classes.tableCell}>
                        {invoice.Payment_Status ? invoice.Payment_Status : "N/A"}
                    </td> */}

                        <td className={classes.tableCell}>
                            <MoreVertOutlinedIcon onClick={(event) => {
                                { setSelect(invoices[i].Types_of_Services) }
                                { setValues(invoices[i].Visibility) }
                                {
                                    setPrice({
                                        ...price,
                                        minPrice: invoices[i].priceRange.minPrice,
                                        maxPrice: invoices[i].priceRange.maxPrice
                                    })
                                }
                                // console.log("testing visiblity: ", invoices[i].Visibility)
                                setServiceSelected(invoices[i])
                                setPopperOpen(!popperOpen)
                                // console.log("popperOpen 3 dots: ", popperOpen)
                                setAnchorEl(anchorEl ? null : event.currentTarget);
                                // console.log("anchorEl 3 dots: ", anchorEl)
                                setShow(false)
                            }} />
                            <Popper style={!anchorEl ? { display: "none" } : { zIndex: 1000 }} placement={'right'} id={'transitions-popper'} open={popperOpen} anchorEl={anchorEl} transition>
                                {({ TransitionProps }) => (
                                    <Fade {...TransitionProps} >
                                        <Card style={{ width: "154px", display: "flex" }}>
                                            <Button
                                                onClick={(event) => {
                                                    setAnchorEl(anchorEl ? null : event.currentTarget);
                                                    console.log("anchorEl", anchorEl)
                                                    setPopperOpen(!popperOpen)
                                                    console.log("popperOpen: ", popperOpen)
                                                }}
                                                simple
                                                className={classes.modalCloseButton}
                                                key="close"
                                                aria-label="Close"
                                            >
                                                {""}
                                                <Close className={classes.modalClose} />
                                            </Button>
                                            <div onClick={() => history.push(`/admin/services/${serviceSelected.Invoice_ID}`)} className={classes.popperDiv}>
                                                <div className={classes.popperSelections}>
                                                    View Details
                                                    <Info />
                                                </div>

                                            </div>
                                            <div
                                                className={classes.popperDiv}>
                                                <div className={classes.popperSelections} onClick={(event) => { setShow(true) }}>
                                                    Edit Visibility
                                                    <EditOutlinedIcon />
                                                </div>
                                                <Dialog
                                                    open={show}
                                                    onClose={(event) => { setShow(false); setPopperOpen(!popperOpen); setAnchorEl(anchorEl ? null : event.currentTarget); }}
                                                    aria-labelledby="alert-inner-title"
                                                    aria-describedby="alert-inner-description"
                                                    fullWidth={true}
                                                >
                                                    <form onSubmit={(e) => saveForm(e, serviceSelected.Invoice_ID)}>
                                                        <DialogTitle id="alert-inner-title">
                                                            {"Service Form"}
                                                        </DialogTitle>
                                                        <DialogContent>
                                                            <Card login style={{ backgroundColor: "rgb(247, 243, 240)" }}>
                                                                <GridContainer justify="center" style={{ padding: "0 10px", paddingBottom: "10px", marginTop: "-10px" }}>
                                                                    <GridItem xs={12}>
                                                                        <Card style={{ paddingLeft: "20px", marginBottom: "0", marginTop: "20px" }}>
                                                                            {checkbox}
                                                                            <div style={{ color: "red" }}>{error.valueError}</div>
                                                                            {values.Exchange === true ? (

                                                                                <>
                                                                                    <GridContainer>
                                                                                        <GridItem xs={6} style={{ paddingLeft: "0px" }}>
                                                                                            <Card style={{ width: "85%" }}>
                                                                                                <TextField
                                                                                                    required
                                                                                                    label="*Min Price"
                                                                                                    id="minPrice"
                                                                                                    formControlProps={{
                                                                                                        fullWidth: true
                                                                                                    }}
                                                                                                    type="number"
                                                                                                    InputProps={{
                                                                                                        inputProps: {
                                                                                                            onChange: handlePrice,
                                                                                                            min: 1,
                                                                                                            step: 0.01,
                                                                                                            defaultValue: serviceSelected.priceRange.minPrice
                                                                                                        }

                                                                                                    }}
                                                                                                    error={price.priceErrorRange}
                                                                                                    helperText={price.priceErrorRange ? 'Error! Please check your ranges and try again" ' : ''}
                                                                                                />

                                                                                            </Card>
                                                                                            You can edit parameters in Service Page.
                                                                                        </GridItem>

                                                                                        <GridItem xs={6}>
                                                                                            <Card style={{ width: "85%" }}>

                                                                                                <TextField
                                                                                                    label="*Max Price"
                                                                                                    id="maxPrice"
                                                                                                    formControlProps={{
                                                                                                        fullWidth: true
                                                                                                    }}
                                                                                                    type="number"
                                                                                                    InputProps={{
                                                                                                        inputProps: {
                                                                                                            onChange: handlePrice,
                                                                                                            min: 0.01,
                                                                                                            step: 0.01,
                                                                                                            defaultValue: serviceSelected.priceRange.maxPrice
                                                                                                        }

                                                                                                    }}
                                                                                                    error={price.priceErrorRange}
                                                                                                    helperText={price.priceErrorRange ? 'Error! Please check your ranges and try again" ' : ''}
                                                                                                />
                                                                                            </Card>

                                                                                        </GridItem>

                                                                                    </GridContainer>
                                                                                </>
                                                                            ) : null}


                                                                        </Card>
                                                                        Select all that apply.
                                                                    </GridItem>
                                                                </GridContainer>
                                                            </Card>

                                                        </DialogContent>
                                                        <DialogActions>
                                                            <Button onClick={(event) => { setShow(false); setAnchorEl(anchorEl ? null : event.currentTarget); setPopperOpen(!popperOpen) }} style={{ fontWeight: "600", color: "#FFFFFF", backgroundColor: "#FC6047", marginTop: "10px", display: "flex" }}>Cancel</Button>
                                                            <Button type="submit" style={{ fontWeight: "600", color: "#FFFFFF", backgroundColor: "#FC6047", marginTop: "10px", display: "flex" }}>
                                                                Update Edits
                                                            </Button>
                                                        </DialogActions>
                                                    </form>
                                                </Dialog>
                                            </div>


                                            <div
                                                className={classes.popperDiv}>
                                                <div className={classes.popperSelections} onClick={(event) => { setShow2(true) }}>
                                                    Edit Type(s) of Services
                                                    <EditOutlinedIcon />
                                                </div>
                                                <Dialog
                                                    open={show2}
                                                    onClose={(event) => { setShow2(false); setPopperOpen(!popperOpen); setAnchorEl(anchorEl ? null : event.currentTarget); }}
                                                    aria-labelledby="alert-inner-title"
                                                    aria-describedby="alert-inner-description"
                                                    fullWidth={true}
                                                >
                                                    <form onSubmit={(e) => saveForm(e, serviceSelected.Invoice_ID)}>
                                                        <DialogTitle id="alert-inner-title">
                                                            {"Service Form"}
                                                        </DialogTitle>
                                                        <DialogContent>
                                                            <Card login style={{ backgroundColor: "rgb(247, 243, 240)" }}>
                                                                <GridContainer justify="center" style={{ padding: "0 10px", paddingBottom: "10px", marginTop: "-10px" }}>
                                                                    <GridItem xs={12}>
                                                                        <Card style={{ paddingLeft: "20px", marginBottom: "0", marginTop: "20px" }}>
                                                                            <Multiselect
                                                                                selectedValues={serviceSelected.Types_of_Services}
                                                                                id="services"
                                                                                onSelect={onSelect}
                                                                                onRemove={onSelect}
                                                                                required={true}
                                                                                isObject={false}
                                                                                options={services}
                                                                                selectionLimit={3}
                                                                                placeholder="*Select up to 3 services"
                                                                            />
                                                                            <div style={{ color: "red" }}>{error.selectError}</div>
                                                                        </Card>
                                                                    </GridItem>
                                                                </GridContainer>
                                                            </Card>

                                                        </DialogContent>
                                                        <DialogActions>
                                                            <Button onClick={(event) => { setShow2(false); setAnchorEl(anchorEl ? null : event.currentTarget); setPopperOpen(!popperOpen) }} style={{ fontWeight: "600", color: "#FFFFFF", backgroundColor: "#FC6047", marginTop: "10px", display: "flex" }}>Cancel</Button>
                                                            <Button type="submit" style={{ fontWeight: "600", color: "#FFFFFF", backgroundColor: "#FC6047", marginTop: "10px", display: "flex" }}>
                                                                Update Edits
                                                            </Button>
                                                        </DialogActions>
                                                    </form>
                                                </Dialog>
                                            </div>
                                        </Card>
                                    </Fade>
                                )}
                            </Popper>
                        </td>
                    </tr>
                    : null
            )
        })
    }



    const renderTableHeader = () => {
        return tableHeaders.map((header, index) => {
            return <th style={{
                width: "14%", fontSize: "25px", borderBottom: "1px solid rgb(171 167 167)", background: "#AD1E5A", color: "white", textTransform: "uppercase",
                fontFamily: "Quattrocento Sans", fontWeight: "bold",
            }} key={index}>{header}
                {/* <TextField InputProps={{
              classes: {
                input: classes.textField
              }
            }}  />  */}
            </th>
        })
    }

    return (
        <div>
            {/* <GridContainer style={{
                alignItems: "center",
                justifyContent: "center", margin: "0px auto", width: "95%"
            }}> */}
            <h1 style={{ color: "#4F0E51", fontFamily: "Oleo Script Swash Caps", fontWeight: "bold" }}>Services</h1>
            <Card style={{ background: `linear-gradient(180deg, rgba(79, 14, 81, 0.93) 0%, rgba(127, 31, 106, 0.93) 100%)`, padding: "20px", paddingTop: "5px" }}>
                <GridContainer>
                    <GridItem xs={6}><h1 style={{ color: "white", fontFamily: "Quattrocento Sans", fontWeight: "bold" }}>All Services</h1></GridItem>
                    <GridItem xs={6} sm={6} md={6}>
                        <CustomInput
                            labelText="Search Services"
                            id="material"
                            formControlProps={{
                                fullWidth: true
                            }}
                            inputProps={{
                                endAdornment: (<InputAdornment position="end"><Search style={{ color: "white" }} /></InputAdornment>)
                            }}
                        />
                    </GridItem>
                </GridContainer>
                <Card style={{ marginTop: "5px" }}>
                    <table style={{ width: "100%", }}>
                        {/* <tbody> */}
                        <tr className={classes.headerRow}>{renderTableHeader()} </tr>
                        {renderTableData()}
                        {/* </tbody> */}
                    </table>
                </Card>
            </Card>
            {/* </GridContainer> */}


            <GridContainer>
                <GridItem xs={3}>
                    <Link to="/admin/services/service-form">
                        <Button style={{ height: "50px", width: "50px", fontSize: "2rem", right: "20px", bottom: "16px", position: "fixed", zIndex: "10", background: "#FFDEAB", color: "#4F0E51" }} justIcon round>+</Button>
                    </Link>
                </GridItem>
            </GridContainer>
        </div >
    );
}

ServicesPage.propTypes = {
    classes: PropTypes.object.isRequired,
}

function mapStateToProps(state) {
    return {

    };
}

function mapDispatchToProps(actions) {
    return (dispatch) => ({
    })
}

export default withRouter(compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(servicesPageStyle)
)(ServicesPage)
)