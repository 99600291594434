/* eslint-disable no-unused-vars */
import LoginPage from "views/Pages/LoginPage.js";
import RegisterPage from "views/Pages/Registration/RegisterPage.js";
import UserProfile from "views/Dashboard/Profile/UserProfile.js";
import ProfileEditor from "views/Dashboard/Profile/ProfileEditor";
import DocumentsPage from "views/Dashboard/Documents/DocumentsPage.js";
import ServicesPage from "views/Dashboard/Services/ServicesPage.js";
import MarketplacePage from "views/Dashboard/Marketplace/MarketplacePage.js";
import ActivitiesPage from "views/Dashboard/Orders/ActivitiesPage.js";
import HistoryReportsPage from "views/Dashboard/Reports/HistoryReportsPage.js";
import WalletPage from "views/Dashboard/Wallet/WalletPage.js";
import ReviewsPage from "views/Dashboard/Reviews/ReviewsPage.js";
import SettingsPage from "views/Dashboard/Settings/SettingsPage.js";
import CheckoutPage from "views/Dashboard/Payments/CheckoutPage.js"
import InvoicePage from "views/Dashboard/Invoices/InvoicePage.js";
import InvoiceDetails from "views/Dashboard/Invoices/InvoiceDetails";
import PublicInvoice from "views/Pages/Invoices/PublicInvoice";
import PublicInvoiceDetails from "views/Pages/Invoices/PublicInvoiceDetails";
import CreateInvoice from "views/Dashboard/Invoices/CreateInvoice";
import CreateEstimate from "views/Dashboard/Invoices/CreateEstimate";
import EstimateDetails from "views/Dashboard/Invoices/EstimateDetails";
import iFramePage from "views/Dashboard/Utilities/iFramePage.js";
import PublicSingleInvoice from "views/Pages/Invoices/PublicSingleInvoice";
import RequestInvoice from "views/Pages/Invoices/RequestInvoice";
import iFramePubPage from "views/Pages/Utilities/iFramePubPage";
import ExchangeServicesPage from "views/Dashboard/Exchange/ExchangeServicesPage";
import ExchangeVendorServicesPage from "views/Dashboard/Exchange/ExchangeVendorServicesPage";
import ExchangeDetailPage from "views/Dashboard/Exchange/ExchangeDetailPage.js";
import ProofOfFundsForm from "views/Forms/DocumentsForms/ProofOfFunds.js";
import ProofOfFulfillmentForm from "views/Forms/DocumentsForms/ProofOfFulfillmentForm";
import ProofOfInsuranceForm from "views/Forms/DocumentsForms/ProofOfInsuranceForm";
import ProofOfOwnershipForm from "views/Forms/DocumentsForms/ProofOfOwnershipForm";
import AddServiceForm from "views/Forms/ServicesForms/AddServiceForm";
import ServiceDetailsPage from "views/Dashboard/Services/ServiceDetailsPage";
import MarketplaceCategoryPage from "views/Dashboard/Marketplace/MarketplaceCategoryPage";
import MarketplaceDetailsPage from "views/Dashboard/Marketplace/MarketplaceDetailsPage";
import EditServiceForm from "views/Forms/ServicesForms/EditServiceForm";
import PayrollPage from "views/Dashboard/Payroll/PayrollPage";
import PayrollDetailsPage from "views/Dashboard/Payroll/PayrollDetailsPage";
import StaffRegisterstrationForm from "views/Forms/StaffRegistration/StaffRegisterstrationForm";
import TrackingPage from "views/Dashboard/Tracking/TrackingPage";
import NewRegistration from "views/Pages/Utilities/NewRegistration";
// @material-ui/icons
import Person from "@material-ui/icons/Person";
import SettingsIcon from "@material-ui/icons/Settings";
import PermMediaIcon from '@material-ui/icons/PermMedia';
import CachedIcon from '@material-ui/icons/Cached';
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
import StoreIcon from '@material-ui/icons/Store';
import CategoryIcon from '@material-ui/icons/Category';
import AssessmentIcon from '@material-ui/icons/Assessment';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import RateReviewIcon from '@material-ui/icons/RateReview';
import ReceiptIcon from "@material-ui/icons/Receipt";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import GpsFixed from "@material-ui/icons/GpsFixed";



const pagesRoutes = [
  {
    path: "/profile",
    component: UserProfile,
    layout: "/admin",
  },
  {
    path: "/user/editor",
    component: ProfileEditor,
    layout: "/admin",
  },
  {
    path: "/login-page",
    component: LoginPage,
    layout: "/auth",
  },
  {
    path: "/invoice/create-invoice",
    component: CreateInvoice,
    layout: "/admin",
  },

  {
    path: "/invoice/create-estimate",
    component: CreateEstimate,
    layout: "/admin",
  },
  {
    path: "/public-invoice/:uid/:docId",
    component: PublicSingleInvoice,
    layout: "/auth",
  },
  {
    path: "/public-invoice/:uid",
    component: PublicInvoiceDetails,
    layout: "/auth",
  },
  {
    path: "/public-invoice",
    component: PublicInvoice,
    layout: "/auth",
  },
  {
    path: "/request-invoice",
    component: RequestInvoice,
    layout: "/auth",
  },
  {
    path: "/register-page",
    component: RegisterPage,
    layout: "/auth"
  },
  {
    path: "/estimate/:id",
    component: EstimateDetails,
    layout: "/admin",
  },
  {
    path: "/invoice/:id",
    component: InvoiceDetails,
    layout: "/admin",
  },
  {
    path: "/registration",
    component: NewRegistration,
    layout: "/pages"
  },
  {
    path: "/iframe-invoice/:uid/:id",
    component: iFramePage,
    layout: "/pages"
  },
  {
    path: "/pub-iframe-invoice/:uid/:id",
    component: iFramePubPage,
    layout: "/pages"
  },
  {
    path: "/invoice",
    name: "Invoices",
    icon: ReceiptIcon,
    component: InvoicePage,
    layout: "/admin",
    role: "Vendor"
  },
  {
    path: "/services/service-form",
    component: AddServiceForm,
    layout: "/admin"
  },
  {
    path: "/services/edit/:id",
    component: EditServiceForm,
    layout: "/admin"
  },
  {
    path: "/services/:id",
    component: ServiceDetailsPage,
    layout: "/admin"
  },
  {
    path: "/services",
    name: "Services",
    icon: StoreIcon,
    component: ServicesPage,
    layout: "/admin",
    role: "Vendor"
  },
  {
    path: "/payroll/details/:id",
    component: PayrollDetailsPage,
    layout: "/admin"
  },
  {
    path: "/payroll",
    name: "Payroll",
    icon: MonetizationOnIcon,
    component: PayrollPage,
    layout: "/admin",
    role: "Vendor"
  },
  // {
  //   path: "/staff",
  //   name: "StaffTest",
  //   icon: MonetizationOnIcon,
  //   component: StaffTest,
  //   layout: "/admin",
  //   role: "Vendor"
  // },
  {
    path: "/staffsignup/:id",
    component: StaffRegisterstrationForm,
    layout: "/auth"
  },
  {
    path: "/activities",
    name: "Activities",
    icon: AddShoppingCartIcon,
    component: ActivitiesPage,
    layout: "/admin",
    role: "Vendor"
  },
  {
    path: "/marketplace/:category/:id",
    component: MarketplaceDetailsPage,
    layout: "/admin"
  },
  {
    path: "/marketplace/:category",
    component: MarketplaceCategoryPage,
    layout: "/admin"
  },
  {
    path: "/marketplace",
    name: "Marketplace",
    icon: CategoryIcon,
    component: MarketplacePage,
    layout: "/admin",
    role: "Customer"
  },

  {
    path: "/exchange/:category/:id",
    component: ExchangeDetailPage,
    layout: "/admin",
  },
  {
    path: "/exchange/:category",
    component: ExchangeVendorServicesPage,
    layout: "/admin",
  },
  {
    path: "/exchange",
    name: "Exchange",
    icon: CachedIcon,
    component: ExchangeServicesPage,
    layout: "/admin",
    role: "Customer"
  },
  {
    path: "/documents/proof-of-funds",
    component: ProofOfFundsForm,
    layout: "/admin",
  },
  {
    path: "/documents/proof-of-fulfillment",
    component: ProofOfFulfillmentForm,
    layout: "/admin",
  },
  {
    path: "/documents/proof-of-insurance",
    component: ProofOfInsuranceForm,
    layout: "/admin",
  },
  {
    path: "/documents/proof-of-ownership",
    component: ProofOfOwnershipForm,
    layout: "/admin",
  },
  {
    path: "/documents",
    name: "Documents",
    icon: PermMediaIcon,
    component: DocumentsPage,
    layout: "/admin",
  },
  {
    path: "/history-reports",
    name: "Reports",
    icon: AssessmentIcon,
    component: HistoryReportsPage,
    layout: "/admin",
  },
  {
    path: "/wallet",
    name: "Wallet",
    icon: AccountBalanceWalletIcon,
    component: WalletPage,
    layout: "/admin",
  },
  {
    path: "/reviews",
    component: ReviewsPage,
    layout: "/admin",
  },
  {
    path: "/tracking",
    name: "Tracking",
    icon: GpsFixed,
    component: TrackingPage,
    layout: "/admin",
    role: "Staff"
  },
  {
    path: "/settings",
    name: "Settings",
    icon: SettingsIcon,
    component: SettingsPage,
    layout: "/admin",
  },
];
export default pagesRoutes;
