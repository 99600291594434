/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import dotenv from 'dotenv';
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// @material-ui/icons

// core components
import { db } from 'firebase/client.js';
import image from "camera.jpg"
import compose from "recompose/compose";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import loginPageStyle from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.js";
import Style from "assets/jss/material-dashboard-pro-react/views/registerPageStyle";
import GridItem from 'components/Grid/GridItem.js';
import { CheckOutlined } from "@material-ui/icons";
import customLinearProgressStyle from "assets/jss/material-dashboard-pro-react/components/customLinearProgressStyle.js";
import { Container } from "@material-ui/core";

export function CheckoutPage(props) {
    // const [checkout, setCheckOut] = useState(false);
    const {classes, total} = props;
    const paypal = useRef();
    useEffect(() => {
        window.paypal
            .Buttons({
                style: {
                    color: 'blue',
                    shape: 'pill',
                    height: 40,
                },
                createOrder: (data, actions) => {
                    return actions.order.create({
                        intent: "CAPTURE",
                        purchase_units: [
                            {
                                description: "Camera",
                                amount: {
                                    currency_code: "USD",
                                    value: 10.00,
                                },
                            },
                        ],
                    });
                },
                onApprove: async (data, actions) => {
                    return actions.order.capture().then(function (details) {
                        // console.log(details.purchase_units[0].shipping.name.full_name);
                        //Writes payment info into database
                        let itemInfo =
                        {
                            Item: details.purchase_units[0].description,
                            Item_Price: details.purchase_units[0].amount.value,
                        }
                        let paymentInfo = {
                            Address: details.purchase_units[0].shipping.address,
                            Name: details.purchase_units[0].shipping.name.full_name,
                            Email: details.payer.email_address,
                            Item_Info: itemInfo,
                            Payment_ID: details.purchase_units[0].payments.captures[0].id,
                            Payment_Status: details.purchase_units[0].payments.captures[0].status
                        }
                        db.collection("users").doc().set(paymentInfo)
                    });
                },
                onError: (err) => {
                    console.log(err);
                }
            })
            .render(paypal.current);
    }, []);
    return (
        <div className={classes.container}>
            <center>
                <GridContainer display="flex" justify="center">

                    <GridItem>
                        <div ref={paypal}></div>
                    </GridItem>
                </GridContainer>
            </center>
        </div>

    );
}
CheckoutPage.propTypes = {
    classes: PropTypes.object.isRequired,
}

const mapStateToProps = (state) => {
    return {
        AuthBool: !!state.auth.user,
        authError: state.auth.error
    }
}

function mapDispatchToProps(actions) {
    return (dispatch) => ({

    })
}
export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(Style)
)(CheckoutPage);