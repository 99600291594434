/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import compose from "recompose/compose";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import actionsForNavigation from "store/actions/navigation";
import { db, firebase } from "firebase/client";

// @material-ui/core components
import InputAdornment from "@material-ui/core/InputAdornment";
import withStyles from "@material-ui/core/styles/withStyles";
import servicesPageStyle from "assets/jss/material-dashboard-pro-react/views/servicesPageStyles.js";
import styles from "assets/jss/material-dashboard-pro-react/cardImagesStyles.js";

// @material-ui/icons
import Search from '@material-ui/icons/Search'

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CustomInput from 'components/CustomInput/CustomInput.js';


export function ExchangeServicePage(props) {
    const { classes } = props;
    const [invoices, setInvoice] = useState([]);
    const [loading, setLoading] = useState(false);
    const [services, setServices] = useState(["Manufacturing", "Fabrication", "Construction", "Real Estate", "Title Transfer", "Ranching",
        "Agriculture", "Recruiting", "Drivers", "Hospitatlity", "Digital Advertising", "Application Design", "Software Development",
        "Shipping FTL", "Shipping LTL", "Shipping Air", "Contract Law", "Accounting", "Coporate Law", "Wood Working", "Plumbing", "Electrician", "Architecture",
        "Civil Enginerring", "3D Design", "2D Design", "3D Printing", "Influencer Marketing", "Aerospace Engineering", "Structural Engineering", "IT Managed Services",
        "Warehousing", "Hazmat Freight", "Cold Chain Freight", "Oversized Freight", "Logistics", "Factoring"]);
    const [images, setImages] = useState([])

    const getImage = async() => {
        let storageRef = await firebase.storage().ref().child('Images/').listAll();
        const urls = await Promise.all(storageRef.items.map((ref) => ref.getDownloadURL()));
        setImages(urls);
    }


    function getInvoice() {
        setLoading(true);
        db.collection('services').onSnapshot((querySnapshot) => {
            const items = [];
            querySnapshot.forEach((doc) => {
                items.push(doc.data());
            });
            setInvoice(items);
            setLoading(false);
        });
    }
    useEffect(() => {
        getInvoice();
    }, []);

    if (loading) {
        return <h1>Loading..</h1>
    }
    return (
        <div>
              <h1 style={{ color: "#4F0E51", fontFamily: "Oleo Script Swash Caps", fontWeight: "bold" }}>Exchange Services</h1>
            <Card style={{background: `linear-gradient(180deg, rgba(79, 14, 81, 0.93) 0%, rgba(127, 31, 106, 0.93) 114.48%)`, paddingLeft:"10px"}}>
                <GridContainer>

                    <GridItem xs={6} sm={6} md={6}>
                        <h2 style={{color:"white", fontFamily: "Quattrocento Sans", fontWeight:"bold"}}>Services</h2>
                    </GridItem>
                    <GridItem xs={6} sm={6} md={6} style={{marginTop: "10px"}} >
                        <CustomInput
                            labelText="Search Services"
                            id="material"
                            formControlProps={{
                                fullWidth: true,

                            }}
                            inputProps={{
                                endAdornment: (<InputAdornment position="end"><Search  style={{color:"white"}}/></InputAdornment>),
                                input:"red"
                                
                            }}
                        />
                    </GridItem>
                </GridContainer>


                <GridContainer>
                    {getImage() && services.map((service, i) => (
                        <>
                            <GridItem xs={12} sm={6} md={4} lg={3}>
                                <Card style={{ width: "20rem", height: "20rem", borderRadius: 5 }}>
                                    <img
                                        className={classes.cardImgTop}
                                        alt="100%x180"
                                        style={{ height: "180px", width: "100%", display: "block", borderRadius: 5 }}
                                        src={images[i]}
                                    />
                                    <center>
                                        <CardBody>
                                            <h4>{services.sort()[i]} </h4>
                                        </CardBody>
                                        <Link to={`/admin/exchange/${services.sort()[i]}`}>
                                            <Button color="primary" style={{ width: "80%" }}>View Category</Button>
                                        </Link>
                                    </center>
                                </Card>
                            </GridItem>
                        </>
                    ))}




                </GridContainer>
            </Card>

        </div>
    );
}

ExchangeServicePage.propTypes = {
    classes: PropTypes.object.isRequired,
}

function mapStateToProps(state) {
    return {

    };
}

function mapDispatchToProps(actions) {
    return (dispatch) => ({
    })
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(servicesPageStyle)
)(ExchangeServicePage);