import React from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// @material-ui/icons

// core components
import compose from "recompose/compose";
import { connect } from "react-redux";


import walletPageStyle from "assets/jss/material-dashboard-pro-react/views/walletPageStyles.js";

export function WalletPage(props) {
    const { classes } = props;

    return (
        <div>
            <h1 className={classes.header}>Render Reviews Page</h1>
        </div>
    );
}

WalletPage.propTypes = {
    classes: PropTypes.object.isRequired,
}

function mapStateToProps(state) {
    return {

    };
}

function mapDispatchToProps(actions) {
    return (dispatch) => ({

    })
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(walletPageStyle)
)(WalletPage);