const settingsPageStyle = {
    cardHeader: {
        fontFamily: "Quattrocento Sans;",
        fontWeight: "700",
        color: "white",
        paddingLeft: "10px",
        marginBottom: "5px"
    },
    cardLink: {
        display: "flex",
        padding: "10px",
        fontSize: "20px"
    },
    linkName: {
        // textDecoration: "underline",
        margin: "0 0 0 10px",
        cursor: "pointer"
    },
    logOutButton: {
        marginTop: "20px",
        marginBottom: "40px",
        width: "100%",
        color: "#3C4858",
        fontFamily: "Quattrocento Sans;",
        fontWeight: "bold",
        fontSize: "20px",
        backgroundColor: "#FFDEAB",
        borderRadius: "5px",
        height: "50px",
        "&:hover": {
            cursor: "pointer",
            background: "#ffdeab"
          }
    }
}

export default settingsPageStyle;