/* eslint-disable no-unused-vars */
import React from "react";
import PropTypes from "prop-types";
import compose from "recompose/compose";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

// @material-ui/core components

// @material-ui/icons

// core components
import Button from "components/CustomButtons/Button.js";
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import Card from 'components/Card/Card.js';

//styles
import withStyles from "@material-ui/core/styles/withStyles";
import Style from "assets/jss/material-dashboard-pro-react/views/settingsPageStyle";

export function DocumentsPage(props) {
    const { classes } = props;

    return (
        <div style={{ alignItems: "center" }}>
            <h1>Tracking Page</h1>
            <center>
                <h2>Integration Options</h2>
                <GridContainer >
                    <GridItem xs={12}>
                        <p className={classes.cardHeader}>Keep Trucking</p>
                        <Card style={{ width: "50%", height: "60%" }}>
                            <center>
                                <Button style={{ fontWeight: "600", color: "#FFFFFF", backgroundColor: "#FC6047", marginTop: "80px", width: "200px" }}>Connect</Button>
                            </center>
                        </Card>
                    </GridItem>
                    <GridItem xs={12}>
                        <p className={classes.cardHeader}>
                            Toggl
                        </p>

                        <Card style={{ width: "50%", height: "60%" }}>
                            <center>
                                <Button style={{ fontWeight: "600", color: "#FFFFFF", backgroundColor: "#FC6047", marginTop: "80px", width: "200px" }}>Connect</Button>
                            </center>
                        </Card>

                    </GridItem>
                </GridContainer>
            </center>
        </div>
    );
}

DocumentsPage.propTypes = {
    classes: PropTypes.object.isRequired,
}

function mapStateToProps(state) {
    return {

    };
}

function mapDispatchToProps(actions) {
    return (dispatch) => ({

    })
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(Style)
)(DocumentsPage);
