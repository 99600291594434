/* eslint-disable react/jsx-key */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import BoundEditorForm from 'components/Explorer/BoundEditorForm';
import actions from 'store/actions/forms/profile';
import accessors from 'store/reducers/forms/profile';
import EstimateForm from "views/Forms/InvoiceForms/EstimateForm";

// @material-ui/icons

// core components
import Card from "components/Card/Card";
import { db, firebase } from 'firebase/client.js';
import compose from "recompose/compose";
import { connect } from "react-redux";
import walletPageStyle from "assets/jss/material-dashboard-pro-react/views/walletPageStyles.js";
import loginPageStyle from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.js";
import { async } from "@firebase/util";

const EditorForm = BoundEditorForm(actions, accessors);
export function CreateEstimate(){
    return(
        <EstimateForm/>
    )
}

export default compose(
    // connect(mapStateToProps, mapDispatchToProps),
    withStyles(loginPageStyle)
)(CreateEstimate);