/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-key */
/* eslint-disable no-unused-vars */
import React from "react";
import PropTypes from "prop-types";
import compose from "recompose/compose";
import { connect } from "react-redux";
import Orders from "./OrdersPage";
import Requests from "./RequestsPage";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";


// @material-ui/icons

// core components
import CustomTabs from "components/CustomTabs/CustomTabs";


//styles
import walletPageStyle from "assets/jss/material-dashboard-pro-react/views/walletPageStyles.js";


export function ActivitiesPage(props) {
    const {classes} = props;
    return(
        <div className={classes.container} style = {{paddingTop: "20px"}}>
            <CustomTabs
            headerColor="rose"
            tabs={[
                {
                    tabName:"Orders",
                    tabContent:(
                        <div>
                            <Orders />
                        </div>
                    )
                },
                {
                    tabName: "Requests",
                    tabContent: (
                      <div>
                          <Requests/>
                      </div>
                    )
                  },
            ]}
            >

            </CustomTabs>
        </div>
    )
}
ActivitiesPage.propTypes = {
    classes: PropTypes.object.isRequired,
}

const mapStateToProps = (state) => {
    return {
        AuthBool: !!state.auth.user,
        authError: state.auth.error
    }
}
function mapDispatchToProps(actions) {
    return (dispatch) => ({
    })
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(walletPageStyle)
)(ActivitiesPage);