/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";

// @material-ui/icons

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardAvatar from "components/Card/CardAvatar.js";
import compose from "recompose/compose";
import { connect } from "react-redux";
import actionsForNavigation from "store/actions/navigation";

import userProfileStyle from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";

import avatar from "assets/img/faces/marc.jpg";
import { Business, PaymentSharp, Person, PlaylistPlayOutlined } from "@material-ui/icons";
import firebase from "@firebase/app";
import Email from "@material-ui/icons/Email";
import Phone from "@material-ui/icons/Phone";

export function UserProfile(props) {
    const { classes, firstName, lastName, userType, email, dateOfBirth, phoneNumber, staffType, company, payPalEmail,description } = props;
    const user = firebase.auth().currentUser.uid;
    const [show, setShow] = useState(false);
    const [files, setFiles] = useState({
        fileName: '',
        fileError: ''
    });
    const [image, setImage] = useState([])
    const onClose = () => {
        setShow(false)
    }

    const onFileChange = async (e) => {
        setFiles({
            ...files,
            [e.target.id]: e.target.files[0]
        })
    }

    const getImage = async () => {
        let storageRef = await firebase.storage().ref().child(`${user}/profilePicture`).listAll();
        const urls = await Promise.all(storageRef.items.map((ref) => ref.getDownloadURL()));
        setImage(urls);
    }
    function formatPhoneNumber(phoneNumber) {
        var cleaned = ("" + phoneNumber).replace(/\D/g, "")
        var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
        if (match) {
            return "(" + match[1] + ") " + match[2] + "-" + match[3]
        }
        return "Invalid phone number length"
    }
    firebase.auth().onAuthStateChanged(function (user) {
        if (user) {
            // console.log(user.dateOfBirth)
        }
    })
    getImage()
    return (
        <div>

            <GridContainer style={{ justifyContent: "center" }}>
                <GridItem xs={12} sm={12} md={8} >
                    <Card profile style={{ background: `linear-gradient(180deg, rgba(79, 14, 81, 0.93) 0%, rgba(142, 37, 114, 0.93) 100%)`, borderRadius: "5px" }}> 
                                <CardAvatar profile>
                                    <a >
                                        {image.length === 0?<img src={avatar} alt="..." />: <img src={image[0]} alt="..." /> }
                                        
                                    </a>
                                </CardAvatar>
                        <CardBody profile>
                            <h3 className={classes.cardTitle}>{firstName + " " + lastName}</h3>

                            <div className={classes.cardCategory}>
                                <GridContainer>
                                <GridItem xs={6}>
                                        <h6><Business style={{ marginRight: "10px", }} /> {company}</h6>
                                    </GridItem>
                                    <GridItem xs={6}>
                                        {userType === "Staff" ? <h6> <Person style={{ marginRight: "10px", }} /> {userType} : {staffType}</h6> :
                                            <h6> <Person style={{ marginRight: "10px", }} />{userType} </h6>}
                                    </GridItem>
                                    <GridItem xs={4}>
                                        <h6><Email style={{ marginRight: "10px", }} /> {email}</h6>
                                    </GridItem>
                                    <GridItem xs={4}>
                                        <h6>  <Phone style={{ marginRight: "10px", }} />  {formatPhoneNumber(phoneNumber)}</h6>
                                    </GridItem>
                                    <GridItem xs={4}>
                                        <h6> <PaymentSharp style={{ marginRight: "10px", }} />{payPalEmail}</h6>
                                    </GridItem>
                                </GridContainer>
                            </div>

                            <div className={classes.description}>
                                {/* <p>Date of birth: {dateOfBirth}</p> */}
                                <p>{description}</p>
                            </div>

                            <Button className={classes.updateProfileButton} onClick={e => props.edit()}>
                                Update Profile
                            </Button>
                            <Button
                                className={classes.addFundsButton}
                                >
                                Add Funds
                            </Button>
                            <Button
                                className={classes.paypalProfileButton}
                                onClick={e => window.location.href = 'https://www.paypal.com/us/home'}>
                                Link PayPal Account
                            </Button>
                        </CardBody>
                    </Card>
                </GridItem>
            </GridContainer>
            {/* {console.log("TESTING: ", auth.user.firstName)} */}
        </div>

    );
}

UserProfile.propTypes = {
    classes: PropTypes.object.isRequired,
}

function mapStateToProps(state) {
    console.log("TESTING STATE: ", state.auth)
    return {
        firstName: state.auth.user && state.auth.user.firstName,
        lastName: state.auth.user && state.auth.user.lastName,
        userType: state.auth.user && state.auth.user.userType,
        email: state.auth.user && state.auth.user.email,
        dateOfBirth: state.auth.user && state.auth.user.dateOfBirth,
        phoneNumber: state.auth.user && state.auth.user.phoneNumber,
        staffType: state.auth.user && state.auth.user.staffType,
        company: state.auth.user && state.auth.user.company,
        payPalEmail: state.auth.user && state.auth.user.payPalEmail,
        description: state.auth.user && state.auth.user.description
    };
}

function mapDispatchToProps(actions) {
    return (dispatch) => ({
        edit: (key) => dispatch(actionsForNavigation.openChild("Profile Editor", `/admin/user/editor`)),
    })
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(userProfileStyle),
)(UserProfile);
