import React from "react";
import PropTypes from "prop-types";
import compose from "recompose/compose";
import { connect } from "react-redux";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

// @material-ui/icons
import MailOutline from "@material-ui/icons/MailOutline";
import Check from "@material-ui/icons/Check";

// core components
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";

import exchangePageStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";

export function BidForm(props) {
    const [checked, setChecked] = React.useState([24, 22]);
    const { classes } = props;

    const handleToggle = value => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }
        setChecked(newChecked);
    };

    return (
        <div>
            <Card>
                <CardHeader color="rose" icon>
                    <CardIcon color="rose">
                        <MailOutline />
                    </CardIcon>
                    <h4 className={classes.cardIconTitle}>Bid Form</h4>
                </CardHeader>
                <CardBody>
                    <form>
                        <CustomInput
                            labelText="First Input"
                            id="first_input"
                            formControlProps={{
                                fullWidth: true
                            }}
                            inputProps={{
                                type: "text"
                            }}
                        />
                        <CustomInput
                            labelText="Second Input"
                            id="second_input"
                            formControlProps={{
                                fullWidth: true
                            }}
                            inputProps={{
                                type: "text",
                                autoComplete: "off"
                            }}
                        />
                        <div className={classes.checkboxAndRadio}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        tabIndex={-1}
                                        onClick={() => handleToggle(2)}
                                        checkedIcon={<Check className={classes.checkedIcon} />}
                                        icon={<Check className={classes.uncheckedIcon} />}
                                        classes={{
                                            checked: classes.checked,
                                            root: classes.checkRoot
                                        }}
                                    />
                                }
                                classes={{
                                    label: classes.label,
                                    root: classes.labelRoot
                                }}
                                label="Confirm this Bid"
                            />
                        </div>
                        <Button color="rose">Submit</Button>
                    </form>
                </CardBody>
            </Card>
        </div>
    );
}

BidForm.propTypes = {
    classes: PropTypes.object.isRequired,
}

function mapStateToProps(state) {
    return {

    };
}

function mapDispatchToProps(actions) {
    return (dispatch) => ({

    })
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(exchangePageStyle)
)(BidForm);