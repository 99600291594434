/* eslint-disable no-unused-vars */
/* eslint-disable no-extra-boolean-cast */
import { getFirestoreData, updateFirestoreWithOneObject } from 'firebase/datasource.js'
import { startSetUser, updateUser } from 'store/actions/profileActions.jsx'
import moment from "moment"
import { db, firebase } from "firebase/client.js"

export const setFirstAction = (user) => {
    let username
    !!user.displayName ? (username = user.displayName) : (
        username = user.email.substring(0, user.email.indexOf("@"))
    )
    // create a first action
    let actionData = {
        status: "assigned",
        volUID: user.uid,
        title: "Take the Assessment",
        description: "Click Assess Tab and take the assessment",
        dueDate: new Date(),
        days: 1,
        points: 100,
        updatedAt: new Date()
    }

    // create userData
    let userData = {
        username: username,
        email: user.email,
        score: 0,
        hideData: false,
        groups: [],
        termGroups: []
    }

    db.collection("TEST-actions-list").add(actionData)
        .then(() => {
            //setUserScore to 0    
            updateUser(userData, user.uid)
        }).catch((err) => {
            console.log(`error ${err}`)
        })
}

export const startSetActionsList = (userID) => {
    // Get firestore Data and convert it to tableData format  
    return (dispatch) => {
        getFirestoreData("TEST-actions-list", userID)
            .then(data => {
                const dataRows = data.map(row => {
                    let dueDate
                    if (typeof row.dueDate === 'string') {
                        dueDate = row.dueDate
                    } else {
                        dueDate = moment(row.dueDate.toDate()).format('MM/DD/YYYY')
                    }

                    let dataObj = {
                        actionId: row.id,
                        title: row.title,
                        description: row.description,
                        dueDate,
                        days: row.days,
                        points: row.points,
                        status: row.status
                    };

                    if (!!row.comment) {
                        dataObj = {
                            ...dataObj,
                            comment: row.comment
                        }
                    }

                    return dataObj
                })
                dispatch(setActionsList(dataRows))
            })
    }
}

export const setActionsList = (dataRows) => ({
    type: 'SET_ACTIONS_LIST',
    dataRows
})


export const startSetPendingActionsList = () => {
    // Get firestore Data and convert it to tableData format  
    return (dispatch) => {
        // Get all pending Actions
        db.collection("TEST-actions-list").where("status", "==", "pending")
            .get().then((snapshot) => {
                const data = []
                // Parse data into array like in firebase.js
                snapshot.forEach((doc) => {
                    var docObj = {
                        ...doc.data(),
                        id: doc.id
                    }
                    data.push(docObj);
                })
                return (data)
            }, (error) => {
                console.log('error fetching data: ', error)
            }).then(data => {
                const dataRows = data.map(row => {
                    let dueDate
                    if (typeof row.dueDate === 'string') {
                        dueDate = row.dueDate
                    } else {
                        dueDate = moment(row.dueDate.toDate()).format('MMMM Do')
                    }

                    let dataObj = {
                        volUID: row.volUID,
                        actionId: row.id,
                        title: row.title,
                        description: row.description,
                        comment: row.comment,
                        dueDate,
                        days: row.days,
                        points: row.points
                    };

                    if (!!row.comment) {
                        dataObj = {
                            ...dataObj,
                            comment: row.comment
                        }
                    }

                    return dataObj
                })

                dispatch(setPendingActionsList(dataRows))
            })
    }
}

export const setPendingActionsList = (dataRows) => ({
    type: 'SET_PENDING_ACTIONS_LIST',
    dataRows
})

// Get Users for Leaderboard and Profile
export const startSetUsersList = (user) => {
    const UID = user.uid
    let userIndex
    return (dispatch) => {
        db.collection("TEST-users").orderBy("score", "desc").get()
            .then((snapshot) => {
                const data = []
                // Parse data into array like in firebase.js
                snapshot.forEach((doc) => {
                    var docObj = {
                        ...doc.data()
                    }
                    // put index of user to userIndex and pass user info to startSetUser      
                    if (doc.id == UID) {
                        userIndex = data.length
                        dispatch(startSetUser(docObj, user))
                    }

                    data.push([docObj.username, docObj.score]);
                })

                dispatch(setUsersList(data, userIndex))
            })
    }
}

export const setUsersList = (tableData, userIndex) => ({
    type: 'SET_USERS_LIST',
    tableData,
    userIndex
})

export const setUserScore = (score, uid) => {
    const userObject = {
        score: score
    }
    db.collection("TEST-users").doc(uid).set({
        ...userObject,
        updatedAt: new Date()
    }, { merge: true }).then(() => {
        window.location.reload()
    }).catch((err) => {
        return (`Update TEST-users with id: ${uid} with error: ${err}`)
    })
}

export const resetActionsData = () => ({
    type: 'RESET_ACTIONS_DATA'
})

