/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-key */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { Link, useParams } from "react-router-dom";
import actionsForNavigation from "store/actions/navigation";
import { db, firebase } from 'firebase/client.js';
import compose from "recompose/compose";
import { connect } from "react-redux";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { register } from "serviceWorker.js";
import { set } from "immutable";
import Multiselect from 'multiselect-react-dropdown';
import NumberFormat from 'react-number-format';
import Invoices from "./Invoices";
import Estimates from "./Estimates";
import Archived from "./Archived";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import { IconButton, Menu, MenuItem, ListItemIcon } from "@material-ui/core";
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import Checkbox from "@material-ui/core/Checkbox";
import TextField from "@material-ui/core/TextField";

// @material-ui/icons
import MoreVert from "@material-ui/icons/MoreVert";
import { Cancel, ViewHeadline, Check, Schedule, DoubleArrow, Info, Block, SettingsEthernet, ChangeHistory } from "@material-ui/icons";

// core components
import CustomTabs from "components/CustomTabs/CustomTabs";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

//styles
import walletPageStyle from "assets/jss/material-dashboard-pro-react/views/walletPageStyles.js";
import { primaryCardHeader } from "assets/jss/material-dashboard-pro-react";


export function InvoicePage(props) {
    const {classes} = props;
    return(
        <div className={classes.container} style = {{paddingTop: "20px"}}>
            <CustomTabs
            headerColor="rose"
            tabs={[
                {
                    tabName:"Invoices",
                    tabContent:(
                        <div>
                            <Invoices />
                        </div>
                    )
                },
                {
                    tabName: "Estimates",
                    tabContent: (
                      <div>
                          <Estimates/>
                      </div>
                    )
                  },
                  {
                    tabName: "Recurring",
                    tabContent: (
                      <p className={classes.textCenter}>
                        I think that’s a responsibility that I have, to push
                        possibilities, to show people, this is the level that
                        things could be at. I will be the leader of a company
                        that ends up being worth billions of dollars, because
                        I got the answers. I understand culture. I am the
                        nucleus. I think that’s a responsibility that I have,
                        to push possibilities, to show people, this is the
                        level that things could be at.
                      </p>
                    )
                  },
                  {
                    tabName: "Archived",
                    tabContent: (
                      <div>
                      <Archived/>
                  </div>
                    )
                  },
                
            ]}
            >

            </CustomTabs>
        </div>
    )
}
InvoicePage.propTypes = {
    classes: PropTypes.object.isRequired,
}

const mapStateToProps = (state) => {
    return {
        AuthBool: !!state.auth.user,
        authError: state.auth.error
    }
}
function mapDispatchToProps(actions) {
    return (dispatch) => ({
    })
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(walletPageStyle)
)(InvoicePage);