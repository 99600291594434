/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-key */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { Link, useParams } from "react-router-dom";
import { db, firebase } from 'firebase/client.js';
import compose from "recompose/compose";
import { connect } from "react-redux";
import actionsForNavigation from "store/actions/navigation";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import { IconButton, Menu, MenuItem, ListItemIcon } from "@material-ui/core";
import Popper from "@material-ui/core/Popper";

// @material-ui/icons
import MoreVertOutlinedIcon from '@material-ui/icons/MoreVertOutlined';
import { Close, Info } from "@material-ui/icons";

// core components
import Card from "components/Card/Card";
import Button from "components/CustomButtons/Button.js";

//style 
import Style from "assets/jss/material-dashboard-pro-react/views/registerPageStyle";
import servicesPageStyle from "assets/jss/material-dashboard-pro-react/views/servicesPageStyles.js";


export function InvoicePage(props, { handleLogout }) {
    const [invoices, setInvoice] = useState([]);
    const [loading, setLoading] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [popperOpen, setPopperOpen] = useState(false)
    const [currentInvoice, setCurrentInvoice] = useState({});
    const { uid } = useParams();
    const ref = firebase.firestore().collection("users").doc(uid).collection("invoices");
    const { classes } = props;


    function getInvoice() {
        setLoading(true);
        ref.onSnapshot((querySnapshot) => {
            const items = [];
            querySnapshot.forEach((doc) => {
                items.push(doc.data());
            });
            setInvoice(items);
            setLoading(false);
        });
    }
    useEffect(() => {
        getInvoice();
    }, []);
    return (
        <div className={classes.container}>
                <h2 style={{textAlign:"center"}}>Invoices</h2>
                {/* <GridContainer justify="center"> */}
                {invoices.map((invoice, i) => (
                    <Card style={{ width: "95%", justify: "center", fontSize: "17px", paddingTop: "10px", paddingBottom: "10px", paddingLeft: "10px", margin: "20px" }}>
                        <div style={{ display: "flex", flexDirection: "row", whiteSpace:"noWrap" }}>
                            <p><strong>Date Created: </strong>{invoice.Date}</p>
                            <p style={{ flex: "1", marginLeft: "1rem" }}><strong>Invoice ID: </strong> {invoice.Invoice_ID}</p>
                            <p style={{ flex: "1", marginLeft: "1.5rem" }}><strong>Email: </strong> {invoice.Email}</p>
                            <p style={{ flex: "1" }}><strong>Status: </strong> {invoice.Payment_Status}</p>
                            <div style={{ position: 'absolute', right: 0 }}>
                                <MoreVertOutlinedIcon onClick={(event) => {

                                    setPopperOpen(!popperOpen)
                                    // console.log("popperOpen 3 dots: ", popperOpen)
                                    setAnchorEl(anchorEl ? null : event.currentTarget);
                                    // console.log("anchorEl 3 dots: ", anchorEl)
                                    setCurrentInvoice(invoices[i])
                                    // setShow(false)
                                }} />
                            </div>
                            <Popper style={!anchorEl ? { display: "none" } : { zIndex: 1000 }} placement={'left'} id={'transitions-popper'} open={popperOpen} anchorEl={anchorEl}>
                                <Card style={{ width: "154px", display: "flex" }}>
                                    <Button
                                        onClick={(event) => {
                                            setAnchorEl(anchorEl ? null : event.currentTarget);
                                            console.log("anchorEl", anchorEl)
                                            setPopperOpen(!popperOpen)
                                            console.log("popperOpen: ", popperOpen)
                                        }}
                                        simple
                                        className={classes.modalCloseButton}
                                        key="close"
                                        aria-label="Close"
                                    >
                                        {""}
                                        <Close className={classes.modalClose} />
                                    </Button>
                                    <div className={classes.popperDiv}>
                                        <div className={classes.popperSelections}>
                                            <Link to={`/auth/public-invoice/${currentInvoice.uID}/${currentInvoice.Invoice_ID}`} style={{ color: "black" }}>
                                                View Invoice
                                            </Link>
                                            <Info />
                                        </div>

                                    </div>
                                </Card>
                            </Popper>

                        </div>
                        <p style={{ flex: "1" }}><strong>Due Date: </strong> {invoice.dueDate}</p>
                    </Card>
                ))}
                {/* </GridContainer> */}

        </div>

    );
}
InvoicePage.propTypes = {
    classes: PropTypes.object.isRequired,
}

const mapStateToProps = (state) => {
    return {
        AuthBool: !!state.auth.user,
        authError: state.auth.error
    }
}
function mapDispatchToProps(actions) {
    return (dispatch) => ({
        // edit: (key) => dispatch(actionsForNavigation.openChild("View Detailed Invoice", `/admin/invoice/${id}`)),
        // edit: (key) => dispatch(actionsForNavigation.openChild("View Detailed Invoice", `admin/invoice/create`))
    })
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(Style)
)(InvoicePage);