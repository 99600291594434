/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-key */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import compose from "recompose/compose";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { db, firebase } from 'firebase/client.js'
import Multiselect from 'multiselect-react-dropdown';
import InitiatePayrollDialog from "./InitiatePayrollDialog";
import { actionsForDialog } from 'store/actions/dialog';
import avatar from "assets/img/faces/marc.jpg";
// @material-ui/core components
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import Fade from '@material-ui/core/Fade';
import Popper from "@material-ui/core/Popper";
import Slide from '@material-ui/core/Slide';
// @material-ui/icons
import MoreVertOutlinedIcon from '@material-ui/icons/MoreVertOutlined';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import Close from "@material-ui/icons/Close";


// core components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardAvatar from "components/Card/CardAvatar.js";
import Button from "components/CustomButtons/Button.js";


//styles
import withStyles from "@material-ui/core/styles/withStyles";
import servicesPageStyle from "assets/jss/material-dashboard-pro-react/views/servicesPageStyles";
import { title } from "assets/jss/material-dashboard-pro-react";
import { AttachFileOutlined, DeleteOutlineOutlined, Info } from "@material-ui/icons";

const tableHeaders = ["First Name", "Last Name", "Company", "Job Title", "Email", "Phone", "Status", "Rate", "Actions"];
const initiateActions = actionsForDialog("initiate")
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
export function Payroll(props) {
    const { classes, openInitiation } = props;
    const [image, setImage] = useState([])
    const [invoices, setInvoice] = useState([]);
    const [loading, setLoading] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [popperOpen, setPopperOpen] = useState(false);
    const [show, setShow] = useState(false);
    const [staff, setStaff] = useState({});
    const [showEdit, setShowEdit] = useState(false)
    const [showFile, setShowFile] = useState(false)
    const [comp, setComp] = useState('')
    const [error, setError] = useState('')
    const [info, setInfo] = useState({
        fName: '',
        lName: '',
        title: '',
        email: '',
        number: '',
        firstNameErr: '',
        lastNameErr: '',
        emailErr: '',
        titleErr: '',
        numberErr: '',
    })
    const [show2, setShow2] = useState(false);
    const [payroll, setPayRoll] = useState({
        firstName: '',
        lastName: '',
        company: '',
        jobTitle: '',
        email: '',
        number: '',
        status: '',
        firstNameError: '',
        lastNameError: '',
        companyError: '',
        jobTitleError: '',
        emailError: '',
        statusError: '',
    })
    const [file, setFile] = useState('')
    const [fileErr, setFileErr] = useState('')
    const [selectErr, setSelectErr] = useState('')
    const [select, setSelect] = useState('');
    const options = ["Hourly", "Salary", "Mileage"]
    const uID = firebase.auth().currentUser.uid;

    const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const numberReg = /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})\s*$/;


    const onChange = (e) => {
        setInfo({
            ...info,
            [e.target.id]: e.target.value
        })
    }

    const onInitiationChange = (e) => {
        setPayRoll({
            ...payroll,
            [e.target.id]: e.target.value
        })
    }

    const onFileChange = (e) => {
        setFile(e.target.files[0])
    }

    const onSelect = (e) => {
        setSelect(e)
    }

    function generateUniqueFirestoreId() {
        // Alphanumeric characters
        const chars =
            'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let autoId = '';
        for (let i = 0; i < 20; i++) {
            autoId += chars.charAt(Math.floor(Math.random() * chars.length));
        }

        return autoId;
    }
    function getInvoice() {
        setLoading(true);
        db.collection('users').doc(uID).collection('staffs').onSnapshot((querySnapshot) => {
            const items = [];
            querySnapshot.forEach((doc) => {
                items.push(doc.data());
            });
            setInvoice(items);
            setLoading(false);
        });
        db.collection('users').doc(uID).get().then((queryResult) => {
            const vendorInfo = [];
            vendorInfo.push(queryResult.data());
            setComp(vendorInfo[0].company)
            // console.log("vendor", vendorInfo)
        })
    }
    useEffect(() => {
        getInvoice();
    }, []);

    if (loading) {
        return <h1>Loading..</h1>
    }

    const renderTableHeader = () => {
        return tableHeaders.map((header, index) => {
            return <th style={{
                width: "14%", fontSize: "25px", borderBottom: "1px solid rgb(171 167 167)", background: "#AD1E5A", color: "white", textTransform: "uppercase",
                fontFamily: "Quattrocento Sans", fontWeight: "bold",
            }} key={index}>{header}
                {/* <TextField InputProps={{
              classes: {
                input: classes.textField
              }
            }}  />  */}
            </th>
        })
    }

    const renderTableData = () => {
        return invoices.map((invoice, i) => {
            // console.log(invoice)
            return (
                <tr>
                    <td className={classes.tableCell} >
                        {invoice.Name.firstName}
                    </td>
                    <td className={classes.tableCell}>
                        {invoice.Name.lastName}
                    </td>
                    <td className={classes.tableCell}>
                        {comp}
                    </td>
                    <td className={classes.tableCell}>
                        {invoice.jobTitle}

                    </td>
                    <td className={classes.tableCell}>
                        {invoice.email}

                    </td>
                    <td className={classes.tableCell}>
                        {invoice.phoneNumber ? (
                            invoice.phoneNumber
                        ) : (
                            "N/A"
                        )}

                    </td>
                    <td className={classes.tableCell}>
                        {invoice.status}
                    </td>
                    <td className={classes.tableCell}>
                        {invoice.rate}
                    </td>
                    <td className={classes.tableCell}>
                        <MoreVertOutlinedIcon onClick={(event) => {
                            setInfo({
                                ...info,
                                fName: invoices[i].Name.firstName,
                                lName: invoices[i].Name.lastName,
                                title: invoices[i].jobTitle,
                                email: invoices[i].email,
                                number: invoices[i].phoneNumber
                            })
                            setStaff(invoices[i])
                            setSelect(invoices[i].rate)
                            setPopperOpen(!popperOpen)
                            setAnchorEl(anchorEl ? null : event.currentTarget);
                            setShowEdit(false);
                        }} />
                        <Popper style={!anchorEl ? { display: "none" } : { zIndex: 1000 }} placement={'right'} id={'transitions-popper'} open={popperOpen} anchorEl={anchorEl} transition>
                            {({ TransitionProps }) => (
                                <Fade {...TransitionProps} >
                                    <Card style={{ width: "154px", display: "flex" }}>
                                        <Button
                                            onClick={(event) => {
                                                setAnchorEl(anchorEl ? null : event.currentTarget);
                                                setPopperOpen(!popperOpen)
                                            }}
                                            simple
                                            className={classes.modalCloseButton}
                                            key="close"
                                            aria-label="Close"
                                        >
                                            {" "}
                                            <Close className={classes.modalClose} />
                                        </Button>
                                        <div className={classes.popperDiv}>
                                            <div className={classes.popperSelections}>
                                                Disable Staff Acc
                                                <DeleteOutlineOutlined />
                                            </div>
                                        </div>
                                        <div className={classes.popperDiv}>
                                            <div className={classes.popperSelections} onClick={() => { setShowEdit(true) }}>
                                                Edit Info
                                                <EditOutlinedIcon />
                                            </div>
                                            <Dialog
                                                open={showEdit}
                                                onClose={(event) => { onCloseEdit(); setPopperOpen(!popperOpen); setAnchorEl(anchorEl ? null : event.currentTarget); }}
                                                aria-labelledby="alert-dialog-title"
                                                aria-describedby="alert-dialog-description"
                                            >
                                                <form onSubmit={(e) => onUpdate(e, staff.staffID)}>
                                                    <DialogTitle id="alert-dialog-title">
                                                        {"Edit staff member information"}
                                                    </DialogTitle>
                                                    <DialogContent>
                                                        {/* <DialogContentText id="alert-dialog-description">
                                                            Edit staff member information
                                                        </DialogContentText> */}
                                                        <Card login style={{ backgroundColor: "rgb(247, 243, 240)" }}>
                                                            <p style={{ paddingLeft: "10px", paddingTop: "5px" }}>*Required</p>
                                                            <GridContainer justify="center" style={{ padding: "0 10px" }}>
                                                                {/* <CardBody style={{ paddingBottom: "0px"}}> */}
                                                                <GridItem xs={4}>
                                                                    <Card >

                                                                        <TextField
                                                                            label="*First Name"
                                                                            id="fName"
                                                                            formControlProps={{
                                                                                fullWidth: true
                                                                            }}
                                                                            InputProps={{
                                                                                inputProps: {
                                                                                    onChange: onChange,
                                                                                    defaultValue: staff.Name.firstName
                                                                                }

                                                                            }}
                                                                            error={info.firstNameErr}
                                                                            helperText={info.firstNameErr ? 'Error! Input is invalid.' : ''}
                                                                        />
                                                                    </Card>
                                                                </GridItem>
                                                                <GridItem xs={4}>
                                                                    <Card >

                                                                        <TextField
                                                                            label="*Last Name"
                                                                            id="lName"
                                                                            formControlProps={{
                                                                                fullWidth: true,

                                                                            }}
                                                                            InputProps={{
                                                                                inputProps: {
                                                                                    onChange: onChange,
                                                                                    defaultValue: staff.Name.lastName
                                                                                }

                                                                            }}
                                                                            error={info.lastNameErr}
                                                                            helperText={info.lastNameErr ? 'Error! Input is invalid.' : ''}
                                                                        />
                                                                    </Card>
                                                                </GridItem>

                                                                <GridItem xs={4}>
                                                                    <Card >

                                                                        <TextField
                                                                            label="*Job Title"
                                                                            id="title"
                                                                            formControlProps={{
                                                                                fullWidth: true,

                                                                            }}
                                                                            InputProps={{
                                                                                inputProps: {
                                                                                    onChange: onChange,
                                                                                    defaultValue: staff.jobTitle
                                                                                }

                                                                            }}
                                                                            error={info.titleErr}
                                                                            helperText={info.titleErr ? 'Error! Input is invalid.' : ''}
                                                                        />
                                                                    </Card>
                                                                </GridItem>
                                                                <GridItem xs={4}>
                                                                    <Card >

                                                                        <TextField
                                                                            label="*Email"
                                                                            id="email"
                                                                            type="email"
                                                                            formControlProps={{
                                                                                fullWidth: true,

                                                                            }}
                                                                            InputProps={{
                                                                                inputProps: {
                                                                                    defaultValue: staff.email,
                                                                                    onChange: onChange
                                                                                    // readOnly: true
                                                                                }

                                                                            }}
                                                                            error={info.emailErr}
                                                                            helperText={info.emailErr ? 'Error! Input is invalid.' : ''}
                                                                        />
                                                                    </Card>
                                                                </GridItem>

                                                                <GridItem xs={4}>
                                                                    <Card >

                                                                        <TextField
                                                                            label="Phone Number"
                                                                            id="number"
                                                                            formControlProps={{
                                                                                fullWidth: true,

                                                                            }}
                                                                            InputProps={{
                                                                                inputProps: {
                                                                                    onChange: onChange,
                                                                                    defaultValue: staff.phoneNumber
                                                                                }

                                                                            }}
                                                                            error={info.numberErr}
                                                                            helperText={info.numberErr ? 'Error! Input is invalid.' : ''}
                                                                        />
                                                                    </Card>
                                                                </GridItem>

                                                                <GridItem xs={4}>
                                                                    <Card style={{ marginBottom: "0", marginTop: "20px" }}>

                                                                        <Multiselect
                                                                            selectedValues={staff.rate}
                                                                            id="staffType"
                                                                            onSelect={onSelect}
                                                                            onRemove={onSelect}
                                                                            required={true}
                                                                            isObject={false}
                                                                            options={options}
                                                                            selectionLimit={1}
                                                                            placeholder="*Select one option"
                                                                        />
                                                                        <div style={{ color: "red" }}>{selectErr}</div>
                                                                        {/* {console.log("testing select err:", selectErr)} */}
                                                                    </Card>
                                                                </GridItem>


                                                            </GridContainer>
                                                        </Card>
                                                    </DialogContent>
                                                    <DialogActions>
                                                        <Button onClick={(event) => { onCloseEdit(); setAnchorEl(anchorEl ? null : event.currentTarget); setPopperOpen(!popperOpen) }} autoFocus style={{ fontWeight: "600", color: "#FFFFFF", backgroundColor: "#FC6047", marginTop: "10px", display: "flex" }}>
                                                            Cancel
                                                        </Button>
                                                        <Button type="submit" autoFocus style={{ fontWeight: "600", color: "#FFFFFF", backgroundColor: "#FC6047", marginTop: "10px", display: "flex" }}>
                                                            Update
                                                        </Button>
                                                    </DialogActions>
                                                </form>
                                            </Dialog>

                                        </div>
                                        <div className={classes.popperDiv}>
                                            <div className={classes.popperSelections}>
                                                <Link to={`/admin/payroll/details/${staff.staffID}`} style= {{color:"black"}}>
                                                View Staff Logs
                                                </Link>
                                                <Info />
                                            </div>
                                        </div>
                                        <div className={classes.popperDiv}>
                                            <div className={classes.popperSelections} onClick={() => { setShowFile(true) }}>
                                                Add Document
                                                <AttachFileOutlined />
                                            </div>
                                            <Dialog
                                                open={showFile}
                                                onClose={(event) => { onCloseFile(); setPopperOpen(!popperOpen); setAnchorEl(anchorEl ? null : event.currentTarget); }}
                                                aria-labelledby="alert-dialog-title"
                                                aria-describedby="alert-dialog-description"
                                            >
                                                <form onSubmit={(e) => onUpload(e, uID, staff.staffID)}>
                                                    <DialogTitle id="alert-dialog-title">
                                                        {"Upload staff documents"}
                                                    </DialogTitle>
                                                    <DialogContent>
                                                        {/* <DialogContentText id="alert-dialog-description">
                                                            Upload staff documents
                                                        </DialogContentText> */}
                                                        <Card login style={{ backgroundColor: "rgb(247, 243, 240)" }}>
                                                            {/* <p style={{ paddingLeft: "10px", paddingTop: "5px" }}>*Required</p> */}
                                                            <GridContainer justify="center" style={{ padding: "0 10px" }}>
                                                                {/* <CardBody style={{ paddingBottom: "0px"}}> */}
                                                                <GridItem>
                                                                    <Card >

                                                                        <TextField
                                                                            label="File"
                                                                            id="file"
                                                                            formControlProps={{
                                                                                fullWidth: true
                                                                            }}
                                                                            InputProps={{
                                                                                inputProps: {
                                                                                    onChange: onFileChange,
                                                                                    type: "file"
                                                                                }

                                                                            }}
                                                                            error={fileErr}
                                                                            helperText={fileErr ? 'Error! Input is invalid.' : ''}
                                                                        />
                                                                        {/* <div style={{ color: "red" }}>{fileErr}</div> */}
                                                                    </Card>
                                                                </GridItem>


                                                            </GridContainer>
                                                        </Card>
                                                    </DialogContent>
                                                    <DialogActions>
                                                        <Button onClick={(event) => { onCloseFile(); setAnchorEl(anchorEl ? null : event.currentTarget); setPopperOpen(!popperOpen) }} autoFocus style={{ fontWeight: "600", color: "#FFFFFF", backgroundColor: "#FC6047", marginTop: "10px", display: "flex" }}>
                                                            Cancel
                                                        </Button>
                                                        <Button type="submit" autoFocus style={{ fontWeight: "600", color: "#FFFFFF", backgroundColor: "#FC6047", marginTop: "10px", display: "flex" }}>
                                                            Upload
                                                        </Button>
                                                    </DialogActions>
                                                </form>
                                            </Dialog>
                                        </div>



                                    </Card>
                                </Fade>
                            )}
                        </Popper>
                    </td>


                </tr>
            )
        })
    }
    // console.log('email test: ', email)
    const validate = async () => {
        let firstNameErr = '';
        let lastNameErr = '';
        let titleErr = '';
        let numberErr = '';
        let check = true;

        if (!emailRegex.test(info.email) || !info.email) {
            check = false
            setError("INVALID INPUT")
        }
        await firebase.auth().fetchSignInMethodsForEmail(info.email)
            .then((methods) => {
                if (methods.length) {
                    check = false
                    setError("USER ALREADY EXISTS")


                }
            })
        if (!info.fName) {
            firstNameErr = "Invalid input."
        }
        if (!info.lName) {
            lastNameErr = "Invalid input."
        }
        if (!info.title) {
            titleErr = "Invalid input."
        }
        // if(!numberReg.test(info.number)){
        //     numberErr = "Invalid input."
        // }
        if (firstNameErr || lastNameErr || titleErr) {
            setInfo({
                ...info,
                firstNameErr,
                lastNameErr,
                titleErr,
                // numberErr
            })
            check = false;
        }
        console.log("check outside: ", check)
        return check


    }

    const checkEmpty = () => {
        if (select == '') {
            setSelectErr("Must have one selected.")
            return true
        }
        return false
    }

    const validateUpload = () => {
        console.log("checking name: ", file)
        if (!file) {
            setFileErr("Error! No file selected.");
            return false
        }
        return true
    }

    const onUpload = async (e, userID, staffID) => {
        e.preventDefault();
        const fileExist = validateUpload();
        console.log(file)
        if (fileExist) {
            console.log("success")

            const fileName = file;
            const storageRef = firebase.storage().ref(`Documents/${userID}/staffs/${staffID}`);
            const fileRef = storageRef.child(fileName.name)
            await fileRef.put(fileName)
                .then(() => {
                    window.location.reload();
                })
        }
        else {
            console.log("error")
        }
    }

    const validateUpdate = () => {
        if (!emailRegex.test(info.email) || !info.email) {
            setInfo({
                ...info,
                emailErr: "Invalid input"
            })
            return false
        }
        if (!info.fName) {
            setInfo({
                ...info,
                firstNameErr: "Invalid input"
            })
            return false
        }
        if (!info.lName) {
            setInfo({
                ...info,
                lastNameErr: "Invalid input"
            })
            return false
        }
        if (!info.title) {
            setInfo({
                ...info,
                titleErr: "Invalid input"
            })
            return false
        }
        if (!numberReg.test(info.number)) {
            setInfo({
                ...info,
                numberErr: "Invalid input."
            })
            return false
        }
        return true
    }

    const onUpdate = async (e, staffID) => {
        e.preventDefault();
        const isValid = await validateUpdate();
        const isEmpty = checkEmpty();
        // console.log(info)
        if (isValid && !isEmpty) {
            console.log("success")
            await db.collection('users').doc(uID).collection('staffs').doc(staffID).update({
                Name: {
                    firstName: info.fName,
                    lastName: info.lName
                },
                jobTitle: info.title,
                email: info.email,
                phoneNumber: info.number,
                rate: select,
            })
                .then(() => {
                    window.location.reload();
                })

        }
        else {
            console.log("error")
        }
    }
    const onSend = async (e) => {
        e.preventDefault();
        console.log("checking info values", info)
        const isValid = await validate();
        const isEmpty = checkEmpty();
        let docID = generateUniqueFirestoreId();
        console.log("Printing validate option: ", isValid)

        if (isValid === true && !isEmpty) {
            console.log("do something with send grid api")
            setError('')
            await db.collection('users').doc(uID).update({
                sendEmail: true,
                staffEmail: info.email,
                newStaffID: docID
            })
                //creates a temporary doc in order to create a unique id for '/admin/staffsign/:id'
                // this tempoarary doc will late be deleted and a new doc will be created with docid = uid
                // this is needed to load info when staff goes to register
                .then(async () => {
                    await db.collection('users').doc(docID).set({
                        Name: {
                            firstName: info.fName,
                            lastName: info.lName
                        },
                        email: info.email,
                        jobTitle: info.title,
                        phoneNumber: info.number,
                        vendorID: uID,

                    })
                })

                //creates a temporary doc in the subcollection staffs to help display information 
                //*especially for the status header
                .then(async () => {
                    await db.collection('users').doc(uID).collection('staffs').doc(docID).set({
                        Name: {
                            firstName: info.fName,
                            lastName: info.lName
                        },
                        email: info.email,
                        jobTitle: info.title,
                        phoneNumber: info.number,
                        vendorID: uID,
                        newStaffID: docID,
                        rate: select,
                        status: "Pending"

                    })
                })
                .then(async () => {
                    console.log("Email has been sent")
                    await db.collection('users').doc(uID).update({
                        sendEmail: false
                    })
                    window.location.reload();
                })
        }
        else {

            console.log("error with email")
        }
    }

    const onClose = () => {
        setShow(false);
        setError('');
        setInfo({
            ...info,
            fName: '',
            lName: '',
            title: '',
            email: '',
            number: '',
            firstNameErr: '',
            lastNameErr: '',
            titleErr: '',
            numberErr: '',
        })
        setSelect('')
        setSelectErr('')
    }

    const onCloseInitation = () => {
        setShow2(false);
        setPayRoll({
            firstName: '',
            lastName: '',
            company: '',
            jobTitle: '',
            email: '',
            number: '',
            status: '',
            firstNameError: '',
            lastNameError: '',
            companyError: '',
            jobTitleError: '',
            emailError: '',
            statusError: '',
        })
    }

    const onCloseEdit = () => {
        setShowEdit(false);
        setError('');
        setInfo({
            ...info,
            fName: '',
            lName: '',
            title: '',
            email: '',
            number: '',
            firstNameErr: '',
            lastNameErr: '',
            titleErr: '',
            numberErr: '',
        })
        setSelect('')
        setSelectErr('')
    }

    const onCloseFile = () => {
        setShowFile(false);
        setFileErr('')
        setFile({})

    }

    const validatePayroll = () => {
        let firstNameError = "";
        let lastNameError = "";
        let companyError = "";
        let jobTitleError = "";
        let emailError = "";
        let statusError = "";

        if (!payroll.firstName) {
            firstNameError = "Invalid input."
        }
        if (!payroll.lastName) {
            lastNameError = "Invalid input."
        }
        if (!payroll.company) {
            companyError = "Invalid input"
        }
        if (!payroll.jobTitle) {
            jobTitleError = "Invalid input"
        }
        if (!emailRegex.test(payroll.email)) {
            emailError = "Invalid input"
        }
        if (!payroll.status) {
            statusError = "Invalid input"
        }

        if (firstNameError || lastNameError || companyError ||
            jobTitleError || emailError || statusError) {
            setPayRoll({
                ...payroll,
                firstNameError,
                lastNameError,
                companyError,
                jobTitleError,
                emailError,
                statusError
            })
            return false
        }
        return true
    }

    const onSubmitPayroll = async (e) => {
        e.preventDefault()
        const isValid = validatePayroll()

        if (isValid) {
            console.log("success")
        }
        else {
            console.log("error")
        }

    }
    const getImage = async () => {
        const user = firebase.auth().currentUser.uid;
        let storageRef = await firebase.storage().ref().child(`${user}/profilePicture`).listAll();
        const urls = await Promise.all(storageRef.items.map((ref) => ref.getDownloadURL()));
        setImage(urls);
    }
    getImage()


    return (
        <div>

            <GridContainer style={{
                alignItems: "center",
                margin: "0px auto", width: "95%",
            }}>
                <GridItem>
                    <h1 style={{ color: "#4F0E51", fontFamily: "Oleo Script Swash Caps", fontWeight: "bold", marginBottom: "-50px" }}>Payroll</h1>
                </GridItem>

                <GridContainer style={{ justifyContent: "center" }}>
                    <GridItem >
                        <Button onClick={() => setShow2(true)} style={{ fontSize: "16px", fontFamily: "Quattrocento Sans", fontWeight: "bold", color: "#FFFFFF", background: `linear-gradient(0deg, #8E2572, #8E2572), #8E2572`, width: "200px" }}>
                            Initiate Payroll
                        </Button>
                    </GridItem>
                    <Dialog
                        open={show2}
                        // onClose={onClose}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                        maxWidth="md">
                        <form onSubmit={onSubmitPayroll} style={{ background: `linear-gradient(180deg, rgba(79, 14, 81, 0.93) 0%, rgba(127, 31, 106, 0.93) 100%)` }}>
                            {/* <CardAvatar profile> */}
                            <a >
                                {image.length === 0 ? <img className={classes.profilePic} src={avatar} alt="..." /> : <img className={classes.profilePic} src={image[0]} alt="..." />}

                            </a>
                            {/* </CardAvatar> */}
                           
                            <DialogTitle id="alert-dialog-title" style={{ color: "white", fontFamily: "Quattrocento Sans", textAlign: "center" }}>
                                Please fill out the information below
                            </DialogTitle>
                            <DialogContent>
                                <GridContainer style={{ justifyContent: "center" }}>
                                    <GridItem xs={6} style={{ marginTop: "-20px" }}>
                                        <Card >
                                            <TextField
                                                label="First Name"
                                                id="firstName"
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                                inputProps={{
                                                    onChange: onInitiationChange,
                                                    // disableUnderline: true,
                                                }}
                                                error={payroll.firstNameError}
                                                helperText={payroll.firstNameError ? 'Error! Invalid input.' : ''}
                                            />

                                        </Card>

                                    </GridItem>
                                    <GridItem xs={6} style={{ marginTop: "-20px" }}>
                                        <Card>
                                            <TextField
                                                label="Last Name"
                                                id="lastName"
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                                inputProps={{
                                                    onChange: onInitiationChange,
                                                    disableUnderline: true,
                                                }}
                                                error={payroll.lastNameError}
                                                helperText={payroll.lastNameError ? 'Error! Invalid input.' : ''}
                                            />

                                        </Card>

                                    </GridItem>
                                    <GridItem xs={6} style={{ marginTop: "-20px" }}>
                                        <Card>
                                            <TextField
                                                label="Company"
                                                id="company"
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                                inputProps={{
                                                    onChange: onInitiationChange,
                                                    disableUnderline: true,
                                                }}
                                                error={payroll.companyError}
                                                helperText={payroll.companyError ? 'Error! Invalid input.' : ''}
                                            />

                                        </Card>

                                    </GridItem>
                                    <GridItem xs={6} style={{ marginTop: "-20px" }}>
                                        <Card>
                                            <TextField
                                                label="Job Title"
                                                id="jobTitle"
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                                inputProps={{
                                                    onChange: onInitiationChange,
                                                    disableUnderline: true,
                                                }}
                                                error={payroll.jobTitleError}
                                                helperText={payroll.jobTitleError ? 'Error! Invalid input.' : ''}
                                            />

                                        </Card>

                                    </GridItem>
                                    <GridItem xs={4} style={{ marginTop: "-20px" }}>
                                        <Card>
                                            <TextField
                                                label="Email"
                                                id="email"
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                                inputProps={{
                                                    onChange: onInitiationChange,
                                                    disableUnderline: true,
                                                }}
                                                error={payroll.emailError}
                                                helperText={payroll.emailError ? 'Error! Invalid input.' : ''}
                                            />

                                        </Card>

                                    </GridItem>
                                    <GridItem xs={4} style={{ marginTop: "-20px" }}>
                                        <Card>
                                            <TextField
                                                label="Phone (optional)"
                                                id="number"
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                                inputProps={{
                                                    onChange: onInitiationChange,
                                                    disableUnderline: true,
                                                }}
                                            />

                                        </Card>

                                    </GridItem>
                                    <GridItem xs={4} style={{ marginTop: "-20px" }}>
                                        <Card>
                                            <TextField
                                                label="Status"
                                                id="status"
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                                inputProps={{
                                                    onChange: onInitiationChange,
                                                    disableUnderline: true,
                                                }}
                                                error={payroll.statusError}
                                                helperText={payroll.statusError ? 'Error! Invalid input.' : ''}
                                            />

                                        </Card>

                                    </GridItem>
                                </GridContainer>

                            </DialogContent>
                            <Button onClick={onCloseInitation} className={classes.cancelButton} >
                                Cancel
                            </Button>
                            <Button type="submit" className={classes.submitButton} >
                                Send
                            </Button>

                        </form>

                    </Dialog>
                    {/* <br /> <br /> */}
                    <Button style={{ fontSize: "16px", fontFamily: "Quattrocento Sans", fontWeight: "bold", color: "#FFFFFF", backgroundColor: "#FC6047", width: "200px" }}
                        onClick={(e) => { setShow(true) }}>
                        Invite staff to sign up
                    </Button>
                </GridContainer>
                <Dialog
                    open={show}
                    onClose={onClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"

                >
                    <form onSubmit={onSend} style={{ background: `linear-gradient(180deg, rgba(79, 14, 81, 0.93) 0%, rgba(127, 31, 106, 0.93) 100%)` }}>
                        <DialogTitle id="alert-dialog-title" style={{ color: "white", fontFamily: "Quattrocento Sans" }}>
                            Staff Registration
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description" style={{ color: "white" }}>
                                Send an email invitation to a staff member
                            </DialogContentText>
                            <GridContainer >
                                <GridItem xs={4}>
                                    <Card >

                                        <TextField
                                            label="*First Name"
                                            id="fName"
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            InputProps={{
                                                inputProps: {
                                                    onChange: onChange
                                                }

                                            }}
                                            error={info.firstNameErr}
                                            helperText={info.firstNameErr ? 'Error! Input is invalid.' : ''}
                                        />
                                    </Card>
                                </GridItem>
                                <GridItem xs={4}>
                                    <Card >

                                        <TextField
                                            label="*Last Name"
                                            id="lName"
                                            formControlProps={{
                                                fullWidth: true,

                                            }}
                                            InputProps={{
                                                inputProps: {
                                                    onChange: onChange
                                                }

                                            }}
                                            error={info.lastNameErr}
                                            helperText={info.lastNameErr ? 'Error! Input is invalid.' : ''}
                                        />
                                    </Card>
                                </GridItem>

                                <GridItem xs={4}>
                                    <Card >

                                        <TextField
                                            label="*Job Title"
                                            id="title"
                                            formControlProps={{
                                                fullWidth: true,

                                            }}
                                            InputProps={{
                                                inputProps: {
                                                    onChange: onChange
                                                }

                                            }}
                                            error={info.titleErr}
                                            helperText={info.titleErr ? 'Error! Input is invalid.' : ''}
                                        />
                                    </Card>
                                </GridItem>
                                <GridItem xs={4}>
                                    <Card >

                                        <TextField
                                            label="*Email"
                                            id="email"
                                            type="email"
                                            formControlProps={{
                                                fullWidth: true,

                                            }}
                                            InputProps={{
                                                inputProps: {
                                                    // defaultValue: invoice.email,
                                                    onChange: onChange
                                                    // readOnly: true
                                                }

                                            }}
                                            error={error}
                                            helperText={error === "INVALID INPUT" ? 'Error! Input is invalid.' : error === "USER ALREADY EXISTS" ? 'Error! this email has already been used' : ''}
                                        />
                                    </Card>
                                </GridItem>

                                <GridItem xs={4}>
                                    <Card >

                                        <TextField
                                            label="Phone Number"
                                            id="number"
                                            formControlProps={{
                                                fullWidth: true,

                                            }}
                                            InputProps={{
                                                inputProps: {
                                                    onChange: onChange
                                                }

                                            }}
                                        // error={info.numberErr}
                                        // helperText={info.numberErr ? 'Error! Input is invalid.' : ''}
                                        />
                                    </Card>
                                </GridItem>
                                <GridItem xs={4}>
                                    <Card style={{ marginBottom: "0", marginTop: "20px" }}>

                                        <Multiselect
                                            id="staffType"
                                            onSelect={onSelect}
                                            required={true}
                                            isObject={false}
                                            options={options}
                                            selectionLimit={1}
                                            placeholder="*Select one option"
                                        />
                                        <div style={{ color: "red" }}>{selectErr}</div>
                                        {/* {console.log("testing select err:", selectErr)} */}
                                    </Card>
                                </GridItem>
                            </GridContainer>
                            <div style={{ display: "flex", justifyContent: "space-between", paddingTop: "15px" }}>
                                <Button onClick={onClose} className={classes.cancelButton}>
                                    Cancel
                                </Button>
                                <Button type="submit" className={classes.submitButton} >
                                    Send
                                </Button>
                            </div>
                        </DialogContent>
                    </form>
                </Dialog>

            </GridContainer>
            <GridContainer style={{
                alignItems: "center",
                justifyContent: "center", margin: "0px auto", width: "95%"
            }}>
                <Card style={{ background: `linear-gradient(180deg, rgba(79, 14, 81, 0.93) 0%, rgba(127, 31, 106, 0.93) 100%)`, padding: "20px", paddingTop: "5px" }}>
                    <GridContainer>
                        <GridItem xs={6}><h1 style={{ color: "white", fontFamily: "Quattrocento Sans", fontWeight: "bold" }}>All Staff </h1></GridItem>
                        {/* <GridItem xs={6} sm={6} md={6}>
                            <CustomInput
                                labelText="Search Services"
                                id="material"
                                formControlProps={{
                                    fullWidth: true
                                }}
                                inputProps={{
                                    endAdornment: (<InputAdornment position="end"><Search style={{color:"white"}}/></InputAdornment>)
                                }}
                            />
                        </GridItem> */}
                    </GridContainer>
                    <Card style={{ marginTop: "5px" }}>
                        <table style={{ width: "100%", }}>
                            {/* <tbody> */}
                            <tr className={classes.headerRow}>{renderTableHeader()} </tr>
                            {renderTableData()}
                            {/* </tbody> */}
                        </table>
                    </Card>
                </Card>
            </GridContainer>
        </div>
    );
}

Payroll.propTypes = {
    classes: PropTypes.object.isRequired,
}

function mapStateToProps(state) {
    return {

    };
}

function mapDispatchToProps(actions) {
    return (dispatch) => ({
        openInitiation: () => dispatch(initiateActions.openDialog())
    })
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(servicesPageStyle)
)(Payroll);
