import React, { useState } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import { connect } from "react-redux";
import compose from "recompose/compose";
import { db, firebase } from 'firebase/client.js'
import BoundDialog from 'components/Dialog/BoundDialog';
import { actionsForDialog } from 'store/actions/dialog';
import { getAccessorsForDialog } from 'store/reducers/dialog';
import { DialogActions, DialogContent, DialogContentText, DialogTitle } from "@material-ui/core";
import Card from "components/Card/Card.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import { TextField } from '@material-ui/core';
import Style from 'assets/jss/kordspace-template/views/components/integrationDialogStyle';

const Actions = actionsForDialog("integration");
const Accessors = getAccessorsForDialog("dialog", "integration");
const Dialog = BoundDialog(Actions, Accessors);


export function IntegrationDialog(props) {
    let { classes, closeDialog } = props;
    const uID = firebase.auth().currentUser.uid;
    const [info, setInfo] = useState({
        api: '',
        apiError: ''
    })
    const [open, setOpen] = useState(true);
    const onClose = () => {
        setOpen(false)
    }
    const onChange = (e) => {
        setInfo({
            ...info,
            [e.target.id]: e.target.value
        })
    }

    const onSubmit = async (e) => {
        e.preventDefault()
        if (!info.api) {
            setInfo({
                ...info,
                apiError: "Invalid input."
            })
            console.log("error")
        }
        else {
            console.log("works")
            db.collection('users').doc(uID).update({
                apiKey: info.api
            })
                .then(() => {
                    window.location.reload();
                })

        }

    }
    return (
        <Dialog name="integration" {...props}>
            <form onSubmit={onSubmit}>
                <DialogTitle id="alert-dialog-title" style={{ color: "white", fontFamily: "Quattrocento Sans", textAlign:"center" }}>
                    Please enter your Clickup API key below 
                </DialogTitle>
                <DialogContent>
                    <GridContainer justify="center" style={{ padding: "0 10px" }}>
                        <GridItem xs={12}>
                            <Card>
                                <TextField
                                    // label="*Please enter your API Key"
                                    id="api"
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    inputProps={{
                                        onChange: onChange,
                                        disableUnderline: true,
                                    }}
                                    error={info.apiError}
                                    helperText={info.apiError ? 'Error! Invalid input.' : ''}
                                />

                            </Card>

                        </GridItem>
                        <Button type="submit" className={classes.submitButton}>
                            Upload
                        </Button>
                    </GridContainer>
                </DialogContent>
                {/* <DialogActions>
                    <Button className={classes.cancelButton} onClick={(e) => onClose}>
                        Cancel
                    </Button>
           
                    
                </DialogActions> */}
            </form>

        </Dialog>
    );
}

// function mapStateToProps() {
//     return (state) => {
//         return {
//             uid: state.auth.uid
//         }
//     };
// }

// function mapDispatchToProps(actions){
//     return (dispatch) => {
//         return {
//             closeDialog: () => dispatch(actions.closeDialog()),
//         };
//     }
// }

// export default compose(
//     connect(mapStateToProps, mapDispatchToProps),
//     withStyles(Style)
// )(IntegrationDialog);

export default withStyles(Style)(IntegrationDialog);